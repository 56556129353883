import React, { useState, useEffect, useRef } from "react";
import {
  CardContainer,
  CenterFlexContainer,
  MainContainer,
  ErrorContainer,
  SuccessContainer,
  DesktopOnly,
  MobileOnly,
  GridContainer,
  TextButton,
  LightText,
  AnchorText,
  StyledTextField,
} from "../../Global";

//Components
// import TextField from "../../components/ui/TextField";
import styled from "styled-components";

import avatar from "../../assets/avatar.svg";
import Modal from "react-modal";

import {
  Container,
  FlexBoxContainer,
  Text,
  DeleteIcon,
  ScrollableContainer,
} from "./MainPages.elements";
import HeaderCard from "../../components/ui/HeaderCard";
import SearchField from "../../components/ui/SearchField";

import {
  Heading,
  StyledTable,
  StyledTD,
  StyledTR,
  StyledTH,
  EditIcon,
  TableWrapper,
  TableHeader,
  BtnWrap,
} from "./MainPages.elements";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/ui/Button";
import NoRecords from "../../components/ui/NoRecords";
import { parseRole } from "../../utils/parseSchedule";
import SimpleUserCard from "./Users/helpers/SimpleUserCard";

//Image compressor
import Upload from "../../utils/compress";

import { useScrollWithShadow } from "../../hooks/useScrollShadow";
import { useSelector } from "react-redux";
import { auth } from "../../reduxSlices/authSlice";
import {
  useDeleteProfilePictureMutation,
  useGetReferralCodeQuery,
  useGetSingleUserQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
} from "../../api/userEndpoints/usersEndpoint";
import Shimmering from "../../components/ui/CardShimmering";

export const ProfileImage = styled.div`
  border-radius: 50%;
  height: 150px;
  width: 150px;
  overflow: hidden;
  margin: auto;
  border: 5px solid ${(props) => props.theme.colors.borderColor};
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

function Profile() {
  Modal.setAppElement("#root");
  const authData = useSelector(auth);
  const navigate = useNavigate();
  //rtk query
  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery();
  const { data: refCode, isLoading: isRefCodeLoading } =
    useGetReferralCodeQuery();

  console.log(refCode);
  const {
    data: singleUser,
    refetch: refetchSingleUser,
    isLoading: isSingleUserLoading,
  } = useGetSingleUserQuery(authData?.user);

  const [deleteProfilePicture, { isLoading: isDeleteProfilePicLoading }] =
    useDeleteProfilePictureMutation();
  const [updateUser, { isLoading: isUpdateUserLoading }] =
    useUpdateUserMutation();

  const { boxShadow, onScrollHandler } = useScrollWithShadow();

  const [isEditing, setEditing] = useState(false);

  const [usersData, setUsers] = useState(users || []);

  const [userName, setUserName] = useState(singleUser?.name);
  const [userEmail, setUserEmail] = useState(singleUser?.email);
  const [userPhone, setUserPhone] = useState(singleUser?.phone_number);

  const [imgError, setImgError] = useState("");
  const [imgSuccess, setImgSuccess] = useState("");

  const [query, setQuery] = useState("");

  const [modalState, setModal] = useState(false);

  useEffect(() => {
    setUsers(users);
  }, [users]);
  useEffect(() => {
    setUserName(singleUser?.name);
    setUserEmail(singleUser?.email);
    setUserPhone(singleUser?.phone_number);
  }, [isEditing]);

  const filteredItems = users?.filter((user) => {
    return (
      user.name.toLowerCase().includes(query.toLowerCase()) ||
      user.email.toLowerCase().includes(query.toLowerCase())
    );
  });
  useEffect(() => {
    setUsers(filteredItems);
  }, [query]);

  function createUserRow(props, i) {
    return (
      <StyledTR key={i}>
        <StyledTD>{props.name}</StyledTD>
        <StyledTD>{props.roles.map(parseRole).join(", ")}</StyledTD>
        <StyledTD>{props.email}</StyledTD>
      </StyledTR>
    );
  }

  function createUserMobileCard(props, i) {
    return <SimpleUserCard key={i} cardColumn="50px 1fr " users={props} />;
  }

  function UserDataTile(props) {
    return (
      <CenterFlexContainer style={{ width: "100%" }}>
        <Container style={{ width: "100%" }}>
          <CenterFlexContainer
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <LightText>
              <strong>{props.field}</strong>
            </LightText>
            <LightText style={{ margin: "8px 0" }}>
              {props.fieldValue}
            </LightText>
          </CenterFlexContainer>
          <hr
            style={{
              width: "100%",
              backgroundColor: "#eee",
              border: "none",
              height: "2px",
              margin: " 0",
            }}
          ></hr>
        </Container>
      </CenterFlexContainer>
    );
  }

  async function handleUpdateProfile() {
    try {
      await updateUser({
        id: authData?.user,
        data: {
          email: userEmail,
          name: userName,
          phone_number: userPhone,
          id: authData?.user,
          roles: authData?.roles,
        },
      });

      setEditing(false);
    } catch (err) {
      console.log(err);
    }
  }
  //modal
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      margin: "auto",
      transform: "translate(-50%, -50%)",
      borderRadius: "20px",
      width: "70%",

      zIndex: "",
      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24)",
    },
    overlay: { zIndex: "1000" },
  };

  function openAdminModal() {
    setModal(true);
  }
  function closeAdminModal() {
    setModal(false);
  }

  function getAdminRows(props, i) {
    return (
      <StyledTR key={i}>
        <StyledTD>{props.name}</StyledTD>
        <StyledTD>{props.email}</StyledTD>
        <StyledTD>{props.phone_number}</StyledTD>
      </StyledTR>
    );
  }

  //remove profile pic
  async function removeProfilePic() {
    try {
      await deleteProfilePicture();

      setImgSuccess("");
      setImgSuccess("Profile Picture successfully deleted");
    } catch (err) {
      console.log(err);
      setImgSuccess("");
      setImgError("Something went wrong, Please try again.");
    }
  }

  const closeProfileUpdate = () => {
    setEditing(false);
  };

  function copyRefrralToClipboard() {
    navigator.clipboard.writeText(
      `https://app.curus.co.in/signup?referral=${refCode?.code}`
    );

    alert("Referral Link copied to your clipboard!");
  }
  if (isUsersLoading || isSingleUserLoading || isRefCodeLoading) {
    return <Shimmering />;
  } else {
    return (
      <MainContainer>
        <Modal
          isOpen={modalState}
          onRequestClose={closeAdminModal}
          contentLabel="Example Modal"
          style={customStyles}
        >
          <Heading>Your Hospital Admin</Heading>
          <DesktopOnly>
            <StyledTable>
              <TableHeader>
                <StyledTR>
                  <StyledTH>Name</StyledTH>
                  <StyledTH>Email ID</StyledTH>
                  <StyledTH>Phone No.</StyledTH>
                </StyledTR>
              </TableHeader>
              <tbody>{users.map(getAdminRows)}</tbody>
            </StyledTable>
          </DesktopOnly>
          <MobileOnly>
            <ScrollableContainer
              onScroll={onScrollHandler}
              style={{
                boxShadow,
              }}
            >
              {usersData?.map(createUserMobileCard)}
            </ScrollableContainer>
          </MobileOnly>
        </Modal>

        <DesktopOnly>
          <GridContainer
            margin="0 1.5rem 1.5rem 1.5rem"
            align="flex-start"
            columns="2fr 3fr"
          >
            <CardContainer>
              <HeaderCard
                title="Profile Details"
                icon={isEditing ? "" : <EditIcon />}
                onclick={() => setEditing(true)}
              ></HeaderCard>
              <CenterFlexContainer
                style={{ flex: "1", justifyContent: "center" }}
              >
                {isEditing ? (
                  <GridContainer margin="1rem 0" rgap="1rem">
                    <StyledTextField
                      label="Full Name"
                      name="userName"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                    <StyledTextField
                      label="Email"
                      name="userEmail"
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                    />
                    <StyledTextField
                      label="Mobile Number"
                      name="userPhone"
                      value={userPhone}
                      onChange={(e) => setUserPhone(e.target.value)}
                      disabled
                    />

                    <Text>
                      Please reach out to your{" "}
                      <a href="#" onClick={openAdminModal}>
                        Admin
                      </a>
                      &nbsp; if you need to change your Role.
                    </Text>
                    <BtnWrap style={{ justifyContent: "space-between" }}>
                      <TextButton onClick={closeProfileUpdate}>
                        Cancel
                      </TextButton>
                      <Button
                        isloading={isUpdateUserLoading}
                        disabled={isUpdateUserLoading}
                        label="Update Profile"
                        onclick={handleUpdateProfile}
                      />
                    </BtnWrap>
                  </GridContainer>
                ) : (
                  <Container style={{ flex: "1" }}>
                    <GridContainer style={{ position: "relative" }}>
                      {singleUser?.profile_picture && (
                        <DeleteIcon
                          disabled={isDeleteProfilePicLoading}
                          onClick={() => removeProfilePic()}
                          style={{
                            position: "absolute",
                            top: "-28px",
                            right: "-28px",
                          }}
                        />
                      )}
                      <ProfileImage>
                        <Img
                          src={
                            singleUser?.profile_picture
                              ? singleUser?.profile_picture
                              : avatar
                          }
                        />
                      </ProfileImage>
                      {!singleUser?.profile_picture && (
                        <Upload
                          setImgError={setImgError}
                          setImgSuccess={setImgSuccess}
                          refetchSingleUser={refetchSingleUser}
                        />
                      )}
                    </GridContainer>
                    <UserDataTile field="Name" fieldValue={singleUser?.name} />
                    <UserDataTile
                      field="Email"
                      fieldValue={singleUser?.email}
                    />
                    <UserDataTile
                      field="Phone Number"
                      fieldValue={singleUser?.phone_number}
                    />
                    <UserDataTile
                      field="Role"
                      fieldValue={singleUser?.roles?.map(parseRole).join()}
                    />
                    <UserDataTile
                      field=" Referral Code"
                      fieldValue={refCode?.code}
                    />
                    <GridContainer columns="1fr auto" margin="1rem 0 0 0">
                      <AnchorText onClick={copyRefrralToClipboard}>
                        Copy Invite Link
                      </AnchorText>
                    </GridContainer>
                  </Container>
                )}
              </CenterFlexContainer>
            </CardContainer>
            <CardContainer>
              <GridContainer columns="1fr" width="100%">
                <Heading>Hospital Directory</Heading>
                <GridContainer
                  width="100%"
                  justify="space-between"
                  margin="0 0 1rem 0"
                  columns="1fr auto"
                >
                  <Text>
                    To view Doctors section please visit{" "}
                    <AnchorText onClick={() => navigate("/doctors")}>
                      Manage Doctor
                    </AnchorText>{" "}
                    on the Home Page.
                  </Text>
                  <SearchField
                    onchange={(e) => setQuery(e.target.value)}
                    value={query}
                    label="Search Users Here"
                  ></SearchField>
                </GridContainer>

                {usersData?.length === 0 ? (
                  <NoRecords message="No records matching your query" />
                ) : (
                  <TableWrapper>
                    <StyledTable>
                      <TableHeader>
                        <StyledTR>
                          <StyledTH>Name </StyledTH>
                          <StyledTH>Role </StyledTH>
                          <StyledTH>Email </StyledTH>
                        </StyledTR>
                      </TableHeader>
                      <tbody>{usersData?.map(createUserRow)}</tbody>
                    </StyledTable>
                  </TableWrapper>
                )}
              </GridContainer>
            </CardContainer>
          </GridContainer>
        </DesktopOnly>
        <MobileOnly>
          <GridContainer margin="0.2rem" columns="1fr" rgap="0.2rem">
            <CardContainer>
              <HeaderCard
                title="Profile Details"
                icon={isEditing ? "" : <EditIcon />}
                onclick={() => setEditing(true)}
              ></HeaderCard>
              <CenterFlexContainer
                style={{ flex: "1", justifyContent: "start" }}
              >
                {isEditing ? (
                  <GridContainer margin="1rem 0" rgap="1rem">
                    <StyledTextField
                      label="Full Name"
                      name="userName"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                    <StyledTextField
                      label="Email"
                      name="userEmail"
                      value={userEmail}
                      onChange={(e) => setUserEmail(e.target.value)}
                    />
                    <StyledTextField
                      label="Mobile Number"
                      name="userPhone"
                      value={userPhone}
                      onChange={(e) => setUserPhone(e.target.value)}
                    />

                    <Text>
                      Please reach out to your{" "}
                      <a href="#" onClick={openAdminModal}>
                        Admin
                      </a>{" "}
                      if you need to change your Role.
                    </Text>
                    <BtnWrap style={{ justifyContent: "end" }}>
                      <Button
                        isloading={isUpdateUserLoading}
                        disabled={isUpdateUserLoading}
                        label="Update Profile"
                        onclick={handleUpdateProfile}
                      />
                    </BtnWrap>
                  </GridContainer>
                ) : (
                  <Container style={{ flex: "1" }}>
                    <GridContainer style={{ position: "relative" }}>
                      {singleUser?.profile_picture && (
                        <DeleteIcon
                          disabled={isDeleteProfilePicLoading}
                          onClick={() => removeProfilePic()}
                          style={{
                            position: "absolute",
                            top: "-28px",
                            right: "-28px",
                          }}
                        />
                      )}
                      <ProfileImage>
                        <Img
                          src={
                            singleUser?.profile_picture
                              ? singleUser?.profile_picture
                              : avatar
                          }
                        />
                      </ProfileImage>
                      {!singleUser?.profile_picture && (
                        <Upload
                          setImgError={setImgError}
                          setImgSuccess={setImgSuccess}
                          refetchSingleUser={refetchSingleUser}
                        />
                      )}
                    </GridContainer>
                    <UserDataTile field="Name" fieldValue={singleUser?.name} />
                    <UserDataTile
                      field="Email"
                      fieldValue={singleUser?.email}
                    />
                    <UserDataTile
                      field="Phone Number"
                      fieldValue={singleUser?.phone_number}
                    />
                    <UserDataTile
                      field="Role"
                      fieldValue={singleUser?.roles?.map(parseRole).join()}
                    />
                    <UserDataTile
                      field="Referral Code"
                      fieldValue={refCode?.code}
                    />
                  </Container>
                )}
              </CenterFlexContainer>
            </CardContainer>
            <CardContainer>
              <Heading>Hospital Directory</Heading>
              <FlexBoxContainer style={{ alignItems: "center" }}>
                <Text>
                  To view Doctors section please visit{" "}
                  <Link to="/doctors">Manage Doctor</Link> on the Home Page.
                </Text>
                <SearchField
                  width="100%"
                  onchange={(e) => setQuery(e.target.value)}
                  value={query}
                  label="Search Users Here"
                ></SearchField>
              </FlexBoxContainer>
              <ScrollableContainer
                onScroll={onScrollHandler}
                style={{
                  boxShadow,
                }}
              >
                {usersData?.map(createUserMobileCard)}
              </ScrollableContainer>
            </CardContainer>
          </GridContainer>
        </MobileOnly>
      </MainContainer>
    );
  }
}

export default Profile;
