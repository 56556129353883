import React from "react";
import { Shimmer, ShimmerCard, ShimmerLine } from "./ui.elements";
function CardShimmering() {
  return (
    <ShimmerCard>
      <ShimmerLine width="30%"></ShimmerLine>
      <ShimmerLine></ShimmerLine>
      <ShimmerLine></ShimmerLine>
      <ShimmerLine width="90%"></ShimmerLine>
      <Shimmer></Shimmer>
    </ShimmerCard>
  );
}

export default CardShimmering;
