import axios from "../api/axiosApi";
import { useDispatch } from "react-redux";
import { setCredential } from "../reduxSlices/authSlice";
import useAuth from "./useAuth";
import jwt_decode from "jwt-decode";


const REFRESH_TOKEN_URL = "/auth/login/refresh/";

const useRefreshToken = () => {
  const dispatch = useDispatch();
  const { setAuth } = useAuth();
  
  const refresh = async () => {
    const response = await axios.post(
      REFRESH_TOKEN_URL,

      { withCredentials: true }
    );
    var decoded = jwt_decode(response.data.access);
    // console.log(response.data.access);
    setAuth((prev) => {
      return {
        ...prev,
        accessToken: response.data.access,
        userId: decoded.user_id,
        fullName: decoded.name,
        roles: decoded.user_roles,
        applicationStatus: decoded.status,
        isSubscribed: decoded.is_subscribed,
      };
    });

    dispatch(setCredential({ ...response.data }));

    return response.data.access;
  };
  return refresh;
};

export default useRefreshToken;
