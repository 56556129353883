//Constants
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    transform: "translate(-50%, -50%)",
    height: "500px",
    padding: "0",
    overflow: "auto",
    borderRadius: "8px",
  },
  overlay: { zIndex: "1000", backgroundColor: "#00000099" },
};
export const availabilityModalStyles = {
  content: {
    width: "fit-content",
    minWidth: "280px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    transform: "translate(-50%, -50%)",

    padding: "1.5rem",
    overflow: "visible",
    borderRadius: "8px",
  },
  overlay: { zIndex: "100", backgroundColor: "#00000099" },
};
export const darkCustomStyles = {
  content: {
    backgroundColor: "#222",
    top: "50%",
    border: "0px solid transparent",
    left: "50%",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    transform: "translate(-50%, -50%)",
    height: "500px",
    padding: "1.5rem",
    overflow: "auto",
    borderRadius: "8px",
  },
  overlay: { zIndex: "1000", backgroundColor: "#00000099" },
};
export const darkAvailabilityModalStyles = {
  content: {
    backgroundColor: "#222",
    width: "max-content",
    border: "0px solid transparent",
    top: "15%",
    // left: "50%",
    // right: "auto",
    // bottom: "auto",
    margin: "auto",
    // transform: "translate(-50%, -50%)",
    position: "relative",
    padding: "1.5rem",
    overflow: "visible",
    borderRadius: "8px",
  },
  overlay: { zIndex: "100", backgroundColor: "#00000099", border: "none" },
};

export const generateInvoiceModalStyles = {
  content: {
    margin: "auto",
    height: "min-content",
    width: "max-content",
    padding: "1.5rem",
    overflow: "auto",
    borderRadius: "8px",
    postion: "relative",
  },
  overlay: { zIndex: "1000", backgroundColor: "#00000099" },
};

export const markVisitModalStyles = {
  content: {
    margin: "auto",
    height: "min-content",
    width: "min-content",
    padding: "1.5rem",
    overflow: "auto",
    borderRadius: "8px",
    postion: "relative",
  },
  overlay: { zIndex: "1000", backgroundColor: "#00000099" },
};

export const InvoiceModalStyles = {
  content: {
    margin: "auto",
    aspectRatio: "1.414/1",
    maxHeight: "90vh",
    padding: "0",
    overflow: "auto",
    borderRadius: "8px",
    postion: "relative",
  },
  overlay: { zIndex: "1000", backgroundColor: "#00000099" },
};

export const darkGenerateInvoiceModalStyles = {
  content: {
    backgroundColor: "#B2BEB5",
    margin: "auto",
    height: "min-content",
    width: "max-content",
    padding: "2rem",
    overflow: "auto",
    borderRadius: "8px",
    postion: "relative",
  },
  overlay: { zIndex: "1000", backgroundColor: "#00000099", border: "none" },
};

export const darkMarkVisitModalStyles = {
  content: {
    backgroundColor: "#222",
    margin: "auto",
    border: "0px solid transparent",
    height: "min-content",
    width: "min-content",
    padding: "2rem",
    overflow: "auto",
    borderRadius: "8px",
    postion: "relative",
  },
  overlay: { zIndex: "1000", backgroundColor: "#00000099", border: "none" },
};

export const darkInvoiceModalStyles = {
  content: {
    backgroundColor: "#B2BEB5",
    margin: "auto",
    aspectRatio: "1.414/1",
    maxHeight: "90vh",
    padding: "0",
    overflow: "auto",
    borderRadius: "8px",
    postion: "relative",
  },
  overlay: { zIndex: "1000", backgroundColor: "#00000099" },
};

//constants
export const consultationModalStyles = {
  content: {
    margin: "auto",
    height: "min-content",
    width: "85%",
    height: "85%",
    overflow: "auto",
    padding: "1.4rem",
    borderRadius: "8px",
    postion: "relative",
    //
  },
  overlay: { zIndex: "1000", backgroundColor: "#00000077" },
};
export const darkConsultationModalStyles = {
  content: {
    backgroundColor: "#B2BEB5",
    margin: "auto",
    height: "min-content",
    width: "max-content",
    maxHeight: "85%",
    overflow: "auto",
    borderRadius: "8px",
    postion: "relative",
  },
  overlay: { zIndex: "1000", backgroundColor: "#00000099" },
};
