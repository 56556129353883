import { apiSlice } from "../apiSlice";

export const prospectiveDoctorEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProspectiveDoctors: builder.query({
      query: () => "/api/prospective-doctor/",
      providesTags: ["ProspectiveDoctors"],
    }),

    acceptProspectiveDoctor: builder.mutation({
      query: (data) => ({
        url: `/api/prospective-doctor/${data.id}`,
        method: "PUT",
        body: {
          action: "add",
          assistant_id: data.assistantName,
        },
      }),
      invalidatesTags: ["ProspectiveDoctors"],
    }),
    rejectProspectiveDoctor: builder.mutation({
      query: (data) => ({
        url: `/api/prospective-doctor/${data.id}`,
        method: "PUT",
        body: {
          action: "delete",
        },
      }),
      invalidatesTags: ["ProspectiveDoctors"],
    }),

    acceptMultipleProsDoctor: builder.mutation({
      query: (data) => ({
        url: `/api/prospective-doctor/`,
        method: "PUT",
        body: {
          action: "add multiple",
          doctor_hospital_list: data.selectedId,
        },
      }),
      invalidatesTags: ["ProspectiveDoctors"],
    }),
    rejectMultipleProsDoctor: builder.mutation({
      query: (data) => ({
        url: `/api/prospective-doctor/`,
        method: "PUT",
        body: {
          action: "delete multiple",
          doctor_hospital_list: data.selectedId,
        },
      }),
      invalidatesTags: ["ProspectiveDoctors"],
    }),
  }),
});

export const { useGetProspectiveDoctorsQuery } = prospectiveDoctorEndpoints;
export const { useRejectProspectiveDoctorMutation } =
  prospectiveDoctorEndpoints;
export const { useAcceptProspectiveDoctorMutation } =
  prospectiveDoctorEndpoints;

export const { useAcceptMultipleProsDoctorMutation } =
  prospectiveDoctorEndpoints;
export const { useRejectMultipleProsDoctorMutation } =
  prospectiveDoctorEndpoints;
