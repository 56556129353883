//React Imports
import React, { useState, useReducer, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

//Styled Components
import {
  AnchorText,
  BtnWrap,
  CardContainer,
  DesktopOnly,
  ErrorContainer,
  GridContainer,
  LightText,
  MobileOnly,
  ResponsiveGrid,
  StyledTextField,
  SuccessContainer,
  Text,
} from "../../../../Global";
import { CrossIcon, Heading, PlusIcon, Title } from "../../MainPages.elements";
import ShimmerCard from "../../../../components/ui/CardShimmering";

//RTK Query
import {
  useAddConsultationMutation,
  useGetMyConsultationQuery,
  useLazyGetAllConsultationQuery,
} from "../../../../api/userEndpoints/consultationEndpoint";

//Components
import Button from "../../../../components/ui/Button";
import ConsultationCard from "./DocConsultationCard";
import NoRecords from "../../../../components/ui/NoRecords";

//Utils
import { parseDate } from "../../../../utils/parseDate";

//3rd Party Packages
import ReactModal from "react-modal";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";

import {
  useLazyGetConsentOtpQuery,
  useLazyGetConsentTokenQuery,
} from "../../../../api/userEndpoints/patientsEndpoint";
import AuthContext from "../../../../context/authProvider";

import {
  consultationModalStyles,
  darkConsultationModalStyles,
} from "../../../../utils/ModalStyles";
import { Heading3 } from "../../../Auth/Auth.elements";
import ReactQuill from "react-quill";

const ACTION = {
  doctorPhoneNumber: "handleDoctorPhoneNumber",
  indications: "handleIndications",
  medications: "handleMedication",
  precautions: "handlePrecautions",
  patientDetail: "handlePatientDetail",
  labTests: "handleLabTests",
  nextCheckup: "handleNextCheckup",
  nextCheckup: "handleNextCheckup",
  appointmentId: "handleAppointmentId",
};

function DocPatientsDetails() {
  const { consentTokens, setConsentTokens, isDarkTheme } =
    useContext(AuthContext);

  const { id } = useParams();
  const [addConsutationModal, setAddConsultation] = useState(false);
  // const [allConsultation, setAllConsultation] = useState([]);
  const [otpState, setOtpState] = useState(false);
  const [otp, setOtp] = useState("");
  const [acError, setACError] = useState("");
  const [acSuccess, setACSuccess] = useState("");
  const [allConsultation, setAllConsultation] = useState();

  //option to select my consultation or consulation made by others
  const [alignment, setAlignment] = useState("my");

  const reducer = (state, action) => {
    switch (action.type) {
      case ACTION.indications:
        return { ...state, indications: action.payload };
      case ACTION.medications:
        return { ...state, medications: action.payload };
      case ACTION.precautions:
        return { ...state, precautions: action.payload };
      case ACTION.patientDetail:
        return { ...state, patientDetail: action.payload };
      case ACTION.labTests:
        return { ...state, labTests: action.payload };
      case ACTION.nextCheckup:
        return { ...state, nextCheckup: action.payload };
      case ACTION.appointmentId:
        return { ...state, appointmentId: action.payload };
    }
  };
  const [data, dispatch] = useReducer(reducer, {
    indications: "",
    medications: "",
    precautions: "",
    patientDetail: id,
    labTests: "",
    nextCheckup: "",
    appointmentId: "",
  });

  const { data: myConsultation, isLoading: isMyConsultationtLoading } =
    useGetMyConsultationQuery(id);
  const [getAllConsultation, { isLoading: isAllConsultationtLoading }] =
    useLazyGetAllConsultationQuery();

  const [
    addConsultation,
    { isLoading: isAddConsultationLoading, isError: isAddConsultationError },
  ] = useAddConsultationMutation();

  const [getConsentOtp, { isLoading: isConsentOtpLoading }] =
    useLazyGetConsentOtpQuery();
  const [getConsentToken, { isLoading: isConsentTokenLoading }] =
    useLazyGetConsentTokenQuery();

  const createConsultationCard = (props, i) => {
    return <ConsultationCard patientId={id} consultation={props} i={i} />;
  };

  const handleAddConsultation = async () => {
    try {
      await addConsultation({
        indications: data.indications,
        medications: data.medications,
        precautions: data.precautions,
        patientDetail: parseInt(data.patientDetail),
        labTests: data.labTests,
        nextCheckup: data.nextCheckup,
        appointmentId: data.appointmentId,
      });
      if (isAddConsultationError) {
        setACError(isAddConsultationError);
        return;
      }
      setACError("");
      setACSuccess("The consultation was added successfully.");
      setAddConsultation(false);
    } catch (err) {
      console.log(err);
    }
  };

  async function requestConsentOtp() {
    try {
      await getConsentOtp(id);

      setOtpState(true);
    } catch (err) {
      console.log(err);
    }
  }
  async function handleOtpSubmit() {
    try {
      const response = await getConsentToken({ id: id, otp: otp });
      console.log(response);
      setOtpState(false);
      setConsentTokens({
        ...consentTokens,
        [id]: {
          iv: response.data.iv,
          consent_token: response.data.consent_token,
        },
      });
    } catch (err) {
      console.log(err);
    }
  }
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleGetAllConsultation = async () => {
    try {
      const result = await getAllConsultation({
        id: id,
        iv: consentTokens[id]?.iv,
        token: consentTokens[id]?.consent_token,
      });
      setAllConsultation(result.data);
      console.log(result);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (alignment === "others") {
      if (Object.keys(consentTokens).includes(id)) {
        handleGetAllConsultation();
      } else {
        setAllConsultation();
      }
    }
  }, [alignment, id]);

  const modules = {
    toolbar: [
      // You can customize the toolbar options as needed
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML
      matchVisual: false,
    },
    // Enable multiline
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: function () {},
        },
      },
    },
  };

  if (isMyConsultationtLoading) {
    return <ShimmerCard />;
  } else {
    return (
      <>
        {/* OTP Modal */}
        <ReactModal
          isOpen={otpState}
          style={
            isDarkTheme ? darkConsultationModalStyles : consultationModalStyles
          }
        >
          <Heading margin="0 0 1rem 0">Access Requested</Heading>
          <LightText>
            Please enter the OTP sent to{" "}
            {myConsultation?.gender === "Male" ? "Mr." : ""}
            {myConsultation?.name}.{" "}
          </LightText>
          <StyledTextField
            fullWidth
            onChange={(e) => setOtp(e.target.value)}
            value={otp}
            label="Enter OTP"
            margin="dense"
          ></StyledTextField>
          <BtnWrap
            margin="8px 0 0 0"
            columns="auto auto"
            justify="space-between"
          >
            <AnchorText onClick={() => setOtpState(false)}>Cancel</AnchorText>
            <Button
              onclick={handleOtpSubmit}
              disabled={isConsentTokenLoading}
              isloading={isConsentTokenLoading}
              label="Submit"
            ></Button>
          </BtnWrap>
        </ReactModal>

        {/* Add Consultation Modal */}
        <ReactModal
          isOpen={addConsutationModal}
          onRequestClose={() => setAddConsultation(false)}
          style={
            isDarkTheme ? darkConsultationModalStyles : consultationModalStyles
          }
        >
          <GridContainer columns="1fr auto">
            <Heading>Add Consultation</Heading>
            <CrossIcon onClick={() => setAddConsultation(false)} />
          </GridContainer>
          <ResponsiveGrid
            columns="repeat(auto-fill,minmax(250px,1fr))"
            width="100%"
            align="flex-start"
          >
            <GridContainer columns="1fr">
              <Text>
                <b>{myConsultation?.name}</b> ({myConsultation?.gender} &nbsp;
                {myConsultation?.age} y/o)&nbsp;{myConsultation?.blood_group}
              </Text>
            </GridContainer>
          </ResponsiveGrid>
          <LightText>
            Patient registered on: {parseDate(myConsultation?.created_ts)}
          </LightText>

          <Title style={{ margin: "1.3rem 0 0.7rem 0" }}>
            Details of New Consultation
          </Title>
          <GridContainer rgap="1rem" columns="1fr">
            <ResponsiveGrid columns="1fr 2fr " align="flex-start" width="100%">
              {/* <ReactQuill
                theme="snow"
                value={data.indications}
                onChange={(value) =>
                  dispatch({
                    type: ACTION.indications,
                    payload: value,
                  })
                }
                placeholder="Indications"
              /> */}
              <StyledTextField
                onChange={(e) =>
                  dispatch({
                    type: ACTION.indications,
                    payload: e.target.value,
                  })
                }
                value={data.indications}
                margin="dense"
                label="Indications"
                rows={6}
                multiline
              />

              {/* <ReactQuill
                theme="snow"
                value={data.medications}
                onChange={(value) =>
                  dispatch({
                    type: ACTION.medications,
                    payload: value,
                  })
                }
                placeholder="Medications"
              /> */}
              <StyledTextField
                onChange={(e) =>
                  dispatch({
                    type: ACTION.medications,
                    payload: e.target.value,
                  })
                }
                value={data.medications}
                margin="dense"
                label="Medications"
                rows={6}
                multiline
              />
            </ResponsiveGrid>
            <ResponsiveGrid columns="3fr 2fr" align="flex-start">
              {/* <ReactQuill
                theme="snow"
                value={data.precautions}
                onChange={(value) =>
                  dispatch({
                    type: ACTION.precautions,
                    payload: value,
                  })
                }
                placeholder="Precautions"
              /> */}
              <StyledTextField
                onChange={(e) =>
                  dispatch({
                    type: ACTION.precautions,
                    payload: e.target.value,
                  })
                }
                value={data.precautions}
                margin="dense"
                label="Precautions"
                rows={6}
                multiline
              />

              {/* <ReactQuill
                theme="snow"
                value={data.labTests}
                onChange={(value) =>
                  dispatch({
                    type: ACTION.labTests,
                    payload: value,
                  })
                }
                placeholder="Lab Tests"
              /> */}
              <StyledTextField
                onChange={(e) =>
                  dispatch({
                    type: ACTION.labTests,
                    payload: e.target.value,
                  })
                }
                value={data.labTests}
                margin="dense"
                label="Lab Tests"
                rows={6}
                multiline
              />
            </ResponsiveGrid>
          </GridContainer>

          <Title style={{ margin: "1.3rem 0 0.7rem 0" }}>Next Checkup</Title>
          {/* <ReactQuill
            theme="snow"
            value={data.nextCheckup}
            onChange={(value) =>
              dispatch({
                type: ACTION.nextCheckup,
                payload: value,
              })
            }
            placeholder="Enter Details"
          /> */}
          <StyledTextField
            onChange={(e) =>
              dispatch({
                type: ACTION.nextCheckup,
                payload: e.target.value,
              })
            }
            value={data.nextCheckup}
            fullWidth
            margin="dense"
            label="Enter Details"
            rows={6}
            multiline
          />
          {acError && <ErrorContainer>{acError}</ErrorContainer>}
          <BtnWrap>
            <Button
              disabled={isAddConsultationLoading}
              isloading={isAddConsultationLoading}
              orange
              onclick={handleAddConsultation}
              label="Add Consultation"
            />
          </BtnWrap>
        </ReactModal>
        <DesktopOnly>
          <CardContainer>
            <GridContainer rows="auto 1fr" columns="1fr" width="100%">
              <GridContainer columns="1fr" width="100%">
                <Heading style={{ margin: "0" }}>
                  {myConsultation?.name} ({myConsultation?.age} y/o)
                </Heading>

                <LightText style={{ margin: "0" }}>
                  {myConsultation?.gender}{" "}
                  {myConsultation?.blood_group !== "None" &&
                    myConsultation?.blood_group}
                </LightText>
                <LightText>{parseDate(myConsultation?.dob)}</LightText>
              </GridContainer>
              <GridContainer
                margin="0 0 1rem 0"
                columns="1fr auto auto"
                width="100%"
              >
                <Heading3>Consultations</Heading3>

                <ToggleButtonGroup
                  style={{
                    backgroundColor: "#fff",
                    height: "28px",
                  }}
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                >
                  <ToggleButton value="my">My Consultations</ToggleButton>
                  <ToggleButton value="others">Others</ToggleButton>
                </ToggleButtonGroup>
                <PlusIcon onClick={() => setAddConsultation(true)} />
              </GridContainer>

              {alignment === "my" ? (
                <GridContainer
                  columns="1fr"
                  width="100%"
                  style={{
                    overflow: "auto",
                    placeContent: "start",
                  }}
                >
                  {myConsultation?.consultations?.length === 0 ? (
                    <>
                      <NoRecords message="You have not consulted this patient." />
                      <Button
                        onclick={requestConsentOtp}
                        label="View All Consultations"
                      ></Button>
                    </>
                  ) : (
                    myConsultation?.consultations?.map(createConsultationCard)
                  )}
                </GridContainer>
              ) : allConsultation?.length === 0 ||
                allConsultation === undefined ? (
                <>
                  <NoRecords message="Get Patient's consent to view Consultaions made by other Doctors" />
                  <Button
                    isloading={isConsentOtpLoading}
                    disbaled={isConsentOtpLoading}
                    onclick={requestConsentOtp}
                    label="Get Consent"
                  ></Button>
                </>
              ) : (
                <GridContainer
                  columns="1fr"
                  width="100%"
                  style={{
                    height: "350px",
                    overflow: "auto",
                    placeContent: "start",
                  }}
                >
                  {allConsultation?.consultations?.map(createConsultationCard)}
                </GridContainer>
              )}
              {acSuccess && <SuccessContainer>{acSuccess}</SuccessContainer>}
            </GridContainer>
          </CardContainer>
        </DesktopOnly>
        <MobileOnly>
          <GridContainer columns="1fr" margin="0.2rem" rgap="0.2rem">
            <CardContainer>
              <GridContainer rows="auto 1fr" columns="1fr" width="100%">
                <GridContainer columns="1fr" width="100%">
                  <Heading>
                    {myConsultation?.name} ({myConsultation?.age} y/o)
                  </Heading>

                  <LightText style={{ margin: "0" }}>
                    {myConsultation?.gender}{" "}
                    {myConsultation?.blood_group !== "None" &&
                      myConsultation?.blood_group}
                  </LightText>
                  <LightText>{parseDate(myConsultation?.dob)}</LightText>
                </GridContainer>
                <GridContainer
                  margin="8px 0"
                  columns="1fr auto auto"
                  width="100%"
                >
                  <Heading>Consultations</Heading>

                  <PlusIcon onClick={() => setAddConsultation(true)} />
                </GridContainer>
                <GridContainer columns="1fr" width="100%">
                  <ToggleButtonGroup
                    style={{
                      backgroundColor: "#fff",
                      margin: "1rem auto",
                    }}
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                  >
                    <ToggleButton value="my">My Consultations</ToggleButton>
                    <ToggleButton value="others">Others</ToggleButton>
                  </ToggleButtonGroup>
                </GridContainer>

                {alignment === "my" ? (
                  <GridContainer
                    columns="1fr"
                    width="100%"
                    style={{
                      overflow: "auto",
                      placeContent: "start",
                    }}
                  >
                    {myConsultation?.consultations?.length === 0 ? (
                      <>
                        <NoRecords message="You have not consulted this patient." />
                        <Button
                          onclick={requestConsentOtp}
                          label="View All Consultations"
                        ></Button>
                      </>
                    ) : (
                      myConsultation?.consultations?.map(createConsultationCard)
                    )}
                  </GridContainer>
                ) : allConsultation?.length === 0 ||
                  allConsultation === undefined ? (
                  <>
                    <NoRecords message="Get Patient's consent to view Consultaions made by other Doctors" />
                    <Button
                      isloading={isConsentOtpLoading}
                      disbaled={isConsentOtpLoading}
                      onclick={requestConsentOtp}
                      label="Get Consent"
                    ></Button>
                  </>
                ) : (
                  <GridContainer
                    columns="1fr"
                    width="100%"
                    style={{
                      overflow: "auto",
                      placeContent: "start",
                    }}
                  >
                    {allConsultation?.consultations?.map(
                      createConsultationCard
                    )}
                  </GridContainer>
                )}
                {acSuccess && <SuccessContainer>{acSuccess}</SuccessContainer>}
              </GridContainer>
            </CardContainer>
          </GridContainer>
        </MobileOnly>
      </>
    );
  }
}

export default DocPatientsDetails;
