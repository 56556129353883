import storage from "local-storage-fallback";
import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [, setSubscribed] = useState(true);

  const [docAuth, setDocAuth] = useState({});
  const [consentTokens, setConsentTokens] = useState({});
  const [persist, setPersist] = useState(
    JSON.parse(storage.getItem("persist")) || true
  );
  const [isDarkTheme, setDarkTheme] = useState(false);
  // const [isDarkTheme, setDarkTheme] = useState(
  //   JSON.parse(storage.getItem("theme")) || false
  // );

  return (
    <AuthContext.Provider
      value={{
        setDarkTheme,
        isDarkTheme,
        docAuth,
        setDocAuth,
        auth,
        setAuth,
        persist,
        setPersist,
        consentTokens,
        setConsentTokens,
        subscribed: auth?.isSubscribed,
        setSubscribed,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
