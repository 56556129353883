import { apiSlice } from "../apiSlice";

export const doctorEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDoctors: builder.query({
      query: () => "/api/doctor/",
      providesTags: ["Doctors"],
    }),
    addDoctor: builder.mutation({
      query: (data) => ({
        url: "/api/doctor/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Doctors", "TaggedDoctors"],
    }),
    changeDoctorAssistant: builder.mutation({
      query: (data) => ({
        url: `/api/doctor/${data.id}`,
        method: "PUT",
        body: {
          action: "change assistant",
          assistant_id: data.newAssistant,
        },
      }),
      invalidatesTags: ["Doctors", "TaggedDoctors"],
    }),
  }),
});

export const { useGetDoctorsQuery } = doctorEndpoints;
export const { useAddDoctorMutation } = doctorEndpoints;
export const { useChangeDoctorAssistantMutation } = doctorEndpoints;
