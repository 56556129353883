import React from "react";
import { CenterFlexContainer } from "../../Global";
import { ImgWrap, Img, Text } from "./ui.elements";
import norecords from "../../assets/no-records.svg";

function NoRecords({ message }) {
  return (
    <CenterFlexContainer
      style={{ width: "100%", margin: "2rem 0 0 0", flexDirection: "column" }}
    >
      <ImgWrap width="160px">
        <Img src={norecords} />
      </ImgWrap>
      <Text style={{ margin: "0.7rem 0 ", textAlign: "center" }}>
        {message}
      </Text>
    </CenterFlexContainer>
  );
}

export default NoRecords;
