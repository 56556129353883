//React Query
import React, { useEffect, useState } from "react";

//Styled Components
import { CenterFlexContainer } from "../../../../Global";
import {
  DeleteIcon,
  Text,
  Title,
  SHContainer,
  SHWrapper,
  SHHeader,
} from "../../MainPages.elements";

//Components
import TimeField from "../../../../components/ui/TimeField";

//Util Function
import { getDayName, getDayNum } from "../../../../utils/parseSchedule";
import WeekDayToggle from "./WeekDayToggle";

// const input = {
//   days_of_week: ["0", "1", "2", "3", "4", "5", "6"],
//   end_time: "21:27:00",
//   start_time: "11:29:00",
// };
function ScheduleCard({
  i,
  newSchedule,
  setNewSchedule,
  setDelSchedule,
  delSchedule,
}) {
  let days = newSchedule[i]?.days_of_week?.map(getDayName);
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [selectedWeekdays, setSelectedWeekdays] = useState(days ?? []);
  const [startTime, setStartTime] = useState(newSchedule[i]?.start_time);
  const [endTime, setEndTime] = useState(newSchedule[i]?.end_time);

  const handleWeekdayChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedWeekdays([...selectedWeekdays, value]);
    } else {
      setSelectedWeekdays(
        selectedWeekdays.filter((weekday) => weekday !== value)
      );
    }
  };

  useEffect(() => {
    // setNewSchedule([curSchedule]);
    setNewSchedule((prevItems) => [
      ...prevItems.slice(0, i),
      {
        ...prevItems[i],
        start_time: startTime?.length === 5 ? startTime + ":00" : startTime,
        end_time: endTime?.length === 5 ? endTime + ":00" : endTime,
        days_of_week: selectedWeekdays?.map(getDayNum),
      },
      ...prevItems.slice(i + 1),
    ]);
  }, [endTime, startTime, selectedWeekdays]);

  function deleteSchedule() {
    const newArr = newSchedule.splice(i, 1);
    setDelSchedule([...delSchedule, newArr]);
    setNewSchedule([...newSchedule]);
  }

  return (
    <SHContainer>
      <SHWrapper>
        <SHHeader>
          <Title>
            {newSchedule[i]?.start_time === "" &&
            newSchedule[i]?.end_time === "" &&
            newSchedule[i]?.days_of_week.length === 0
              ? "New"
              : ""}
            Schedule
          </Title>
          <DeleteIcon onClick={deleteSchedule} />
        </SHHeader>
        <Text>Working days</Text>
        <CenterFlexContainer>
          {weekdays?.map((weekday) => (
            <WeekDayToggle
              key={weekday}
              type="checkbox"
              value={weekday}
              checked={selectedWeekdays?.includes(weekday)}
              onchange={handleWeekdayChange}
              weekday={weekday}
            >
              {weekday}
            </WeekDayToggle>
          ))}
        </CenterFlexContainer>{" "}
        <Text>Shift timings</Text>
        <CenterFlexContainer
          style={{ width: "100%", justifyContent: "space-between" }}
        >
          <Text>From</Text>

          <TimeField
            onchange={(e) => setStartTime(e.target.value)}
            value={startTime}
          />
          <Text>To</Text>

          <TimeField
            onchange={(e) => setEndTime(e.target.value)}
            value={endTime}
          />
        </CenterFlexContainer>
      </SHWrapper>
    </SHContainer>
  );
}

export default ScheduleCard;
