import { docApiSlice } from "../docApiSlice";

export const consultationEndpoints = docApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllConsultation: builder.query({
      query: (id) => `/consultations?patient_detail=${id}&consultation_by=me`,
      providesTags: ["Consultation"],
    }),
    getConsultationHistory: builder.query({
      query: (id) => `/consultations?patient_detail=${id}`,
      providesTags: ["ConsultationHistory"],
    }),

    addConsultation: builder.mutation({
      query: (data) => ({
        url: `/consultations`,
        method: "POST",
        body: {
          doctor_phone_number: data.doctorPhoneNumber,
          indications: data.indications,
          medications: data.medications,
          precautions: data.precautions,
          patient_detail: data.patientDetail,
          lab_tests: data.labTests,
          next_checkup: data.nextCheckup,
          appointment_id: data.appointmentId,
        },
      }),
      invalidatesTags: ["Consultation"],
    }),
  }),
});

export const { useGetAllConsultationQuery } = consultationEndpoints;
export const { useGetConsultationHistoryQuery } = consultationEndpoints;
export const { useAddConsultationMutation } = consultationEndpoints;
