import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();

  if (
    auth.accessToken &&
    auth?.applicationStatus !== "pending" &&
    auth?.roles?.find((role) => allowedRoles?.includes(role))
  ) {
    return <Outlet />;
  } else {
    if (auth.applicationStatus && auth.applicationStatus !== "approved") {
      return <Navigate to="/underprocess" state={{ from: location }} replace />;
    } else if (auth.accessToken) {
      return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    } else {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }
};
export default RequireAuth;
