import { useDispatch } from "react-redux";
import { setDocCredential } from "../reduxSlices/docAuthSlice";
import useAuth from "./useAuth";
import storage from "local-storage-fallback";
import axios from "axios";

const REFRESH_TOKEN_URL =
  "https://dev.curus.co.in/auth/app-auth/?action=refresh-token&user=doctor";

const useDocRefreshToken = () => {
  const dispatch = useDispatch();
  const { setDocAuth } = useAuth();
  const doctorPhone = storage.getItem("doctorPhone");
  const refreshToken = storage.getItem("refreshToken");
  const refresh = async () => {
    const response = await axios.post(
      REFRESH_TOKEN_URL,
      { phone_number: "+91" + doctorPhone, refresh_tokn: refreshToken },

      { withCredentials: true }
    );

    const subscriptionStatus = await fetch(
      "https://l1x3gkp512.execute-api.ap-south-1.amazonaws.com/dev/verify-doctor-subscription",
      {
        headers: {
          authorization: `Bearer ${response.data?.AuthenticationResult?.IdToken}`,
        },
      }
    );
    const ss = await subscriptionStatus.json();

    if (ss) {
      setDocAuth((prev) => {
        return {
          ...prev,
          phoneNumber: doctorPhone,
          AuthenticationResult: response.data.AuthenticationResult,
          WebappAuthenticationResult: response.data.WebappAuthenticationResult,
          subscribed: ss.is_subscribed,
        };
      });

      dispatch(
        setDocCredential({
          phoneNumber: doctorPhone,
          AuthenticationResult: response.data.AuthenticationResult,
          WebappAuthenticationResult: response.data.WebappAuthenticationResult,
          subscribed: ss.is_subscribed,
        })
      );
    }

    return response.data.AuthenticationResult?.IdToken;
  };
  return refresh;
};

export default useDocRefreshToken;
