import React from "react";

import styled from "styled-components";
import { CenterFlexContainer } from "../../Global";

const ProfileContainer = styled(CenterFlexContainer)`
  flex-direction: row;
  margin: 0 1rem;
  justify-content: start;
  @media screen and (max-width: 912px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const WelcomeText = styled.p`
  font-weight: 700;
  letter-spacing: 0.1rem;
  font-size: 24px;
  margin: 0.7rem 0;
  color: #f5994a;

  @media screen and (max-width: 912px) {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
`;
const NameText = styled.p`
  font-weight: 500;
  letter-spacing: 0.1rem;
  font-size: 24px;
  color: ${(props) => props.theme.colors.curusBlue};
  margin: 0.3rem;
  line-height: 16px;
  @media screen and (max-width: 912px) {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }
`;
function ProfileCard({ name }) {
  return (
    <ProfileContainer>
      <WelcomeText>Welcome, </WelcomeText>
      <NameText>{name}</NameText>
    </ProfileContainer>
  );
}

export default ProfileCard;
