import React, { memo, useContext } from "react";
import { StyledTD, StyledTR } from "../../MainPages.elements";
import Button from "../../../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/authProvider";
import { gatingMessage } from "../../../../data/GatingMessage";

// name: "Veerr",
//       age: null,
//       blood_group: "A+",
//       gender: "Male",
//       created_ts: "2023-01-30T12:11:17.332977+05:30",
//       modified_ts: null,
//       patient: 59,
//       id: 175,
//       dob: "2023-01-01",
//       basic_indications: null,
const DocPatientRow = memo(({ patients, i, patientId }) => {
  const navigate = useNavigate();
  const { subscribed } = useContext(AuthContext);

  return (
    <StyledTR>
      <StyledTD>{i + 1}</StyledTD>
      <StyledTD>
        <b>{patients.name}</b>
        <br />
        <small>
          {patients.gender} {patients.age}
        </small>
      </StyledTD>

      <StyledTD>{patients.blood_group}</StyledTD>

      <StyledTD>{patients.dob}</StyledTD>
      <StyledTD>
        <Button
          orange
          onclick={() => navigate(`/patients/${patients.id}`)}
          label={patients.id == patientId ? "Viewing..." : "View Details"}
          disabled={patients.id == patientId}
          premium={!subscribed}
          gatingMessage={gatingMessage.patientsDetailsViewingGM}
        ></Button>
      </StyledTD>
    </StyledTR>
  );
});

export default DocPatientRow;
