import storage from "local-storage-fallback";
import axios from "../api/docAxios";
import useAuth from "./useAuth";

const useDocLogout = () => {
  const { setDocAuth, docAuth } = useAuth();
  var phone = storage.getItem("doctorPhone");
  const logout = async () => {
    try {
      await axios.post(
        "/user-auth?action=logout",
        {
          access_token: docAuth.idToken,
          phone_number: phone,
        },
        {
          withCredentials: true,
        }
      );
    } catch (err) {
      console.log(err);
    }
    setDocAuth({});
  };

  return logout;
};

export default useDocLogout;
