import React from "react";

import {
  TextfieldContainer,
  TextfieldWrapper,
  Input,
  Label,
  Message,
  ErrorMessage,
} from "./ui.elements";
import inFlag from "../../assets/in-flag.svg";
import { ImgWrap } from "../../pages/Admin/MainPages.elements";
import { Img } from "../../pages/Auth/Auth.elements";

const PhoneField = (props) => {
  return (
    <TextfieldContainer margin={props.margin}>
      <TextfieldWrapper>
        <Input
          value={props.value}
          name={props.name}
          multiple={props.multiple ? "multiple" : ""}
          type={props.type}
          placeholder={props.label}
          accept={props.accept}
          onChange={props.onchange}
          ref={props.fieldRef}
          disabled={props.disabled}
          id={props.id ? props.id : ""}
          required={props.required ? "required" : ""}
        />
        {/* <IntlTelInput
          containerClassName="intl-tel-input"
          inputClassName="phone-field"
          defaultCountry="in"
          allowDropdown={false}
          value={props.value}
          ref={props.fieldRef}
          onPhoneNumberChange={props.onchange}
        /> */}
        {/* <FlagIcon /> */}
        <Label>{props.label}</Label>
        <ImgWrap
          width="24px"
          height="24px"
          style={{ position: "absolute", right: "14.5px", top: "7.6px" }}
        >
          <Img src={inFlag}></Img>
        </ImgWrap>
      </TextfieldWrapper>
      {props.error ? <ErrorMessage>{props.error}</ErrorMessage> : ""}
      <Message></Message>
    </TextfieldContainer>
  );
};

export default PhoneField;
