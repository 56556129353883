import { apiSlice } from "../apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login?action=validateOTP",
        method: "POST",
        body: { ...credentials },
      }),
    }),

    requestotp: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login?action=requestOTP",
        method: "POST",
        body: { ...credentials },
      }),
    }),

    refresh: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login/refresh/",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    getSubscriptionPlan: builder.query({
      query: () => "/subscription/plans",
      providesTags: ["Subscription"],
    }),
    getActivePlan: builder.query({
      query: () => "/subscription/plans/hospital",
      providesTags: ["ActivePlans"],
    }),
  }),
});

export const { useLoginMutation } = authApiSlice;
export const { useRequestotpMutation } = authApiSlice;
export const { useRefreshMutation } = authApiSlice;
export const { useGetSubscriptionPlanQuery, useGetActivePlanQuery } =
  authApiSlice;
