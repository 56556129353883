import React from "react";
import {
  BorderedGridContainer,
  CardContainer,
  GridContainer,
  LightText,
  MainContainer,
  ResponsiveGrid,
} from "../../Global";
import {
  Container,
  Heading,
  Heading2,
  ScrollableContainer,
  Title,
} from "../Admin/MainPages.elements";
import { useGetActivePlanQuery } from "../../api/userEndpoints/authApiSlice";
import { format } from "date-fns";
import DoughnutChart from "../../components/ui/DougnnutChart";
import styled from "styled-components";
import NoRecords from "../../components/ui/NoRecords";
const ProgressBarTextContainer = styled(Container)`
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: #333;
  & > small {
    text-align: center;
  }
  & > p {
    text-align: center;
    margin: 0;
    font-weight: 600;
    font-size: 22px;
    color: #222;
  }
`;

const PlanDetailsContainer = styled(GridContainer)`
  grid-template-columns: 2fr 1fr;
  margin: 0 1.5rem;
  gap: 1.5rem;
  align-items: stretch;
  @media screen and (max-width: 968px) {
    grid-template-columns: 1fr;
    margin: 0.2rem 0.2rem 0 0.2rem;
    gap: 0.2rem;
  }
`;
function PlanDetails() {
  const { data: activePlan } = useGetActivePlanQuery();
  let startDate;
  let endDate;
  if (activePlan) {
    startDate = new Date(activePlan[0]?.start_datetime.substring(0, 19));
    endDate = new Date(activePlan[0]?.end_datetime.substring(0, 19));
  }

  const data = {
    title: activePlan && activePlan[0]?.plan_name,
    labels: ["Days Elapsed", "Days Remaining"],
    values: [
      365 - (activePlan && activePlan[0]?.days_remaining),
      activePlan && activePlan[0]?.days_remaining,
    ],
    colors: ["#eee", "#36A2EB"],
  };

  return (
    <MainContainer>
      <PlanDetailsContainer
        columns="2fr 1fr "
        margin="0 1rem"
        gap="1rem"
        align="stretch"
      >
        <CardContainer>
          <Heading margin="0 0 1.5rem 0" width="100%">
            Current plan: &nbsp;<b>{activePlan && activePlan[0]?.plan_name}</b>
          </Heading>
          <ResponsiveGrid columns="3fr 1fr" align="flex-start">
            <Container align="flex-start">
              <LightText>
                The Annual Subscription Plan offers a comprehensive healthcare
                solution with a Hospital Web App, Doctor App, Patient App, and
                advanced Appointment Management capabilities. This plan enables
                seamless communication and interaction between hospitals,
                doctors, and patients, enhancing overall healthcare service
                delivery and patient experience.
              </LightText>
              <br></br>
              <LightText>
                <small>
                  Plan Validity: {startDate && format(startDate, "dd/MM/yy")} -{" "}
                  {endDate && format(endDate, "dd/MM/yy")}
                </small>
              </LightText>
            </Container>
            <Container>
              <div style={{ position: "relative" }}>
                <ProgressBarTextContainer>
                  <p>
                    {activePlan && activePlan[0]?.days_remaining}
                    <br></br>
                  </p>
                  <small>days remaining</small>
                </ProgressBarTextContainer>
                <DoughnutChart data={data} />
              </div>
            </Container>
          </ResponsiveGrid>
        </CardContainer>
        <CardContainer>
          <Heading width="100%">Upcoming plans</Heading>
          {activePlan?.length <= 1 ? (
            <NoRecords message="No Upcoming Plans"></NoRecords>
          ) : (
            <ScrollableContainer>
              <GridContainer
                columns="1fr"
                place="flex-start"
                align="flex-start"
                rgap="1rem"
              >
                {activePlan?.slice(1).map((plan) => (
                  <BorderedGridContainer columns="2fr 1fr" padding="0.7rem">
                    <Container align="flex-start">
                      <Heading2>{plan.plan_name}</Heading2>
                      <LightText>
                        <small>
                          {new Date(plan?.start_datetime.substring(0, 19)) &&
                            format(
                              new Date(plan?.start_datetime.substring(0, 19)),
                              "dd/MM/yy"
                            )}{" "}
                          -{" "}
                          {new Date(plan?.end_datetime.substring(0, 19)) &&
                            format(
                              new Date(plan?.end_datetime.substring(0, 19)),
                              "dd/MM/yy"
                            )}
                        </small>
                      </LightText>
                    </Container>
                    <Container>
                      <Title style={{ justifyContent: "center" }}>
                        {plan.days_remaining}
                      </Title>
                      <LightText>
                        <small>days</small>
                      </LightText>
                    </Container>
                  </BorderedGridContainer>
                ))}
              </GridContainer>
            </ScrollableContainer>
          )}
        </CardContainer>
      </PlanDetailsContainer>
    </MainContainer>
  );
}

export default PlanDetails;
