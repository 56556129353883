import { Link } from "react-router-dom";
import styled from "styled-components";
import { CenterFlexContainer, GridContainer } from "../../Global";
import { FaTimes, FaTrash } from "react-icons/fa";

export const AuthContainer = styled.div`
  width: 100%;
  margin: auto;
  position: relative;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.fillColor};
  overflow: hidden;
  @media screen and (min-width: 1376px) {
    max-width: 1747px;
  }
  @media screen and (max-width: 768px) {
    background-color: transparent;
  }
`;

export const LoginWrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 5px 1fr;
  gap: 1rem;
  place-content: center;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

export const Column1 = styled(CenterFlexContainer)`
  flex-direction: column;
  min-height: 80vh;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const Column2 = styled(CenterFlexContainer)`
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;

  @media screen and (max-width: 768px) {
    height: 100%;
    padding: 0;
  }
`;
export const Line = styled.hr`
  border: none;
  background-color: ${(props) => props.theme.colors.borderColor};
  width: 3px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ImgWrap = styled(CenterFlexContainer)`
  min-height: 180px;
  min-width: 180px;
  max-height: 300px;
  max-width: 300px;
  margin: 14px 0;
`;

export const Img = styled.img`
  width: 100%;
`;

export const SignUpHeading = styled.h1`
  font-weight: 600;
  width: 100%;
  margin: 0;
  letter-spacing: 1px;
  color: #fff;
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

export const Heading = styled.h1`
  font-weight: 500;
  width: 100%;
  margin: 2rem 0;
  color: ${(props) =>
    props.dark ? props.theme.colors.curusBlue : props.theme.colors.borderColor};
`;

export const Heading3 = styled.h3`
  font-weight: 500;
  width: 100%;
  margin: ${(props) => (props.margin ? props.margin : "1rem 0")};
  color: ${(props) => props.theme.colors.curusBlue};
`;
export const InputWrap = styled(CenterFlexContainer)`
  width: 100%;
  justify-content: start;

  flex-direction: column;
`;
export const Label = styled.p`
  margin: 7px 0;
  color: ${(props) => props.theme.colors.curusBlue};
  font-weight: 600;
`;
export const TextField = styled.input.attrs((props) => ({
  placeholder: props.ph,
}))`
  &::placeholder {
    font-weight: 600;
    font-size: 14px;
    color: #c3cfd9;
  }
  width: 100%;
  padding: 14px 7px;

  border: ${(props) =>
    props.error === ""
      ? `2px solid ${props.theme.colors.borderColor}`
      : "2px solid red"};
  border-radius: 4px;
  transition: 0.5s;
  :focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.curusBlue};
  }
`;

export const FormContainer = styled.div`
  display: flex;
  height: 100%;
  width: max-content;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 1rem;
  background-color: ${(props) => props.theme.colors.fillColor};
  @media screen and (max-width: 768px) {
    border-radius: 20px;
    width: auto;
    padding: 24px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
  }
`;

export const Button = styled.button`
  padding: 14px 24px;
  font-family: "Nunito Sans";
  border: none;
  color: #fff;
  transition: all 0.2s ease-in-out;

  background-color: ${(props) =>
    props.color
      ? props.theme.colors.curusLightBlue
      : props.theme.colors.brightCurusOrange};
  border-radius: 7px;
  font-size: 14px;
  margin: 0 1rem;
  font-weight: 600;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.19), 0 2px 2px rgba(0, 0, 0, 0.23);
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.color ? "#3498DB" : props.theme.colors.curusOrange};
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const RouteButton = styled(Link)`
  display: flex;
  justify-content: center;
  padding: 14px 24px;
  text-decoration: none;
  font-size: 14px;
  border: none;
  color: #fff;
  transition: all 0.2s ease-in-out;
  margin: 0.7rem 14px 0.7rem 0.7rem;
  background-color: ${(props) =>
    props.color
      ? props.theme.colors.curusLightBlue
      : props.theme.colors.brightCurusOrange};
  border-radius: 7px;
  font-weight: 600;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.19), 0 2px 2px rgba(0, 0, 0, 0.23);
  &:hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.color ? "#3498DB" : props.theme.colors.curusOrange};
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0.7rem;
  }
`;

export const BtnWrap = styled(CenterFlexContainer)`
  width: 100%;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const ErrorMessage = styled.p`
  margin: 3px 2px;
  font-weight: 400;
  font-size: 12px;
  color: red;
  transition: all 1s ease-in-out;
`;

export const ResendOtp = styled.small`
  margin: 0;
  color: ${(props) => props.theme.colors.curusBlue};
`;

export const Text = styled.p`
  margin: 5px 1rem;

  font-size: 14px;
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.colors.curusBlue};
`;

export const Text2 = styled.p`
  margin: 0.2rem;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  text-align: left;
  color: ${(props) => props.theme.colors.curusBlue};
`;

//Sign up styled components

export const SignUpWrapper = styled(CenterFlexContainer)`
  flex-direction: column;
  width: 100%;
`;
export const Container = styled(CenterFlexContainer)`
  flex-wrap: wrap;
  margin: 0.7rem 2rem;
  padding: 2rem;
  justify-content: start;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  @media screen and (max-width: 768px) {
    padding: 1rem;
    margin: 0.7rem;
  }
`;

export const InputContainer = styled(CenterFlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  min-width: 300px;
  @media screen and (max-width: 768px) {
    margin: 0;
    width: 100%;
  }
`;
export const File = styled(CenterFlexContainer)`
  align-items: flex-start;
  justify-content: space-between;
  margin: 0.2rem 1rem;
  max-width: 300px;

  color: ${(props) => props.theme.colors.curusBlue};
  & > span {
    margin-left: 1rem;
  }
`;

export const MapContainer = styled(CenterFlexContainer)`
  flex-direction: column;

  & > div {
    width: 100%;
  }
`;

export const AddressField = styled(CenterFlexContainer)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const ErrorContainer = styled(CenterFlexContainer)`
  background-color: #e57373;
  color: #fff;
  font-weight: 600;
  padding: 0.7rem 1rem;
  margin: 1rem;
  border-radius: 5px;
`;

//Modal Styled components
export const ModalContainer = styled.div`
  margin: auto;
  max-width: 1140px;
  padding: 0.7rem 1rem;
`;

export const ModalWrapper = styled(CenterFlexContainer)`
  flex-direction: column;
  align-items: flex-start;
`;

export const ModalHeading = styled.h3`
  font-weight: 500;
  margin: 0;
  color: ${(props) => props.theme.colors.textColor};
`;
export const ModalHeader = styled(CenterFlexContainer)`
  width: 100%;
  justify-content: space-between;
  height: 36px;
`;

export const HR = styled.hr`
  width: 100%;
  height: 2px;
  background-color: ${(props) => props.theme.colors.borderColor};
  border: 0px;
`;

export const CloseIcon = styled(FaTimes)`
  width: 24px;
  height: 24px;
  color: ${(props) => props.theme.colors.curusBlue};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusLightBlue};
  }
`;
export const FileName = styled.div`
  max-width: 260px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DeleteIcon = styled(FaTrash)`
  color: #25337d;
  cursor: pointer;
  &:hover {
    color: red;
  }
`;

export const Title = styled.p`
  color: ${(props) => props.theme.colors.curusBlue};
  font-weight: 600;
  font-size: 28px;
  margin: 0;
`;

export const ToggleCard = styled(CenterFlexContainer)`
  position: relative;
  /* aspect-ratio: 1/1; */
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem;
  border: 2px solid
    ${(props) => (props.active ? props.theme.colors.curusLightBlue : "#ccc")};
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: #eeeeee66;
  }
`;

export const PlanCard = styled(GridContainer)`
  position: relative;
  padding: 1rem;
  opacity: ${(props) => (props.subscribed ? "0.6" : "1")};
  border: 2px solid
    ${(props) =>
      props.active
        ? props.theme.colors.curusLightBlue
        : props.subscribed
        ? "grey"
        : "#ccc"};
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  grid-template-columns: 1fr 1fr 1fr;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`;

export const AuthHeader = styled(GridContainer)`
  margin: 1rem 2rem 0 2rem;
  grid-template-columns: 1fr max-content;

  justify-content: space-between;
  @media screen and (max-width: 768px) {
    margin: 1rem;
  }
`;

export const SignupContainer = styled(GridContainer)`
  margin: 0 2rem;
  gap: 2rem;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: stretch;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-auto-columns: auto;
    grid-auto-flow: dense;
    margin: 0 1rem;
  }
`;

export const SignupCard = styled(GridContainer)`
  grid-template-columns: 1fr;
  margin: 2rem 0;

  place-content: flex-start;
  padding: 1.8rem;
  background-color: ${(props) => props.theme.colors.fillColor};
  border-radius: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;
