import React from "react";
import {
  TextfieldContainer,
  TextfieldWrapper,
  SearchInput,
  SearchIcon,
} from "./ui.elements";

function SearchField(props) {
  return (
    <TextfieldContainer width={props.width}>
      <TextfieldWrapper>
        <SearchInput
          value={props.value}
          name={props.name}
          multiple={props.multiple ? "multiple" : ""}
          type={props.type}
          placeholder={props.label}
          accept={props.accept}
          onChange={props.onchange}
          ref={props.fieldRef}
          disabled={props.disabled}
          id={props.id ? props.id : ""}
          required={props.required ? "required" : ""}
          onKeyDown={props.onKeyDown}
          onFocus={props.onfocus}
          onBlur={props.onblur}
        />
        <SearchIcon />
      </TextfieldWrapper>
    </TextfieldContainer>
  );
}

export default SearchField;
