import { apiSlice } from "../apiSlice";

export const prescriptionEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPrescription: builder.query({
      query: (id) => `/api/download-prescription/?consultation=${id} `,
    }),
  }),
});

export const { useLazyGetPrescriptionQuery } = prescriptionEndpoints;
