import { docApiSlice } from "../docApiSlice";

export const docAuthApiSlice = docApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    docLogin: builder.mutation({
      query: (credentials) => ({
        url: "/user-auth?action=respond-to-auth&user=doctor",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: [],
    }),

    requestDocOtp: builder.mutation({
      query: (credentials) => ({
        url: "/user-auth?action=initiate-auth&user=doctor",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: [],
    }),
    docRefresh: builder.mutation({
      query: (data) => ({
        url: "/user-auth?action=refresh-token&user=doctor",
        method: "POST",
        body: {
          phone_number: data.phone_number,
          refresh_token: data.refresh_token,
        },
      }),
      invalidatesTags: [],
    }),
  }),
});

export const { useDocLoginMutation } = docAuthApiSlice;
export const { useRequestDocOtpMutation } = docAuthApiSlice;
export const { useDocRefreshMutation } = docAuthApiSlice;
