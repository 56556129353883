//React Imports
import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

//Styled Components
import {
  MainContainer,
  CenterFlexContainer,
  CardContainer,
  ErrorContainer,
  SuccessContainer,
  DesktopOnly,
  MobileOnly,
  GridContainer,
  LightText,
  TextButton,
  BorderedGridContainer,
  LargeText,
} from "../../../Global";
import {
  StyledTable,
  StyledTR,
  StyledTH,
  TableWrapper,
  TableHeader,
  Heading,
  AddIcon,
  ScrollableContainer,
  Heading2,
} from "../MainPages.elements";

//Components
import SearchField from "../../../components/ui/SearchField";
import UserTableRow from "./helpers/UserTableRow";
import UserMobileCard from "./helpers/UserMobileCard";
import Button from "../../../components/ui/Button";
import NoRecords from "../../../components/ui/NoRecords";
import Shimmering from "../../../components/ui/CardShimmering";

//Hooks
import { useScrollWithShadow } from "../../../hooks/useScrollShadow";

//RTK Query
import {
  useDeleteMultipleUsersMutation,
  useGetSingleUserQuery,
  useGetUsersQuery,
} from "../../../api/userEndpoints/usersEndpoint";

//Util Functions
import { parseRole } from "../../../utils/parseSchedule";

import { useSelector } from "react-redux";
import { auth } from "../../../reduxSlices/authSlice";
import ReactModal from "react-modal";
import AuthContext from "../../../context/authProvider";
import {
  availabilityModalStyles,
  darkAvailabilityModalStyles,
} from "../../../utils/ModalStyles";
import { gatingMessage } from "../../../data/GatingMessage";

function ManageUser() {
  const authData = useSelector(auth);
  const { subscribed, isDarkTheme } = useContext(AuthContext);
  //rtk query
  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery();

  const [
    deleteMultipleUsers,
    { isLoading: isDeleteUsersLoading, isError: isDeleteUsersError },
  ] = useDeleteMultipleUsersMutation();

  const { data: singleUser } = useGetSingleUserQuery(authData?.user);

  const navigate = useNavigate();
  const location = useLocation();
  const { boxShadow, onScrollHandler } = useScrollWithShadow();

  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const [query, setQuery] = useState("");
  const [multipleDeleteUsersModal, setMultipleDeleteUsersModal] =
    useState(false);

  const [members, setMembers] = useState(users);

  function createUserRow(props, i) {
    return (
      <UserTableRow
        key={i}
        addSelectedRows={addSelectedRows}
        removeSelectedRows={removeSelectedRows}
        userData={members}
        setUserData={setMembers}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setError={setError}
        setSuccess={setSuccess}
        currentUser={singleUser}
        i={i}
      />
    );
  }
  function createUserMobileCard(props, i) {
    return (
      <UserMobileCard
        key={i}
        cardColumn="3fr 2fr"
        addSelectedRows={addSelectedRows}
        removeSelectedRows={removeSelectedRows}
        userData={members}
        setUserData={setMembers}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setError={setError}
        setSuccess={setSuccess}
        currentUser={singleUser}
        i={i}
      />
    );
  }

  const filteredItems = users?.filter((user) => {
    return (
      user.name.toLowerCase().includes(query.toLowerCase()) ||
      user.email.toLowerCase().includes(query.toLowerCase())
    );
  });
  useEffect(() => {
    setMembers(filteredItems);
  }, [query]);
  useEffect(() => {
    setMembers(users);
  }, [users]);

  const [selectedRows, setSelectedRows] = useState([]);

  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  // && users[i].isSelected
  function addSelectedRows(i) {
    setSelectedRows([...selectedRows, i]);
  }

  function removeSelectedRows(i) {
    const result = arrayRemove(selectedRows, i);
    setSelectedRows([...result]);
  }

  const ackMultipleDeleteUsers = () => {
    setMultipleDeleteUsersModal(true);
  };

  async function deleteUsers() {
    try {
      let result = await deleteMultipleUsers(selectedRows);
      if (result.data) {
        setSelectedRows([]);
        setError("");
        setSuccess("Selected users deleted succesfully");
        setMultipleDeleteUsersModal(false);
      } else if (result.error) {
        setSuccess("");
        setError(
          result.error.data.message ||
            "Could not delete Users, Please try again"
        );
      }
    } catch (err) {
      console.log(err);
      setSuccess("");
      setError(err.data.message || "Could not delete Users, Please try again");
    }
  }

  if (isUsersLoading) {
    return <Shimmering />;
  } else {
    return (
      <MainContainer>
        <ReactModal
          onRequestClose={() => setMultipleDeleteUsersModal(false)}
          isOpen={multipleDeleteUsersModal}
          style={
            isDarkTheme ? darkAvailabilityModalStyles : availabilityModalStyles
          }
        >
          <Heading2>Delete Users?</Heading2>
          <LightText>
            Please Confirm if you want to delete following Users from your
            Director.
          </LightText>
          {users
            ?.filter((user) => selectedRows.includes(user.id))
            .map((obj) => (
              <BorderedGridContainer padding="0.2rem 1rem" margin="8px 0">
                <GridContainer>
                  <LargeText>
                    {obj.name} <small>({obj.email})</small>
                  </LargeText>
                  <LightText>
                    <small>{obj?.roles.map((r) => parseRole(r)).join()}</small>
                  </LightText>
                </GridContainer>
              </BorderedGridContainer>
            ))}
          <GridContainer justify="space-between" columns="auto auto">
            <TextButton onClick={() => setMultipleDeleteUsersModal(false)}>
              Cancel
            </TextButton>
            <Button red label="Delete All" onclick={deleteUsers}></Button>
          </GridContainer>
        </ReactModal>

        <DesktopOnly>
          <GridContainer
            align="stretch"
            margin="0 1.5rem"
            columns={location.pathname.includes("/add") ? "3fr 1fr" : "1fr"}
          >
            <CardContainer>
              <GridContainer
                margin="0 0 1rem 0"
                align="flex-start"
                columns={
                  location.pathname != "/users/add"
                    ? "1fr 200px 100px"
                    : "1fr 200px "
                }
                width="100%"
              >
                <Heading>Manage Users</Heading>

                <SearchField
                  onchange={(e) => setQuery(e.target.value)}
                  value={query}
                  label="Search Users Here"
                ></SearchField>
                {location.pathname != "/users/add" && (
                  <Button
                    blue
                    label="Add User"
                    onclick={() => navigate("/users/add")}
                  />
                )}
              </GridContainer>

              {users?.length === 0 ? (
                <NoRecords message="No Users Available" />
              ) : (
                <TableWrapper
                  onScroll={onScrollHandler}
                  style={{
                    boxShadow,
                  }}
                >
                  <StyledTable>
                    <TableHeader>
                      <StyledTR>
                        <StyledTH></StyledTH>
                        <StyledTH>Name </StyledTH>
                        <StyledTH>Role </StyledTH>
                        <StyledTH>Email </StyledTH>
                        <StyledTH>Actions </StyledTH>
                      </StyledTR>
                    </TableHeader>
                    <tbody>{members?.map(createUserRow)}</tbody>
                  </StyledTable>
                </TableWrapper>
              )}
              {success && (
                <SuccessContainer>{success ? success : ""} </SuccessContainer>
              )}
              {error && <ErrorContainer>{error ? error : ""}</ErrorContainer>}
              {selectedRows.length !== 0 && (
                <CenterFlexContainer
                  style={{ width: "100%", justifyContent: "flex-end" }}
                >
                  <Button
                    premium={!subscribed}
                    gatingMessage={gatingMessage.addMoreHospitalUserGM}
                    red
                    isloading={isDeleteUsersError}
                    disabled={
                      isDeleteUsersLoading || selectedRows.length === 0
                        ? "disabled"
                        : ""
                    }
                    onclick={ackMultipleDeleteUsers}
                    label="Delete Selected Users"
                  />
                </CenterFlexContainer>
              )}
            </CardContainer>
            <Outlet />
          </GridContainer>
        </DesktopOnly>
        <MobileOnly>
          <GridContainer columns="1fr" margin="0.2rem" rgap="0.2rem">
            <Outlet />
            <CardContainer>
              <CenterFlexContainer justify="space-between" width="100%">
                <Heading>Manage Users</Heading>
                {location.pathname !== "/users/add" && (
                  <AddIcon onClick={() => navigate("/users/add")} />
                )}
              </CenterFlexContainer>

              <SearchField
                onchange={(e) => setQuery(e.target.value)}
                value={query}
                label="Search Users Here"
              ></SearchField>
              <ScrollableContainer
                onScroll={onScrollHandler}
                style={{
                  boxShadow,
                }}
              >
                {members?.map(createUserMobileCard)}
              </ScrollableContainer>
            </CardContainer>
          </GridContainer>
        </MobileOnly>
      </MainContainer>
    );
  }
}

export default ManageUser;
