import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  MainContainer,
  CenterFlexContainer,
  CardContainer,
  ErrorContainer,
  SuccessContainer,
  Text,
  TextButton,
  DesktopOnly,
  MobileOnly,
  GridContainer,
} from "../../../Global";

import { useDetectOutsideClick } from "../../../hooks/useDetectOutsideClick";
import Button from "../../../components/ui/Button";
import TextField from "../../../components/ui/TextField";
import {
  StyledTable,
  StyledTR,
  StyledTH,
  TableHeader,
  TableWrapper,
  BtnWrap,
  FilterDropDown,
  FilterContainer,
  RightArrow,
  ScrollableContainer,
  AddIcon,
  Heading,
  PlusIcon,
} from "../MainPages.elements";

import NoRecords from "../../../components/ui/NoRecords";
import SearchField from "../../../components/ui/SearchField";

import Modal from "react-modal";
import FilterButton from "../../../components/ui/FilterButton";
import Box from "../../../components/ui/Box";

import SelectField from "../../../components/ui/SelectField";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AppointmentMobileCard from "./helpers/AppointmentMobileCard";

import { useScrollWithShadow } from "../../../hooks/useScrollShadow";
import { useSelector } from "react-redux";
import { auth } from "../../../reduxSlices/authSlice";
import { useGetAppointmentsQuery } from "../../../api/userEndpoints/appointmentEndpoint";
import CardShimmering from "../../../components/ui/CardShimmering";
import AppointmentRow from "./helpers/AppointmentRow";

Modal.setAppElement("#root");

function ManageAppointments() {
  //rtk  query
  const { data: appointments, isLoading: isAppointmentLoading } =
    useGetAppointmentsQuery();

  const authData = useSelector(auth);
  const axiosPrivate = useAxiosPrivate();

  const navigate = useNavigate();
  const location = useLocation();
  const { boxShadow, onScrollHandler } = useScrollWithShadow();
  const [appointmentsData, setAppointmentData] = useState(appointments || []);

  //filter dropdown
  const [dropDownState, setDropDownState] = useState(false);
  const [dropDownMobileState, setDropDownMobileState] = useState(false);

  const [query, setQuery] = useState("");

  //Acknowledgement for appointment actions
  const [aptSuccess, setAptSuccess] = useState();
  const [aptError, setAptError] = useState();
  //filter

  const [dateFilter, setDateFilter] = useState("Select Booked date");
  const [pNameFilter, setPNameFilter] = useState("");
  const [dNameFilter, setDNameFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const [aptFilter, setAptFilter] = useState("");

  //ref for detecting outside click
  const dropDownRef = useRef(null);
  const dropDownMobileRef = useRef(null);

  //for mobile decices: appointment
  function createAptMobileCard(props, i) {
    return (
      <AppointmentMobileCard
        key={i}
        cardColumn="2fr 1fr 2fr"
        i={props.i}
        appointmentData={props}
        setAppointmentData={setAppointmentData}
        setAptError={setAptError}
        setAptSuccess={setAptSuccess}
      ></AppointmentMobileCard>
    );
  }

  function createAppointmentRow(props, i) {
    return (
      <AppointmentRow
        i={i}
        appointmentData={props}
        setAppointmentData={setAppointmentData}
        setAptError={setAptError}
        setAptSuccess={setAptSuccess}
      />
    );
  }

  //search bar functions

  useEffect(() => {
    const filteredItems = appointments?.filter((apt) => {
      return (
        apt.patient_name?.toLowerCase().includes(query.toLowerCase()) ||
        apt.doctor_name?.toLowerCase().includes(query.toLowerCase()) ||
        apt.patient_phone_number?.toString().includes(query.toLowerCase())
      );
    });
    setAppointmentData(filteredItems);
  }, [query]);

  //close drop down on outside click
  useDetectOutsideClick(dropDownRef, () => {
    setDropDownState(false);
  });
  useDetectOutsideClick(dropDownMobileRef, () => {
    setDropDownMobileState(false);
  });

  //filter according to date created
  function handleDateFilter(filter) {
    if (filter === "Last 3 Months") {
      return "P3M";
    } else if (filter === "Last 6 Months") {
      return "P6M";
    } else if (filter === "Last Year") {
      return "P1Y";
    } else {
      return "";
    }
  }
  async function clearFilter() {
    setDateFilter("Select Booked date");
    setPNameFilter("");
    setDNameFilter("");
    setPhoneFilter("");
    setAptFilter("");
    setAppointmentData(appointments);
  }

  async function handleFilter() {
    var dateIso = "";

    dateIso = handleDateFilter(dateFilter);

    try {
      const response = await axiosPrivate.get(
        `https://dev.curus.co.in/api/appointment/?patient_phone_number=${phoneFilter}&status=${aptFilter}&doctor_name=${dNameFilter}&patient_name=${pNameFilter}&from=${dateIso}`,
        {
          headers: {
            Authorization: `Bearer ${authData?.accessToken}`,
          },
        }
      );

      setAppointmentData(response.data);
    } catch (err) {}
  }
  useLayoutEffect(() => {
    setAppointmentData(appointments);
  }, [appointments]);

  if (isAppointmentLoading) {
    return <CardShimmering />;
  } else {
    return (
      <MainContainer>
        <MobileOnly>
          <GridContainer margin="0.2rem" columns="1fr" rgap="0.2rem">
            <Outlet />
            <CardContainer>
              <CenterFlexContainer width="100%" justify="space-between">
                <Heading>Appointments</Heading>
                {location.pathname !== "/appointments/add" && (
                  <PlusIcon onClick={() => navigate("/appointments/add")} />
                )}
              </CenterFlexContainer>
              <GridContainer
                width="100%"
                columns="2fr auto"
                style={{ position: "relative" }}
              >
                <SearchField
                  onchange={(e) => setQuery(e.target.value)}
                  value={query}
                  label="Search Users Here"
                ></SearchField>
                <FilterButton onclick={() => setDropDownMobileState(true)} />
                <div ref={dropDownMobileRef}>
                  <FilterDropDown dropDownState={dropDownMobileState}>
                    <FilterContainer>
                      <Text light>Filter By</Text>
                      <CenterFlexContainer width="100%">
                        <SelectField
                          width="100%"
                          active={dateFilter !== ""}
                          firstChoice="Select Booked date"
                          choices={[
                            "Last 3 Months",
                            "Last 6 Months",
                            "Last Year",
                          ]}
                          onchange={(e) => setDateFilter(e.target.value)}
                          value={dateFilter !== "" ? dateFilter : ""}
                        />
                      </CenterFlexContainer>
                      <CenterFlexContainer width="100%">
                        <TextField
                          width="100%"
                          active={pNameFilter !== ""}
                          label="Patient Name"
                          onchange={(e) => setPNameFilter(e.target.value)}
                          value={pNameFilter}
                        />
                      </CenterFlexContainer>
                      <CenterFlexContainer width="100%">
                        <TextField
                          width="100%"
                          active={phoneFilter !== ""}
                          label="Patient Pnone Number"
                          onchange={(e) => setPhoneFilter(e.target.value)}
                          value={phoneFilter}
                        />
                      </CenterFlexContainer>
                      <CenterFlexContainer width="100%">
                        <TextField
                          width="100%"
                          active={dNameFilter !== ""}
                          label="Doctor Name"
                          onchange={(e) => setDNameFilter(e.target.value)}
                          value={dNameFilter}
                        />
                      </CenterFlexContainer>
                      <CenterFlexContainer width="100%">
                        <SelectField
                          width="100%"
                          active={aptFilter !== ""}
                          firstChoice="Select appointment status"
                          choices={["Confirmed", "Rejected", "Done"]}
                          onchange={(e) => setAptFilter(e.target.value)}
                          value={aptFilter}
                        />
                      </CenterFlexContainer>
                    </FilterContainer>

                    <CenterFlexContainer>
                      <BtnWrap
                        style={{
                          backgroundColor: "#eee",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextButton textColor="#6c6c6c" onClick={clearFilter}>
                          Clear All
                        </TextButton>
                        <TextButton
                          textColor="blue"
                          onClick={() => {
                            handleFilter();
                            setDropDownMobileState(false);
                          }}
                        >
                          Apply
                        </TextButton>
                      </BtnWrap>
                    </CenterFlexContainer>
                  </FilterDropDown>
                </div>
              </GridContainer>
              {aptError && (
                <ErrorContainer>{aptError ? aptError : ""}</ErrorContainer>
              )}
              {aptSuccess && (
                <SuccessContainer>
                  {aptSuccess ? aptSuccess : ""}
                </SuccessContainer>
              )}
              {appointmentsData?.length === 0 ? (
                <NoRecords message="No Appointments Available" />
              ) : (
                <ScrollableContainer
                  onScroll={onScrollHandler}
                  style={{
                    boxShadow,
                  }}
                >
                  {appointmentsData?.map(createAptMobileCard)}
                </ScrollableContainer>
              )}
            </CardContainer>
          </GridContainer>
        </MobileOnly>

        <DesktopOnly>
          <GridContainer columns="1fr" margin="0 1.5rem" rgap="1.5rem">
            <Outlet />
            <CardContainer>
              <CenterFlexContainer
                justify="space-between"
                width="100%"
                margin="0 0 8px 0"
              >
                <Heading>Appointments</Heading>
                {location.pathname !== "/appointments/add" && (
                  <Button
                    blue
                    label="Add Appointment"
                    onclick={() => navigate("/appointments/add")}
                  />
                )}
              </CenterFlexContainer>
              <GridContainer
                style={{ position: "relative" }}
                columns="1fr 100px"
                margin="0 0 8px 0"
                width="100%"
              >
                <SearchField
                  onchange={(e) => setQuery(e.target.value)}
                  value={query}
                  label="Search Users Here"
                ></SearchField>
                <FilterButton onclick={() => setDropDownState(true)} />
                <div ref={dropDownRef}>
                  <FilterDropDown dropDownState={dropDownState}>
                    <FilterContainer>
                      <Text light>Filter By</Text>
                      <GridContainer columns="1fr 10px 250px  " width="100%">
                        <Box
                          active={dateFilter !== "Select Booked date"}
                          text="Date Booked On"
                        />
                        <RightArrow />
                        <SelectField
                          width="100%"
                          firstChoice="Select Booked date"
                          choices={[
                            "Last 3 Months",
                            "Last 6 Months",
                            "Last Year",
                          ]}
                          onchange={(e) => setDateFilter(e.target.value)}
                          value={dateFilter}
                        />
                      </GridContainer>
                      <GridContainer columns="1fr 10px 250px" width="100%">
                        <Box active={pNameFilter !== ""} text="Patient Name" />
                        <RightArrow />
                        <TextField
                          width="100%"
                          label="Patient Name"
                          onchange={(e) => setPNameFilter(e.target.value)}
                          value={pNameFilter}
                        />
                      </GridContainer>
                      <GridContainer columns="1fr 10px 250px" width="100%">
                        <Box active={phoneFilter !== ""} text="Phone Number" />
                        <RightArrow />
                        <TextField
                          width="100%"
                          label="Patient Phone Number"
                          onchange={(e) => setPhoneFilter(e.target.value)}
                          value={phoneFilter}
                        />
                      </GridContainer>
                      <GridContainer columns="1fr 10px 250px" width="100%">
                        <Box active={dNameFilter !== ""} text="Doctor Name" />
                        <RightArrow />
                        <TextField
                          width="100%"
                          label="Doctor Name"
                          onchange={(e) => setDNameFilter(e.target.value)}
                          value={dNameFilter}
                        />
                      </GridContainer>
                      <GridContainer columns="1fr 10px 250px" width="100%">
                        <Box
                          active={aptFilter !== ""}
                          text="Appointment Status"
                        />
                        <RightArrow />
                        <SelectField
                          width="100%"
                          firstChoice="Select appointment status"
                          choices={["Confirmed", "Rejected", "Done"]}
                          onchange={(e) => setAptFilter(e.target.value)}
                          value={aptFilter}
                        />
                      </GridContainer>
                    </FilterContainer>

                    <CenterFlexContainer>
                      <BtnWrap
                        style={{
                          backgroundColor: "#eee",
                          justifyContent: "space-between",
                          padding: "0.7rem 1rem",
                        }}
                      >
                        <TextButton textColor="#6c6c6c" onClick={clearFilter}>
                          Clear All
                        </TextButton>
                        <TextButton
                          textColor="blue"
                          onClick={() => {
                            handleFilter();
                            setDropDownState(false);
                          }}
                        >
                          Apply
                        </TextButton>
                      </BtnWrap>
                    </CenterFlexContainer>
                  </FilterDropDown>
                </div>
              </GridContainer>

              {appointmentsData?.length === 0 ? (
                <NoRecords message="No Appointments for today" />
              ) : (
                <TableWrapper
                  onScroll={onScrollHandler}
                  style={{
                    boxShadow,
                  }}
                >
                  <StyledTable>
                    <TableHeader>
                      <StyledTR>
                        <StyledTH>Sr. No </StyledTH>
                        <StyledTH>Patient Details </StyledTH>
                        <StyledTH>Dr. Name </StyledTH>
                        <StyledTH>Appointment Status </StyledTH>
                        <StyledTH>Appointment Type </StyledTH>
                        <StyledTH>Actions </StyledTH>
                      </StyledTR>
                    </TableHeader>

                    <tbody>{appointmentsData?.map(createAppointmentRow)}</tbody>
                  </StyledTable>
                </TableWrapper>
              )}
              {aptError && (
                <ErrorContainer>{aptError ? aptError : ""}</ErrorContainer>
              )}
              {aptSuccess && (
                <SuccessContainer>
                  {aptSuccess ? aptSuccess : ""}
                </SuccessContainer>
              )}
            </CardContainer>
          </GridContainer>
        </DesktopOnly>
      </MainContainer>
    );
  }
}

export default ManageAppointments;
