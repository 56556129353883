import { createSlice } from "@reduxjs/toolkit";

export const docAuthSlice = createSlice({
  name: "docAuth",
  initialState: {
    accessToken: "",
    phoneNumber: "",
    idToken: "",
    subscribed: false,
  },
  reducers: {
    setDocCredential: (state, action) => {
      const {
        AuthenticationResult,
        WebappAuthenticationResult,
        phoneNumber,
        subscribed,
      } = action.payload;

      state.phoneNumber = phoneNumber;
      state.subscribed = subscribed;

      //new auth api
      state.AuthenticationResult = AuthenticationResult;
      state.WebappAuthenticationResult = WebappAuthenticationResult;
    },
    logOut: (state, action) => {
      state.accessToken = null;
      state.user = null;
    },
  },
});

export const { setDocCredential, logOut } = docAuthSlice.actions;

export default docAuthSlice.reducer;

export const docAuth = (state) => state.docAuth;
