import styled from "styled-components";
import { CenterFlexContainer } from "../../Global";
import { FaArrowRight, FaSearch } from "react-icons/fa";
import { AiFillHome, AiFillWarning, AiOutlineHome } from "react-icons/ai";

//TextField Styled Components
export const TextfieldWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;
export const TextfieldContainer = styled.div`
  display: flex;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  flex-direction: column;
  justify-content: flex-start;
  width: ${(props) => (props.width ? props.width : "auto")};

  transition: 0.1s ease-out;
  @media screen and (max-width: 768px) {
    margin: 0.5rem 0;
  }
`;

export const Input = styled.input.attrs((props) => ({
  type: props.type,
}))`
  font-size: 1rem;
  line-height: 21px;
  outline: none;
  border: 2px solid ${(props) => props.theme.colors.borderColor};
  border-radius: 5px;
  padding: 0.7rem 1rem;
  color: #6c6c6c;
  transition: 0.1s ease-out;
  filter: blur(0.000001px);

  &:focus {
    border: 2px solid ${(props) => props.theme.colors.curusBlue};
  }
  &:focus + label {
    color: ${(props) => props.theme.colors.curusBlue};
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }
  &:not(:placeholder-shown) + label {
    top: 0;
    color: ${(props) => props.theme.colors.curusBlue};
    background-color: #fff;
    transform: translateY(-50%) scale(0.9);
  }
  &::placeholder {
    color: transparent;
  }
`;

export const SearchInput = styled.input.attrs((props) => ({
  type: props.type,
}))`
  display: flex;
  justify-self: end;
  font-size: 14px;

  outline: none;
  border: 2px solid transparent;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  background-color: ${({ theme }) => theme.colors.fieldColor};
  padding: 0.7rem 1rem;
  color: gray;
  transition: 0.1s ease-out;
  &:focus {
    border: 2px solid ${(props) => props.theme.colors.curusBlue};
  }
`;

export const Label = styled.label`
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  color: gray;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.2s ease-out;
  transform-origin: left top;
  pointer-events: none;
`;

export const Message = styled.small`
  color: #666;
  margin: 0;
  font-size: 12px;
  /* min-height: 17px; */
`;

export const ErrorMessage = styled.p`
  margin: 0 0 0 0.2rem;
  font-weight: 500;
  font-size: 14px;
  color: red;
  transition: all 1s ease-in-out;
`;

//Select field styled components
export const SelectFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  width: ${(props) => (props.width ? props.width : "auto")};
  & > select {
    border: 2px solid
      ${(props) => (props.active ? props.theme.colors.curusBlue : "#DEE3EB")};
    color: ${(props) => (props.active ? props.theme.colors.curusBlue : "gray")};
  }
`;
export const StyledSelectField = styled.select`
  font-size: 1rem;
  border-radius: 5px;
  line-height: 21px;
  position: relative;
  outline: none;
  border: 2px solid ${(props) => props.theme.colors.borderColor};
  padding: 0.7rem 1rem;
  color: gray;
  transition: 0.1s ease-in-out;
  filter: blur(0.000001px);
  &option:first-child {
    background-color: red;
  }
  &:focus {
    border: 2px solid ${(props) => props.theme.colors.curusBlue};
  }
`;

export const SearchIcon = styled(FaSearch)`
  position: absolute;
  height: 24px;
  width: 24px;
  top: 50%;
  right: 14.5px;
  transform: translate(0, -50%);
  color: ${(props) => props.theme.colors.borderColor};
`;

export const ImgWrap = styled(CenterFlexContainer)`
  max-height: 300px;
  max-width: 300px;
  flex-direction: column;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: 0.2rem 0;
`;

export const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const Text = styled.p`
  font-size: 16px;

  color: ${(props) => props.theme.colors.lightText};
  margin: 0 1rem;
`;

export const FilterContainer = styled(CenterFlexContainer)`
  height: 36px;
  border: 2px solid #666;
  justify-content: space-around;
  color: #6c6c6c;
  border-radius: 50px;
  padding: 0.3rem 0.7rem;

  &:hover {
    border: 2px solid ${(props) => props.theme.colors.curusBlue};
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusBlue};
  }
`;

export const BoxContainer = styled(CenterFlexContainer)`
  color: ${(props) =>
    props.active ? props.theme.colors.curusBlue : "#6c6c6c"};
  border: ${(props) =>
    props.active
      ? `2px solid ${props.theme.colors.curusBlue}`
      : `2px solid ${props.theme.colors.borderColor}`};
  border-radius: 4px;
  padding: 0.7rem 1rem;
  margin: 1rem 0;

  &:hover {
    color: ${(props) => props.theme.colors.curusBlue};
    border: 2px solid ${(props) => props.theme.colors.curusBlue};
  }
`;
export const BoxText = styled.p`
  margin: 0 0.5rem;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;

export const RightArrow = styled(FaArrowRight)`
  color: ${(props) => props.theme.colors.borderColor};
`;

//shimmering effect

export const ShimmerCard = styled.div`
  background: ${(props) => props.theme.colors.fillColor};
  position: relative;
  margin: 1.5rem;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    margin: 0.2rem;
  }
`;
export const NavbarShimerCard = styled(ShimmerCard)`
  margin: 1.5rem 1.5 0 1.5rem;
  padding: 1rem 2rem;
  @media screen and (max-width: 768px) {
    padding: 0.7rem 1rem;
    margin: 0.2rem;
  }
`;

export const ShimmerLine = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 20px;
  background-color: #eee;
  margin: 20px 0;
  border-radius: 5px;
`;
export const Shimmer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 80%
  );
  animation: shimmer 1s infinite linear;
  @keyframes shimmer {
    0% {
      transform: translateX(-300%);
    }
    100% {
      transform: translateX(300%);
    }
  }
`;
//Plan Expiry Warrnings
export const PlanValidityContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 999;
`;

export const PlanValidityWrapper = styled.div`
  display: flex;
  gap: 8px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.validity < 3 ? "#B33F3F" : "#ECDD76")};
  color: ${(props) => (props.validity < 3 ? "white" : "grey")};
  padding: 0 0.7rem;
  & h3 {
    font-size: 18px;
    margin: 0.7rem;
  }
  & p {
    font-size: 14px;
    margin: 0.7rem 0;
  }
  & button {
    border-radius: 4px;
    border: 1px solid ${(props) => (props.validity < 3 ? "white" : "grey")};
    background-color: transparent;
    padding: 8px;
    font-size: 11px;
    margin: 0.2rem 0.7rem;
    color: ${(props) => (props.validity < 3 ? "white" : "grey")};
    &:hover {
      cursor: pointer;
      background-color: ${(props) => (props.validity < 3 ? "white" : "grey")};
      color: ${(props) => (props.validity < 3 ? "#B33F3F" : "#ECDD76")};
    }
  }
  @media screen and (max-width: 768px) {
    & p {
      font-size: 12px;
      max-width: 200px;
    }
  }
`;

export const WarningIcon = styled(AiFillWarning)`
  color: ${(props) => (props.validity < 3 ? "white" : "grey")};
  height: 20px;
  width: 20px;
`;

export const ActiveHomeIcon = styled(AiFillHome)`
  color: ${(props) => props.theme.colors.curusBlue};
  height: 22px;
  width: 22px;
  margin: 1rem;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
export const InactiveHomeIcon = styled(AiOutlineHome)`
  color: ${(props) => props.theme.colors.curusBlue};
  height: 22px;
  width: 22px;
  margin: 1rem;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
