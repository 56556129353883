import { apiSlice } from "../apiSlice";

const couponEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCoupons: builder.query({
      query: () => "/coupon/coupon-code/",
      providesTags: ["Coupons"],
    }),
    verifyCoupon: builder.query({
      query: (code) => `/coupon/coupon-code/verify/?coupon_code=${code}`,
    }),
  }),
});

export const { useGetCouponsQuery, useLazyVerifyCouponQuery } = couponEndpoints;
