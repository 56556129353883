//React Imports
import React, { useState, useReducer, useContext } from "react";
import { useParams } from "react-router-dom";

//Styled Components
import {
  BtnWrap,
  CardContainer,
  DesktopOnly,
  ErrorContainer,
  GridContainer,
  LightText,
  MobileOnly,
  ResponsiveGrid,
  StyledTextField,
  SuccessContainer,
  Text,
} from "../../../../Global";
import {
  CrossIcon,
  HLine,
  Heading,
  PlusIcon,
  SubTitle,
  Title,
} from "../../../Admin/MainPages.elements";
import ShimmerCard from "../../../../components/ui/CardShimmering";

//RTK Query
import {
  useGetAllConsultationQuery,
  useAddConsultationMutation,
} from "../../../../api/docEndpoints/consultationEndpoint";

//Components
import Button from "../../../../components/ui/Button";
import ConsultationCard from "./ConsultationCard";

//Utils
import { parseDate } from "../../../../utils/parseDate";

import ReactModal from "react-modal";
import NoRecords from "../../../../components/ui/NoRecords";
import AuthContext from "../../../../context/authProvider";
import {
  darkConsultationModalStyles,
  consultationModalStyles,
} from "../../../../utils/ModalStyles";
import storage from "local-storage-fallback";
import { Heading3 } from "../../../Auth/Auth.elements";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const ACTION = {
  doctorPhoneNumber: "handleDoctorPhoneNumber",
  indications: "handleIndications",
  medications: "handleMedication",
  precautions: "handlePrecautions",
  patientDetail: "handlePatientDetail",
  labTests: "handleLabTests",
  nextCheckup: "handleNextCheckup",
  appointmentId: "handleAppointmentId",
};

const docPhone = storage.getItem("doctorPhone");
function PatientsDetails() {
  const { id } = useParams();
  const { isDarkTheme } = useContext(AuthContext);
  const [addConsutationModal, setAddConsultation] = useState(false);
  const [acError, setACError] = useState("");
  const [acSuccess, setACSuccess] = useState("");

  //option to select my consultation or consulation made by others

  const reducer = (state, action) => {
    switch (action.type) {
      case ACTION.indications:
        return { ...state, indications: action.payload };
      case ACTION.medications:
        return { ...state, medications: action.payload };
      case ACTION.precautions:
        return { ...state, precautions: action.payload };
      case ACTION.patientDetail:
        return { ...state, patientDetail: action.payload };
      case ACTION.labTests:
        return { ...state, labTests: action.payload };
      case ACTION.nextCheckup:
        return { ...state, nextCheckup: action.payload };
      case ACTION.appointmentId:
        return { ...state, appointmentId: action.payload };
    }
  };
  const [data, dispatch] = useReducer(reducer, {
    indications: "",
    medications: "",
    precautions: "",
    patientDetail: id,
    labTests: "",
    nextCheckup: "",
    appointmentId: "",
  });

  const { data: allConsultation, isLoading: isAllConsultationtLoading } =
    useGetAllConsultationQuery(id);

  const [
    addConsultation,
    { isLoading: isAddConsultationLoading, isError: isAddConsultationError },
  ] = useAddConsultationMutation();

  const createConsultationCard = (props, i) => {
    return <ConsultationCard consultation={props} i={i} />;
  };

  const handleAddConsultation = async () => {
    try {
      await addConsultation({
        doctorPhoneNumber: docPhone,
        indications: data.indications,
        medications: data.medications,
        precautions: data.precautions,
        patientDetail: data.patientDetail,
        labTests: data.labTests,
        nextCheckup: data.nextCheckup,
        appointmentId: data.appointmentId,
      });
      if (isAddConsultationError) {
        setACError(isAddConsultationError);
        return;
      }
      setACError("");
      setACSuccess("The consultation was added successfully.");
    } catch (err) {
      console.log(err);
    }
  };

  if (isAllConsultationtLoading) {
    return <ShimmerCard />;
  } else {
    return (
      <>
        {/* Consultation Modal */}
        <ReactModal
          isOpen={addConsutationModal}
          onRequestClose={() => setAddConsultation(false)}
          style={
            isDarkTheme ? darkConsultationModalStyles : consultationModalStyles
          }
        >
          <GridContainer columns="1fr auto">
            <Heading>Add Consultation</Heading>
            <CrossIcon onClick={() => setAddConsultation(false)} />
          </GridContainer>
          <GridContainer
            columns="repeat(auto-fill,minmax(250px,1fr))"
            width="100%"
            align="flex-start"
          >
            <GridContainer columns="min-content auto">
              <Text>Name:</Text>
              <LightText>{allConsultation?.name}</LightText>
            </GridContainer>
            <GridContainer columns="min-content auto">
              <Text>Age:</Text>
              <LightText>{allConsultation?.age}</LightText>
            </GridContainer>
            <GridContainer columns="min-content auto">
              <Text>Gender:</Text>
              <LightText>{allConsultation?.gender}</LightText>
            </GridContainer>
            <GridContainer columns="max-content auto">
              <Text>Blood Group:</Text>
              <LightText>{allConsultation?.blood_group}</LightText>
            </GridContainer>
          </GridContainer>
          <small>
            Patient registered on: {parseDate(allConsultation?.created_ts)}
          </small>

          <Title style={{ margin: "1.3rem 0 0 0" }}>
            Details of New Consultation
          </Title>
          <ResponsiveGrid columns="1fr 2fr" align="flex-start" width="100%">
            <StyledTextField
              onChange={(e) =>
                dispatch({
                  type: ACTION.indications,
                  payload: e.target.value,
                })
              }
              value={data.indications}
              margin="dense"
              label="Indications"
              rows={3}
              multiline
            />
            <StyledTextField
              onChange={(e) =>
                dispatch({
                  type: ACTION.medications,
                  payload: e.target.value,
                })
              }
              value={data.medications}
              margin="dense"
              label="Medications"
              rows={3}
              multiline
            />
          </ResponsiveGrid>
          <ResponsiveGrid columns="3fr 2fr" align="flex-start">
            <StyledTextField
              onChange={(e) =>
                dispatch({
                  type: ACTION.precautions,
                  payload: e.target.value,
                })
              }
              value={data.precautions}
              margin="dense"
              label="Precautions"
              rows={3}
              multiline
            />
            <StyledTextField
              onChange={(e) =>
                dispatch({
                  type: ACTION.labTests,
                  payload: e.target.value,
                })
              }
              value={data.labTests}
              margin="dense"
              label="Lab Tests"
              rows={3}
              multiline
            />
          </ResponsiveGrid>
          <Title style={{ margin: "1.3rem 0 0 0" }}>Next Checkup</Title>
          <StyledTextField
            onChange={(e) =>
              dispatch({
                type: ACTION.nextCheckup,
                payload: e.target.value,
              })
            }
            value={data.nextCheckup}
            fullWidth
            margin="dense"
            label="Enter Details"
            multiline
          />
          {acError && <ErrorContainer>{acError}</ErrorContainer>}
          <BtnWrap>
            <Button
              disabled={isAddConsultationLoading}
              isloading={isAddConsultationLoading}
              orange
              onclick={handleAddConsultation}
              label="Add Consultation"
            />
          </BtnWrap>
        </ReactModal>
        <DesktopOnly>
          <GridContainer align="stretch" columns="1fr">
            <CardContainer style={{ width: "100%" }}>
              <GridContainer rows="auto 1fr" columns="1fr" width="100%">
                <GridContainer columns="1fr" width="100%">
                  <Heading style={{ margin: "0" }}>
                    {allConsultation?.name} ({allConsultation?.age} y/o)
                  </Heading>

                  <LightText style={{ margin: "0" }}>
                    {allConsultation?.gender}{" "}
                    {allConsultation?.blood_group !== "None" &&
                      allConsultation?.blood_group}
                  </LightText>
                  <LightText>{parseDate(allConsultation?.dob)}</LightText>
                </GridContainer>

                <GridContainer
                  margin="0 0 1rem 0"
                  columns="1fr auto "
                  width="100%"
                >
                  <Heading3>Consultations</Heading3>

                  <PlusIcon onClick={() => setAddConsultation(true)} />
                </GridContainer>
                <GridContainer
                  columns="1fr"
                  width="100%"
                  style={{
                    overflow: "auto",
                    placeContent: "start",
                  }}
                >
                  {allConsultation?.my_consultations?.length === 0 ? (
                    <NoRecords message="You have not consulted this patient." />
                  ) : (
                    allConsultation?.my_consultations?.map(
                      createConsultationCard
                    )
                  )}
                </GridContainer>
              </GridContainer>
            </CardContainer>
          </GridContainer>
        </DesktopOnly>
        <MobileOnly>
          <GridContainer
            align="stretch"
            margin="0.2rem"
            rgap="0.2rem"
            columns="1fr"
          >
            <CardContainer>
              <GridContainer columns="1fr" width="100%">
                <Heading margin="0 0 1rem 0" width="100%">
                  Patient Profile
                </Heading>
                <GridContainer columns="1fr" width="100%">
                  <GridContainer columns="2fr 3fr">
                    <SubTitle>Name</SubTitle>
                    <LightText style={{ margin: "0" }}>
                      {allConsultation?.name}
                    </LightText>
                  </GridContainer>
                  <HLine />
                  <GridContainer columns="2fr 3fr">
                    <SubTitle>Gender</SubTitle>
                    <LightText style={{ margin: "0" }}>
                      {allConsultation?.gender}
                    </LightText>
                  </GridContainer>
                  <HLine />
                  <GridContainer columns="2fr 3fr">
                    <SubTitle>Age</SubTitle>
                    <LightText style={{ margin: "0" }}>
                      {allConsultation?.age}
                    </LightText>
                  </GridContainer>
                  <HLine />
                  <GridContainer columns="2fr 3fr">
                    <SubTitle>Blood Group</SubTitle>
                    <LightText style={{ margin: "0" }}>
                      {allConsultation?.blood_group}
                    </LightText>
                  </GridContainer>
                  <HLine />
                  <GridContainer columns="2fr 3fr">
                    <SubTitle>Date of Birth</SubTitle>
                    <LightText style={{ margin: "0" }}>
                      {parseDate(allConsultation?.dob)}
                    </LightText>
                  </GridContainer>
                </GridContainer>
              </GridContainer>
            </CardContainer>
            <CardContainer>
              <GridContainer rows="auto 1fr" columns="1fr" width="100%">
                <GridContainer
                  margin="0 0 1rem 0"
                  columns="1fr auto"
                  width="100%"
                >
                  <Heading>Consultations</Heading>
                  <PlusIcon onClick={() => setAddConsultation(true)} />
                </GridContainer>
                <GridContainer
                  columns="1fr"
                  width="100%"
                  style={{
                    height: "350px",
                    overflow: "auto",
                    placeContent: "start",
                  }}
                >
                  {allConsultation?.my_consultations.length === 0 ? (
                    <NoRecords message="No consultations were provided to this patient." />
                  ) : (
                    allConsultation?.my_consultations.map(
                      createConsultationCard
                    )
                  )}
                </GridContainer>
                {acSuccess && <SuccessContainer>{acSuccess}</SuccessContainer>}
              </GridContainer>
            </CardContainer>
          </GridContainer>
        </MobileOnly>
      </>
    );
  }
}

export default PatientsDetails;
