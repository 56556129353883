import React, {
  cloneElement,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Price, AggregatorCardContainer } from "./Leader.elements";

import {
  CheckIcon,
  Container,
  HLine,
  Title,
} from "../Admin/MainPages.elements";
import { Heading3, PlanCard } from "../Auth/Auth.elements";

import { FormControl, Radio, RadioGroup } from "@mui/material";
import Button from "../../components/ui/Button";
import {
  AnchorText,
  BorderedGridContainer,
  BtnWrap,
  GridContainer,
  LightText,
  MainContainer,
  SharpCard,
  BorderedButton,
  MobileOnly,
  DesktopOnly,
  StyledFormControlLabel,
  StyledTextField,
} from "../../Global";
import { FaCheck } from "react-icons/fa";

import NoRecords from "../../components/ui/NoRecords";
import { useGetActivePlanQuery } from "../../api/userEndpoints/authApiSlice";
import AuthContext from "../../context/authProvider";
import axios from "axios";
import {
  useGetCouponsQuery,
  useLazyVerifyCouponQuery,
} from "../../api/userEndpoints/couponEndpoint";
import ReactModal from "react-modal";
import { availabilityModalStyles } from "../../utils/ModalStyles";
import errorVector from "../../assets/error-vector.svg";

const plans = [
  {
    id: 1,
    title: "Digital Clinic Annual Plan",
    price: 799,
    days: "365 Days",
    months: 12,
    features: [
      "24/7 Emergency Care",
      "Hospitalization Coverage",
      "Surgical Coverage",
    ],
  },
  {
    id: 2,
    title: "Digital Clinic Value Plan",
    price: 749,
    days: "730 Days",
    months: 24,
    features: [
      "24/7 Emergency Care",
      "Hospitalization Coverage",
      "Surgical Coverage",
      "Maternity Coverage",
      "Diagnostic Tests",
    ],
  },
  {
    id: 3,
    title: "Digital Clinic Free Plan",
    price: 0,
    days: "7 Days",
    months: 0,
    features: [
      "24/7 Emergency Care",
      "Hospitalization Coverage",
      "Surgical Coverage",
      "Maternity Coverage",
      "Diagnostic Tests",
    ],
  },
];

function PlansPage() {
  const { data: activePlan } = useGetActivePlanQuery();
  const { data: coupons } = useGetCouponsQuery();

  const [selectedPlan, setSelectedPlan] = useState();
  const [appliedOffers, setAppliedOffers] = useState([]);

  const currentPlan = activePlan && activePlan[0];

  const cards = [
    {
      id: 0,
      number: "1",
      title: "Select Plan",
      body: (
        <CardSelect
          setSelectedPlan={setSelectedPlan}
          selectedPlan={selectedPlan}
          currentPlan={currentPlan}
        />
      ),
      status: "changing",
    },
    {
      id: 1,
      number: "2",
      title: "Apply Offers",
      body: (
        <OfferCardBody
          appliedOffers={appliedOffers}
          setAppliedOffers={setAppliedOffers}
        />
      ),
      status: "disabled",
    },
    {
      id: 2,
      number: "3",
      title: "Payment Options",
      body: <PaymentCardBody setSelectedPlan={setSelectedPlan} />,
      status: "disabled",
    },
  ];

  //cards have 3 states:
  //disabled:when previous cards are still pending
  //pending: when Card body is not updated
  //changing: when Card is being updated
  //done:when Card body is updated

  function reducer(state, action) {
    switch (action.type) {
      case "changing":
        const { id, status } = action.payload;
        let newArr = state.map((c) => {
          if (c.id === id) {
            return {
              ...c,
              status: status,
            };
          } else {
            if (c.id === 2) {
              return {
                ...c,
                status: "disabled",
              };
            } else {
              return {
                ...c,
                status: "done",
              };
            }
          }
        });
        return newArr;
      case "initiateOffer":
        let offerArray = state.map((c) => {
          if (c.id === 0) {
            return {
              ...c,
              status: "done",
            };
          } else if (c.id === 1) {
            return {
              ...c,
              status: "changing",
            };
          } else if (c.id === 2) {
            return {
              ...c,
              status: "disabled",
            };
          }
        });
        return offerArray;

      case "inititatePayment":
        let paymentArray = state.map((c) => {
          if (c.id === 0) {
            return {
              ...c,
              status: "done",
            };
          } else if (c.id === 1) {
            return {
              ...c,
              status: "done",
            };
          } else if (c.id === 2) {
            return {
              ...c,
              status: "changing",
            };
          }
        });
        return paymentArray;

      default:
        return;
    }
  }
  const [state, dispatch] = useReducer(reducer, cards);

  const discount = appliedOffers
    .map((id) => coupons.find((c) => c.code === id)?.reward_value)
    .reduce((pv, cv) => pv + cv, 0);

  // (appliedOffers
  //   .map((id) => coupons[id].dPercent)
  //   .reduce((pv, cv) => pv + cv, 0) *
  //   selectedPlan?.price *
  //   12) /
  //   100;

  return (
    <MainContainer>
      <MobileOnly>
        <GridContainer
          margin="0.2rem"
          columns="1fr"
          rgap="0.2rem"
          align="flex-start"
        >
          <Container gap="8px" justify="flex=start" align="stretch">
            {state.map((pc) => (
              <PaymentPlanCard
                dispatch={dispatch}
                selectedPlan={selectedPlan}
                appliedOffers={appliedOffers}
                {...pc}
              />
            ))}
          </Container>
          {selectedPlan ? (
            <SharpCard margin="0">
              <Container align="start" width="100%" margin="1rem 0" gap="1rem">
                <Title>Price</Title>
                <HLine style={{ margin: "0" }}></HLine>
                <GridContainer columns="2fr 1fr 1fr" width="100%">
                  <LightText>Subscription Amount</LightText>
                  <LightText> ( {selectedPlan.price} x 12)</LightText>
                  <LightText>Rs. {selectedPlan.price * 12} </LightText>
                </GridContainer>
                <GridContainer columns="2fr 1fr 1fr" width="100%">
                  <LightText>GST</LightText>
                  <LightText> @18%</LightText>
                  <LightText>Rs. {selectedPlan.price * 12 * 0.18} </LightText>
                </GridContainer>
                <HLine style={{ margin: "0" }} />
                <GridContainer columns="3fr  1fr" width="100%">
                  <LightText>Gross Amount</LightText>
                  <LightText>
                    Rs.
                    {selectedPlan.price * 12 + selectedPlan.price * 12 * 0.18}
                  </LightText>
                </GridContainer>
                {discount !== 0 && (
                  <GridContainer columns="3fr 1fr" width="100%">
                    <LightText> Discount and Referrals</LightText>
                    <LightText> - Rs.{discount}</LightText>
                  </GridContainer>
                )}

                <HLine style={{ margin: "0" }} />
                <GridContainer columns="3fr 1fr" width="100%">
                  <b> Net Payable Amount</b>
                  <b>
                    {" "}
                    Rs.{" "}
                    {selectedPlan.price * 12 +
                      selectedPlan.price * 12 * 0.18 -
                      discount}
                  </b>
                </GridContainer>
              </Container>
            </SharpCard>
          ) : (
            <SharpCard margin="0" align="center" justify="center">
              <NoRecords message="Please select a plan to generate reciept"></NoRecords>
            </SharpCard>
          )}
        </GridContainer>
      </MobileOnly>
      <DesktopOnly>
        <GridContainer
          margin="1.5rem"
          columns="2fr 1fr"
          gap="1.5rem"
          align="flex-start"
        >
          <Container gap="8px" justify="flex=start" align="stretch">
            {state.map((pc) => (
              <PaymentPlanCard
                dispatch={dispatch}
                selectedPlan={selectedPlan}
                appliedOffers={appliedOffers}
                {...pc}
              />
            ))}
          </Container>
          {selectedPlan ? (
            <SharpCard margin="0">
              <Container align="start" width="100%" gap="1rem">
                <Title>Price</Title>
                <HLine style={{ margin: "0" }}></HLine>

                <GridContainer columns="2fr 1fr 1fr" width="100%">
                  <LightText>Subscription Amount</LightText>
                  <LightText>
                    {" "}
                    ( {selectedPlan.price} * {selectedPlan.months})
                  </LightText>
                  <LightText>
                    Rs. {selectedPlan.price * selectedPlan.months}{" "}
                  </LightText>
                </GridContainer>
                <GridContainer columns="2fr 1fr 1fr" width="100%">
                  <LightText>GST</LightText>
                  <LightText> @18%</LightText>
                  <LightText>
                    Rs.{" "}
                    {(
                      parseInt(selectedPlan.price) *
                      selectedPlan.months *
                      0.18
                    ).toFixed(2)}
                  </LightText>
                </GridContainer>
                <HLine style={{ margin: "0" }} />
                <GridContainer columns="3fr  1fr" width="100%">
                  <LightText>Gross Amount</LightText>
                  <LightText>
                    Rs.
                    {(
                      parseInt(selectedPlan.price) * selectedPlan.months +
                      parseInt(selectedPlan.price) * selectedPlan.months * 0.18
                    ).toFixed(2)}
                  </LightText>
                </GridContainer>
                {discount !== 0 && (
                  <GridContainer columns="3fr 1fr" width="100%">
                    <LightText> Discount and Referrals</LightText>
                    <LightText> - Rs.{discount}</LightText>
                  </GridContainer>
                )}

                <HLine style={{ margin: "0" }} />
                <GridContainer columns="3fr 1fr" width="100%">
                  <b> Net Payable Amount</b>
                  <b>
                    Rs.
                    {(
                      parseInt(selectedPlan.price) * selectedPlan.months +
                      parseInt(selectedPlan.price) *
                        selectedPlan.months *
                        0.18 -
                      discount
                    ).toFixed(2)}
                  </b>
                </GridContainer>
              </Container>
            </SharpCard>
          ) : (
            <SharpCard margin="0" align="center" justify="center">
              <NoRecords message="Please select a plan to generate reciept"></NoRecords>
            </SharpCard>
          )}
        </GridContainer>
      </DesktopOnly>
    </MainContainer>
  );
}

export default PlansPage;

function PaymentPlanCard({
  id,
  number,
  title,
  body,
  status,
  dispatch,
  selectedPlan,
  appliedOffers,
}) {
  const CardBody = cloneElement(body, {
    dispatch: dispatch,
    selectedPlan: selectedPlan,
    appliedOffers: appliedOffers,
  });
  function initiateChange() {
    dispatch({
      type: "changing",
      payload: {
        id: id,
        status: "changing",
      },
    });
  }
  return (
    <SharpCard margin="0" padding="0">
      <GridContainer
        style={
          status === "changing"
            ? {
                background: "#25337D",
                color: "white",
                padding: "0 1rem",
                borderRadius: "4px 4px 0 0",
              }
            : {
                background: "white",
                color: "#25337D",
                padding: "0 1rem",
                borderRadius: "4px 4px 0 0",
              }
        }
        width="100% "
        columns="auto 1fr auto"
      >
        <LightText style={{ color: "inherit" }}>{number}</LightText>
        <GridContainer columns="auto auto" gap="0">
          <Heading3
            style={
              status === "changing"
                ? {
                    color: "white",
                    textTransform: "uppercase",
                    fontWeight: "600",
                    fontSize: "14px",
                  }
                : {
                    color: "grey",
                    textTransform: "uppercase",
                    fontWeight: "600",
                    fontSize: "14px",
                  }
            }
          >
            {title}
          </Heading3>
          {status === "done" && <CheckIcon small style={{ margin: "0 8px" }} />}
        </GridContainer>
        {status !== "changing" && status !== "disabled" && (
          <BorderedButton onClick={initiateChange}>CHANGE</BorderedButton>
        )}
      </GridContainer>
      {status === "changing" && CardBody}
    </SharpCard>
  );
}

//first card body
function CardSelect({ setSelectedPlan, selectedPlan, dispatch, currentPlan }) {
  const [isFreeTrialClaimed, setFreeTrialClaimed] = useState(true);
  const { auth } = useContext(AuthContext);

  const handlePlanSelect = (plan) => {
    if (currentPlan?.plan_id !== plan.id) {
      setSelectedPlan(plan);
    }
  };

  function initiateOffer() {
    dispatch({
      type: "initiateOffer",
    });
  }
  //check if user has used his free trial

  useEffect(() => {
    axios
      .get("https://dev.curus.co.in/subscription/free-trial-status", {
        headers: { authorization: `Bearer ${auth.accessToken}` },
      })
      .then((response) => {
        setFreeTrialClaimed(response?.data.is_free_trial_claimed);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Container margin="1rem" width="100%">
      <GridContainer columns="1fr" width="100%" rgap="1rem" gap="1rem">
        {/* Digital Clinic Annual Plan Card */}
        <PlanCard
          onClick={() => {
            handlePlanSelect(plans[0]);
          }}
          active={
            selectedPlan && selectedPlan.title === "Digital Clinic Annual Plan"
          }
          subscribed={currentPlan?.plan_id === 1}
        >
          <Container align="flex-start">
            <Title>{plans && plans[0].title}</Title>
            <LightText>validity : {plans[0].days}</LightText>
          </Container>
          <Container>
            <Price>Rs. {plans && plans[0]?.price}</Price>
            <LightText>/month</LightText>
            <small>Payable Annually</small>
          </Container>

          <LightText>
            {plans && plans[0]?.description}
            {/* {plans[0].features.map((feature) => (
              <li key={feature}>{feature}</li>
            ))} */}
          </LightText>

          {selectedPlan?.title === "Digital Clinic Annual Plan" && (
            <FaCheck
              style={{
                position: "absolute",
                right: "-8px",
                top: "-8px",
                color: "white",
                borderRadius: "100%",
                background: "green",
                padding: "4px",
                transition: "0.3s",
              }}
            />
          )}
          {currentPlan?.plan_id === 1 && (
            <FaCheck
              style={{
                position: "absolute",
                right: "-8px",
                top: "-8px",
                color: "white",
                borderRadius: "100%",
                background: "grey",
                padding: "4px",
                transition: "0.3s",
              }}
            />
          )}
        </PlanCard>
        {/* Digital Clinic Value Plan Card */}
        <PlanCard
          onClick={() => {
            handlePlanSelect(plans && plans[1]);
          }}
          active={
            selectedPlan && selectedPlan?.title === "Digital Clinic Value Plan"
          }
          subscribed={currentPlan?.plan_id === 2}
        >
          <Container align="flex-start">
            <Title>{plans && plans[1].title}</Title>
            <LightText>validity : {plans[1].days}</LightText>
          </Container>
          <Container>
            <Price>Rs. {plans && plans[1]?.price}</Price>
            <LightText>/month</LightText>
            <small>Payable Annually</small>
          </Container>

          <LightText>
            {/* {plans && plans[1]?.description} */}
            {/* {plans[1]?.features.map((feature) => (
              <li key={feature}>{feature}</li>
            ))} */}
          </LightText>
          {/* <Button>Select Plan</Button> */}
          {selectedPlan?.title === "Digital Clinic Value Plan" && (
            <FaCheck
              style={{
                position: "absolute",
                right: "-8px",
                top: "-8px",
                color: "white",
                borderRadius: "100%",
                background: "green",
                padding: "4px",
                transition: "0.3s",
              }}
            />
          )}
          {currentPlan?.plan_id === 2 && (
            <FaCheck
              style={{
                position: "absolute",
                right: "-8px",
                top: "-8px",
                color: "white",
                borderRadius: "100%",
                background: "grey",
                padding: "4px",
                transition: "0.3s",
              }}
            />
          )}
        </PlanCard>
        {/* Digital Clinic Free trial Card */}

        {!isFreeTrialClaimed && (
          <PlanCard
            onClick={() => {
              handlePlanSelect(plans && plans[2]);
            }}
            active={
              selectedPlan && selectedPlan?.title === "Digital Clinic Free Plan"
            }
            subscribed={currentPlan?.plan_id === 3}
          >
            <Container align="flex-start">
              <Title>{plans && plans[2].title}</Title>
              <LightText>validity : {plans[2].days}</LightText>
            </Container>
            <Container>
              <Price>Rs. {plans && plans[2]?.price}</Price>
              <LightText>/month</LightText>
            </Container>

            <LightText></LightText>

            {selectedPlan?.title === "Digital Clinic Free Plan" && (
              <FaCheck
                style={{
                  position: "absolute",
                  right: "-8px",
                  top: "-8px",
                  color: "white",
                  borderRadius: "100%",
                  background: "green",
                  padding: "4px",
                  transition: "0.3s",
                }}
              />
            )}
            {currentPlan?.plan_id === 3 && (
              <FaCheck
                style={{
                  position: "absolute",
                  right: "-8px",
                  top: "-8px",
                  color: "white",
                  borderRadius: "100%",
                  background: "grey",
                  padding: "4px",
                  transition: "0.3s",
                }}
              />
            )}
          </PlanCard>
        )}
      </GridContainer>
      {selectedPlan !== undefined && (
        <BtnWrap justify="start" margin="0.7rem 0">
          <Button orange label="Proceed" onclick={initiateOffer}></Button>
        </BtnWrap>
      )}
    </Container>
  );
}

//second card body
function OfferCardBody({ appliedOffers, setAppliedOffers, dispatch }) {
  const { data: coupons } = useGetCouponsQuery();
  const [verifyCoupon, verifyCouponResults] = useLazyVerifyCouponQuery();
  function handleOfferSelection(id) {
    setAppliedOffers((curOffer) => {
      if (curOffer.includes(id)) {
        return curOffer.filter((curId) => curId !== id);
      } else {
        return [...curOffer, id];
      }
    });
  }

  const [invalidCouponModal, setInvalidCoupon] = useState(false);
  var invalidOffers = [];
  async function initiatePayment() {
    try {
      for (let i = 0; i < appliedOffers.legth; i++) {
        const res = await verifyCoupon(appliedOffers[i]);
        if (!res.data) {
          invalidOffers.push(appliedOffers[i]);
        }
      }
      if (invalidOffers.length === 0) {
        dispatch({
          type: "inititatePayment",
        });
      } else {
        setInvalidCoupon(true);
      }
    } catch (e) {}
  }
  return (
    <Container gap="8px" margin="1rem 2rem" align="flex-start" width="100%">
      <ReactModal
        isOpen={invalidCouponModal}
        onRequestClose={() => setInvalidCoupon(false)}
        style={availabilityModalStyles}
      >
        <img src={errorVector} height={100} />
        <LightText>
          Following Coupon Codes are Invalid:
          <ul style={{ margin: "4px" }}>
            {invalidOffers.map((ci) => (
              <li>{ci}</li>
            ))}
          </ul>
        </LightText>
      </ReactModal>
      {/* <LightText>Enter Referral code:</LightText>
      <GridContainer
        width="calc(100% - 3rem)"
        margin="0 0 1rem 0"
        columns="1fr auto"
      >
        <StyledTextField
          margin="dense"
          fullWidth
          label="Offer/Refferrel Code"
        ></StyledTextField>
        <Button orange label="Apply"></Button>
      </GridContainer> */}
      <LightText>Available Offers</LightText>

      {coupons === undefined ? (
        <NoRecords message="No Coupons Available" />
      ) : (
        coupons.map((coupon, i) => {
          return (
            <BorderedGridContainer
              width="calc(100% - 5rem)"
              padding="0.7rem 1rem"
              columns="auto 1fr auto"
            >
              <LightText>{i + 1}</LightText>
              <Container align="flex-start">
                <b>{coupon.title}</b>
                <LightText>{coupon.description}</LightText>
              </Container>

              <input
                type="checkbox"
                label="Apply"
                checked={appliedOffers.includes(coupon.code)}
                onClick={() => handleOfferSelection(coupon.code)}
              ></input>
            </BorderedGridContainer>
          );
        })
      )}

      <BtnWrap justify="flex-start">
        <Button
          orange
          loading={verifyCouponResults.isLoading}
          onclick={initiatePayment}
          label="GO TO PAYMENT"
        ></Button>
      </BtnWrap>
    </Container>
  );
}

//third card body
function PaymentCardBody() {
  const [selectedPayment, setSelectedPayment] = useState("UPI");
  return (
    <Container align="flex-start" width="100%" margin="0">
      {/* <GridContainer columns="1fr" width="100%">
        <Container gap="8px">
          <GridContainer columns="3fr  2fr" width="100%">
            <LightText> Hospital Name</LightText>
            <LightText> ABC Hospital</LightText>
          </GridContainer>
          <GridContainer columns="3fr  2fr" width="100%" align="flex-start">
            <LightText> Hospital Address</LightText>
            <LightText>
              {" "}
              Street: Nr Tolani School, Sher E Punjab Society, Andheri(e) City:
              Mumbai,India
            </LightText>
          </GridContainer>
          <HLine />
          <GridContainer columns="3fr 1fr 1fr" width="100%">
            <LightText> Subscription Plan</LightText>
            <LightText> Value Plan</LightText>
            <LightText> Rs.749</LightText>
          </GridContainer>
          <GridContainer columns="3fr  2fr" align="flex-start" width="100%">
            <LightText> Plan Features</LightText>
            <LightText>
              {" "}
              <ul style={{ margin: "0" }}>
                {[
                  "24/7 Emergency Care",
                  "Hospitalization Coverage",
                  "Surgical Coverage",
                ].map((f) => (
                  <li>{f}</li>
                ))}
              </ul>
            </LightText>
          </GridContainer>
        </Container>
      </GridContainer> */}
      <FormControl fullWidth>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectedPayment}
          onChange={(e) => setSelectedPayment(e.target.value)}
        >
          <AggregatorCardContainer
            onClick={() => setSelectedPayment("UPI")}
            active={selectedPayment === "UPI"}
          >
            <StyledFormControlLabel
              value="UPI"
              control={<Radio />}
              label="UPI"
              style={{ padding: "0.7rem 1rem" }}
            ></StyledFormControlLabel>
          </AggregatorCardContainer>

          <AggregatorCardContainer
            onClick={() => setSelectedPayment("Wallets")}
            active={selectedPayment === "Wallets"}
          >
            <StyledFormControlLabel
              value="Wallets"
              control={<Radio />}
              label="Wallets"
              style={{ padding: "0.7rem 1rem" }}
            ></StyledFormControlLabel>
          </AggregatorCardContainer>

          <AggregatorCardContainer
            onClick={() => setSelectedPayment("Card")}
            active={selectedPayment === "Card"}
          >
            <StyledFormControlLabel
              value="Card"
              control={<Radio />}
              label="Credit / Debit / ATM Card"
              style={{ padding: "0.7rem 1rem" }}
            ></StyledFormControlLabel>
          </AggregatorCardContainer>

          <AggregatorCardContainer
            onClick={() => setSelectedPayment("NetBanking")}
            active={selectedPayment === "NetBanking"}
          >
            <StyledFormControlLabel
              value="NetBanking"
              control={<Radio />}
              label="Net Banking"
              style={{ padding: "0.7rem 1rem" }}
            ></StyledFormControlLabel>
          </AggregatorCardContainer>
        </RadioGroup>
      </FormControl>
      {/* <BtnWrap justify="flex-start" margin="0 0 0 1rem">
        <Button label="Pay Now"></Button>
      </BtnWrap> */}
    </Container>
  );
}
