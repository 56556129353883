import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut, setDocCredential } from "../reduxSlices/docAuthSlice";
import { docAuthApiSlice } from "./docEndpoints/docAuthApiSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://l1x3gkp512.execute-api.ap-south-1.amazonaws.com/test/",

  prepareHeaders: (headers, { getState }) => {
    const token = getState().docAuth.AuthenticationResult.IdToken;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
  credentials: "include",
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 403) {
    const refreshResult = await api.dispatch(
      docAuthApiSlice.endpoints.docRefresh.initiate()
    );
    console.log(refreshResult);
    if (refreshResult?.data) {
      const user = api.getState().docAuth.user;
      //store the new token
      api.dispatch(setDocCredential({ ...refreshResult.data, user }));

      //retru the original query with result
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const docApiSlice = createApi({
  reducerPath: "docApi",
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({}),
});
