import { docApiSlice } from "../docApiSlice";

export const scheduleEndpoints = docApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchedule: builder.query({
      query: (id) => `/doctor-hospital-schedule?hospital_place_id=${id}`,
      providesTags: ["DoctorSchedule"],
    }),
    updateDocSchedule: builder.mutation({
      query: (data) => ({
        url: `/doctor-hospital-schedule?doctor_hospital_id=${data.id}`,
        method: "PUT",
        body: {
          doctor_hospital_id: data.id,
          delete: data.delete,
          add: data.add,
        },
      }),

      invalidatesTags: ["Doc", "DoctorSchedule"],
    }),

    addSchedule: builder.mutation({
      query: (data) => ({
        url: `/doctor-hospital-schedule`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["DoctorSchedule"],
    }),
  }),
});
//data format for addSchedule
// [
//     {
//         "start_time": "06:00:00",
//         "end_time": "17:00:00",
//         "days_of_week": [
//             3
//         ]
//     },
//     {
//         "start_time": "08:00:00",
//         "end_time": "18:40:00",
//         "days_of_week": [
//             2
//         ]
//     },
//     {
//         "start_time": "09:00:00",
//         "end_time": "17:00:00",
//         "days_of_week": [
//             0,
//             1,
//             2,
//             4
//         ]
//     }
// ]

export const { useGetScheduleQuery } = scheduleEndpoints;
export const { useUpdateDocScheduleMutation } = scheduleEndpoints;
export const { useAddScheduleMutation } = scheduleEndpoints;
