import { docApiSlice } from "../docApiSlice";

export const practiceChargeEndpoint = docApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDocPracticeCharge: builder.query({
      query: (id) => `/doctor-hospital-practice/${id}`,
      providesTags: ["DocPracticeCharge"],
    }),
    addPracticeCharge: builder.mutation({
      query: (data) => ({
        url: `/doctor-hospital-practice/${data.id}`,
        method: "POST",
        body: data.practiceCharges,
      }),
      invalidatesTags: ["DocPracticeCharge"],
    }),
  }),
});

export const { useGetDocPracticeChargeQuery } = practiceChargeEndpoint;
export const { useAddPracticeChargeMutation } = practiceChargeEndpoint;
