import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import {
  CardContainer,
  GridContainer,
  MainContainer,
  Text,
  TextButton,
} from "../../Global";
import { File, FileName, Heading3 } from "../Auth/Auth.elements";
import {
  BtnWrap,
  Heading,
  StyledTable,
  StyledTD,
  StyledTH,
  StyledTR,
  TableWrapper,
  DeleteIcon,
  TableHeader,
} from "../../pages/Admin/MainPages.elements";

import Button from "../../components/ui/Button";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
import { InputField } from "../../components/ui/InputField";
import TextField from "../../components/ui/TextField";
import NoRecords from "../../components/ui/NoRecords";
import useLogout from "../../hooks/useLogout";

const GET_KYC_DOCS = "/auth/onboarding";
const ADD_KYC_DOCS = "/auth/onboarding?action=add";
const REMOVE_KYC_DOCS = "/auth/onboarding?action=remove";
function UnderProcess() {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const navigate = useNavigate();
  const logout = useLogout();
  const kycFileRef = useRef();

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [kycDocs, setKycDocs] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [kycFiles, setKycFiles] = useState([]);
  const [kycError, setKycError] = useState("");
  const [kycDocCount, setKycDocCount] = useState(0);
  const [isUploading, setUploading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    getKycDocs();

    return () => {
      isMounted = false;
    };
  }, []);

  //get Kyc Docs on actions
  async function getKycDocs() {
    try {
      const response = await axiosPrivate.get(GET_KYC_DOCS, {
        headers: {
          Authorization: `Bearer ${auth?.accessToken}`,
        },
      });
      setKycDocs(response.data.kyc_docs);
    } catch (err) {}
  }

  function handleSubmit(e) {
    e.preventDefault();
    const data = new FormData();
    data.append("action", "add");
    // if (kycFiles.length < 2) {
    //   alert("Please upload atleast 2 Files for KYC.");
    //   return;
    // }
    kycFiles.forEach((item) => {
      console.log(item);
      data.append("kyc", item);
    });

    const fd = new FormData();
    for (var i = 0; i < kycFiles.length; i++) {
      if (kycFiles[i].size > 0) {
        fd.append("file", kycFiles[i]);
      }
    }

    setUploading(true);
    axiosPrivate
      .put(
        ADD_KYC_DOCS,

        fd,
        {
          headers: { Authorization: `Bearer ${auth?.accessToken}` },
        }
      )
      .then((response) => {
        setKycFiles([]);
        getKycDocs();
        setUploading(false);
      })
      .catch((err) => {
        console.log(err);
        setUploading(false);
      });
  }

  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  function createKycRow(doc, i) {
    return (
      <KycRow
        i={i}
        key={i}
        doc={doc}
        docs={kycDocs}
        addSelectedRows={addSelectedRows}
        removeSelectedRows={removeSelectedRows}
        selectedRows={selectedRows}
        setError={setError}
        setSuccess={setSuccess}
        getKycDocs={getKycDocs}
      />
    );
  }
  function addSelectedRows(i) {
    setSelectedRows([...selectedRows, i]);
  }

  function removeSelectedRows(i) {
    const result = arrayRemove(selectedRows, i);
    setSelectedRows([...result]);
  }

  function uploadKyc() {
    document.getElementById("kycFile").click();
  }

  //handle KYC input
  function handleKycInput(e) {
    var files = e.target.files;
    if (
      e.target.files[0]?.size > 1048576 ||
      e.target.files[1]?.size > 1048576
    ) {
      setKycError("KYC Doc must be less than 1MB");
      kycFileRef.current.value = [];
      setKycFiles([]);
      return kycError;
    } else {
      // var kycFilesArr = Array.prototype.slice.call(files);
      setKycDocCount(kycDocCount + 1);
      setKycFiles((curFiles) => [...curFiles, ...Array.from(files)]);
      setKycDocCount(e.target.files.length);
    }
  }
  console.log(kycFiles);
  //handle KYC docs delete
  function removeKycFile(f) {
    setKycFiles(kycFiles.filter((x) => x !== f));
    setKycError("");
    setKycDocCount(kycDocCount - 1);
  }
  //delte selected rows
  function deleteMultipleRows() {
    axiosPrivate
      .put(REMOVE_KYC_DOCS, {
        action: "remove",
        doc_id: selectedRows,

        headers: {
          Authorization: `Bearer ${auth?.accessToken}`,
        },
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          getKycDocs();

          setError("");
          setSuccess(`Doc with name ${kycFiles.name} deleted successfully.`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //removve kycerror on file upload
  useEffect(() => {
    setKycError("");
  }, [kycFiles.length]);

  //handle logout function
  const signOut = async () => {
    await logout();

    navigate("/login");
  };
  return (
    <MainContainer>
      <CardContainer margin="1.2rem">
        {/* <ImgWrap>
          <Img src={processing} />
        </ImgWrap> */}
        <GridContainer width="100%" columns="1fr 80px">
          <Heading>Verification under process.</Heading>
          <TextButton onClick={signOut}>Logout</TextButton>
        </GridContainer>

        <Heading3 style={{ margin: "1rem 0" }}>
          Verify and Update your KYC Documents
        </Heading3>
        {kycDocs.length !== 0 ? (
          <>
            <TableWrapper style={{ height: "auto", margin: "0 1rem" }}>
              <StyledTable>
                <TableHeader>
                  <StyledTR>
                    <StyledTH></StyledTH>
                    <StyledTH>S.No</StyledTH>
                    <StyledTH>File Name</StyledTH>
                    <StyledTH>View</StyledTH>
                    <StyledTH>Actions</StyledTH>
                  </StyledTR>
                </TableHeader>
                <tbody>{kycDocs.map(createKycRow)}</tbody>
              </StyledTable>
            </TableWrapper>

            <BtnWrap justify="flex-end">
              <Button
                red
                disabled={selectedRows.length === 0}
                onclick={() => {
                  deleteMultipleRows();
                }}
                label="Delete Selected"
              />
            </BtnWrap>
          </>
        ) : (
          <NoRecords message="No Kyc Documents found." />
        )}

        <form
          onSubmit={handleSubmit}
          method="post"
          encType="multipart/form-data"
        >
          <div>
            <InputField
              btnLabel="Upload KYC Documents"
              handleClick={uploadKyc}
              message="upload in PDF,JPG,JPEG, or PNG formats only"
              error={kycError ? kycError : ""}
            />
            {kycFiles.length !== 0 &&
              kycFiles.map((x, i) => {
                return (
                  <File key={i}>
                    <FileName>
                      {i + 1}.&nbsp;{x.name}
                    </FileName>
                    <span>
                      <DeleteIcon onClick={() => removeKycFile(x)} />
                    </span>
                  </File>
                );
              })}
          </div>
          {kycFiles.length !== 0 && kycFiles.length + kycDocs.length < 2 && (
            <Text style={{ margin: "0 1rem" }}>
              {2 - kycDocs.length - kycFiles.length} &nbsp;{" "}
              {2 - kycFiles.length == 2 ? "files" : "file"} required
            </Text>
          )}

          {kycFiles.length !== 0 && (
            <Button
              type="submit"
              label={kycFiles.length >= 2 ? "Submit files" : "Submit file"}
              isloading={isUploading}
              disabled={isUploading}
            />
          )}
          <TextField
            type="file"
            multiple={kycDocs.length === 0}
            accept="image/png, image/jpeg,image/jpg,.pdf"
            name="name[]"
            fieldRef={kycFileRef}
            id="kycFile"
            onchange={handleKycInput}
          />
        </form>
      </CardContainer>
    </MainContainer>
  );
}

export default UnderProcess;

//row component
function KycRow(props) {
  const auth = useAuth();
  const addSelectedRows = props.addSelectedRows;
  const removeSelectedRows = props.removeSelectedRows;
  const selectedRows = props.selectedRows;
  const docs = props.docs;
  const setError = props.setError;
  const setSuccess = props.setSuccess;
  const getKycDocs = props.getKycDocs;

  const axiosPrivate = useAxiosPrivate();

  const [isSelected, setSelected] = useState(false);

  //handle selecteion and desellction of rows
  function handleSelection(e) {
    if (e.target.checked) {
      addSelectedRows(docs[props.i].id);
    } else {
      removeSelectedRows(docs[props.i].id);
    }
  }

  useEffect(() => {
    if (selectedRows.includes(docs[props.i].id)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedRows]);

  //api key for removing single doc
  function removeKycDoc(i) {
    axiosPrivate
      .put(REMOVE_KYC_DOCS, {
        action: "remove",
        doc_id: [i],

        headers: {
          Authorization: `Bearer ${auth?.accessToken}`,
        },
      })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          getKycDocs();

          setError("");
          setSuccess(
            `Doc with name ${docs[props.i].name} deleted successfully.`
          );
        }
      })
      .catch((err) => {});
  }

  return (
    <StyledTR>
      <StyledTD>
        <input
          checked={isSelected}
          onChange={handleSelection}
          type="checkbox"
        ></input>
      </StyledTD>
      <StyledTD>{props.i + 1}</StyledTD>
      <StyledTD>{props.doc.name.split("/")[2]}</StyledTD>
      <StyledTD>
        <a href={props.doc.url} target="_blank">
          View Document
        </a>
      </StyledTD>
      <StyledTD>
        <DeleteIcon onClick={() => removeKycDoc(docs[props.i].id)} />
      </StyledTD>
    </StyledTR>
  );
}
