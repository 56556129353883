import { docApiSlice } from "../docApiSlice";

export const docEndpoints = docApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDoc: builder.query({
      query: () => "/validate-token",
      providesTags: ["Doc"],
    }),
    updateDoc: builder.mutation({
      query: (data) => ({
        url: `/update-user`,
        method: "PUT",
        body: {
          name: data.name,
          registration_number: data.regNo,
          specialty: data.specialty,
          access_token: data.access,
        },
      }),

      invalidatesTags: ["Doc"],
    }),
    getDocLeave: builder.query({
      query: (id) => `/doctor-hospital-leave?doctor_hospital_id=${id}`,
      providesTags: ["Doc"],
    }),
    getDocOffShift: builder.query({
      query: (id) => `/doctor-hospital-adhoc-shift?doctor_hospital_id=${id}`,
      providesTags: ["Doc"],
    }),
    updateDoctorLeave: builder.mutation({
      query: (data) => ({
        url: "doctor-hospital-leave",
        method: "POST",
        body: {
          doctor_hospital_id: data.id,
          leave_from_ts: data.leaveFromDate,
          leave_till_ts: data.leaveToDate,
        },
      }),
      invalidatesTags: ["Doc"],
    }),
    handleScheduleOverlap: builder.mutation({
      query: (data) => ({
        url: `/doctor-hospital-adhoc-shift?doctor_hospital_id=${data.id}`,
        method: "POST",
        body: {
          doctor_hospital_id: data.id,
          start_time: data.offShiftFromDate,
          end_time: data.offShiftToDate,
          discard_overlapping_schedule: data.status,
        },
      }),
      invalidatesTags: ["TaggedDoctors"],
    }),
    updateOffShift: builder.mutation({
      query: (data) => ({
        url: "doctor-hospital-adhoc-shift",
        method: "POST",
        body: {
          doctor_hospital_id: data.id,
          start_time: data.offShiftFromDate,
          end_time: data.offShiftToDate,
        },
      }),
      invalidatesTags: ["Doc"],
    }),
  }),
});

export const { useGetDocQuery } = docEndpoints;
export const { useGetDocLeaveQuery } = docEndpoints;
export const { useGetDocOffShiftQuery } = docEndpoints;
export const { useHandleScheduleOverlapMutation } = docEndpoints;
export const { useUpdateDocMutation } = docEndpoints;
export const { useUpdateDoctorLeaveMutation } = docEndpoints;
export const { useUpdateOffShiftMutation } = docEndpoints;
