//React Imports
import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useContext,
} from "react";
import Modal from "react-modal";

//Styled Components
import {
  CenterFlexContainer,
  CardContainer,
  TextButton,
  IconContainer,
  ErrorContainer,
  SuccessContainer,
  GridContainer,
  AbsoluteDiv,
  LightText,
  BoldText,
  StyledTextField,
  StyledSelect,
  StyledDatePicker,
  StyledInputLabel,
  StyledMobileTimePicker,
} from "../../../Global";
import { Heading, BtnWrap, AddPersonIcon } from "../MainPages.elements";

//Components
import Button from "../../../components/ui/Button";

import { FormControl, MenuItem } from "@mui/material";

import HeaderCard from "../../../components/ui/HeaderCard";
import CardShimmering from "../../../components/ui/CardShimmering";

//RTK Query
import { useAddAppointmentMutation } from "../../../api/userEndpoints/appointmentEndpoint";
import { useGetDoctorsQuery } from "../../../api/userEndpoints/doctorsEndpoint";
import { useGetUsersQuery } from "../../../api/userEndpoints/usersEndpoint";
import {
  useAddPatientMutation,
  useAddPatientsNumberMutation,
  useGetPatientsQuery,
  useLazyGetPatientsQuery,
} from "../../../api/userEndpoints/patientsEndpoint";

//Util Function
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
//API
import axios from "axios";
import {
  darkAvailabilityModalStyles,
  availabilityModalStyles,
} from "../../../utils/ModalStyles";
import AuthContext from "../../../context/authProvider";
import { format } from "date-fns";
import { LocalizationProvider } from "@mui/x-date-pickers";
Modal.setAppElement("#root");

function AddAppointments() {
  const { isDarkTheme } = useContext(AuthContext);
  //rtk  query
  const [
    addAppointment,
    { isLoading: isAddAppointmentLoading, error: addAppointmentError },
  ] = useAddAppointmentMutation();

  const [addPatientMutation, { isLoading: isAddPatientLoading }] =
    useAddPatientMutation();

  const { data: doctors, isLoading: isdoctorLoading } = useGetDoctorsQuery();

  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery();

  const [getPatients, { data: patients }] = useLazyGetPatientsQuery();

  const [
    addPatientNumberMutation,
    {
      data: addPatientResponse,
      isSuccess: isAddPatientNumberSuccess,
      isError: isAddPatientNumberError,
      error: addPatientNumberError,
    },
  ] = useAddPatientsNumberMutation();

  //future Date states

  //doctors
  const [addPatientInfo, setAddPatientInfo] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);

  //acknowledgemetn messages
  const [error, setError] = useState("");
  const [success, setSuccess] = useState();

  //patient acknowledgemetn messages
  const [patientError, setPatientError] = useState("");
  const [patientSuccess, setPatientSuccess] = useState("");

  //state for formData
  const [patientPhoneNumber, setPatientPhoneNumber] = useState("");
  const [doctorId, setDoctorId] = useState();
  const [assistantId, setAssistantId] = useState("");
  const [slotId, setSlotId] = useState();

  const [appointmentTS, setAppointmentTS] = useState("");

  const [patientDob, setPatientDob] = useState();
  const [patientId, setPatientId] = useState();

  const [newPatientId, setNewPatientId] = useState();

  //states to handle specialties selection
  const [specialties, setSpecialties] = useState([]);
  const [specialty, setSpecialty] = useState("");

  //disable dates in future date
  const [reschduleDT, setReschduleDT] = useState(dayjs());
  const [avblDates, setAvblDates] = useState([]);
  const [docSlotsCopy, setDocSlotsCopy] = useState([]);

  //ref for selecting assistant upon selecting doctor
  const assistantRef = useRef();

  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      patient: "",
      name: "",
      dob: "",
      gender: "Select your Gender",
      blood_group: "Select your Blood Group",
    }
  );

  const handleFormDataChange = (event) => {
    const { name, value } = event.target;
    setFormData({ [name]: value });
  };

  //remove error msg on change in field

  useEffect(() => {
    setError("");
    setSuccess("");
  }, [formData.name, formData.gender, formData.blood_group]);

  //modal functions
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  function clearForm() {
    setPatientPhoneNumber("");
    handlePatientSelection();

    setDoctorId("");
    setSlotId("");

    setAssistantId("");
    setSuccess("");
    setError("");
  }
  //set assistant id on selecting doctor
  function handleDocSelection(id) {
    setDoctorId(id);
    getDocSlots(id);
    const doc_ast_id = doctors?.find((doc) => {
      return doc.doctor_hospital_id === id;
    });
    setAssistantId(doc_ast_id?.assistant_id);
    setSpecialties(doc_ast_id?.specialties);
  }

  useEffect(() => {
    setSpecialty(specialties[0]);
  }, [specialties]);
  //clear add patient form

  function clearAddPatientForm() {
    setFormData({
      patient: "",
      name: "",
      dob: "",
      gender: "Select your Gender",
      blood_group: "Select your Blood Group",
    });
    setPatientDob("");
    setPatientSuccess("");
    setPatientError("");
  }
  //add patient
  async function addPatient() {
    setPatientError("");
    setPatientSuccess("");
    if (formData.name === "") {
      setPatientError("Please enter Patient Name");
      return;
    } else if (formData.gender === "Select your Gender") {
      setPatientError("Please Select your gender");
      return;
    } else if (formData.blood_group === "Select your Blood Group") {
      setPatientError("Please select your Blood Group");
      return;
    }

    try {
      await addPatientMutation({
        patient: newPatientId,
        name: formData.name,
        dob: patientDob.format("YYYY-MM-DD"),
        gender: formData.gender,
        blood_group: formData.blood_group,
      }).unwrap();

      setPatientError("");
      setPatientSuccess("Patient Added successfully");
      clearAddPatientForm();
      setIsOpen(false);
    } catch (err) {
      console.log(addAppointmentError);
      setPatientSuccess("");
      setPatientError("Something went wrong.");
    }
  }
  console.log("newPatientId", newPatientId);
  const [patientsData, setPatients] = useState([]);

  //get patients on phone number match
  async function addPatientPhoneNumber(number) {
    //register new number of it does not exists
    try {
      if (number.length === 10) {
        await addPatientNumberMutation({
          mobile: number,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  //get slots of doctor
  async function getDocSlots(id) {
    const response = await axios.get(
      `https://l1x3gkp512.execute-api.ap-south-1.amazonaws.com/test/doctor-listing?type=availability-slot&doctor_hospital_id=${id}`
    );

    setDocSlotsCopy(response?.data);

    const availableDates = response?.data.map((ad) => ad.schedule_date);
    setAvblDates(availableDates);
  }

  //create appoinment

  async function handleAddAppointment() {
    setSuccess("");
    setError("");
    if (!specialty) {
      setSuccess("");
      setError("Please select Doctor's specialty. ");
      return;
    }
    if (!patientId) {
      setSuccess("");
      setError("Please select a patient. ");
      return;
    }

    try {
      let response = await addAppointment({
        patient_detail: patientId,
        doctor_hospital: doctorId,
        appointment_ts: appointmentTS,
        specialty: specialty,
      });
      console.log(response);
      if (response.data) {
        setSuccess("Appointment created successfully");
      }
      if (response.error) {
        setError("Appointment creation failed");
      }
    } catch (err) {
      setSuccess("");
      setError("Could not create Appointment. Please Try Again.");
    }
  }

  //On Enter add appointment
  const handleEnterKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleAddAppointment();
    }
  };

  //set pateint name and id
  async function handlePatientSelection(id) {
    if (id === undefined) {
      setPatientId("Select Patient Name");
      return;
    }
    // await addPatientPhoneNumber(patientPhoneNumber);
    setPatientId(id);
  }

  const disableDate = (currentDate) => {
    return !avblDates.includes(dayjs(currentDate).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    const getPatientsList = async () => {
      if (patientPhoneNumber.length === 10) {
        await getPatients(patientPhoneNumber);
      }
    };
    getPatientsList();
    setPatients(patients);
  }, [patientPhoneNumber, patients]);

  //logic to concat time slot and date chosen
  useEffect(() => {
    if (slotId) {
      let newDate =
        format(reschduleDT?.$d, "yyyy-MM-dd") +
        "T" +
        format(slotId?.$d, "HH:mm:ss") +
        "+05:30";
      console.log(newDate);
      setAppointmentTS(newDate);
    }
  }, [slotId, reschduleDT]);

  useEffect(() => {
    const getPNumber = async () => {
      if (patientPhoneNumber.length === 10) {
        await addPatientPhoneNumber(patientPhoneNumber);
      }
    };
    getPNumber();
    if (isAddPatientNumberSuccess) {
      setNewPatientId(addPatientResponse?.id);
    } else if (isAddPatientNumberError && patients) {
      if (patients.length !== 0) {
        setNewPatientId(patients[0]?.patient);
      } else {
        console.log("inside", addPatientNumberError);
        setNewPatientId(parseInt(addPatientNumberError?.data?.data?.id));
      }
    }
  }, [patientPhoneNumber, patients]);
  // console.log(reschduleDT.toISOString().split("T")[0]);
  // console.log(docSlots);
  const bloodGroupChoices = ["O+", "O-", "A+", "A-", "B+", "B-"];
  if (isUsersLoading || isdoctorLoading) {
    return <CardShimmering />;
  } else {
    return (
      <>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={
            isDarkTheme ? darkAvailabilityModalStyles : availabilityModalStyles
          }
        >
          <Heading margin="0 0 1rem 0">Add Patient</Heading>
          <GridContainer rgap="1rem">
            <StyledTextField
              name="name"
              value={formData.name}
              onChange={handleFormDataChange}
              label="Full Name"
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledDatePicker
                name="dob"
                format="DD-MM-YYYY"
                value={patientDob}
                onChange={(date) => setPatientDob(date)}
              />
            </LocalizationProvider>
            <FormControl>
              <StyledInputLabel>Select your Blood Group</StyledInputLabel>{" "}
              <StyledSelect
                name="blood_group"
                value={formData.blood_group}
                onChange={handleFormDataChange}
                label="Select youe Blood Group"
              >
                <MenuItem value="Select your Blood Group">
                  Select your Blood Group
                </MenuItem>
                {bloodGroupChoices.map((c) => (
                  <MenuItem value={c}>{c}</MenuItem>
                ))}
              </StyledSelect>
            </FormControl>

            <FormControl>
              <StyledInputLabel>Select Your Gender</StyledInputLabel>
              <StyledSelect
                name="gender"
                value={formData.gender}
                onChange={handleFormDataChange}
                label="Select your Gender"
              >
                <MenuItem value="Select your Gender">
                  Select Your Gender
                </MenuItem>
                {["Male", "Female"].map((c) => (
                  <MenuItem value={c}>{c}</MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          </GridContainer>

          {patientError && <ErrorContainer>{patientError}</ErrorContainer>}
          {patientSuccess && (
            <SuccessContainer>{patientSuccess}</SuccessContainer>
          )}
          <BtnWrap justify="space-between">
            {patientSuccess ? (
              <Button blue label="Back to Form" onclick={closeModal} />
            ) : (
              <>
                <TextButton textColor={"#9C9C9C"} onClick={clearAddPatientForm}>
                  Clear
                </TextButton>
                {/* <Button red label="Reset Data" onclick={clearForm} /> */}
                <Button
                  blue
                  label="Add Patient"
                  onclick={addPatient}
                  disabled={isAddPatientLoading}
                  isloading={isAddPatientLoading}
                />
              </>
            )}
          </BtnWrap>
        </Modal>
        <CardContainer tabIndex="0" onKeyDown={handleEnterKeypress}>
          <HeaderCard title="Add Appointment" />

          <BoldText light>Patient Details</BoldText>
          <GridContainer columns="repeat(auto-fill, minmax(250px, 1fr))">
            <StyledTextField
              value={patientPhoneNumber}
              type="tel"
              onChange={(e) => setPatientPhoneNumber(e.target.value)}
              label="Patient Phone Number"
              required
            ></StyledTextField>
            <CenterFlexContainer
              style={{ position: "relative" }}
              align="center"
              justify="space-between"
            >
              <FormControl fullWidth>
                <StyledInputLabel id="patient-select-label">
                  Select Patient Name
                </StyledInputLabel>
                <StyledSelect
                  labelId="patient-select-label"
                  label="Select Patient Name"
                  value={patientId}
                  onChange={(e) => handlePatientSelection(e.target.value)}
                  required
                >
                  <MenuItem value="">Select Patient Name</MenuItem>
                  {patientsData?.map((pat) => {
                    return (
                      <MenuItem key={pat.id} value={pat.id}>
                        {pat.name}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
              </FormControl>

              <IconContainer
                disabled={patientPhoneNumber.length !== 10}
                style={{ margin: "1rem 0 1rem 0.4rem", height: "41px" }}
                onClick={() => {
                  patientPhoneNumber.length === 10 && openModal();
                }}
                onMouseOver={() => {
                  patientPhoneNumber.length !== 10 && setAddPatientInfo(true);
                }}
                onMouseLeave={() => setAddPatientInfo(false)}
              >
                <AddPersonIcon />
              </IconContainer>
              {addPatientInfo && (
                <AbsoluteDiv>
                  <LightText>
                    Please Enter 10-Digit Phone number to add a new patient.
                  </LightText>
                </AbsoluteDiv>
              )}
            </CenterFlexContainer>
          </GridContainer>
          <BoldText light>Doctor Details</BoldText>
          <GridContainer columns="repeat(auto-fill, minmax(250px, 1fr))">
            <FormControl fullWidth margin="dense">
              <StyledInputLabel id="doc-id-label">
                Select Doctor Name
              </StyledInputLabel>
              <StyledSelect
                labelId="doc-id-label"
                label="Select Doctor Name"
                name="doctor_id"
                value={doctorId}
                onChange={(e) => handleDocSelection(e.target.value)}
              >
                <MenuItem value="">Select Doctor Name</MenuItem>
                {doctors?.map((doctor) => {
                  return (
                    <MenuItem
                      key={doctor.doctor_hospital_id}
                      value={doctor.doctor_hospital_id}
                    >
                      {doctor.name}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <StyledInputLabel id="doc-specialty-label">
                Select Doctor Specialty
              </StyledInputLabel>
              <StyledSelect
                MenuProps={{
                  disableScrollLock: true,
                  sx: {
                    maxHeight: "300px",
                  },
                }}
                labelId="doc-specialty-label"
                label="Select Doctor Specialty"
                name="doctor_id"
                value={specialty}
                disabled={!doctorId}
                onChange={(e) => setSpecialty(e.target.value)}
              >
                <MenuItem value="">Select Doctor Specialty</MenuItem>
                {specialties?.map((spl) => {
                  return (
                    <MenuItem key={spl} value={spl}>
                      {spl}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>

            <FormControl fullWidth margin="dense">
              <StyledInputLabel id="assistant-label">
                Assistant Name
              </StyledInputLabel>
              <StyledSelect
                label="Assistant Name"
                labelId="assistant-label"
                ref={assistantRef}
                name="assistant_id"
                value={assistantId}
                disabled
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem value="">Assistant Name</MenuItem>
                {users?.map((member) => {
                  return (
                    <MenuItem key={member.id} value={member.id}>
                      {member.name}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </GridContainer>
          <BoldText light>Appointment Date and Timeslot</BoldText>
          <GridContainer columns="repeat(auto-fill, minmax(250px, 1fr))">
            <FormControl fullWidth margin="dense">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledDatePicker
                  label="Enter the date"
                  minDate={dayjs()}
                  format="DD-MM-YYYY"
                  slotProps={{
                    textField: {
                      error: false,
                    },
                  }}
                  shouldDisableDate={disableDate}
                  value={reschduleDT}
                  onChange={(date) => {
                    setReschduleDT(date);
                    docSlotsCopy.filter(
                      (ad) =>
                        ad.schedule_date === date.toISOString().split("T")[0]
                    );
                  }}
                />
              </LocalizationProvider>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledMobileTimePicker
                label="Select Timeslot"
                labelId="id-slots-label"
                minutesStep="30"
                name="slot_id"
                value={slotId}
                disabled={!doctorId}
                onChange={(time) => setSlotId(time)}
                MenuProps={{
                  disableScrollLock: true,
                  sx: {
                    maxHeight: "300px",
                  },
                }}
              />
            </LocalizationProvider>
          </GridContainer>

          <CenterFlexContainer width="100%">
            {error ? <ErrorContainer>{error}</ErrorContainer> : ""}
            {success ? <SuccessContainer>{success}</SuccessContainer> : ""}
          </CenterFlexContainer>

          <GridContainer width="100%" columns="1fr 90px 150px">
            <div></div>
            <TextButton textColor={"#9C9C9C"} onClick={clearForm}>
              Clear All
            </TextButton>

            <Button
              blue
              label="Add Appointment"
              onclick={() => handleAddAppointment()}
              isloading={isAddAppointmentLoading}
              disabled={isAddAppointmentLoading}
            ></Button>
          </GridContainer>
        </CardContainer>
      </>
    );
  }
}

export default AddAppointments;
