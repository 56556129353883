import React, { useState, useReducer, useEffect, useMemo } from "react";

//Styled Components
import {
  BtnWrap,
  CardContainer,
  DesktopOnly,
  ErrorContainer,
  GridContainer,
  LightText,
  MobileOnly,
  StyledInputLabel,
  StyledSelect,
  StyledTextField,
  SuccessContainer,
} from "../../Global";
import {
  CrossIcon,
  EditIcon,
  HLine,
  Heading,
  PlusIcon,
  SubTitle,
} from "../Admin/MainPages.elements";
import {
  FormControl,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@mui/material";

//Components
import Button from "../../components/ui/Button";
import { ShimmerCard } from "../../components/ui/ui.elements";
import StyledField from "../../components/ui/TextField";
import HospitalRow from "./HospitalRow";
import NoRecords from "../../components/ui/NoRecords";
import CardShimmering from "../../components/ui/CardShimmering";

//data
import { specialtiesOptions } from "../../data/specialties";

//Google Maps API
import { useLoadScript } from "@react-google-maps/api";
import { MapContainer } from "../Auth/Auth.elements";
import { GoogleMap, Marker } from "@react-google-maps/api";
import PlacesAutoComplete from "../Auth/helpers/PlacesAutoComplete";

//Hooks
import useDocRefreshToken from "../../hooks/useDocRefreshToken";
import { useSelector } from "react-redux";

//RTK-Query
import {
  useAddHospitalMutation,
  useGetMyHospitalQuery,
} from "../../api/docEndpoints/hospitalEndpoint";
import { docAuth } from "../../reduxSlices/docAuthSlice";
import {
  useGetDocQuery,
  useUpdateDocMutation,
} from "../../api/docEndpoints/doctorEndpoint";

const libraries = ["places"];

function DocProfile() {
  const authData = useSelector(docAuth);
  const refresh = useDocRefreshToken();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    region: "India",
  });

  const center = useMemo(() => ({ lat: 12.9716, lng: 77.5946 }), []);
  const [editing, setEditing] = useState(false);

  const [addingHostpital, setAddingHospital] = useState(false);

  const [placeId, setPlaceId] = useState("");
  const [selected, setSelected] = useState();

  const [coordinates, setCoordinates] = useState({});

  //ACK
  const [uptError, setUptError] = useState("");
  const [uptSuccess, setUptSuccess] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const { data: singleDoc, isLoading: isSingleDocLoading } = useGetDocQuery();

  const { data: myHospitals, isLoading: isMyHospitalLoading } =
    useGetMyHospitalQuery();

  const [
    updateDoc,
    { isLoading: isUpdateDocLoading, isError: isUpdateDocError },
  ] = useUpdateDocMutation();
  const [
    addHospital,
    { isLoading: isAddHospitalLoading, isError: isAddHospitalError },
  ] = useAddHospitalMutation();

  const handleAddHospital = async () => {
    try {
      await addHospital({
        name: mapData.hName,
        placeId: placeId,
        address1: mapData.hAddress,
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        websiteUrl: "",
        phoneNumber: "",
      });
      if (isAddHospitalError) {
        return;
      }

      setMapData({ ["hName"]: "" });
      setMapData({ ["hAddress"]: "" });
      setAddingHospital(false);
    } catch (err) {
      console.log(err);
    }
  };
  const specialty = singleDoc?.["custom:specialty"]
    .substring(1, singleDoc?.["custom:specialty"].length - 2)
    .split(",");

  const [specialties, setSpecialties] = useState([]);
  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      fullName: singleDoc?.name,
      phone: singleDoc?.phone_number,

      regNo: singleDoc?.["custom:registration_number"],
    }
  );

  useEffect(() => {
    setSpecialties(specialty);
    setFormData({ ["fullName"]: singleDoc?.name });
    setFormData({ ["phone"]: singleDoc?.phone_number });
    setFormData({ ["regNo"]: singleDoc?.["custom:registration_number"] });
  }, [editing]);
  const handleSpecialtiesChange = (event) => {
    const {
      target: { value },
    } = event;
    setSpecialties(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  async function handleUpdate() {
    await refresh();
    try {
      await updateDoc({
        name: formData.fullName,
        regNo: formData.regNo,
        specialty: "[" + specialties.toString() + "]",

        access: authData?.idToken,
      });
      if (isUpdateDocError) {
        setUptError(isUpdateDocError);
        return;
      }
      setUptError("");
      setUptSuccess(`Your details were updated succesfully.`);
    } catch (err) {
      console.log(err);
    }
  }
  const [mapData, setMapData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      pName: "",
      pEmail: "",
      pPhone: "",
      pDesignation: "",
      pEmployeeId: "",
      hName: "",
      hAddress: "",
      hPhone: "",
    }
  );

  const handleFormDataChange = (event) => {
    const { name, value } = event.target;
    setFormData({ [name]: value });
  };

  const createHospitalRow = (props, i) => {
    return (
      <HospitalRow
        key={i}
        index={i}
        hospital={props}
        setSuccess={setSuccess}
        setError={setError}
        doctorData={singleDoc}
      ></HospitalRow>
    );
  };

  if (isSingleDocLoading || isMyHospitalLoading) {
    return <CardShimmering />;
  } else {
    return (
      <>
        <DesktopOnly>
          <GridContainer
            align="flex-start"
            gap="1.5rem"
            rgap="1.5rem"
            margin="0 1.5rem"
            columns="1fr 1fr"
          >
            <CardContainer>
              <GridContainer columns="1fr" width="100%">
                <GridContainer margin="0 0 1rem 0" columns="1fr min-content">
                  <Heading>My Profile</Heading>
                  {editing ? (
                    <CrossIcon onClick={() => setEditing(false)} />
                  ) : (
                    <EditIcon onClick={() => setEditing(true)} />
                  )}
                </GridContainer>
                {!editing ? (
                  <GridContainer columns="1fr">
                    <GridContainer columns="2fr 3fr">
                      <SubTitle style={{ padding: "0 0 0 8px" }}>Name</SubTitle>
                      <LightText style={{ margin: "0" }}>
                        {singleDoc?.name}
                      </LightText>
                    </GridContainer>
                    <HLine />

                    <GridContainer columns="2fr 3fr">
                      <SubTitle style={{ padding: "0 0 0 8px" }}>
                        Phone Number
                      </SubTitle>
                      <LightText style={{ margin: "0" }}>
                        {singleDoc?.phone_number}
                      </LightText>
                    </GridContainer>
                    <HLine />
                    <GridContainer columns="2fr 3fr">
                      <SubTitle style={{ padding: "0 0 0 8px" }}>
                        Registration Number
                      </SubTitle>
                      <LightText style={{ margin: "0" }}>
                        {singleDoc?.["custom:registration_number"]}
                      </LightText>
                    </GridContainer>
                    <HLine />
                    <GridContainer columns="2fr 3fr">
                      <SubTitle style={{ padding: "0 0 0 8px" }}>
                        Specialties
                      </SubTitle>
                      <LightText style={{ margin: "0" }}>
                        {specialty.join(",")}
                      </LightText>
                    </GridContainer>
                  </GridContainer>
                ) : (
                  <GridContainer columns="1fr">
                    <StyledTextField
                      fullWidth
                      name="phone"
                      margin="dense"
                      value={formData.phone}
                      disabled
                      label="Phone Number"
                    ></StyledTextField>
                    <StyledTextField
                      fullWidth
                      name="fullName"
                      margin="dense"
                      value={formData.fullName}
                      onChange={handleFormDataChange}
                      label="Enter your full name*"
                    ></StyledTextField>

                    <StyledTextField
                      fullWidth
                      name="regNo"
                      margin="dense"
                      value={formData.regNo}
                      onChange={handleFormDataChange}
                      label="Enter your registration number*"
                    ></StyledTextField>
                    <FormControl margin="dense" fullWidth>
                      <StyledInputLabel id="specialties-label">
                        Specialties
                      </StyledInputLabel>
                      <StyledSelect
                        labelId="specialties-label"
                        multiple
                        value={specialties ?? []}
                        onChange={handleSpecialtiesChange}
                        input={<OutlinedInput label="Qualification" />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {specialtiesOptions.map((eduName) => (
                          <MenuItem key={eduName} value={eduName}>
                            <Checkbox
                              checked={specialties?.indexOf(eduName) > -1}
                            />
                            <ListItemText primary={eduName} />
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControl>
                    {uptError && <ErrorContainer>{uptError}</ErrorContainer>}
                    {uptSuccess && (
                      <SuccessContainer>{uptSuccess}</SuccessContainer>
                    )}
                    <Button
                      isloading={isUpdateDocLoading}
                      disabled={isUpdateDocLoading}
                      label="Update"
                      onclick={handleUpdate}
                    ></Button>
                  </GridContainer>
                )}
              </GridContainer>
            </CardContainer>
            <CardContainer>
              <GridContainer
                margin="0 0 1rem 0"
                columns="1fr max-content"
                width="100%"
              >
                <Heading>Manage your Hospitals</Heading>
                {addingHostpital ? (
                  <CrossIcon onClick={() => setAddingHospital(false)} />
                ) : (
                  <PlusIcon onClick={() => setAddingHospital(true)} />
                )}
              </GridContainer>
              {addingHostpital ? (
                <>
                  <div style={{ width: "100%" }}>
                    <PlacesAutoComplete
                      value={mapData.hName}
                      setHospitalName={setMapData}
                      setHospitalAddress={setMapData}
                      setSelected={setSelected}
                      setPlaceId={setPlaceId}
                      setCoordinates={setCoordinates}
                      required
                    />
                  </div>

                  {isLoaded ? (
                    <MapContainer width="100%">
                      {" "}
                      <StyledField
                        fullWidth
                        name="hAddress"
                        value={mapData.hAddress}
                        onchange={handleFormDataChange}
                        label="Hospital Address*"
                        disabled
                      ></StyledField>
                      <GoogleMap
                        center={selected ? selected : center}
                        zoom={selected ? 15 : 1}
                        mapContainerStyle={{
                          height: "15rem",
                          width: "100%",
                          margin: "8px 0 0 0",
                          borderRadius: "5px",
                        }}
                        options={{
                          zoomControl: false,
                          streetViewControl: false,
                          mapTypeControl: false,
                          fullscreenControl: false,
                        }}
                      >
                        {selected && <Marker position={selected} />}
                      </GoogleMap>
                    </MapContainer>
                  ) : (
                    <ShimmerCard />
                  )}
                  <BtnWrap>
                    <Button
                      orange
                      label="Add Hospital"
                      isloading={isAddHospitalLoading}
                      disabled={isAddHospitalLoading}
                      onclick={handleAddHospital}
                    />
                  </BtnWrap>
                </>
              ) : (
                <GridContainer GridContainer columns="1fr" width="100%">
                  {myHospitals?.length === 0 ? (
                    <>
                      <NoRecords message="You have not added any Hospital" />
                      <Button
                        onclick={() => setAddingHospital(true)}
                        label="Add"
                      />
                    </>
                  ) : (
                    <div style={{ overflow: "auto", height: "400px" }}>
                      {myHospitals?.map(createHospitalRow)}
                    </div>
                  )}
                </GridContainer>
              )}
              {success && <SuccessContainer>{success}</SuccessContainer>}
              {error && <ErrorContainer>{error}</ErrorContainer>}
            </CardContainer>
          </GridContainer>
        </DesktopOnly>
        <MobileOnly>
          <GridContainer
            align="flex-start"
            rgap="0.2rem"
            margin="0.2rem"
            columns="1fr"
          >
            <CardContainer>
              <GridContainer columns="1fr" width="100%">
                <GridContainer margin="0 0 1rem 0" columns="1fr min-content">
                  <Heading>My Profile</Heading>
                  {editing ? (
                    <CrossIcon onClick={() => setEditing(false)} />
                  ) : (
                    <EditIcon onClick={() => setEditing(true)} />
                  )}
                </GridContainer>
                {!editing ? (
                  <GridContainer columns="1fr">
                    <GridContainer columns="2fr 3fr">
                      <SubTitle style={{ padding: "0 0 0 8px" }}>Name</SubTitle>
                      <LightText style={{ margin: "0" }}>
                        {singleDoc?.name}
                      </LightText>
                    </GridContainer>
                    <HLine />

                    <GridContainer columns="2fr 3fr">
                      <SubTitle style={{ padding: "0 0 0 8px" }}>
                        Phone Number
                      </SubTitle>
                      <LightText style={{ margin: "0" }}>
                        {singleDoc?.phone_number}
                      </LightText>
                    </GridContainer>
                    <HLine />
                    <GridContainer columns="2fr 3fr">
                      <SubTitle style={{ padding: "0 0 0 8px" }}>
                        Registration Number
                      </SubTitle>
                      <LightText style={{ margin: "0" }}>
                        {singleDoc?.["custom:registration_number"]}
                      </LightText>
                    </GridContainer>
                    <HLine />
                    <GridContainer columns="2fr 3fr">
                      <SubTitle style={{ padding: "0 0 0 8px" }}>
                        Specialties
                      </SubTitle>
                      <LightText style={{ margin: "0" }}>
                        {specialty.join(",")}
                      </LightText>
                    </GridContainer>
                  </GridContainer>
                ) : (
                  <GridContainer columns="1fr">
                    <StyledTextField
                      fullWidth
                      name="phone"
                      margin="dense"
                      value={formData.phone}
                      disabled
                      label="Phone Number"
                    ></StyledTextField>
                    <StyledTextField
                      fullWidth
                      name="fullName"
                      margin="dense"
                      value={formData.fullName}
                      onChange={handleFormDataChange}
                      label="Enter your full name*"
                    ></StyledTextField>

                    <StyledTextField
                      fullWidth
                      name="regNo"
                      margin="dense"
                      value={formData.regNo}
                      onChange={handleFormDataChange}
                      label="Enter your registration number*"
                    ></StyledTextField>
                    <FormControl margin="dense" fullWidth>
                      <StyledInputLabel id="specialties-label">
                        Specialties
                      </StyledInputLabel>
                      <StyledSelect
                        labelId="specialties-label"
                        multiple
                        value={specialties ?? []}
                        onChange={handleSpecialtiesChange}
                        input={<OutlinedInput label="Qualification" />}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {specialtiesOptions.map((eduName) => (
                          <MenuItem key={eduName} value={eduName}>
                            <Checkbox
                              checked={specialties?.indexOf(eduName) > -1}
                            />
                            <ListItemText primary={eduName} />
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControl>
                    {uptError && <ErrorContainer>{uptError}</ErrorContainer>}
                    {uptSuccess && (
                      <SuccessContainer>{uptSuccess}</SuccessContainer>
                    )}
                    <Button
                      isloading={isUpdateDocLoading}
                      disabled={isUpdateDocLoading}
                      label="Update"
                      onclick={handleUpdate}
                    ></Button>
                  </GridContainer>
                )}
              </GridContainer>
            </CardContainer>
            <CardContainer>
              <GridContainer
                margin="0 0 1rem 0"
                columns="1fr max-content"
                width="100%"
              >
                <Heading>Manage your Hospitals</Heading>
                {addingHostpital ? (
                  <CrossIcon onClick={() => setAddingHospital(false)} />
                ) : (
                  <PlusIcon onClick={() => setAddingHospital(true)} />
                )}
              </GridContainer>
              {addingHostpital ? (
                <>
                  <div style={{ width: "100%" }}>
                    <PlacesAutoComplete
                      value={mapData.hName}
                      setHospitalName={setMapData}
                      setHospitalAddress={setMapData}
                      setSelected={setSelected}
                      setPlaceId={setPlaceId}
                      setCoordinates={setCoordinates}
                      required
                    />
                  </div>

                  {isLoaded ? (
                    <MapContainer width="100%">
                      {" "}
                      <StyledField
                        fullWidth
                        name="hAddress"
                        value={mapData.hAddress}
                        onchange={handleFormDataChange}
                        label="Hospital Address*"
                        disabled
                      ></StyledField>
                      <GoogleMap
                        center={selected ? selected : center}
                        zoom={selected ? 15 : 1}
                        mapContainerStyle={{
                          height: "15rem",
                          width: "100%",
                          margin: "8px 0 0 0",
                          borderRadius: "5px",
                        }}
                        options={{
                          zoomControl: false,
                          streetViewControl: false,
                          mapTypeControl: false,
                          fullscreenControl: false,
                        }}
                      >
                        {selected && <Marker position={selected} />}
                      </GoogleMap>
                    </MapContainer>
                  ) : (
                    <ShimmerCard />
                  )}
                  <BtnWrap>
                    <Button
                      orange
                      label="Add Hospital"
                      isloading={isAddHospitalLoading}
                      disabled={isAddHospitalLoading}
                      onclick={handleAddHospital}
                    />
                  </BtnWrap>
                </>
              ) : (
                <GridContainer GridContainer columns="1fr" width="100%">
                  {myHospitals?.length === 0 ? (
                    <>
                      <NoRecords message="You have not added any Hospital" />
                      <Button
                        onclick={() => setAddingHospital(true)}
                        label="Add"
                      />
                    </>
                  ) : (
                    <div style={{ overflow: "auto", height: "400px" }}>
                      {myHospitals?.map(createHospitalRow)}
                    </div>
                  )}
                </GridContainer>
              )}
              {success && <SuccessContainer>{success}</SuccessContainer>}
              {error && <ErrorContainer>{error}</ErrorContainer>}
            </CardContainer>
          </GridContainer>
        </MobileOnly>
      </>
    );
  }
}

export default DocProfile;
