import { docApiSlice } from "../docApiSlice";

export const hospitalEndpoint = docApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMyHospital: builder.query({
      query: () => "/hospitals?associated_by=self",
      providesTags: ["MyHospital"],
    }),
    getHospitalDetails: builder.query({
      query: (id) => `/hospitals?place_id=${id}`,
      providesTags: ["Hospital"],
    }),
    addHospital: builder.mutation({
      query: (data) => ({
        url: "/hospitals",
        method: "POST",
        body: {
          name: data.name,
          place_id: data.placeId,
          address_1: data.address1,
          latitude: data.latitude,
          longitude: data.longitude,
          website_url: data.websiteUrl,
          phone_number: data.phoneNumber,
        },
      }),
      invalidatesTags: ["MyHospital"],
    }),
    removeHospital: builder.mutation({
      query: (id) => ({
        url: `/hospitals/${id}`,
        method: "DELETE",
      }),

      invalidatesTags: ["MyHospital"],
    }),
  }),
});

export const { useGetMyHospitalQuery } = hospitalEndpoint;
export const { useGetHospitalDetailsQuery } = hospitalEndpoint;
export const { useAddHospitalMutation } = hospitalEndpoint;
export const { useRemoveHospitalMutation } = hospitalEndpoint;
