//React Query
import React, { useState, useEffect, memo, useContext } from "react";
import Modal from "react-modal";

//Styled Components
import {
  StyledTD,
  StyledTR,
  EditIcon,
  Text,
  Title,
  AddIcon,
  Heading,
  Tabs,
  SchTileContainer,
  Heading2,
} from "../../MainPages.elements";
import {
  CenterFlexContainer,
  ErrorIcon,
  GridContainer,
  BtnWrap,
  TextButton,
  ErrorContainer,
  LightText,
  AnchorText,
  StyledLockIcon,
  StyledDatePicker,
  StyledMobileTimePicker,
  StyledMobileDateTimePicker,
  StyledFormControlLabel,
  StyledTextField,
  StyledInputLabel,
  StyledSelect,
} from "../../../../Global";
import { Container } from "../../MainPages.elements";
import { ScrollableContainer } from "../../MainPages.elements";
//Components
import Button from "../../../../components/ui/Button";
import ScheduleCard from "./ScheduleCard";
import MessageBox from "../../../../components/ui/MessageBox";
import SchTile from "./SchTile";
import { RadioGroup, Radio, Checkbox } from "@mui/material";

//Utils function
import {
  getDayName,
  changeTimeFormat,
  handleTimeFormat,
} from "../../../../utils/parseSchedule";
import { parseDate } from "../../../../utils/parseDate";

//RTK Query
import {
  useHandleScheduleOverlapMutation,
  useUpdateDoctorLeaveMutation,
  useUpdateOffShiftMutation,
  useUpdateScheduleMutation,
} from "../../../../api/userEndpoints/taggedDoctorEndpoint";

//MUI
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { FormControl, MenuItem } from "@mui/material";
import axios from "axios";
import {
  useRejectMultipleAppointmentsMutation,
  useRescheduleMultipleAppointmentMutation,
} from "../../../../api/userEndpoints/appointmentEndpoint";
import { PracticeChargeRow } from "../../../Doctor/PracticeCharge";
import { FaPlus } from "react-icons/fa";
import { useAddPracticeChargeMutation } from "../../../../api/userEndpoints/practiceChargeEndpoint";
import { format } from "date-fns";

import AuthContext from "../../../../context/authProvider";

//MOdal Styles
import {
  darkCustomStyles,
  customStyles,
  availabilityModalStyles,
  darkAvailabilityModalStyles,
} from "../../../../utils/ModalStyles";
import { gatingMessage } from "../../../../data/GatingMessage";
import { CloseIcon } from "../../../Auth/Auth.elements";

Modal.setAppElement("#root");
const TaggedDoctorsRow = memo(
  ({ doctorsData, docId, selectedRows, setError, setSuccess, index }) => {
    //get subscribed status
    const { subscribed, isDarkTheme } = useContext(AuthContext);
    const [updateDoctorLeave, { isLoading: isDoctorLeaveLoading }] =
      useUpdateDoctorLeaveMutation();
    const [updateDoctorOffShift, { isLoading: isDoctorOffShiftLoading }] =
      useUpdateOffShiftMutation();
    const [handleScheduleOverlap, { isLoading: isScheduleOverlapLoading }] =
      useHandleScheduleOverlapMutation();
    const [updateSchedule, { isLoading: isUpdateScheduleLoading }] =
      useUpdateScheduleMutation();
    const [
      rejectMultipleAppointments,
      { isLoading: isRejectMultipleAppointmentsLoading },
    ] = useRejectMultipleAppointmentsMutation();
    const [
      rescheduleMultipleAppointments,
      { isLoading: isRescheduletMultipleAppointmentsLoading },
    ] = useRescheduleMultipleAppointmentMutation();

    const [updatePracticeCharge, { isLoading: isPracticeChargeLoading }] =
      useAddPracticeChargeMutation();

    const [isSelected, setSelected] = useState(false);

    const [modalIsOpen, setIsOpen] = useState(false);

    //next availability modal states
    const [avlError, setAvlError] = useState("");

    const [leaveFromDate, setLFDate] = useState("");
    const [leaveToDate, setLTDate] = useState("");

    //New Leave states
    const [leaveType, setLeaveType] = useState("full");

    //Less than a day leave states
    const [lthTime, setLTHTime] = useState("");
    const [lfhTime, setLFHTime] = useState("");
    const [lfhDate, setLFHDate] = useState("");

    //Handle Existing appointments on schedule leave or adhoc shift
    const [existingAppointment, setExistingAppointment] = useState(false);
    const [existingAptData, setExistingAptData] = useState({});
    const [cancelAllApt, setCancelAllApt] = useState(false);

    const [offShiftFromDate, setOSFDate] = useState("");
    const [offShiftToDate, setOSTDate] = useState("");

    //Practicwe charges states
    const [updatingCharges, setUpdateCharges] = useState(false);
    const [addingNewCharge, setAddingNewCharge] = useState(false);
    const [pcValue, setPCValue] = useState("");
    const [pcName, setPCName] = useState("");
    const [updatedPC, setUpdatedPC] = useState(doctorsData[index]?.practices);

    const [message, setMessage] = useState(false);
    const [editingAvailability, setEditAvailability] = useState(false);
    const [newSchedule, setNewSchedule] = useState(
      doctorsData[index].schedules.map((item, index) => ({
        ...item,
      }))
    );

    const [activeTab, setActiveTab] = useState("offShift");
    const [scheduleOverlap, setScheduleOverlap] = useState(false);
    const [schOverlapData, setOverlapData] = useState({});
    const [delSchedule, setDelSchedule] = useState([]);

    const [curReschduledApt, setCurRescheduledApt] = useState([]);
    const [curCancelledApt, setCurCancelledApt] = useState([]);

    function openEditModal() {
      setIsOpen(true);
    }

    function closeModal() {
      setIsOpen(false);
    }

    useEffect(() => {
      if (selectedRows.includes(doctorsData[index]?.doctor_hospital_id)) {
        setSelected(true);
      } else {
        setSelected();
      }
    }, [selectedRows]);

    function createScheduleList(props, index) {
      let days = props.days_of_week.map(getDayName);

      let start_time = handleTimeFormat(props.start_time);
      let end_time = handleTimeFormat(props.end_time);

      return (
        <LightText
          key={index}
          style={{ margin: "0.2rem 0 ", textAlign: "start" }}
        >
          <small>
            <b>{index + 1}</b>.&nbsp;{days.join()} <br></br>
            {start_time} - {end_time}
          </small>
        </LightText>
      );
    }

    //input: 2023-01-15T09:35:22.677670+05:30
    async function updateOffShift() {
      const curDate = new Date();
      if (offShiftFromDate === "" && offShiftToDate === "") {
        setAvlError("Please enter either Leave fields or Off Shift fields");
        return;
      }

      if (offShiftFromDate !== "" && offShiftToDate !== "") {
        if (offShiftFromDate > offShiftToDate) {
          setAvlError("Invalid range in doctor shift field");
          return;
        }
        if (offShiftFromDate <= curDate || offShiftToDate <= curDate) {
          setAvlError("Please enter future date");
          return;
        }
      }

      try {
        let result = await updateDoctorOffShift({
          id: doctorsData[index]?.doctor_hospital_id,

          offShiftFromDate:
            format(offShiftFromDate.$d, "yyyy-MM-dd") +
            "T" +
            format(offShiftFromDate.$d, "HH:mm:ss"),
          offShiftToDate:
            format(offShiftToDate.$d, "yyyy-MM-dd") +
            "T" +
            format(offShiftToDate.$d, "HH:mm:ss"),
        });
        if (result.data) {
          setEditAvailability(false);
          setError("");
          setSuccess(
            `Details of Dr.${doctorsData[index]?.name} update successfully.`
          );
        } else if (result.error) {
          if (result.error.status === 409) {
            setScheduleOverlap(true);
            setOverlapData(result.error.data);
            return;
          }
          setError(
            result.error.data.message ||
              "Update failed. Please verify entered details."
          );
        }
        console.log(result);
      } catch (err) {
        console.log(err);
        setError(err || "Update failed. Please verify entered details.");
      }
    }

    async function handleUpdateDoctorLeave() {
      const curDate = new Date();
      if (leaveFromDate === "" && leaveToDate === "") {
        setAvlError("Please enter either Leave fields or Off Shift fields");
        return;
      }
      if (leaveFromDate !== "" && leaveToDate !== "") {
        if (leaveFromDate > leaveToDate) {
          setAvlError("Invalid range in Doctor leave fields");
          return;
        }
        if (leaveToDate <= curDate || leaveFromDate <= curDate) {
          setAvlError("Please enter future date");
          return;
        }
      }

      try {
        let result = await updateDoctorLeave({
          id: doctorsData[index]?.doctor_hospital_id,
          leaveFromDate:
            format(leaveFromDate.$d, "yyyy-MM-dd") +
            "T" +
            format(leaveFromDate.$d, "HH:mm:ss"),
          leaveToDate:
            leaveFromDate.toString() === leaveToDate.toString()
              ? format(leaveToDate.add(1, "day").$d, "yyyy-MM-dd") +
                "T" +
                format(leaveToDate.$d, "HH:mm:ss")
              : format(leaveToDate.$d, "yyyy-MM-dd") +
                "T" +
                format(leaveToDate.$d, "HH:mm:ss"),
        });
        if (result.data || result.data === null) {
          setEditAvailability(false);
          setError("");
          setSuccess(
            `Details of Dr.${doctorsData[index]?.name} update successfully.`
          );
        } else if (result.error) {
          if (result.error.status === 409) {
            setExistingAppointment(true);
            setExistingAptData(result.error.data?.appointments);
            return;
          }
          setError(
            result.error.data.message ||
              "Update failed. Please verify entered details."
          );
        }
      } catch (err) {
        console.log(err);
        setError(err || "Update failed. Please verify entered details.");
      }
    }

    const handleOffShiftReset = () => {
      setOSFDate("");
      setOSTDate("");
      setAvlError("");
      setScheduleOverlap(false);
    };
    const handleDocLeaveReset = () => {
      setLFDate("");
      setLTDate("");
      setAvlError("");
    };
    useEffect(() => {
      setAvlError("");
    }, [
      leaveFromDate,
      leaveToDate,
      offShiftFromDate,
      offShiftToDate,
      editingAvailability,
    ]);

    function createScheduleRow(data, i) {
      return (
        <ScheduleCard
          key={i}
          newSchedule={newSchedule}
          setNewSchedule={setNewSchedule}
          setDelSchedule={setDelSchedule}
          delSchedule={delSchedule}
          i={i}
          docId={docId}
        />
      );
    }

    async function handleUpdateSchedule() {
      const scheduleToAdd = [];
      const scheduleToDelete = [];

      //Loop through the updated schedule array and compare each object
      //with the existing schedule array to determine whether it needs
      //to be added or updated. If the object is already present in the existing
      // schedule array, update its properties. Otherwise, add the object to the scheduleToAdd array.
      for (let i = 0; i < newSchedule.length; i++) {
        let updatedObject = newSchedule[i];
        const schCopy = { ...doctorsData[index].schedules };
        let existingIndex = doctorsData[index].schedules.findIndex(
          (obj) => JSON.stringify(obj) === JSON.stringify(updatedObject)
        );
        if (existingIndex !== -1) {
          schCopy[existingIndex] = updatedObject;
        } else {
          scheduleToAdd.push(updatedObject);
        }
      }

      //Loop through the existing schedule array and compare each object with
      // the updated schedule array to determine whether it needs to be deleted.
      //If the object is not present in the updated schedule array, add it to the scheduleToDelete array.
      for (let i = 0; i < doctorsData[index].schedules.length; i++) {
        let existingObject = doctorsData[index].schedules[i];
        let updatedIndex = newSchedule.findIndex(
          (obj) => JSON.stringify(obj) === JSON.stringify(existingObject)
        );
        let difference = doctorsData[index].schedules[i]?.days_of_week.filter(
          (x) => !newSchedule[i]?.days_of_week.includes(x)
        );
        if (updatedIndex === -1 && difference.length !== 0) {
          console.log(difference);
          const objCopy = { ...existingObject };
          objCopy.days_of_week = difference;

          scheduleToDelete.push(objCopy);
          // scheduleToDelete.push(...delSchedule)
          console.log(delSchedule);
          console.log(objCopy);
        }
      }
      try {
        await updateSchedule({
          id: doctorsData[index]?.doctor_hospital_id,
          add: scheduleToAdd,
          delete: scheduleToDelete,
        });
        closeModal();
        setError("");
        setSuccess(
          `Details of Dr.${doctorsData[index]?.name} update successfully.`
        );
      } catch (err) {
        console.log(err);
        setSuccess("");
        setError("Schedule Update failed. Please verify entered details.");
      }
    }
    var upcomingSchedule = {
      start_time: "",
      end_time: "",
      days_of_week: [],
    };
    //add new schedule
    function addSchedule() {
      setNewSchedule([...newSchedule, upcomingSchedule]);
    }

    async function handleDiscardSchedule() {
      try {
        let result = await handleScheduleOverlap({
          id: doctorsData[index]?.doctor_hospital_id,
          offShiftFromDate:
            format(offShiftFromDate.$d, "yyyy-MM-dd") +
            "T" +
            format(offShiftFromDate.$d, "HH:mm:ss"),
          offShiftToDate:
            format(offShiftToDate.$d, "yyyy-MM-dd") +
            "T" +
            format(offShiftToDate.$d, "HH:mm:ss"),
          status: "true",
        });
        if (result.data) {
          setEditAvailability(false);
          setScheduleOverlap(false);
          handleOffShiftReset();
          setError("");
          setSuccess(
            `Details of Dr.${doctorsData[index]?.name} update successfully.`
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
    async function handleKeepSchedule() {
      try {
        let result = await handleScheduleOverlap({
          id: doctorsData[index]?.doctor_hospital_id,
          offShiftFromDate:
            format(offShiftFromDate.$d, "yyyy-MM-dd") +
            "T" +
            format(offShiftFromDate.$d, "HH:mm:ss"),
          offShiftToDate:
            format(offShiftToDate.$d, "yyyy-MM-dd") +
            "T" +
            format(offShiftToDate.$d, "HH:mm:ss"),
          status: "false",
        });
        if (result.data) {
          setEditAvailability(false);
          setScheduleOverlap(false);
          handleOffShiftReset();

          setError("");
          setSuccess(
            `Details of Dr.${doctorsData[index]?.name} update successfully.`
          );
        }
      } catch (err) {
        console.log(err);
      }
    }

    function handleLeaveType(e) {
      setLeaveType(e.target.value);
    }

    function createAppointmentRow(props, i) {
      return (
        <ExistingAppointmentRow
          appointmentData={props}
          setCurRescheduledApt={setCurRescheduledApt}
          setCurCancelledApt={setCurCancelledApt}
          cancelAllApt={cancelAllApt}
          i={i}
        ></ExistingAppointmentRow>
      );
    }

    async function handleLessThanDayLeave() {
      if (lfhTime === "" || lthTime === "" || lfhDate === "") {
        setAvlError("Please enter either Leave fields");
        return;
      }

      try {
        let result = await updateDoctorLeave({
          id: doctorsData[index]?.doctor_hospital_id,
          leaveFromDate:
            format(lfhDate.$d, "yyyy-MM-dd") +
            "T" +
            format(lfhTime.$d, "HH:mm:ss"),
          leaveToDate:
            format(lfhDate.$d, "yyyy-MM-dd") +
            "T" +
            format(lthTime.$d, "HH:mm:ss"),
        });
        if (result.data || result.data === null) {
          setEditAvailability(false);
          setError("");
          setSuccess(
            `Details of Dr.${doctorsData[index]?.name} update successfully.`
          );
        } else if (result.error) {
          if (result.error.status === 409) {
            setExistingAppointment(true);
            setExistingAptData(result.error.data?.appointments);
            return;
          }
          setError(
            result.error.data.message ||
              "Update failed. Please verify entered details."
          );
        }
      } catch (err) {
        console.log(err);
        setError("Update failed. Please verify entered details.");
      }
    }

    async function handleCancelAllApt() {
      const idList = existingAptData.map((apt) => apt.id);

      try {
        let result = await rejectMultipleAppointments({
          appointment_id: idList,
        });
        if (result.data || result.data === null) {
          setEditAvailability(false);
          setError("");
          setExistingAppointment(false);
          setSuccess(`${idList.length} Appointments cancelled successfully.`);
        } else if (result.error) {
          setError("Update failed. Please verify entered details.");
        }
      } catch (err) {
        console.log(err);
        setError("Update failed. Please verify entered details.");
      }
    }

    function createPracticeChargeRow(props) {
      return (
        <PracticeChargeRow
          data={updatedPC}
          setData={setUpdatedPC}
          {...props}
        ></PracticeChargeRow>
      );
    }

    async function handleAddPC() {
      const newPC = [
        ...updatedPC,
        {
          id:
            updatedPC?.length !== 0
              ? updatedPC[updatedPC.length - 1]?.id + 1
              : 0,
          name: pcName,
          charge: pcValue,
          doctor_hospital: doctorsData[index]?.doctor_hospital_id,
        },
      ];
      setAddingNewCharge(false);

      setUpdatedPC(newPC);
      setPCValue("");
      setPCName("");
    }

    async function updateCharges() {
      try {
        let result = await updatePracticeCharge({
          id: doctorsData[index]?.doctor_hospital_id,
          practiceCharges: updatedPC,
        });
        if (result.data || result.data === null) {
          setAddingNewCharge(false);
          setError("");
          setSuccess(
            `Practice Charge of Dr.${doctorsData[index]?.name} updated successfully.`
          );
        } else if (result.error) {
          setError(
            result.error.data.message ||
              "Update failed. Please verify entered details."
          );
        }
      } catch (err) {
        console.log(err);
        setError("Update failed. Please verify entered details.");
      }
      setPCValue("");
      setPCName("");
      setUpdateCharges(false);
    }

    //Reschdule and cancel appointments based on choosen options
    async function handleReschCancelAppointment() {
      const filteredReschduleApt = curReschduledApt.filter(
        (apt, i) =>
          apt.slot_id !== undefined &&
          apt.appointment_ts !== "" &&
          curReschduledApt.indexOf(apt) === i
      );
      if (curCancelledApt.length !== 0) {
        try {
          let result = await rejectMultipleAppointments({
            appointment_ids: curCancelledApt,
          });
          if (result.data || result.data === null) {
            setEditAvailability(false);
            setError("");
            setExistingAppointment(false);
            setSuccess(`${curCancelledApt.length} Appointments cancelled.`);
          } else if (result.error) {
            // setError("Update failed. Please verify entered details.");
          }
        } catch (err) {
          console.log(err);
          setError("Update failed. Please verify entered details.");
        }
      }

      if (curReschduledApt.length !== 0) {
        try {
          let result = await rescheduleMultipleAppointments({
            appointments: filteredReschduleApt,
          });
          if (result.data || result.data === null) {
            setEditAvailability(false);
            setError("");
            setExistingAppointment(false);
            setSuccess(
              `${filteredReschduleApt.length} Appointments rescheduled.`
            );
          } else if (result.error) {
            setError("Update failed. Please verify entered details.");
          }
        } catch (err) {
          console.log(err);
          // setError("Update failed. Please verify entered details.");
        }
      }
    }

    return (
      <StyledTR isSelected={isSelected} key={index + 1}>
        {/* Schedule Modal */}
        <Modal
          isOpen={modalIsOpen}
          style={isDarkTheme ? darkCustomStyles : customStyles}
          onRequestClose={() => setIsOpen(false)}
        >
          <div style={{ padding: "1.5rem" }}>
            <CenterFlexContainer
              style={{
                justifyContent: "space-between",
                width: "100%",
                position: "sticky",
                zIndex: "10",
                background: "#fff",
                top: "0",
                padding: "1rem 0",
              }}
            >
              {/* <button
                onClick={() =>
                  populateDeleteSchedule(
                    doctorsData[index].schedules,
                    newSchedule
                  )
                }
              >
                test
              </button> */}
              <GridContainer columns="1fr">
                <Title style={{ width: "100%" }}>Schedule Update</Title>
                <LightText>
                  Total No. of Schedules: {newSchedule?.length}
                </LightText>
              </GridContainer>

              <GridContainer columns="auto auto">
                <AddIcon small onClick={addSchedule} />
                <Button
                  blue
                  label="Update Schedule"
                  onclick={() => handleUpdateSchedule(index)}
                  isloading={isUpdateScheduleLoading}
                  disabled={isUpdateScheduleLoading}
                />
              </GridContainer>
            </CenterFlexContainer>
            <CenterFlexContainer style={{ flexDirection: "column-reverse" }}>
              {newSchedule?.map(createScheduleRow)}
            </CenterFlexContainer>
          </div>
        </Modal>
        {/* Availability Modal */}
        <Modal
          isOpen={editingAvailability}
          onRequestClose={() => setEditAvailability(false)}
          style={
            isDarkTheme ? darkAvailabilityModalStyles : availabilityModalStyles
          }
        >
          <Tabs>
            <ul className="nav">
              <li
                onClick={() => setActiveTab("doctorLeave")}
                className={activeTab === "doctorLeave" && "active"}
              >
                Enter Doctor Leave
              </li>
              <li
                onClick={() => setActiveTab("offShift")}
                className={activeTab === "offShift" && "active"}
              >
                Enter Doctor Off Shift
              </li>
            </ul>
          </Tabs>
          {activeTab === "doctorLeave" && (
            <>
              {!existingAppointment && (
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={leaveType}
                  defaultValue="full"
                  onChange={handleLeaveType}
                >
                  <Heading>
                    Record Doctor Time off or Leave
                    {!subscribed && <StyledLockIcon />}
                  </Heading>
                  <GridContainer
                    columns="1fr"
                    margin="0"
                    place="flex-start"
                    align="flex-start"
                  >
                    <StyledFormControlLabel
                      value="full"
                      control={<Radio />}
                      label="Full Day Leave"
                    />
                    {leaveType === "full" && (
                      <GridContainer
                        margin="0 0 0 1.8rem"
                        rgap="1rem"
                        columns="1fr"
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StyledDatePicker
                            label="From"
                            value={leaveFromDate || ""}
                            onChange={(date) => setLFDate(date)}
                            disabled={existingAppointment || !subscribed}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StyledDatePicker
                            label="To"
                            value={leaveToDate || ""}
                            onChange={(date) => setLTDate(date)}
                            disabled={existingAppointment || !subscribed}
                          />
                        </LocalizationProvider>
                      </GridContainer>
                    )}
                  </GridContainer>

                  <GridContainer columns="1fr">
                    <StyledFormControlLabel
                      value="partial"
                      control={<Radio />}
                      label="Off for less than a Day"
                    />
                    {leaveType === "partial" && (
                      <Container margin="0 0 0 1.8rem">
                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StyledDatePicker
                              value={lfhDate || ""}
                              onChange={(date) => setLFHDate(date)}
                              label="Date"
                              disabled={!subscribed}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <GridContainer
                          width="100%"
                          columns="120px 1fr 120px"
                          margin="0.7rem 0 0 0"
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StyledMobileTimePicker
                              label="From"
                              value={lfhTime}
                              onChange={(time) => setLFHTime(time)}
                              disabled={!subscribed}
                            />
                          </LocalizationProvider>

                          <LightText style={{ textAlign: "center" }}>
                            To
                          </LightText>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StyledMobileTimePicker
                              label="To"
                              value={lthTime}
                              onChange={(time) => setLTHTime(time)}
                              disabled={!subscribed}
                            />
                          </LocalizationProvider>
                        </GridContainer>
                      </Container>
                    )}
                  </GridContainer>
                </RadioGroup>
              )}

              {avlError && <ErrorContainer>{avlError}</ErrorContainer>}

              {existingAppointment && (
                <>
                  <Heading>Appointments found on choosen dates</Heading>
                  <LightText>
                    There are Appointments already booked for the cancelled slot
                    <br></br>
                    Please select the appropriate action for below appointments
                  </LightText>
                  <StyledFormControlLabel
                    control={
                      <Checkbox
                        checked={cancelAllApt}
                        onChange={(e) => setCancelAllApt(e.target.checked)}
                      />
                    }
                    label="Cancel All Appointments"
                  />
                  <ScrollableContainer height="250px">
                    {existingAptData?.map(createAppointmentRow)}
                  </ScrollableContainer>
                  <BtnWrap columns="120px 100px">
                    <TextButton onClick={() => setExistingAppointment(false)}>
                      Go Back
                    </TextButton>
                    {cancelAllApt ? (
                      <Button
                        isloading={isRejectMultipleAppointmentsLoading}
                        disabled={isRejectMultipleAppointmentsLoading}
                        onclick={handleCancelAllApt}
                        label="Update"
                      ></Button>
                    ) : (
                      <Button
                        isloading={
                          isRescheduletMultipleAppointmentsLoading ||
                          isRejectMultipleAppointmentsLoading
                        }
                        disabled={
                          isRescheduletMultipleAppointmentsLoading ||
                          isRejectMultipleAppointmentsLoading
                        }
                        onclick={handleReschCancelAppointment}
                        label="Update"
                      ></Button>
                    )}
                  </BtnWrap>
                </>
              )}
              {!existingAppointment && (
                <BtnWrap columns="120px 100px">
                  <TextButton onClick={handleDocLeaveReset}>Reset</TextButton>

                  {leaveType === "full" ? (
                    <Button
                      premium={!subscribed}
                      isloading={isDoctorLeaveLoading}
                      disabled={isDoctorLeaveLoading}
                      onclick={handleUpdateDoctorLeave}
                      label="Update"
                      gatingMessage={gatingMessage.docAvailabilityGM}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      premium={!subscribed}
                      isloading={isDoctorLeaveLoading}
                      disabled={isDoctorLeaveLoading}
                      onclick={handleLessThanDayLeave}
                      gatingMessage={gatingMessage.docAvailabilityGM}
                      label="Update"
                    >
                      Update
                    </Button>
                  )}
                </BtnWrap>
              )}
            </>
          )}
          {activeTab === "offShift" && (
            <>
              <Heading>
                Record One Off Doctor Shift {!subscribed && <StyledLockIcon />}
              </Heading>
              <GridContainer margin="1rem 0" rgap="1rem" columns="1fr">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StyledMobileDateTimePicker
                    type="datetime-local"
                    value={offShiftFromDate || ""}
                    onChange={(date) => setOSFDate(date)}
                    label="From"
                    disabled={!subscribed}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StyledMobileDateTimePicker
                    type="datetime-local"
                    value={offShiftToDate || ""}
                    onChange={(date) => setOSTDate(date)}
                    label="To"
                    disabled={!subscribed}
                  />
                </LocalizationProvider>
              </GridContainer>

              {avlError && <ErrorContainer>{avlError}</ErrorContainer>}

              {scheduleOverlap ? (
                <>
                  <Heading2 style={{ margin: "1rem 0" }}>
                    Overlapped Schedules:
                  </Heading2>
                  <SchTileContainer>
                    {schOverlapData?.overlapping_schedules?.map((sch) => (
                      <SchTile {...sch}></SchTile>
                    ))}
                  </SchTileContainer>
                  <Text style={{ margin: " 1rem 0" }}>
                    Do you want to discard the existing Schedule?
                  </Text>

                  <BtnWrap rows="1fr 1fr" columns="120px 120px">
                    <TextButton onClick={handleKeepSchedule}>No</TextButton>
                    <Button
                      isloading={isScheduleOverlapLoading}
                      disabled={isScheduleOverlapLoading}
                      onclick={handleDiscardSchedule}
                      label="Yes"
                    />
                  </BtnWrap>
                </>
              ) : (
                <BtnWrap columns="120px 120px">
                  <TextButton onClick={handleOffShiftReset}>Reset</TextButton>
                  <Button
                    premium={!subscribed}
                    isloading={isDoctorOffShiftLoading}
                    disabled={isDoctorOffShiftLoading}
                    onclick={updateOffShift}
                    gatingMessage={gatingMessage.docAvailabilityGM}
                    label="Update"
                  >
                    Update
                  </Button>
                </BtnWrap>
              )}
            </>
          )}
        </Modal>

        {/* Practice Charge Modal */}
        <Modal
          isOpen={updatingCharges}
          onRequestClose={() => setUpdateCharges(false)}
          style={
            isDarkTheme ? darkAvailabilityModalStyles : availabilityModalStyles
          }
        >
          <GridContainer columns="1fr auto">
            <Heading>Update Practice Charges</Heading>
            <CloseIcon onClick={() => setUpdateCharges(false)} />
          </GridContainer>
          <GridContainer
            margin="1rem 0 0 0"
            width="100%"
            columns="1fr"
            rgap="4px"
          >
            {updatedPC?.map(createPracticeChargeRow)}
          </GridContainer>
          {addingNewCharge && (
            <GridContainer
              margin="1rem 0"
              width="100%"
              columns="120px 120px 1fr"
            >
              <StyledTextField
                value={pcName}
                onChange={(e) => setPCName(e.target.value)}
                label="Name"
              ></StyledTextField>
              <StyledTextField
                value={pcValue}
                onChange={(e) => setPCValue(e.target.value)}
                label="Value"
              ></StyledTextField>
              <Button orange label="Add" onclick={handleAddPC}></Button>
            </GridContainer>
          )}
          <AnchorText
            style={{ justifyContent: "center", margin: "0.7rem 0" }}
            onClick={() => setAddingNewCharge(true)}
          >
            <FaPlus></FaPlus>&nbsp;Add New Charge
          </AnchorText>
          <BtnWrap justify="space-between" columns="auto auto">
            <TextButton onClick={() => setUpdateCharges(false)}>
              Cancel
            </TextButton>
            <Button
              isloading={isPracticeChargeLoading}
              disabled={isPracticeChargeLoading}
              label="Update"
              onclick={updateCharges}
            ></Button>
          </BtnWrap>
        </Modal>
        <StyledTD>
          <Text>
            <b> {doctorsData[index]?.name}</b>
          </Text>
          <LightText>
            <small>{doctorsData[index]?.specialties.join(", ")}</small>
          </LightText>
          <LightText>
            <small>{doctorsData[index]?.phone_number}</small>
          </LightText>
        </StyledTD>
        {/* <StyledTD></StyledTD>
        <StyledTD></StyledTD> */}
        <StyledTD width="25%">
          {doctorsData[index]?.practices.length > 0 ? (
            <GridContainer columns="1fr auto">
              <GridContainer width="100%" columns="1fr">
                {doctorsData[index]?.practices?.map((pc, i) => (
                  <LightText>
                    <small>
                      {i + 1}. {pc.name} : {pc.charge}
                    </small>
                  </LightText>
                ))}
              </GridContainer>

              <EditIcon onClick={() => setUpdateCharges(true)} />
            </GridContainer>
          ) : (
            <CenterFlexContainer
              style={{ justifyContent: "center", width: "100% " }}
            >
              <TextButton onClick={() => setUpdateCharges(true)}>
                <FaPlus />
                &nbsp;Add Charges{" "}
              </TextButton>
            </CenterFlexContainer>
          )}
        </StyledTD>
        <StyledTD width="15%" style={{ position: "relative" }}>
          {doctorsData[index]?.schedules.length !== 0 && (
            <CenterFlexContainer>
              <GridContainer columns="1fr auto">
                {doctorsData[index]?.next_availability === "Right Now" ? (
                  <LightText>Right Now</LightText>
                ) : (
                  <LightText style={{ textAlign: "start" }}>
                    <small>
                      {parseDate(doctorsData[index]?.next_availability)}
                      <br></br>
                      <b>
                        {changeTimeFormat(
                          doctorsData[index]?.next_availability
                        )}
                      </b>
                    </small>
                  </LightText>
                )}
                <EditIcon onClick={() => setEditAvailability(true)} />
              </GridContainer>
            </CenterFlexContainer>
          )}
          {doctorsData[index]?.schedules.length === 0 && (
            <CenterFlexContainer>
              <ErrorIcon
                onMouseOver={() => setMessage(true)}
                onMouseLeave={() => setMessage(false)}
              />
            </CenterFlexContainer>
          )}
          {message && (
            <MessageBox message="Please add Doctor schedule to update availability." />
          )}
        </StyledTD>
        <StyledTD>
          <GridContainer columns="1fr auto">
            {doctorsData[index]?.schedules.length === 0 ? (
              <TextButton onClick={openEditModal}>
                <FaPlus />
                &nbsp;Add Schedule
              </TextButton>
            ) : (
              <GridContainer columns="1fr auto">
                <div>
                  {doctorsData[index]?.schedules.map(createScheduleList)}
                </div>
                <EditIcon onClick={openEditModal} />
              </GridContainer>
            )}
          </GridContainer>
        </StyledTD>
      </StyledTR>
    );
  }
);

export default TaggedDoctorsRow;

export function ExistingAppointmentRow({
  appointmentData,
  i,
  cancelAllApt,
  setCurRescheduledApt,
  setCurCancelledApt,
}) {
  const [selectedAction, setSelectedAction] = useState("");

  const [docSlots, setDocSlots] = useState([]);
  const [slotId, setSlotId] = useState();
  const [docSlotsCopy, setDocSlotsCopy] = useState([]);
  const [avblDates, setAvblDates] = useState([]);
  const [reschduleDT, setReschduleDT] = useState(
    dayjs(appointmentData?.appointment_ts)
  );
  const [appointmentTS, setAppointmentTS] = useState("");

  const disableDate = (currentDate) =>
    !avblDates.includes(currentDate.toISOString().split("T")[0]);

  async function getDocSlots() {
    try {
      const response = await axios.get(
        `https://l1x3gkp512.execute-api.ap-south-1.amazonaws.com/test/doctor-listing?type=availability-slot&doctor_hospital_id=${appointmentData?.doctor_hospital}`
      );
      setDocSlots(response.data || []);
      setDocSlotsCopy(response.data || []);

      const availableDates = response.data.map((ad) => ad.schedule_date);
      console.log(availableDates);
      setAvblDates(availableDates);
    } catch (err) {
      console.log(err);
    }
  }

  //call get slots api on selecting reschedule
  useEffect(() => {
    if (selectedAction === "reschedule") {
      getDocSlots();
    }
  }, [selectedAction, reschduleDT]);

  //logic to concat time slot and date chosen
  //error within the logic of the filtering id slots and calender
  useEffect(() => {
    let appointmentts = docSlotsCopy?.filter(
      (sch) => sch.schedule_date === reschduleDT.toISOString().split("T")[0]
    );

    let availableSlots = appointmentts
      ?.map((apt) => apt.available_slots)
      .flat(1);

    let id = availableSlots.filter((slot) => {
      return slot.slot_id === parseInt(slotId);
    });
    // console.log(appointmentts)
    console.log(id);
    if (id?.length > 0) {
      let newDate =
        appointmentts[0]?.schedule_date + "T" + id[0].start_time.slice(0, 5);
      setAppointmentTS(newDate);
    }
  }, [slotId, reschduleDT]);

  useEffect(() => {
    if (cancelAllApt) {
      setSelectedAction("cancel");
    } else {
      setSelectedAction("");
    }
  }, [cancelAllApt]);
  // console.log(reschduleDT.toISOString().split("T")[0]);

  //store cancelled is in one array

  useEffect(() => {
    if (selectedAction === "cancel") {
      setCurCancelledApt((current) => [...current, appointmentData.id]);
      setCurRescheduledApt((current) => {
        return current.filter((obj) => obj.id !== appointmentData.id);
      });
    } else {
      setCurCancelledApt((current) => {
        return current.filter((obj) => obj !== appointmentData.id);
      });
    }

    if (selectedAction === "reschedule") {
      setCurRescheduledApt((current) => [
        ...current,
        {
          id: appointmentData.id,
          appointment_ts: appointmentTS,
          slot_id: slotId,
        },
      ]);
    }
  }, [selectedAction, appointmentTS, slotId]);
  //store reschduled objects api in array
  return (
    <GridContainer
      align="flex-start"
      columns="1fr 2fr"
      style={{ borderBottom: "1px solid #dcdcdc" }}
    >
      <LightText style={{ margin: "0.5rem 0" }}>
        <Text>
          {appointmentData.patient_name}&nbsp;<br></br>(
          {appointmentData.patient_phone_number})<br></br>
        </Text>

        <small>
          {parseDate(appointmentData.appointment_ts)}
          &nbsp;
          {handleTimeFormat(appointmentData.appointment_ts)}
        </small>
      </LightText>

      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={cancelAllApt ? "cancel" : selectedAction}
        defaultValue="cancel"
        onChange={(e) => setSelectedAction(e.target.value)}
      >
        <GridContainer columns="1fr">
          <StyledFormControlLabel
            value="cancel"
            control={<Radio />}
            label="Cancel Appointment"
            disabled={cancelAllApt}
          />
        </GridContainer>
        <GridContainer
          columns="1fr"
          margin="0"
          place="flex-start"
          align="flex-start"
        >
          <StyledFormControlLabel
            value="reschedule"
            control={<Radio />}
            label="Reschedule Appointment"
            disabled={cancelAllApt}
          />
          {selectedAction === "reschedule" && (
            <GridContainer
              margin="0 0 0 1.8rem"
              padding="0.5rem 1rem 1rem 0"
              rgap="1rem"
              columns="1fr"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledDatePicker
                  label="Enter the date"
                  minDate={dayjs()}
                  slotProps={{
                    textField: {
                      error: false,
                    },
                  }}
                  shouldDisableDate={disableDate}
                  value={reschduleDT}
                  onChange={(date) => {
                    setReschduleDT(date);
                    const selectedSchedule = docSlots.filter(
                      (ad) =>
                        ad.schedule_date === date.toISOString().split("T")[0]
                    );

                    setDocSlots(selectedSchedule);
                  }}
                  disabled={
                    appointmentData?.status === "Done" ||
                    appointmentData?.status === "Rejected"
                      ? true
                      : false
                  }
                />
              </LocalizationProvider>

              <FormControl fullWidth>
                <StyledInputLabel id="demo-simple-select-label">
                  Slot Id
                </StyledInputLabel>
                <StyledSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Slot Id"
                  value={slotId}
                  onChange={(e) => {
                    setSlotId(e.target.value);
                  }}
                >
                  <MenuItem>Select Timeslot</MenuItem>

                  {docSlots?.map((sch) => {
                    if (
                      sch.schedule_date ===
                      reschduleDT.toISOString().split("T")[0]
                    ) {
                      return sch.available_slots.map((slots) => {
                        return (
                          <MenuItem key={slots.slot_id} value={slots.slot_id}>
                            {handleTimeFormat(slots.start_time)}
                          </MenuItem>
                        );
                      });
                    }
                  })}
                </StyledSelect>
              </FormControl>
            </GridContainer>
          )}
        </GridContainer>
      </RadioGroup>
    </GridContainer>
  );
}
