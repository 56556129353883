import { apiSlice } from "../apiSlice";

export const practiceChargeEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPracticeCharge: builder.query({
      query: (id) => `/api/doctor-hospital-practice/${id}`,
      providesTags: ["PracticeCharge"],
    }),

    addPracticeCharge: builder.mutation({
      query: (data) => ({
        url: `/api/doctor-hospital-practice/${data.id}`,
        method: "POST",
        body: data.practiceCharges,
      }),
      invalidatesTags: ["PracticeCharge","TaggedDoctors"],
    }),
  }),
});

export const { useLazyGetPracticeChargeQuery } = practiceChargeEndpoints;
export const { useAddPracticeChargeMutation } = practiceChargeEndpoints;
