//React imports
import React, { useEffect, useRef, useState, useReducer, useMemo } from "react";
import Modal from "react-modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";

//Styled components
import {
  Heading3,
  File,
  MapContainer,
  ModalContainer,
  ModalWrapper,
  ModalHeader,
  ModalHeading,
  CloseIcon,
  HR,
  ResendOtp,
  FileName,
  DeleteIcon,
  SignUpHeading,
  AuthHeader,
  SignupCard,
  SignupContainer,
} from "./Auth.elements";
import {
  MainContainer,
  ErrorContainer,
  GridContainer,
  BtnWrap,
  AbsoluteDiv,
  LightText,
  TextButton,
} from "../../Global";
import { Message } from "../../components/ui/ui.elements";

//Components
import { TextField } from "../../components/ui/TextField";
import Button from "../../components/ui/Button";
import PhoneField from "../../components/ui/PhoneField";
import { SelectField } from "../../components/ui/SelectField";
import { InputField } from "../../components/ui/InputField";
import Shimmering from "../../components/ui/CardShimmering";

//External packages

import PlacesAutoComplete from "./helpers/PlacesAutoComplete";
//Google maps API autocomplete
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

//api
import axios from "../../api/axiosApi";

//context
import useAuth from "../../hooks/useAuth";

//Assets
import logo from "../../assets/curus-health-logo.svg";
import referral from "../../assets/referral.svg";

import { availabilityModalStyles } from "../../utils/ModalStyles";
import { Title } from "../Admin/MainPages.elements";

const libraries = ["places"];

Modal.setAppElement("#root");

const OTP_VALIDATE_URL = "/auth/register/onboarding?action=validate";
const FORM_SUBMIT_URL = "/auth/register/onboarding?action=register";

function SignUpPage() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
    region: "India",
  });

  const { auth } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const refCode = searchParams.get("referral");
  const center = useMemo(() => ({ lat: 12.9716, lng: 77.5946 }), []);

  //form inputs handler
  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      pName: "",
      pEmail: "",
      pPhone: "",
      pDesignation: "",
      pEmployeeId: "",
      hName: "",
      hAddress: "",
      hPhone: "",
      referrerName: "",
      referrerCode: refCode ? refCode : "",
      plan: "",
    }
  );

  //file refs
  const kycFileRef = useRef();
  const idFileRef = useRef();

  //modal ref
  const otpFieldRef = useRef();

  //states
  const [imageFile, setImageFile] = useState(null);
  const [kycFiles, setKycFiles] = useState([]);
  const [kycError, setKycError] = useState("");
  const [imgError, setImgError] = useState("");
  const [isImgSelected, setImgSelected] = useState(false);
  const [kycDocCount, setKycDocCount] = useState(0);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [OTP, setOTP] = useState("");

  const [kycInfoMsg, setKycInfoMsg] = useState(false);
  const [personalDocInfoMsg, setPersonalDocInfoMsg] = useState(false);

  const [timer, setTimer] = useState(59);

  const [resendOtp, setResend] = useState(false);
  const [newOtpSent, setNewOtpState] = useState(false);
  const [submitFormLoading, setSubmitFormLoading] = useState(false);
  const [submitOtpLoading, setSubmitOtpLoading] = useState(false);

  const [error, setError] = useState();

  //setting lat and long
  const [placeId, setPlaceId] = useState("");
  const [selected, setSelected] = useState();
  const [coordinates, setCoordinates] = useState({});

  const bedsOption = [
    "No beds",
    "1-20",
    "20-50",
    "50-100",
    "100-200",
    "More than 200",
  ];
  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.applicationStatus === "pending") {
      navigate("/underprocess");
    } else if (
      auth.roles?.includes("hospital_admin") ||
      auth.roles?.includes("curus_admin")
    ) {
      navigate("/admin");
    } else if (auth.roles?.includes("board_member")) {
      navigate("/admin");
    } else if (auth.roles?.includes("support_member")) {
      navigate("/support");
    }
  }, [auth.roles]);

  function startTimer() {
    var timeLeft = 59;
    clearTimeout(x);
    var x = setInterval(() => {
      if (timeLeft === 0) {
        clearTimeout(x);
        setResend(true);
      }
      setTimer(timeLeft);
      timeLeft--;
    }, 1000);
  }

  //handle image upload
  function handleImageInput(e) {
    if (e.target.files[0].size > 1048576) {
      setImgError("Id size must be less than 1MB");
      return imgError;
    } else {
      setImgError("");
      setImageFile(e.target.files[0]);
      setImgSelected(true);
    }
  }

  //handle KYC input
  function handleKycInput(e) {
    var files = e.target.files;

    if (
      e.target.files[0]?.size > 1048576 ||
      e.target.files[1]?.size > 1048576
    ) {
      setKycError("KYC Doc must be less than 1MB");
      kycFileRef.current.value = [];
      setKycFiles([]);
      return kycError;
    } else {
      setKycDocCount(kycDocCount + 1);
      setKycFiles((curFiles) => [...curFiles, ...Array.from(files)]);
      setKycDocCount(e.target.files.length);
    }
  }

  //handle Image Delete
  function removeImgFile(f) {
    setImageFile(null);
    setImgSelected(false);
    idFileRef.current.value = [];
  }

  //handle KYC docs delete
  function removeKycFile(f) {
    setKycFiles(kycFiles.filter((x) => x !== f));
    setKycError("");
    setKycDocCount(kycDocCount - 1);
  }

  //handle Reset button

  function resetForm() {
    setFormData({
      pName: "",
      pEmail: "",
      pPhone: "",
      pDesignation: "",
      pEmployeeId: "",
      hName: "",
      hAddress: "",
      noOfBeds: "",
      hPhone: "",
      plan: "",
    });

    //refs
    kycFileRef.current.value = [];
    idFileRef.current.value = [];

    setKycFiles([]);
    setImageFile([]);
    setKycDocCount(0);
    setImgSelected(false);
    setSelected();
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (formData.pPhone.length !== 10) {
      setError("Invalid Phone Number Please enter without country code ");
      return;
    } else if (parseInt(formData.pPhone).toString().length !== 10) {
      setError("Please Enter only Numbers in Phone field");
      return;
    } else if (formData.hName === "" || formData.hAddress === "") {
      setError("Please Select a hospital");
      return;
    }
    setSubmitFormLoading(true);
    const data = new FormData();
    //user details
    data.append("name", formData.pName);
    data.append("email", formData.pEmail);
    data.append("phone_number", "+91" + formData.pPhone);
    data.append("designation", formData.pDesignation);
    data.append("employee_id", formData.pEmployeeId);
    data.append("referrer_name", formData.referrerName);
    data.append("referrer_code", formData.referrerCode);
    data.append("plan", formData.plan);

    //hospital details
    data.append("hospital_name", formData.hName);
    data.append("no_of_beds", formData.noOfBeds);
    data.append("address", formData.hAddress);
    data.append("place_id", placeId);
    data.append("latitude", coordinates.latitude);
    data.append("longitude", coordinates.longitude);
    data.append("hospital_phone_number", formData.hPhone);
    data.append("id_proof", imageFile);
    kycFiles.forEach((item) => data.append("kyc", item));

    axios
      .post(FORM_SUBMIT_URL, data)

      .then((response) => {
        // handle response from the server
        if (response.status >= 200 && response.status < 300) {
          startTimer();
          openModal();
          setSubmitFormLoading(false);
        }
      })
      .catch((err) => {
        setError(err.response.data.message || "Sign up failed please");
        setSubmitFormLoading(false);
      });
  }
  function uploadImage() {
    document.getElementById("imgFile").click();
  }
  function uploadKyc() {
    document.getElementById("kycFile").click();
  }
  console.log(formData);

  //empty fields on edit
  useEffect(() => {
    setError("");
  }, [
    formData.pName,
    formData.pEmail,
    formData.pPhone,
    formData.pDesignation,
    formData.pEmployeeId,

    formData.hName,
    formData.noOfBeds,
    formData.hAddress,
    formData.plan,
    formData.referrerName,
    formData.referrerCode,
    formData.hPhone,
  ]);

  //Modal handlers

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setTimer(0);
    setIsOpen(false);
  }

  function handleOtpSubmission() {
    if (OTP.length > 6) {
      setOtpError("Please enter valid OTP");
      return;
    } else {
      setSubmitOtpLoading(true);
      const data = new FormData();
      //user details
      data.append("name", formData.pName);
      data.append("email", formData.pEmail);
      data.append("phone_number", "+91" + formData.pPhone);
      data.append("designation", formData.pDesignation);
      data.append("employee_id", formData.pEmployeeId);
      data.append("referrer_code", formData.referrerCode);
      data.append("referrer_name", formData.referrerName);
      data.append("plan", formData.plan);

      //hospital details
      data.append("hospital_name", formData.hName);
      data.append("no_of_beds", formData.noOfBeds);
      data.append("address", formData.hAddress);
      data.append("place_id", placeId);
      data.append("latitude", coordinates.latitude);
      data.append("longitude", coordinates.longitude);
      data.append("hospital_phone_number", formData.hPhone);
      data.append("id_proof", imageFile);
      kycFiles.forEach((item) => data.append("kyc", item));

      data.append("otp", OTP);

      axios
        .post(OTP_VALIDATE_URL, data)
        .then((response) => {
          // handle response from the server
          if (response.status === 200) {
            navigate("/login");
          }
          setSubmitOtpLoading(false);
          console.log(response);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 400) {
            setOtpError(err.response.data.message ?? "Something Went Wrong!");
            return;
          }
          setOtpError("Something Went wrong");
          setSubmitOtpLoading(false);
        });
    }
    setOtpError("");
    // closeModal();
  }

  const handleFormDataChange = (event) => {
    const { name, value } = event.target;
    setFormData({ [name]: value });
  };
  //verify if referral code is valid or not
  const [refStatus, setRefStatus] = useState({
    code_exists: true,
    message: "",
  });
  //verify if referral code is valid or not
  const [refStatusModal, setRefStatusModal] = useState(false);

  const referrerCodeRef = useRef(null);
  useEffect(() => {
    function verifyRefCode() {
      axios
        .get(
          `https://dev.curus.co.in/coupon/referral-code/verify?referral_code=${formData.referrerCode}`
        )
        .then((response) => {
          // handle response from the server
          if (response.status >= 200 && response.status < 300) {
            setRefStatus(response.data);
            if (response.data.code_exists === false) {
              setRefStatusModal(true);
            }
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (formData.referrerCode.length === 6) {
      verifyRefCode();
    }
  }, [formData.referrerCode]);

  //handle Unverified Ref Code
  function handleUnverifyCode() {
    setFormData({ ["referrerCode"]: "" });
    setRefStatusModal(false);
    referrerCodeRef.current.focus();
  }

  // ssend new OTP
  function sendNewOtp() {
    if (formData.pPhone.length !== 10) {
      setError("Invalid Phone Number Please enter without country code ");
      return;
    } else if (parseInt(formData.pPhone).toString().length !== 10) {
      setError("Please Enter only Numbers in Phone field");
      return;
    } else if (formData.hName === "" || formData.hAddress === "") {
      setError("Please Select a hospital");
      return;
    }
    const data = new FormData();
    //user details
    data.append("name", formData.pName);
    data.append("email", formData.pEmail);
    data.append("phone_number", "+91" + formData.pPhone);
    data.append("designation", formData.pDesignation);
    data.append("plan", formData.plan);
    data.append("employee_id", formData.pEmployeeId);

    //hospital details
    data.append("hospital_name", formData.hName);
    data.append("no_of_beds", formData.noOfBeds);
    data.append("address", formData.hAddress);
    data.append("place_id", placeId);
    data.append("latitude", coordinates.latitude);
    data.append("longitude", coordinates.longitude);
    data.append("hospital_phone_number", formData.hPhone);
    data.append("id_proof", imageFile);
    kycFiles.forEach((item) => data.append("kyc", item));

    axios
      .post(FORM_SUBMIT_URL, data)

      .then((response) => {
        // handle response from the server
        if (response.status >= 200 && response.status < 300) {
          setResend(false);
          setNewOtpState(true);
        }
      })
      .catch((err) => {
        setError(err.response.data.message || "Sign up failed please");
      });
  }

  const handleOtpModalClose = () => {
    setIsOpen(false);
    setOTP("");
    setOtpError("");
  };
  //wait for API loading
  if (!isLoaded) {
    return <Shimmering />;
  }

  return (
    <MainContainer>
      <Modal
        isOpen={refStatusModal}
        onRequestClose={() => setRefStatusModal(false)}
        style={availabilityModalStyles}
      >
        <img
          style={{ margin: "auto" }}
          loading="lazy"
          src={referral}
          alt="Referral Image"
          height="160px"
        ></img>
        <LightText talign="center">
          <small>{formData.referrerCode}</small>
        </LightText>
        <LightText style={{ color: "red" }}>{refStatus.message}</LightText>
        <BtnWrap justify="center">
          <Button label="Change Referral Code" onclick={handleUnverifyCode}>
            Verify
          </Button>
        </BtnWrap>
      </Modal>
      <Modal isOpen={modalIsOpen} style={availabilityModalStyles}>
        <ModalWrapper>
          <ModalHeader>
            <Title>Enter the OTP</Title>
            <CloseIcon onClick={() => closeModal()} />
          </ModalHeader>
          <OTPInput
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
            style={{ margin: "1rem 0" }}
            inputStyles={{
              border: "2px solid #ccc",
              borderRadius: "4px",
            }}
          />

          {resendOtp ? (
            <a className="link" onClick={() => sendNewOtp()}>
              resend OTP
            </a>
          ) : newOtpSent ? (
            <small>Otp sent to {formData.pPhone}</small>
          ) : (
            <ResendOtp>Resend OTP in 0:{timer} </ResendOtp>
          )}

          {otpError && <ErrorContainer>{otpError}</ErrorContainer>}

          <BtnWrap columns="auto 1fr auto">
            <TextButton onClick={handleOtpModalClose}>Cancel</TextButton>
            <span></span>
            <Button
              label="Submit"
              onclick={() => handleOtpSubmission()}
              style={{ fontWeight: "600" }}
              isloading={submitOtpLoading}
              disabled={submitOtpLoading}
            ></Button>
          </BtnWrap>
        </ModalWrapper>
      </Modal>
      <form onSubmit={handleSubmit} method="post" encType="multipart/form-data">
        <MainContainer>
          <AuthHeader>
            <SignUpHeading>Welcome to Curus Health!</SignUpHeading>
            <img width="100px" src={logo} alt="logo" />
          </AuthHeader>
          <SignupContainer>
            <SignupCard justify="flex-start">
              <Heading3>Let us Know a bit about you</Heading3>
              <GridContainer
                width="100%"
                align="start"
                columns="repeat(auto-fill, minmax(250px, 1fr))"
                rgap="1rem"
              >
                <TextField
                  value={formData.pName}
                  onchange={handleFormDataChange}
                  name="pName"
                  message=""
                  label="Your Name*"
                  required
                ></TextField>
                <TextField
                  value={formData.pEmail}
                  onchange={handleFormDataChange}
                  name="pEmail"
                  label="Your Email"
                ></TextField>

                <PhoneField
                  value={formData.pPhone}
                  onchange={handleFormDataChange}
                  name="pPhone"
                  label="Your Phone Number*"
                  required
                ></PhoneField>
                <TextField
                  value={formData.pDesignation}
                  onchange={handleFormDataChange}
                  name="pDesignation"
                  label="Your Designation*"
                  required
                ></TextField>
                <TextField
                  value={formData.referrerName}
                  onchange={handleFormDataChange}
                  name="referrerName"
                  message="Optional"
                  label="Name of Referrer"
                ></TextField>
                <TextField
                  fieldRef={referrerCodeRef}
                  value={formData.referrerCode}
                  onchange={handleFormDataChange}
                  name="referrerCode"
                  message="Optional"
                  label="Referral Code"
                ></TextField>
                <select
                  value={formData.plan}
                  className="phone-field"
                  onChange={handleFormDataChange}
                  name="plan"
                  message="Optional"
                >
                  <option value="" disabled>
                    Select Subscription Plan
                  </option>
                  <option value="1">Digital Clinic Annual Plan</option>
                  <option value="2">Digital Clinic Value Plan</option>
                  <option value="3">Digital Clinic Free Plan</option>
                </select>
                <TextField
                  value={formData.pEmployeeId}
                  onchange={handleFormDataChange}
                  name="pEmployeeId"
                  message="Optional"
                  label="Your Employee ID"
                ></TextField>

                <div style={{ position: "relative" }}>
                  <InputField
                    handleClick={uploadImage}
                    infoIcon
                    setInfoState={setPersonalDocInfoMsg}
                    btnLabel="Upload Your ID Proof*"
                    message="upload in PDF,JPG,JPEG, or PNG formats only"
                    error={imgError ? imgError : ""}
                    disabled={isImgSelected}
                  />
                  {personalDocInfoMsg && (
                    <AbsoluteDiv>
                      <Message>
                        Acceptable Documents: Aadhar Card / Voter Id / Hospital
                        Employee Id / Driving license / Passport / Pan card.
                      </Message>
                    </AbsoluteDiv>
                  )}
                  {imageFile && (
                    <File>
                      <FileName>1.&nbsp;{imageFile.name} </FileName>
                      <span>
                        <DeleteIcon onClick={removeImgFile} />
                      </span>
                    </File>
                  )}
                </div>

                <TextField
                  accept="image/png, image/jpeg,image/jpg,.pdf"
                  type="file"
                  id="imgFile"
                  disabled={isImgSelected}
                  fieldRef={idFileRef}
                  onchange={handleImageInput}
                ></TextField>
              </GridContainer>
            </SignupCard>

            <SignupCard>
              <Heading3>Let us Know a bit about your Hospital</Heading3>
              <GridContainer
                align="start"
                width="100%"
                columns="repeat(auto-fill,minmax(250px,1fr))"
                rgap="1rem"
                gap="1rem"
              >
                <PlacesAutoComplete
                  value={formData.hName}
                  setHospitalName={setFormData}
                  setHospitalAddress={setFormData}
                  setSelected={setSelected}
                  setPlaceId={setPlaceId}
                  setCoordinates={setCoordinates}
                  required
                />

                <SelectField
                  onchange={handleFormDataChange}
                  value={formData.noOfBeds}
                  name="noOfBeds"
                  firstChoice="No. of Beds"
                  choices={bedsOption}
                  required
                ></SelectField>

                <PhoneField
                  value={formData.hPhone}
                  onchange={handleFormDataChange}
                  name="hPhone"
                  label="Hospital Phone Number*"
                  required
                ></PhoneField>
                <div style={{ position: "relative" }}>
                  <InputField
                    btnLabel="Upload KYC Documents"
                    handleClick={uploadKyc}
                    infoIcon
                    setInfoState={setKycInfoMsg}
                    // message="Acceptable KYC docs:| Hospital Registration certificate,| Hospital Incorporation Certificate,| Hospital PAN Card, etc  | For quicker onboarding,Upload 2 Documents"
                    message="upload in PDF,JPG,JPEG, or PNG formats only"
                    error={kycError ? kycError : ""}
                    disabled={kycFiles.length >= 2}
                  />
                  {kycInfoMsg && (
                    <AbsoluteDiv>
                      <Message>
                        Acceptable KYC Documents: Hospital Registration
                        certificate, Hospital Incorporation Certificate,
                        Hospital PAN Card, etc. For quicker Onboarding, Please
                        Upload 2 Documents.
                      </Message>
                    </AbsoluteDiv>
                  )}

                  {kycFiles != [] &&
                    kycFiles.map((x, i) => {
                      return (
                        <File key={i}>
                          <FileName>
                            {i + 1}.&nbsp;{x.name}
                          </FileName>
                          <span>
                            <DeleteIcon onClick={() => removeKycFile(x)} />
                          </span>
                        </File>
                      );
                    })}
                </div>

                {isLoaded ? (
                  <MapContainer>
                    <TextField
                      name="hAddress"
                      value={formData.hAddress}
                      onchange={handleFormDataChange}
                      label="Hospital Address*"
                      disabled
                    ></TextField>
                    <GoogleMap
                      center={selected ? selected : center}
                      zoom={selected ? 15 : 1}
                      mapContainerStyle={{
                        height: "10rem",
                        width: "100%",
                        margin: "1rem 0 ",
                        borderRadius: "5px",
                      }}
                      options={{
                        zoomControl: false,
                        streetViewControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                      }}
                    >
                      {selected && <Marker position={selected} />}
                    </GoogleMap>
                  </MapContainer>
                ) : (
                  <h1>Loading</h1>
                )}

                <TextField
                  type="file"
                  multiple
                  accept="image/png, image/jpeg,image/jpg,.pdf"
                  name="name[]"
                  fieldRef={kycFileRef}
                  id="kycFile"
                  disabled={kycFiles.length >= 2}
                  onchange={handleKycInput}
                />
              </GridContainer>

              {error && (
                <ErrorContainer style={{ width: "100%" }}>
                  {error && error}
                </ErrorContainer>
              )}

              <BtnWrap
                width="100%"
                justify="right"
                align="start"
                columns=" 1fr 120px 120px"
              >
                <div></div>
                <Button orange label="Reset Form" onclick={resetForm} />

                <Button
                  label="Submit Details"
                  isloading={submitFormLoading}
                  disabled={submitFormLoading}
                />
              </BtnWrap>
            </SignupCard>
          </SignupContainer>
        </MainContainer>
      </form>
    </MainContainer>
  );
}

export default SignUpPage;
