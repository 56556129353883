import React from "react";

import {
  PlanValidityContainer,
  PlanValidityWrapper,
  WarningIcon,
} from "./ui.elements";

import { useNavigate } from "react-router-dom";

function PlanValidity({ plan, validity }) {
  const navigate = useNavigate();

  return (
    <PlanValidityContainer>
      <PlanValidityWrapper validity={validity}>
        <WarningIcon validity={validity} />

        <p>
          Your {plan} expires within {validity} days{" "}
        </p>
        <button onClick={() => navigate("/plans")}>
          {plan === "Digital Clinic Free Plan" ? "Buy Now" : "Renew Today"}
        </button>
      </PlanValidityWrapper>
    </PlanValidityContainer>
  );
}

export default PlanValidity;
