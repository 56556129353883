import React, { useState } from "react";
import { CardContainer, GridContainer, MainContainer } from "../../Global";
import { Heading, Heading2 } from "../Admin/MainPages.elements";
import ReactModal from "react-modal";
import { CloseIcon } from "../Auth/Auth.elements";

function Payment() {
  const [paymentDetails, setPaymentDetails] = useState({
    encReq: "",
    accessCode: "",
    merchant_id: "",
  });
  const [frameState, setFrameState] = useState(false);
  async function handleFormSubmit() {
    try {
      const res = await fetch(
        "https://dev.curus.co.in/test/payment/checkout ",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: "1",
            cancel_url: "https://app.curus.co.in",
          }),
        }
      );
      if (res.ok) {
        const pd = await res.json();
        setPaymentDetails(pd);
        openFrame();
        console.log(pd);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function openFrame() {
    setFrameState(true);
  }
  return (
    <GridContainer
      columns="1fr"
      margin="2rem"
      style={{ width: "90vw", height: "100svh" }}
    >
      <ReactModal isOpen={frameState}>
        <GridContainer columns="1fr auto">
          <Heading2>Payment Window</Heading2>
          <CloseIcon />
        </GridContainer>
        <GridContainer>
          <iframe
            title="Payment Window"
            width="482"
            height="500"
            scrolling="No"
            frameborder="0"
            id="paymentFrame"
            src={
              "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=" +
              paymentDetails.merchant_id +
              "&encRequest=" +
              paymentDetails.encReq +
              "&access_code=" +
              paymentDetails.accessCode
            }
          ></iframe>
        </GridContainer>
      </ReactModal>
      <CardContainer>
        <Heading>Plan Subscription</Heading>

        <button onClick={handleFormSubmit}>Submit</button>
      </CardContainer>
    </GridContainer>
  );
}

export default Payment;
