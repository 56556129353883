import { apiSlice } from "../apiSlice";

export const taggedDoctorEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTaggedDoctors: builder.query({
      query: () => "/api/doctor?q=tagged",
      providesTags: ["TaggedDoctors"],
    }),
    updateDoctorLeave: builder.mutation({
      query: (data) => ({
        url: "/api/doctor-hospital-leave/",
        method: "POST",
        body: {
          doctor_hospital: data.id,
          leave_from_ts: data.leaveFromDate,
          leave_till_ts: data.leaveToDate,
        },
      }),
      invalidatesTags: ["TaggedDoctors"],
    }),
    updateOffShift: builder.mutation({
      query: (data) => ({
        url: "/api/doctor-hospital-adhoc-shift/",
        method: "POST",
        body: {
          doctor_hospital: data.id,
          start_time: data.offShiftFromDate,
          end_time: data.offShiftToDate,
        },
      }),
      invalidatesTags: ["TaggedDoctors"],
    }),
    handleScheduleOverlap: builder.mutation({
      query: (data) => ({
        url: "/api/doctor-hospital-adhoc-shift/",
        method: "POST",
        body: {
          doctor_hospital: data.id,
          start_time: data.offShiftFromDate,
          end_time: data.offShiftToDate,
          discard_overlapping_schedule: data.status,
        },
      }),
      invalidatesTags: ["TaggedDoctors"],
    }),
    updateSchedule: builder.mutation({
      query: (data) => ({
        url: `/api/doctor-hospital-schedule/`,
        method: "PUT",
        body: {
          doctor_hospital_id: data.id,
          delete: data.delete,
          add: data.add,
        },
      }),
      invalidatesTags: ["TaggedDoctors"],
    }),
  }),
});

//filter to be added
// .sort((a, b) =>
//                 new Date(a.availability) < new Date(b.availability)
//                   ? 1
//                   : new Date(a.availability) > new Date(b.availability)
//                   ? -1
//                   : 0
//               )
export const { useGetTaggedDoctorsQuery } = taggedDoctorEndpoints;
export const { useUpdateDoctorLeaveMutation } = taggedDoctorEndpoints;
export const { useHandleScheduleOverlapMutation } = taggedDoctorEndpoints;
export const { useUpdateOffShiftMutation } = taggedDoctorEndpoints;
export const { useUpdateScheduleMutation } = taggedDoctorEndpoints;
