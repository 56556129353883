import React, { useRef } from "react";
import styled from "styled-components";
import { CenterFlexContainer } from "../../Global";
import restricted from "../../assets/restricted.svg";
import curusIcon from "../../assets/curus-icon.png";
import { Button } from "../Auth/Auth.elements";
import { useNavigate } from "react-router-dom";

const Circle = styled.div`
  position: absolute;
  left: 20px;
  top: 60px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.curusOrange};
  z-index: 10;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const SmallCircle = styled.div`
  position: absolute;
  left: 20px;
  top: 55px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #fff;
  z-index: 11;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Triangle = styled.div`
  position: absolute;
  right: 175px;
  top: 1px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 25px solid ${(props) => props.theme.colors.curusOrange};
  z-index: 11;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Square = styled.div`
  position: absolute;
  right: 120px;
  top: 10px;
  width: 70px;
  height: 70px;
  background: aqua;
  z-index: 10;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const ErrorContainer = styled(CenterFlexContainer)`
  height: 100vh;
  width: 100vw;

  flex-direction: column;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.borderColor};
`;

const ErrorWrapper = styled(CenterFlexContainer)`
  margin-top: 120px;
  flex-direction: column;
  padding: 0.7rem 1rem;
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`;
export const ImgWrap = styled(CenterFlexContainer)`
  min-height: 150px;
  min-width: 150px;
  max-height: 300px;
  max-width: 300px;
  margin: 14px 0;
`;

export const Img = styled.img`
  width: 100%;
`;

export const Text = styled.p`
  margin: 0.7rem 0;
  color: ${(props) => (props.light ? "#fff" : "#666666")};
  font-size: 18px;
  margin-bottom: 2rem;
`;

const Heading = styled.h1`
  font-weight: 600;
  width: 100%;
  margin: 0.7rem 0;
  color: ${(props) => props.theme.colors.curusBlue};
`;

const Unauthorized = () => {
  const navigate = useNavigate();
  const curveRef = useRef(null);
  const squareRef = useRef(null);
  const circleRef = useRef(null);
  const smallCircleRef = useRef(null);
  const triangleRef = useRef(null);

  return (
    <ErrorContainer>
      <div ref={curveRef} className="custom-shape-divider-top-1671968147">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
            opacity=".25"
            className="shape-fill"
          ></path>
          <path
            d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
            opacity=".5"
            className="shape-fill"
          ></path>
          <path
            d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <Circle ref={circleRef} />
      <SmallCircle ref={smallCircleRef} />
      <Square ref={squareRef} />
      <Triangle ref={triangleRef} />
      <ErrorWrapper>
        <ImgWrap>
          <Img loading="lazy" src={restricted} alt="hero-icon1"></Img>
        </ImgWrap>
        <Heading>Access Denied.</Heading>
        <Text>
          The page you are looking for is restricted to certain roles.
        </Text>
        <Button onClick={() => navigate("/login")} style={{ margin: "auto" }}>
          Return to Home
        </Button>
        <div style={{ width: "40px", height: "40px", margin: "2rem" }}>
          <Img loading="lazy" src={curusIcon} />
        </div>
      </ErrorWrapper>
    </ErrorContainer>
  );
};

export default Unauthorized;
