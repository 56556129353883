import React from "react";
import { GridContainer } from "../../Global";

import { Heading, HeaderContainer } from "../../pages/Admin/MainPages.elements";

function HeaderCard({
  title,
  onclick,
  onrefresh,
  refreshIcon,
  icon,
  setAlignment,
  alignment,
}) {
  return (
    <HeaderContainer>
      <GridContainer width="100%" columns="1fr min-content min-content">
        <Heading>{title}</Heading>

        <div onClick={onrefresh}>{refreshIcon}</div>
        <div onClick={onclick}>{icon}</div>
      </GridContainer>
    </HeaderContainer>
  );
}

export default HeaderCard;
