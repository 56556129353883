import React from "react";
import { LightText, MessageContainer } from "../../Global";

function MessageBox(props) {
  return (
    <MessageContainer>
      <LightText size="12px">{props.message}</LightText>
    </MessageContainer>
  );
}

export default MessageBox;
