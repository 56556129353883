import React, { memo, useRef, useState, useEffect } from "react";
import {
  MobileCard,
  Container,
  MobileCardTitle,
  MobileCardText,
  MobileCardSmallText,
  MobileCardWrapper,
  CardArrow,
  StatusDoneText,
  StatusRejectedText,
  StatusConfirmedText,
  Title,
  InvoiceText,
  CrossIcon,
} from "../../MainPages.elements";
import Button from "../../../../components/ui/Button";
import { parseDate } from "../../../../utils/parseDate";
import {
  changeTimeFormat,
  handleTimeFormat,
} from "../../../../utils/parseSchedule";
import {
  CenterFlexContainer,
  GridContainer,
  FloatingButton,
  Text,
  LightText,
  ErrorContainer,
  AnchorText,
  StyledInputLabel,
  StyledSelect,
  StyledDatePicker,
  StyledTextField,
} from "../../../../Global";

import axios from "axios";
import {
  useLazyGetHospitalDetailsQuery,
  useMarkVisitAppointmentMutation,
  useRejectAppointmentMutation,
  useRescheduleAppointmentMutation,
} from "../../../../api/userEndpoints/appointmentEndpoint";
import ReactModal from "react-modal";

import Invoice from "./Invoice";

import { useReactToPrint } from "react-to-print";

import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import NoRecords from "../../../../components/ui/NoRecords";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useLazyGetPracticeChargeQuery } from "../../../../api/userEndpoints/practiceChargeEndpoint";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { CloseIcon } from "../../../Auth/Auth.elements";
import { useSelector } from "react-redux";
import { auth } from "../../../../reduxSlices/authSlice";
const customStyles = {
  content: {
    margin: "auto",

    padding: "0",
    overflow: "auto",
    borderRadius: "20px",
    postion: "relative",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24)",
  },
  overlay: { zIndex: "1000" },
};
const modifyColumnStyles = {
  content: {
    margin: "auto",
    height: "min-content",
    width: "min-content",
    padding: "0",
    overflow: "auto",
    borderRadius: "20px",
    postion: "relative",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24)",
  },
  overlay: { zIndex: "1000" },
};
const smallModalStyles = {
  content: {
    margin: "auto",
    height: "min-content",
    width: "min-content",
    padding: "2rem",
    overflow: "auto",
    borderRadius: "20px",
    postion: "relative",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24)",
  },
  overlay: { zIndex: "1000" },
};
const AppointmentMobileCard = memo(
  ({ i, appointmentData, setAptError, setAptSuccess, cardColumn }) => {
    const navigate = useNavigate();
    //rtk  query

    const [getPracticeCharge, practiceCharges] =
      useLazyGetPracticeChargeQuery();

    const [
      markVisitAppointment,
      { isLoading: isMarkVisitLoading, isError: isMarkVisitError },
    ] = useMarkVisitAppointmentMutation();
    const [
      rejectAppointment,
      {
        isLoading: isRejectAppointmentLoading,

        error: rejectAppointmentError,
      },
    ] = useRejectAppointmentMutation();
    const [
      rescheduleAppointment,
      { isLoading: isRescheduleAppointmentLoading },
    ] = useRescheduleAppointmentMutation();
    const [getHospitalDetails] = useLazyGetHospitalDetailsQuery();

    const componentRef = useRef();
    const authData = useSelector(auth);
    const [expanded, setExpanded] = useState(false);
    const [reschError] = useState("");
    const [firstName, lastName] = appointmentData?.doctor_name?.split(" ");
    const [recieptModal, setRecieptModal] = useState(false);
    const [modifyModal, setModifyModal] = useState(false);
    const [reschduleDT, setReschduleDT] = useState(
      dayjs(appointmentData?.appointment_ts)
    );

    //reschdule new approach
    const [docSlots, setDocSlots] = useState([]);
    const [docSlotsCopy, setDocSlotsCopy] = useState([]);
    const [slotId, setSlotId] = useState();
    const [avblDates, setAvblDates] = useState([]);
    const [appointmentTS, setAppointmentTS] = useState("");

    //invoice generation
    const [generateInvoiceModal, setGenerateInvoiceModal] = useState(false);
    const [practiceCharge, setPracticeCharge] = useState([]);
    const [pc, setPc] = useState([]);
    const [selectedCharges, setSelectedCharges] = useState([]);
    const [isAdvance, setAdvance] = useState(false);
    const [advanceAmount, setAdvanceAmount] = useState(0);
    const [, setAdvError] = useState("");
    const [hospitalData, setHospitalDetails] = useState({
      place_id: "",
      name: "",
      address: "",
      phone_number: "",
    });

    async function toggleStatus(i, id, status, name) {
      if (status === "Done") {
        try {
          await rejectAppointment(id);
          if (rejectAppointmentError) {
            setAptError(rejectAppointmentError);
            return;
          }
          setAptError("");
          setAptSuccess(`The appointment of ${name} is successfully rejected.`);
        } catch (err) {
          console.log(err);
        }
      } else if (status === "Confirmed") {
        try {
          await markVisitAppointment(id);
          if (isMarkVisitError) {
            setAptError(isMarkVisitError);
            return;
          }
          setAptError("");
          setAptSuccess(`The appointment of ${name} is marked Visited.`);
        } catch (err) {
          console.log(err);
        }
      }
    }
    async function cancelStatus(i, id, status, name) {
      if (status === "Confirmed" || status === "Confirmation Pending") {
        try {
          await rejectAppointment(id);
          if (rejectAppointmentError) {
            setAptError(rejectAppointmentError);
            return;
          }
          setAptError("");
          setAptSuccess(`The appointment of ${name} is successfully rejected.`);
          setModifyModal(false);
        } catch (err) {
          console.log(err);
        }
      }
    }
    function closeRecieptModal() {
      setRecieptModal(false);
      setExpanded(true);
    }
    async function openRecieptModal() {
      try {
        const res = await getHospitalDetails();
        setHospitalDetails(res?.data);
        if (!res.data) {
          setAdvError("Invoice could not be generated.");
          return;
        } else if (advanceAmount < 0) {
          setAdvError("Amount cannot be less than zero.");
          return;
        } else if (
          advanceAmount >
          selectedCharges
            .map((obj) => parseFloat(obj.charge))
            .reduce((sum, cur) => sum + cur, 0)
        ) {
          setAdvError("Advance cannot be larger than Total amount");
          return;
        }
      } catch (e) {
        console.log(e);
      }

      setRecieptModal(true);
    }
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: "Inovice",
      onAfterPrint: () => alert("print Successfull"),
      onPrintError: () => alert("Print failed"),
    });

    async function handleReschdule() {
      try {
        const result = await rescheduleAppointment({
          id: appointmentData?.id,
          newDate: appointmentTS,
          slot_id: slotId,
        }).unwrap();
        console.log(result);
        if (result?.error?.status === 400) {
          setAptSuccess("");
          setModifyModal(false);
          setAptError(`The appointment is couldn't be rescheduled.`);
          return;
        } else {
          setAptError("");
          setModifyModal(false);
          setAptSuccess(`The appointment is successfully rescheduled.`);
        }
      } catch (err) {
        setAptError("Rescheduling of Appointment failed.");
        console.log(err);
      } finally {
        setModifyModal(false);
      }
    }

    async function getDocSlots() {
      const response = await axios.get(
        `https://l1x3gkp512.execute-api.ap-south-1.amazonaws.com/test/doctor-listing?type=availability-slot&doctor_hospital_id=${
          appointmentData?.doctor_hospital
        }&availability_datetime=${reschduleDT.toISOString().split("T")[0]}`
      );
      setDocSlots(response.data || []);
      setDocSlotsCopy(response.data || []);

      const availableDates = response.data.map((ad) => ad.schedule_date);
      setAvblDates(availableDates);
    }

    const disableDate = (currentDate) => {
      return !avblDates.includes(currentDate.toISOString().split("T")[0]);
    };

    //logic to concat time slot and date chosen
    useEffect(() => {
      let appointmentts = docSlotsCopy?.filter(
        (sch) => sch.schedule_date === reschduleDT.toISOString().split("T")[0]
      );

      let id = appointmentts[0]?.available_slots.filter((slot) => {
        return slot.slot_id.toString() === slotId;
      });
      if (id?.length > 0) {
        let newDate =
          appointmentts[0]?.schedule_date + "T" + id[0].start_time.slice(0, 5);
        setAppointmentTS(newDate);
      }
    }, [slotId]);
    const handlePracticeCharge = (event) => {
      const {
        target: { value },
      } = event;
      setPracticeCharge(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    };

    function openGenerateModal() {
      getPracticeCharge(appointmentData?.doctor_hospital);
      setGenerateInvoiceModal(true);
    }
    let generated = false;
    useEffect(() => {
      if (practiceCharges && practiceCharges.data) {
        setPc(practiceCharges.data);
      }
    }, [practiceCharges]);
    useEffect(() => {
      setSelectedCharges(
        practiceCharge.map((obj) => pc.find((n) => n.id === obj))
      );
    }, [practiceCharge]);

    function handleAdvance() {
      setAdvance(true);
    }

    return (
      <>
        {/* Generate Invoice Modal */}
        <ReactModal
          isOpen={generateInvoiceModal}
          style={smallModalStyles}
          onRequestClose={() => setGenerateInvoiceModal(false)}
        >
          <GridContainer columns="200px auto" margin="0 0 1rem 0">
            <Title>Generate Invoice</Title>
            <CloseIcon onClick={() => setGenerateInvoiceModal(false)} />
          </GridContainer>
          <GridContainer columns="1fr">
            <FormControl margin="dense" fullWidth>
              <StyledInputLabel id="specialties-label">
                Select Practice Charge
              </StyledInputLabel>
              <StyledSelect
                labelId="specialties-label"
                label="Select Practice Charge"
                multiple
                disabled={pc.length === 0}
                value={practiceCharge ?? []}
                onChange={handlePracticeCharge}
                input={<OutlinedInput label="Select Practice Charge" />}
                renderValue={(selected) => {
                  return selected
                    .map((obj) => pc.find((n) => n.id === obj).name)
                    .join(", ");
                }}
              >
                {pc &&
                  pc?.map((currentpc) => (
                    <MenuItem key={currentpc.id} value={currentpc.id}>
                      <Checkbox
                        checked={practiceCharge?.indexOf(currentpc.id) > -1}
                      />
                      <ListItemText primary={currentpc.name} />
                    </MenuItem>
                  ))}
              </StyledSelect>
            </FormControl>
            {pc.length === 0 && (
              <LightText>
                <small>
                  This doctor does not have any Practice Charge.
                  <AnchorText onClick={() => navigate("/doctors")}>
                    Add Here
                  </AnchorText>
                </small>
              </LightText>
            )}

            <GridContainer margin="1rem 0">
              <Title>Selected Charges</Title>
            </GridContainer>
            {selectedCharges.length === 0 ? (
              <NoRecords message="Please select Practice Charges" />
            ) : (
              <GridContainer columns="1fr" rgap="8px">
                {selectedCharges.map((sc, i) => (
                  <GridContainer columns="auto 1fr auto">
                    <LightText>{i + 1}</LightText>
                    <LightText>
                      <b>{sc.name}</b>
                    </LightText>
                    <LightText>{sc.charge}</LightText>
                  </GridContainer>
                ))}

                {isAdvance ? (
                  <>
                    <LightText>Please enter advance amount taken.</LightText>

                    <StyledTextField
                      margin="dense"
                      label="Advance Amount"
                      value={advanceAmount}
                      onChange={(e) => setAdvanceAmount(e.target.value)}
                    ></StyledTextField>
                  </>
                ) : (
                  <GridContainer
                    onClick={handleAdvance}
                    columns="auto 1fr"
                    gap="0.5rem"
                  >
                    <FaPlus style={{ color: "#3C78FC" }} />
                    <AnchorText>Create an Advance </AnchorText>
                  </GridContainer>
                )}
              </GridContainer>
            )}
          </GridContainer>
          <GridContainer
            justify="space-between"
            width="100%"
            columns="auto auto"
          >
            <AnchorText>Cancel</AnchorText>
            <Button onclick={openRecieptModal} label="Generate" orange></Button>
          </GridContainer>
        </ReactModal>
        {/* Invoice Modal */}
        <ReactModal
          style={customStyles}
          onRequestClose={closeRecieptModal}
          isOpen={recieptModal}
        >
          <div ref={componentRef}>
            <Invoice
              invoiceData={{
                charges: selectedCharges,
                advanceAmount: advanceAmount,
              }}
              appointmentData={appointmentData}
              hospitalData={hospitalData}
              hospitalStaff={authData?.fullName}
            />
          </div>
          <GridContainer
            style={{
              position: "absolute",
              bottom: "0",
              left: "0",
              rigth: "0",
              padding: "0 3rem",
              background: "rgba(0,0,0,0.1)",
              zIndex: "999",
              width: "100%",
            }}
            justify="flex-end"
          >
            <FloatingButton onClick={handlePrint}>Print</FloatingButton>
          </GridContainer>
        </ReactModal>

        {/* Modify Modal */}
        <ReactModal
          isOpen={modifyModal}
          onRequestClose={() => {
            setModifyModal(false);
            setExpanded(true);
          }}
          style={modifyColumnStyles}
        >
          <GridContainer
            style={{ margin: "1rem", position: "relative" }}
            height="100%"
            columns="1fr"
            place="flex-start"
          >
            <GridContainer
              columns="1fr min-content"
              justify="space-between"
              place="flex-start"
              align="flex-start"
            >
              <div>
                <Title style={{ width: "max-content" }}>
                  Appointment Details
                </Title>
                <Text style={{ textAlign: "start", width: "max-content" }}>
                  Status:&nbsp; {appointmentData?.status}
                </Text>
              </div>

              <CrossIcon onClick={() => setModifyModal(false)} />
            </GridContainer>
            <GridContainer justify="flex-start" columns="1fr 10px">
              <GridContainer columns="1fr" justify="flex-start">
                <GridContainer columns="1fr 1fr" justify="flex-start">
                  <InvoiceText>Name:</InvoiceText>
                  <LightText>{appointmentData?.patient_name}</LightText>
                </GridContainer>
                <GridContainer columns="1fr 1fr" justify="flex-start">
                  <InvoiceText>Phone:</InvoiceText>
                  <LightText>{appointmentData?.patient_phone_number}</LightText>
                </GridContainer>

                <GridContainer columns="1fr 1fr" justify="flex-start">
                  <InvoiceText>Doctor Name:</InvoiceText>
                  <LightText>{appointmentData?.doctor_name}</LightText>
                </GridContainer>
                <GridContainer columns="1fr 1fr" justify="flex-start">
                  <InvoiceText>Doctor Phone No.:</InvoiceText>
                  <LightText>{appointmentData?.doctor_phone_number}</LightText>
                </GridContainer>
                <GridContainer columns="1fr 1fr" justify="flex-start">
                  <InvoiceText>Created On:</InvoiceText>
                  <LightText>
                    {parseDate(appointmentData?.appointment_ts)}
                    {changeTimeFormat(appointmentData?.appointment_ts)}
                  </LightText>
                </GridContainer>
              </GridContainer>
            </GridContainer>
            <br></br>
            <Title>Reschedule appointment</Title>
            <br></br>
            <GridContainer margin="0 0 1rem 0" columns="1fr" rgap="1rem">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledDatePicker
                  label="Enter the date"
                  minDate={dayjs()}
                  slotProps={{
                    textField: {
                      error: false,
                    },
                  }}
                  shouldDisableDate={disableDate}
                  value={reschduleDT}
                  onChange={(date) => {
                    setReschduleDT(date);
                    const selectedSchedule = docSlots.filter(
                      (ad) =>
                        ad.schedule_date === date.toISOString().split("T")[0]
                    );

                    setDocSlots(selectedSchedule);
                  }}
                  disabled={
                    appointmentData?.status === "Done" ||
                    appointmentData?.status === "Rejected"
                      ? true
                      : false
                  }
                />
              </LocalizationProvider>

              <FormControl fullWidth>
                <StyledInputLabel id="demo-simple-select-label">
                  Select Timeslot
                </StyledInputLabel>
                <StyledSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Timeslot"
                  value={slotId}
                  onChange={(e) => {
                    setSlotId(e.target.value);
                  }}
                >
                  <MenuItem>Select Timeslot</MenuItem>

                  {docSlots?.map((sch) => {
                    if (
                      sch.schedule_date ===
                      reschduleDT.toISOString().split("T")[0]
                    ) {
                      return sch.available_slots.map((slots) => {
                        return (
                          <MenuItem key={slots.slot_id} value={slots.slot_id}>
                            {handleTimeFormat(slots.start_time)}
                          </MenuItem>
                        );
                      });
                    }
                  })}
                </StyledSelect>
              </FormControl>
            </GridContainer>

            <CenterFlexContainer width="100%">
              {reschError ? <ErrorContainer>{reschError}</ErrorContainer> : ""}
            </CenterFlexContainer>
            <GridContainer
              width="calc(100% - 2rem)"
              columns="min-content min-content"
              justify="space-between"
            >
              <Button
                isloading={isRejectAppointmentLoading}
                red
                disabled={
                  appointmentData?.status === "Done" ||
                  appointmentData?.status === "Rejected"
                    ? true
                    : ""
                }
                label="Reject Visit"
                onclick={() =>
                  cancelStatus(
                    i,
                    appointmentData?.id,
                    appointmentData?.status,
                    appointmentData?.patient_name
                  )
                }
              />
              <Button
                isloading={isRescheduleAppointmentLoading}
                onclick={handleReschdule}
                blue
                disabled={
                  appointmentData?.status === "Done" ||
                  appointmentData?.status === "Rejected"
                    ? true
                    : ""
                }
                label="Update Appointment"
              ></Button>
            </GridContainer>
          </GridContainer>
        </ReactModal>
        <MobileCardWrapper
          onClick={() => {
            setExpanded(true);
          }}
          expanded={expanded ? "true" : undefined}
          expandable={true}
        >
          <MobileCard cardColumn={cardColumn}>
            <Container justify="flex-start" align="flex-start">
              <MobileCardTitle>{appointmentData?.patient_name}</MobileCardTitle>
              <MobileCardText>
                {appointmentData?.patient_phone_number}
              </MobileCardText>
              <MobileCardSmallText>
                {parseDate(appointmentData?.appointment_ts)}
                {/* <b>{changeTimeFormat(appointmentData?.created_ts)}</b> */}
              </MobileCardSmallText>
            </Container>
            <Container>
              <MobileCardSmallText style={{ textAlign: "center" }}>
                {appointmentData?.appointment_type} appointment with
              </MobileCardSmallText>
              <CardArrow expanded={expanded ? "true" : undefined} />
            </Container>
            <Container justify="flex-start">
              <div
                style={{
                  fontSize: "11px",
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  textAlign: "right",
                }}
              >
                {appointmentData.status === "Done" && (
                  <StatusDoneText>{appointmentData.status}</StatusDoneText>
                )}
                {appointmentData.status === "Confirmed" && (
                  <StatusConfirmedText>
                    {appointmentData.status}
                  </StatusConfirmedText>
                )}
                {appointmentData?.status === "Confirmation Pending" && (
                  <StatusConfirmedText>
                    {appointmentData?.status}
                  </StatusConfirmedText>
                )}
                {appointmentData.status === "Rejected" && (
                  <StatusRejectedText>
                    {appointmentData.status}
                  </StatusRejectedText>
                )}
              </div>
              {lastName ? (
                <MobileCardTitle>Dr. {lastName}</MobileCardTitle>
              ) : (
                <MobileCardTitle>Dr. {firstName}</MobileCardTitle>
              )}
            </Container>
          </MobileCard>
          <CenterFlexContainer>
            <GridContainer columns="1fr 1fr 1fr">
              <Button
                isloading={isMarkVisitLoading}
                disabled={
                  appointmentData.status === "Done"
                    ? true
                    : "" || appointmentData.status === "Rejected"
                    ? true
                    : ""
                }
                label="Mark Visit"
                onclick={() =>
                  toggleStatus(
                    i,
                    appointmentData?.id,
                    appointmentData?.status,
                    appointmentData?.patient_name
                  )
                }
              />
              <Button
                red
                disabled={
                  appointmentData.status === "Done"
                    ? true
                    : "" || appointmentData.status === "Rejected"
                    ? true
                    : ""
                }
                label="Modify"
                onclick={() => {
                  setModifyModal(true);
                  getDocSlots();
                }}
              />
              <Button
                orange
                disabled={appointmentData.status !== "Done" ? true : ""}
                label={generated ? "View Invoice" : "Generate Invoice"}
                onclick={generated ? openRecieptModal : openGenerateModal}
              />
            </GridContainer>
          </CenterFlexContainer>
        </MobileCardWrapper>
      </>
    );
  }
);

export default AppointmentMobileCard;
