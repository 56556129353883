import React, { useReducer, useState } from "react";

//Styled Components
import {
  AnchorText,
  BtnWrap,
  CenterFlexContainer,
  ErrorContainer,
  GridContainer,
  LightText,
  MainContainer,
  StyledInputLabel,
  StyledSelect,
  StyledTextField,
  SuccessContainer,
} from "../../Global";
import { SignUpHeading, CloseIcon } from "../Auth/Auth.elements";
import { Heading } from "../Admin/MainPages.elements";
import { Heading3 } from "../Auth/Auth.elements";
import { AuthHeader, DocAuthContainer } from "./Doctor.elements";

//Assets
import logo from "../../assets/curus-health-logo.svg";

//Components
import {
  FormControl,
  OutlinedInput,
  ListItemText,
  MenuItem,
  Checkbox,
} from "@mui/material";

import Button from "../../components/ui/Button";
import ReactModal from "react-modal";

//data
import { specialtiesOptions } from "../../data/specialties";

//utils
import docAxios from "../../api/docAxios";
import { useNavigate } from "react-router-dom";

const FORM_SUBMIT_URL = "/user-sign-up?user=doctor";
const INITIATE_REGISTRATION_URL = "/user-registration?user=doctor";
const CONFIRM_REGISTRATION_URL = "/user-confirmation?user=doctor";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "max-content",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: "99",
  },
};
function DocSignUp() {
  const navigate = useNavigate();
  const [specialties, setSpecialties] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [phone, setPhone] = useState("");
  const [isVerified, setVerified] = useState(false);
  const [isOtpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");

  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      fullName: "",
      phone: "",
      email: "",
      regNo: "",
    }
  );

  const handleSpecialtiesChange = (event) => {
    const {
      target: { value },
    } = event;
    setSpecialties(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleFormDataChange = (event) => {
    const { name, value } = event.target;
    setFormData({ [name]: value });
  };

  function handleSubmit() {
    if (phone.length !== 10) {
      setError("Invalid Phone Number Please enter without country code ");
      return;
    } else if (parseInt(phone).toString().length !== 10) {
      setError("Please Enter only Numbers in Phone field");
      return;
    } else if (formData.fullName === "") {
      setError("Please enter your name");
      return;
    }

    docAxios
      .post(FORM_SUBMIT_URL, {
        phone_number: phone,
        name: formData.fullName,
        email: formData.email,
        registration_number: formData.regNo,
        specialty: "[" + specialties.join(",") + "]",
      })

      .then((response) => {
        // handle response from the server
        if (response.status >= 200 && response.status < 300) {
          setError("");
          setSuccess("User Registered Succesfully");
          setTimeout(() => navigate("/login"), 2000);
        }
      })
      .catch((err) => {
        console.log(err);
        setSuccess("");
        setError(err.message || "Sign up failed please");
      });
  }
  function initiateRegistration() {
    setError("");
    if (phone.length !== 10) {
      setError("Invalid Phone Number Please enter without country code ");
      return;
    } else if (parseInt(phone).toString().length !== 10) {
      setError("Please Enter only Numbers in Phone field");
      return;
    }

    docAxios
      .post(INITIATE_REGISTRATION_URL, {
        phone_number: phone,
      })

      .then((response) => {
        // handle response from the server
        console.log(response);
        if (response.status >= 200 && response.status < 300) {
          setOtpSent(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setSuccess("");
        setError(err.response.data.message || "Sign up failed please");
      });
  }
  const handleOtp = () => {
    if (otp.length !== 6) {
      setError("Invalid OTP ");
      return;
    }

    docAxios
      .post(CONFIRM_REGISTRATION_URL, {
        phone_number: phone,
        otp: otp,
      })

      .then((response) => {
        // handle response from the server
        console.log(response);
        if (response.status >= 200 && response.status < 300) {
          setOtpSent(false);

          setVerified(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setSuccess("");
        setError("Sign up failed please");
      });
  };
  return (
    <MainContainer>
      <ReactModal
        isOpen={isOtpSent}
        style={customStyles}
        onRequestClose={() => setOtpSent(false)}
      >
        <GridContainer columns="1fr auto" gap="2rem">
          <Heading>Authentication</Heading>
          <CloseIcon onClick={() => setOtpSent(false)} />
        </GridContainer>

        <LightText>Enter OTP sent to {formData.phone} </LightText>
        <StyledTextField
          margin="dense"
          fullWidth
          label="Enter OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        ></StyledTextField>
        <BtnWrap>
          <Button orange label="Register" onclick={handleOtp} />
        </BtnWrap>
      </ReactModal>
      <AuthHeader>
        <SignUpHeading>Welcome to Curus Health!</SignUpHeading>
        <img width="100px" src={logo} alt="logo" />
      </AuthHeader>
      <CenterFlexContainer style={{ height: "80vh" }}>
        {isVerified ? (
          <DocAuthContainer>
            <Heading3>Let us know about you</Heading3>
            <GridContainer
              width="100%"
              columns="repeat(auto-fill,minmax(250px,1fr))"
            >
              <StyledTextField
                name="phone"
                margin="dense"
                value={phone}
                disabled
                label="Mobile number*"
              ></StyledTextField>
              <StyledTextField
                name="fullName"
                margin="dense"
                onChange={handleFormDataChange}
                label="Enter your full name*"
              ></StyledTextField>

              <StyledTextField
                name="email"
                margin="dense"
                onChange={handleFormDataChange}
                label="Enter your email"
              ></StyledTextField>

              <StyledTextField
                name="regNo"
                margin="dense"
                onChange={handleFormDataChange}
                label="Enter your registration number*"
              ></StyledTextField>
              <FormControl margin="dense">
                <StyledInputLabel id="specialties-label">
                  Specialties
                </StyledInputLabel>
                <StyledSelect
                  labelId="specialties-label"
                  multiple
                  value={specialties}
                  onChange={handleSpecialtiesChange}
                  input={<OutlinedInput label="Qualification" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {specialtiesOptions.map((eduName) => (
                    <MenuItem key={eduName} value={eduName}>
                      <Checkbox checked={specialties.indexOf(eduName) > -1} />
                      <ListItemText primary={eduName} />
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </GridContainer>
            <GridContainer width="100%" columns="1fr">
              {error && <ErrorContainer>{error}</ErrorContainer>}
              {success && <SuccessContainer>{success}</SuccessContainer>}
            </GridContainer>
            <GridContainer
              columns="max-content min-content"
              justify="space-between"
              width="100%"
            >
              <LightText style={{ margin: "0", fontSize: "14px" }}>
                Already Registered? <br></br>
                <AnchorText onClick={() => navigate("/login")}>
                  Sign In
                </AnchorText>
              </LightText>
              <Button onclick={handleSubmit} orange label="Register" />
            </GridContainer>
          </DocAuthContainer>
        ) : (
          <DocAuthContainer>
            <Heading width="100%">Register as Doctor</Heading>
            {/* <LightText>Enter your mobile number to sign up</LightText> */}
            <StyledTextField
              margin="dense"
              fullWidth
              label="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            ></StyledTextField>
            {error && <ErrorContainer>{error}</ErrorContainer>}
            {success && <SuccessContainer>{success}</SuccessContainer>}
            <GridContainer
              columns="max-content min-content"
              justify="space-between"
              width="100%"
            >
              <LightText style={{ margin: "0", fontSize: "14px" }}>
                Already Registered? <br></br>
                <AnchorText onClick={() => navigate("/login")}>
                  Sign In
                </AnchorText>
              </LightText>
              <Button onclick={initiateRegistration} orange label="Register" />
            </GridContainer>
          </DocAuthContainer>
        )}
      </CenterFlexContainer>
    </MainContainer>
  );
}

export default DocSignUp;
