import React from "react";
import styled from "styled-components";
import { FaArrowRight, FaLock } from "react-icons/fa";
import { GridContainer, LightText } from "../../Global";
import { useNavigate } from "react-router-dom";

function Gating({ gatingMessage }) {
  const navigate = useNavigate();
  return (
    <GatingContainer>
      <LockIcon />
      <BoldGatingText>One more step to go !</BoldGatingText>
      <LightText style={{ textAlign: "center", fontSize: "14  px" }}>
        {gatingMessage}
      </LightText>

      <GatingButton onClick={() => navigate("/plans")}>
        Start your Free Trial
        <FaArrowRight />
      </GatingButton>
    </GatingContainer>
  );
}

export const GatingContainer = styled(GridContainer)`
  grid-template-columns: 1fr;
  /* gap: 1rem; */
  width: 100%;
  min-height: 386px;
  place-content: center;
`;

export const LockIcon = styled(FaLock)`
  color: ${(props) => props.theme.colors.curusLightBlue};
  margin: 8px auto;
  height: 28px;
  width: 28px;
`;
export const BoldGatingText = styled.b`
  color: ${(props) => props.theme.colors.curusLightBlue};
  margin: 0 auto 1rem auto;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
`;

export const GatingButton = styled.button`
  background-color: ${(props) => props.theme.colors.curusLightBlue};
  padding: 0.7rem 1rem;
  width: max-content;
  margin: 1rem auto;
  letter-spacing: 0.1em;
  border-radius: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  color: white;
  font-weight: 400;
  border: none;
  font-size: 16px;

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.curusBlue + "DD"};
  }
`;
export default Gating;
