import React, { memo, useContext } from "react";
import { StyledTD, StyledTR } from "../../../Admin/MainPages.elements";
import Button from "../../../../components/ui/Button";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../context/authProvider";
import { gatingMessage } from "../../../../data/GatingMessage";

const PatientRow = memo(({ patients, i }) => {
  const navigate = useNavigate();
  const { subscribed } = useContext(AuthContext);
  function handleViewPatient(id) {
    window.scrollTo(0, 0);
    navigate(`/managepatients/${id}`);
  }
  return (
    <StyledTR>
      <StyledTD>{i + 1}</StyledTD>
      <StyledTD>{patients.name}</StyledTD>
      <StyledTD>{patients.age}</StyledTD>
      <StyledTD>{patients.blood_group}</StyledTD>
      <StyledTD>{patients.gender}</StyledTD>
      <StyledTD>{patients.dob}</StyledTD>
      <StyledTD>
        <Button
          orange
          onclick={() => handleViewPatient(patients?.id)}
          label="View Details"
        ></Button>
      </StyledTD>
    </StyledTR>
  );
});

export default PatientRow;
