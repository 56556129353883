import React from "react";
import logo from "../../assets/curus-icon.png";
import useAuth from "../../hooks/useAuth";

function LogoLoading() {
  const { isDarkTheme } = useAuth();

  return (
    <div
      style={
        isDarkTheme
          ? {
              background: "#222",
              position: "absolute",
              height: "100vh",
              width: "100vw",
              top: 0,
              left: 0,
            }
          : {
              background: "#eee",
              position: "absolute",
              height: "100vh",
              width: "100vw",
              top: 0,
              left: 0,
            }
      }
    >
      <img
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
        }}
        src={logo}
        height="60px"
        alt="Logo"
      ></img>
    </div>
  );
}

export default LogoLoading;
