import { apiSlice } from "../apiSlice";

export const followUpEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFollowUp: builder.query({
      query: (id) => `/api/follow-up/?consultation=${id}&consultation_by=self`,
      providesTags: ["FollowUp"],
    }),
    getOthersFollowUp: builder.query({
      query: (data) => ({
        url: `/api/follow-up/?consultation=${data.id}`,
        headers: {
          IV: data.iv,
          Token: data.token,
        },
      }),
      providesTags: ["OthersFollowUp"],
    }),

    addFollowUp: builder.mutation({
      query: (data) => ({
        url: `/api/follow-up/`,
        method: "POST",
        body: {
          consultation: data.consultationId,
          changes: data.changes,
          next_follow_up: data.nextFollowUp,
        },
      }),
      invalidatesTags: ["FollowUp"],
    }),
  }),
});

export const { useGetFollowUpQuery } = followUpEndpoints;
export const { useLazyGetOthersFollowUpQuery } = followUpEndpoints;
export const { useLazyGetFollowUpQuery } = followUpEndpoints;
export const { useAddFollowUpMutation } = followUpEndpoints;
