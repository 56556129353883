import React from "react";

import { GridContainer, LightText, MainContainer } from "../../../../Global";
import {
  CardHeading,
  Container,
  StyledTable,
  StyledTD,
  StyledTR,
  TableHeader,
  InvoiceHeading,
  InvoiceText,
  InvoiceHeading2,
  InvoiceTH,
} from "../../MainPages.elements";
import dayjs from "dayjs";

function Invoice({
  appointmentData,
  invoiceData,
  hospitalData,
  hospitalStaff,
}) {
  return (
    <MainContainer
      style={{
        background: "white",
        padding: "2rem",
        margin: "1rem",
        minWidth: "683px",
      }}
    >
      <GridContainer
        align="flex-start"
        place="flex-start"
        columns="1fr auto"
        justify="space-between"
      >
        <Container align="flex-start">
          <InvoiceHeading>{hospitalData?.name}</InvoiceHeading>
          <GridContainer columns="auto 1fr" align="flex-start">
            <InvoiceText> Address: </InvoiceText>
            <LightText style={{ maxWidth: "400px" }}>
              {hospitalData?.address}
            </LightText>
          </GridContainer>
          <GridContainer columns="auto 1fr">
            <InvoiceText> Telephone: </InvoiceText>
            <LightText>+91&nbsp;{hospitalData.phone_number}</LightText>
          </GridContainer>
        </Container>

        <div>
          <LightText>powered by: </LightText>
          <InvoiceText>Curus Health</InvoiceText>
        </div>
      </GridContainer>
      <CardHeading>Invoice</CardHeading>
      <GridContainer
        align="flex-start"
        columns="1fr 200px"
        justify="space-between"
      >
        <Container align="flex-start">
          <GridContainer columns="auto 1fr">
            <InvoiceText> Patient Name: </InvoiceText>
            <LightText>{appointmentData?.patient_name}</LightText>
          </GridContainer>

          <GridContainer columns="auto 1fr">
            <InvoiceText>Phone No.: </InvoiceText>
            <LightText>{appointmentData?.patient_phone_number}</LightText>
          </GridContainer>
          <GridContainer columns="auto 1fr">
            <InvoiceText>Doctor Name: </InvoiceText>
            <LightText>{appointmentData?.doctor_name}</LightText>
          </GridContainer>
        </Container>
        <Container align="flex-start">
          <GridContainer columns="auto 1fr">
            <InvoiceText>Bill No.: </InvoiceText>
            <LightText>{appointmentData?.bill_no}</LightText>
          </GridContainer>
          <GridContainer columns="auto 1fr">
            <InvoiceText>Date: </InvoiceText>
            <LightText>
              {dayjs(appointmentData.created_ts).format("DD-MM-YYYY")}
            </LightText>
          </GridContainer>
        </Container>
      </GridContainer>
      <br></br>
      <StyledTable>
        <TableHeader>
          <StyledTR>
            <InvoiceTH>S.No</InvoiceTH>
            <InvoiceTH>Particulars</InvoiceTH>
            <InvoiceTH>Unit Price</InvoiceTH>
          </StyledTR>
        </TableHeader>
        <tbody>
          {invoiceData.charges.map((obj, i) => {
            return (
              <StyledTR>
                <StyledTD>{i + 1}</StyledTD>
                <StyledTD>{obj.name}</StyledTD>
                <StyledTD>{obj.charge}</StyledTD>
              </StyledTR>
            );
          })}

          <StyledTR>
            <StyledTD></StyledTD>
            <StyledTD>Total Amount</StyledTD>
            <StyledTD>
              {invoiceData.charges.reduce(
                (acc, cur) => acc + parseFloat(cur.charge),
                0
              )}
            </StyledTD>
          </StyledTR>
          <StyledTR>
            <StyledTD></StyledTD>
            <StyledTD>Advance Paid</StyledTD>
            <StyledTD>{invoiceData.advanceAmount}</StyledTD>
          </StyledTR>
        </tbody>
      </StyledTable>
      <GridContainer justify="flex-end" width="100%">
        <Container align="flex-start">
          <LightText>Signed by</LightText>
          <InvoiceHeading2>{hospitalStaff}</InvoiceHeading2>
        </Container>
      </GridContainer>
    </MainContainer>
  );
}

export default Invoice;
