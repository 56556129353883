import { docApiSlice } from "../docApiSlice";

export const appointmentEndpoint = docApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppointments: builder.query({
      query: () => "/appointment",
      providesTags: ["Appointments"],
    }),
    getPendingAppointments: builder.query({
      query: () => "/appointment?pending=true",
      providesTags: ["PendingAppointments"],
    }),

    rejectAppointment: builder.mutation({
      query: (id) => ({
        url: `/appointment/${id}`,
        method: "PUT",
        body: {
          action: "reject appointment",
        },
      }),

      invalidatesTags: ["Appointments", "PendingAppointments"],
    }),

    rescheduleAppointment: builder.mutation({
      query: (data) => ({
        url: `/appointment/${data.id}`,
        method: "PUT",
        body: {
          action: "reschedule appointment",
          appointment_ts: data.newDate,
          availability_slot: data.availability_slot,
          slot_id: data.slot_id,
        },
      }),
      invalidatesTags: ["Appointments", "LatestAppointments"],
    }),
    rescheduleMultipleAppointment: builder.mutation({
      query: (data) => ({
        url: `/appointment/`,
        method: "PUT",
        body: {
          action: "reschedule multiple appointments",
          appointment_data: data.appointments,
        },
      }),
      invalidatesTags: ["Appointments", "LatestAppointments"],
    }),
    rejectMultipleAppointments: builder.mutation({
      query: (data) => ({
        url: `/appointment/`,
        method: "PUT",
        body: {
          action: "reject multiple appointments",
          appointment_ids: data.appointment_ids,
        },
      }),
      invalidatesTags: ["Appointments", "LatestAppointments"],
    }),
  }),
});

export const { useGetAppointmentsQuery } = appointmentEndpoint;
export const { useGetPendingAppointmentsQuery } = appointmentEndpoint;
export const { useRejectAppointmentMutation } = appointmentEndpoint;
export const { useRejectMultipleAppointmentsMutation } = appointmentEndpoint;
export const { useRescheduleAppointmentMutation } = appointmentEndpoint;
export const { useRescheduleMultipleAppointmentMutation } = appointmentEndpoint;
