import { GridContainer, LightText } from "../../../../Global";
import { Container, MobileCardText } from "../../MainPages.elements";

import { handleTimeFormat, getDayName } from "../../../../utils/parseSchedule";

function SchTile(props) {
  return (
    <GridContainer
      justify="space-between"
      width="100%"
     
      columns="1fr 1fr 1fr"
    >
      <Container width="100%" align="flex-start">
        <LightText>From</LightText>
        <MobileCardText>{handleTimeFormat(props.start_time)}</MobileCardText>
      </Container>
      <Container width="100%" align="flex-start">
        <LightText>To</LightText>
        <MobileCardText>{handleTimeFormat(props.end_time)}</MobileCardText>
      </Container>
      <Container width="100%" align="flex-start">
        <LightText>Week Day</LightText>
        <MobileCardText>{props.days_of_week.map(getDayName)}</MobileCardText>
      </Container>
    </GridContainer>
  );
}

export default SchTile;
