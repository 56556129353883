import React, { useState } from "react";
import Compressor from "compressorjs";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { UploadIcon } from "../pages/Admin/MainPages.elements";

import { useSelector } from "react-redux";

import { auth } from "../reduxSlices/authSlice";

import { TextButton } from "../Global";

const PUT_PROFILE_PUCTURE_URL = "/auth/profile-picture/";
const Upload = ({
  setImgSuccess,
  setImgError,

  refetchSingleUser,
}) => {
  //rtk query

  const [, setCompressedFile] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const authData = useSelector(auth);

  const handleCompressedUpload = (e) => {
    const image = e.target.files[0];
    console.log(image);
    new Compressor(image, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: async (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.

        console.log("old siize", image.size);
        if (compressedResult.size < 1048576) {
          axiosPrivate
            .put(
              PUT_PROFILE_PUCTURE_URL,
              {
                profile_picture: new File(
                  [compressedResult],
                  "profile-picture.jpg"
                ),
              },
              {
                headers: {
                  Authorization: `Bearer ${authData?.accessToken}`,
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.status >= 200 && response.status <= 300) {
                setImgError("");
                refetchSingleUser();
                setImgSuccess("Image successfully updated");
              }
            })
            .catch((err) => {
              setImgSuccess("");
              console.log(err);
              setImgError("Something went wrong please try again");
            });
        } else {
          setImgSuccess("");
          setImgError("Image size must be less than 1MB");
        }

        console.log(compressedResult);
        console.log(image);
        setCompressedFile(compressedResult);
      },
    });
  };
  function handleDpUploadClick() {
    setImgSuccess("");
    setImgError("");
    document.getElementById("dp-input").click();
  }

  return (
    <>
      <TextButton
        icon={<UploadIcon />}
        title="Upload Profile Picture"
        onClick={handleDpUploadClick}
      >
        Upload Profile Picture
      </TextButton>
      <input
        id="dp-input"
        capture="”camera"
        accept="image/png, image/jpeg,image/jpg"
        type="file"
        onChange={(event) => handleCompressedUpload(event)}
      ></input>
    </>
  );
};

export default Upload;
