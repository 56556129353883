import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    accessToken: "",
    user: "",
    fullName: "",
    roles: [],
    applicationStatus: "",
  },
  reducers: {
    setCredential: (state, action) => {
      const { access } = action.payload;
      var decoded = jwt_decode(access);
      state.accessToken = access;
      state.user = decoded.user_id;
      state.fullName = decoded.name;
      state.roles = decoded.user_roles;
      state.applicationStatus = decoded.status;
      state.isSubscribed = decoded.is_subscribed;
    },
    logOut: (state, action) => {
      state.accessToken = null;
      state.user = null;
    },
  },
});



export const { setCredential, logOut } = authSlice.actions;

export default authSlice.reducer;

export const auth = (state) => state.auth;
export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.accessToken;
