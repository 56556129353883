import { apiSlice } from "../apiSlice";

export const patientEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatients: builder.query({
      query: (number) => `/api/patient-detail/?mobile=${number}`,

      providesTags: ["Patients"],
    }),
    getPatientsSuggestion: builder.query({
      query: (query) => `/api/patients/search/?q=${query}`,

      providesTags: ["PatientsSuggestion"],
    }),
    addPatientsNumber: builder.mutation({
      query: (body) => ({
        url: `/api/patient/`,
        method: "POST",
        body: body,
      }),
      providesTags: ["Patients"],
    }),
    addPatient: builder.mutation({
      query: (data) => ({
        url: "/api/patient-detail/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Patients"],
    }),
    getConsentOtp: builder.query({
      query: (id) => `/auth/consent-otp/?patient_detail=${id}`,
      providesTags: ["ConsentOtp"],
    }),
    getConsentToken: builder.query({
      query: (data) => ({
        url: `/auth/consent-token/?patient_detail=${data.id}`,
        headers: { otp: data.otp },
      }),
      providesTags: ["ConsentToken"],
    }),
  }),
});

export const { useAddPatientsNumberMutation } = patientEndpoints;
export const { useLazyGetPatientsSuggestionQuery } = patientEndpoints;
export const { useAddPatientMutation } = patientEndpoints;
export const { useGetPatientsQuery } = patientEndpoints;
export const { useLazyGetPatientsQuery } = patientEndpoints;

export const { useLazyGetConsentOtpQuery } = patientEndpoints;
export const { useLazyGetConsentTokenQuery } = patientEndpoints;
