import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
//Redux
import {
  useGetScheduleQuery,
  useUpdateDocScheduleMutation,
} from "../../api/docEndpoints/docScheduleEndpoint";
import { useRemoveHospitalMutation } from "../../api/docEndpoints/hospitalEndpoint";
//RTK Query
import {
  useGetDocLeaveQuery,
  useGetDocOffShiftQuery,
  useHandleScheduleOverlapMutation,
  useUpdateDoctorLeaveMutation,
  useUpdateOffShiftMutation,
} from "../../api/docEndpoints/doctorEndpoint";
import {
  useRejectMultipleAppointmentsMutation,
  useRescheduleMultipleAppointmentMutation,
} from "../../api/docEndpoints/appointmentEndpoints";
import {
  useAddPracticeChargeMutation,
  useGetDocPracticeChargeQuery,
} from "../../api/docEndpoints/practiceChargeEndpoint";

//Components
import ScheduleCard from "../Admin/Doctors/helpers/ScheduleCard";
import Button from "../../components/ui/Button";
import DateField from "../../components/ui/DateField";
import DateTimeField from "../../components/ui/DateTimeField";
import SchTile from "../Admin/Doctors/helpers/SchTile";
import { FormControl, TextField } from "@mui/material";
import {
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { PracticeChargeRow } from "./PracticeCharge";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import {
  SelectFieldContainer,
  ShimmerLine,
} from "../../components/ui/ui.elements";
import axios from "axios";
import { CloseIcon } from "../Auth/Auth.elements";

//Hooks
import useDocRefreshToken from "../../hooks/useDocRefreshToken";

//Styled components
import {
  BorderedGridContainer,
  CenterFlexContainer,
  LightText,
  GridContainer,
  Text,
  ErrorContainer,
  BtnWrap,
  TextButton,
  AnchorText,
  StyledFormControlLabel,
  StyledDatePicker,
  StyledTextField,
  StyledMobileTimePicker,
  StyledMobileDateTimePicker,
} from "../../Global";
import {
  AddIcon,
  MenuIcon,
  Title,
  Tabs,
  Heading,
  Heading2,
  SchTileContainer,
  Container,
  ScrollableContainer,
} from "../Admin/MainPages.elements";

//Utils
import { getDayName, handleTimeFormat } from "../../utils/parseSchedule";
import { parseDate } from "../../utils/parseDate";

import { FaPlus } from "react-icons/fa";
import { format } from "date-fns";
import {
  darkCustomStyles,
  customStyles,
  availabilityModalStyles,
  darkAvailabilityModalStyles,
} from "../../utils/ModalStyles";
import AuthContext from "../../context/authProvider";
import crown from "../../assets/crown.png";
import { gatingMessage } from "../../data/GatingMessage";
const HospitalRow = ({ hospital, setError, setSuccess, doctorData }) => {
  const { isDarkTheme, docAuth } = useContext(AuthContext);
  const subscribed = docAuth.subscribed;
  const [
    removeHospital,
    { isError: isRemoveHospitalError, error: removeHospitalError },
  ] = useRemoveHospitalMutation();
  const { data: docSchedule, isLoading: isDocScheduleLoading } =
    useGetScheduleQuery(hospital?.place_id);

  const { data: practiceCharges, isLoading: isPracticeChargesLoading } =
    useGetDocPracticeChargeQuery(hospital?.doctor_hospital_id);

  const [updateSchedule, { isLoading: isUpdateScheduleLoading }] =
    useUpdateDocScheduleMutation();

  const [updateDoctorLeave, { isLoading: isDoctorLeaveLoading }] =
    useUpdateDoctorLeaveMutation();
  const [updateDoctorOffShift, { isLoading: isDoctorOffShiftLoading }] =
    useUpdateOffShiftMutation();

  const [handleScheduleOverlap, { isLoading: isScheduleOverlapLoading }] =
    useHandleScheduleOverlapMutation();

  const [
    rejectMultipleAppointment,
    { isLoading: isRejectMultipleAppointmentsLoading },
  ] = useRejectMultipleAppointmentsMutation();

  const [updatePracticeCharge, { isLoading: isPracticeChargeLoading }] =
    useAddPracticeChargeMutation();

  const [
    rescheduleMultipleAppointments,
    { isLoading: isRescheduletMultipleAppointmentsLoading },
  ] = useRescheduleMultipleAppointmentMutation();

  const refresh = useDocRefreshToken();

  const [newSchedule, setNewSchedule] = useState(
    docSchedule?.map((item) => ({
      ...item,
    }))
  );

  const [scheduleModal, setScheduleModal] = useState(false);

  //next availability modal states
  const [avlError, setAvlError] = useState("");
  const [editingAvailability, setEditAvailability] = useState(false);

  //full day Leave update
  const [leaveFromDate, setLFDate] = useState("");
  const [leaveToDate, setLTDate] = useState("");

  //Less than a day leave states
  const [lthTime, setLTHTime] = useState("");
  const [lfhTime, setLFHTime] = useState("");
  const [lfhDate, setLFHDate] = useState("");

  const [offShiftFromDate, setOSFDate] = useState("");
  const [offShiftToDate, setOSTDate] = useState("");

  const [activeTab, setActiveTab] = useState("offShift");
  const [scheduleOverlap, setScheduleOverlap] = useState(false);
  const [schOverlapData, setOverlapData] = useState({});
  const [leaveType, setLeaveType] = useState("full");

  //Handle Existing appointments on schedule leave or adhoc shift
  const [existingAppointment, setExistingAppointment] = useState(false);
  const [existingAptData, setExistingAptData] = useState({});
  const [cancelAllApt, setCancelAllApt] = useState(false);

  //practice charge states
  const [addingCharge, setAddingCharge] = useState(false);
  const [addingNewCharge, setAddingNewCharge] = useState(false);
  const [pcValue, setPCValue] = useState("");
  const [pcName, setPCName] = useState("");
  const [updatedPC, setUpdatedPC] = useState(practiceCharges);

  const [anchorEl, setAnchorEl] = useState(null);
  const [delSchedule, setDelSchedule] = useState([]);

  const [curReschduledApt, setCurRescheduledApt] = useState([]);
  const [curCancelledApt, setCurCancelledApt] = useState([]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteHospital = async () => {
    await refresh();
    try {
      await removeHospital(hospital?.place_id);
      if (isRemoveHospitalError) {
        console.log(removeHospitalError);
        return;
      }
      // setUptError("");
      // setUptSuccess(`Your details were updated succesfully.`);
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    setNewSchedule(docSchedule);
  }, [docSchedule]);

  useEffect(() => {
    setUpdatedPC(practiceCharges);
  }, [practiceCharges]);

  async function handleUpdateSchedule() {
    const scheduleToAdd = [];
    const scheduleToDelete = [];

    //Loop through the updated schedule array and compare each object
    //with the existing schedule array to determine whether it needs
    //to be added or updated. If the object is already present in the existing
    // schedule array, update its properties. Otherwise, add the object to the scheduleToAdd array.
    for (let i = 0; i < newSchedule.length; i++) {
      let updatedObject = newSchedule[i];
      const schCopy = { ...docSchedule };
      let existingIndex = docSchedule.findIndex(
        (obj) => JSON.stringify(obj) === JSON.stringify(updatedObject)
      );
      if (existingIndex !== -1) {
        schCopy[existingIndex] = updatedObject;
      } else {
        scheduleToAdd.push(updatedObject);
      }
    }

    //Loop through the existing schedule array and compare each object with
    // the updated schedule array to determine whether it needs to be deleted.
    //If the object is not present in the updated schedule array, add it to the scheduleToDelete array.
    for (let i = 0; i < docSchedule.length; i++) {
      let existingObject = docSchedule[i];
      let updatedIndex = newSchedule.findIndex(
        (obj) => JSON.stringify(obj) === JSON.stringify(existingObject)
      );

      //converting this [4, 5] to ["4","5"]
      const parsedWeekDays = docSchedule[i].days_of_week.map((wd) =>
        wd.toString()
      );
      let difference = parsedWeekDays.filter(
        (x) => !newSchedule[i]?.days_of_week.includes(x)
      );

      if (updatedIndex === -1 && difference.length !== 0) {
        const objCopy = { ...existingObject };
        objCopy.days_of_week = difference;

        scheduleToDelete.push(objCopy);
        console.log(objCopy);
      }
    }
    try {
      await updateSchedule({
        id: hospital?.doctor_hospital_id,
        add: scheduleToAdd,
        delete: scheduleToDelete,
      });
      setScheduleModal(false);
    } catch (err) {
      console.log(err);
    }
  }

  function createScheduleRow(data, i) {
    return (
      <ScheduleCard
        key={i}
        i={i}
        newSchedule={newSchedule}
        setNewSchedule={setNewSchedule}
        setDelSchedule={setDelSchedule}
        delSchedule={delSchedule}
      />
    );
  }
  var upcomingSchedule = {
    start_time: "",
    end_time: "",
    days_of_week: [],
  };
  //add new schedule
  function addSchedule() {
    // console.log(newSchedule);
    setNewSchedule([...newSchedule, upcomingSchedule]);
  }
  function createScheduleList(props, i) {
    let days = props.days_of_week.map(getDayName);

    let start_time = handleTimeFormat(props.start_time);
    let end_time = handleTimeFormat(props.end_time);

    return (
      <Text key={i} style={{ margin: "0.2rem 0", textAlign: "start" }}>
        <b>{i + 1}</b>.&nbsp;{days.join()} <br></br>
        {start_time} - {end_time}
      </Text>
    );
  }

  //input: 2023-01-15T09:35:22.677670+05:30
  async function updateOffShift() {
    const curDate = new Date();
    if (offShiftFromDate === "" && offShiftToDate === "") {
      setAvlError("Please enter either Leave fields or Off Shift fields");
      return;
    }

    if (offShiftFromDate !== "" && offShiftToDate !== "") {
      if (offShiftFromDate > offShiftToDate) {
        setAvlError("Invalid range in doctor shift field");
        return;
      }
      if (offShiftFromDate <= curDate || offShiftToDate <= curDate) {
        setAvlError("Please enter future date");
        return;
      }
    }

    try {
      let result = await updateDoctorOffShift({
        id: hospital?.doctor_hospital_id,
        offShiftFromDate:
          format(offShiftFromDate.$d, "yyyy-MM-dd") +
          "T" +
          format(offShiftFromDate.$d, "HH:mm:ss"),
        offShiftToDate:
          format(offShiftToDate.$d, "yyyy-MM-dd") +
          "T" +
          format(offShiftToDate.$d, "HH:mm:ss"),
      });
      if (result.data) {
        setEditAvailability(false);
        setError("");
        setSuccess(`Details of Dr.${doctorData?.name} update successfully.`);
      } else if (result.error) {
        if (result.error.status === 409) {
          setScheduleOverlap(true);
          setOverlapData(result.error.data);
          return;
        }
        setError(
          result.error.data.message ||
            "Update failed. Please verify entered details."
        );
      }
      console.log(result);
    } catch (err) {
      console.log(err);
      setError("Update failed. Please verify entered details.");
    }
  }

  async function handleDiscardSchedule() {
    try {
      let result = await handleScheduleOverlap({
        id: hospital?.doctor_hospital_id,
        offShiftFromDate:
          format(offShiftFromDate.$d, "yyyy-MM-dd") +
          "T" +
          format(offShiftFromDate.$d, "HH:mm:ss"),
        offShiftToDate:
          format(offShiftToDate.$d, "yyyy-MM-dd") +
          "T" +
          format(offShiftToDate.$d, "HH:mm:ss"),
        status: "true",
      });
      if (result.data) {
        setEditAvailability(false);
        handleOffShiftReset();
        setScheduleOverlap(false);
        setError("");
        setSuccess(`Details of Dr.${doctorData?.name} update successfully.`);
      } else if (result.error) {
        if (result.error.status === 409) {
          setExistingAppointment(true);
          setExistingAptData(result.error.data);
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function handleKeepSchedule() {
    try {
      let result = await handleScheduleOverlap({
        id: hospital?.doctor_hospital_id,
        offShiftFromDate:
          format(offShiftFromDate.$d, "yyyy-MM-dd") +
          "T" +
          format(offShiftFromDate.$d, "HH:mm:ss"),
        offShiftToDate:
          format(offShiftToDate.$d, "yyyy-MM-dd") +
          "T" +
          format(offShiftToDate.$d, "HH:mm:ss"),
        status: "false",
      });
      if (result.data) {
        setEditAvailability(false);
        handleOffShiftReset();
        setScheduleOverlap(false);
        setError("");
        setSuccess(`Details of Dr.${doctorData?.name} update successfully.`);
      }
    } catch (err) {
      console.log(err);
    }
  }
  const handleOffShiftReset = () => {
    setOSFDate("");
    setOSTDate("");
    setAvlError("");
    setScheduleOverlap(false);
  };

  function handleLeaveType(e) {
    setLeaveType(e.target.value);
  }

  async function handleUpdateDoctorLeave() {
    const curDate = new Date();
    if (leaveFromDate === "" && leaveToDate === "") {
      setAvlError("Please enter either Leave fields or Off Shift fields");
      return;
    }
    if (leaveFromDate !== "" && leaveToDate !== "") {
      if (leaveFromDate > leaveToDate) {
        setAvlError("Invalid range in Doctor leave fields");
        return;
      }
      if (leaveToDate <= curDate || leaveFromDate <= curDate) {
        setAvlError("Please enter future date");
        return;
      }
    }

    try {
      let result = await updateDoctorLeave({
        id: hospital?.doctor_hospital_id,
        leaveFromDate:
          format(leaveFromDate.$d, "yyyy-MM-dd") +
          "T" +
          format(leaveFromDate.$d, "HH:mm:ss"),
        leaveToDate:
          leaveFromDate.toString() == leaveToDate.toString()
            ? format(leaveToDate.add(1, "day").$d, "yyyy-MM-dd") +
              "T" +
              format(leaveToDate.$d, "HH:mm:ss")
            : format(leaveToDate.$d, "yyyy-MM-dd") +
              "T" +
              format(leaveToDate.$d, "HH:mm:ss"),
      });
      if (result.data || result.data === null) {
        setEditAvailability(false);
        setError("");
        setSuccess(`Details of Dr.${doctorData?.name} update successfully.`);
      } else if (result.error) {
        if (result.error.status === 409) {
          setExistingAppointment(true);
          setExistingAptData(result.error.data?.appointments);
          return;
        }
        setError(
          result.error.data.message ||
            "Update failed. Please verify entered details."
        );
      }
    } catch (err) {
      console.log(err);
      setError("Update failed. Please verify entered details.");
    }
  }
  const handleDocLeaveReset = () => {
    setLFDate("");
    setLTDate("");
    setAvlError("");
  };
  useEffect(() => {
    setAvlError("");
  }, [
    leaveFromDate,
    leaveToDate,
    offShiftFromDate,
    offShiftToDate,
    editingAvailability,
  ]);

  function createPracticeChargesRow(props, i) {
    return (
      <GridContainer
        key={i}
        width="100%"
        align="flex-start"
        columns="auto 3fr 1fr"
      >
        <LightText>{i + 1}</LightText>
        <LightText>{props.name}</LightText>
        <LightText>{props.charge}</LightText>
      </GridContainer>
    );
  }

  function createPracticeChargeRow(props) {
    return (
      <PracticeChargeRow
        data={updatedPC}
        setData={setUpdatedPC}
        {...props}
      ></PracticeChargeRow>
    );
  }

  function createAppointmentRow(props, i) {
    return (
      <ExistingAppointmentRow
        appointmentData={props}
        cancelAllApt={cancelAllApt}
        setCurRescheduledApt={setCurRescheduledApt}
        setCurCancelledApt={setCurCancelledApt}
        i={i}
      ></ExistingAppointmentRow>
    );
  }

  async function handleLessThanDayLeave() {
    if (lfhTime === "" || lthTime === "" || lfhDate === "") {
      setAvlError("Please enter either Leave fields");
      return;
    }

    try {
      let result = await updateDoctorLeave({
        id: hospital?.doctor_hospital_id,
        leaveFromDate:
          format(lfhDate.$d, "yyyy-MM-dd") +
          "T" +
          format(lfhTime.$d, "HH:mm:ss"),
        leaveToDate:
          format(lfhDate.$d, "yyyy-MM-dd") +
          "T" +
          format(lthTime.$d, "HH:mm:ss"),
      });
      if (result.data || result.data === null) {
        setEditAvailability(false);
        setError("");
        setSuccess(`Details of Dr.${doctorData?.name} update successfully.`);
      } else if (result.error) {
        if (result.error.status === 409) {
          setExistingAppointment(true);
          setExistingAptData(result.error.data?.appointments);
          return;
        }
        setError(
          result.error.data.message ||
            "Update failed. Please verify entered details."
        );
      }
    } catch (err) {
      console.log(err);
      setError("Update failed. Please verify entered details.");
    }
  }

  async function handleCancelAllApt() {
    const idList = existingAptData.map((apt) => apt.id);
    console.log(idList);

    try {
      let result = await rejectMultipleAppointment({
        appointment_id: idList,
      });
      if (result.data || result.data === null) {
        setEditAvailability(false);
        setError("");
        setExistingAppointment(false);
        setSuccess(`${idList.length} Appointments cancelled successfully.`);
      } else if (result.error) {
        setError("Update failed. Please verify entered details.");
      }
    } catch (err) {
      console.log(err);
      setError("Update failed. Please verify entered details.");
    }
  }

  function openPracticeChargeModal() {
    setAddingCharge(true);
  }

  async function handleAddPC() {
    console.log(updatedPC);
    const newPC = [
      ...updatedPC,
      {
        id:
          updatedPC?.length !== 0 ? updatedPC[updatedPC.length - 1]?.id + 1 : 0,
        name: pcName,
        charge: pcValue,
        doctor_hospital: hospital?.doctor_hospital_id,
      },
    ];

    setUpdatedPC(newPC);
    setPCValue("");
    setPCName("");
    setAddingNewCharge(false);
  }

  async function updateCharges() {
    try {
      let result = await updatePracticeCharge({
        id: hospital?.doctor_hospital_id,
        practiceCharges: updatedPC,
      });
      if (result.data || result.data === null) {
        setAddingNewCharge(false);
        setError("");
        setSuccess(
          `Practice Charges of ${hospital?.name} updated successfully.`
        );
      } else if (result.error) {
        setError(
          result.error.data.message ||
            "Update failed. Please verify entered details."
        );
      }
    } catch (err) {
      console.log(err);
      setError("Update failed. Please verify entered details.");
    }
    setPCValue("");
    setPCName("");
    setAddingCharge(false);
  }

  //Reschdule and cancel appointments based on choosen options
  async function handleReschCancelAppointment() {
    const filteredReschduleApt = curReschduledApt.filter(
      (apt, i) =>
        apt.slot_id !== undefined &&
        apt.appointment_ts !== "" &&
        curReschduledApt.indexOf(apt) === i
    );
    if (curCancelledApt.length !== 0) {
      try {
        let result = await rejectMultipleAppointment({
          appointment_ids: curCancelledApt.filter(
            (item, i) => curCancelledApt.indexOf(item) === i
          ),
        });
        if (result.data || result.data === null) {
          setEditAvailability(false);
          setError("");
          setExistingAppointment(false);
          setSuccess(`${curCancelledApt.length} Appointments cancelled.`);
        } else if (result.error) {
          // setError("Update failed. Please verify entered details.");
        }
      } catch (err) {
        console.log(err);
        setError("Update failed. Please verify entered details.");
      }
    }

    if (curReschduledApt.length !== 0) {
      try {
        let result = await rescheduleMultipleAppointments({
          appointments: filteredReschduleApt,
        });
        if (result.data || result.data === null) {
          setEditAvailability(false);
          setError("");
          setExistingAppointment(false);
          setSuccess(
            `${filteredReschduleApt.length} Appointments rescheduled.`
          );
        } else if (result.error) {
          setError("Update failed. Please verify entered details.");
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  if (isDocScheduleLoading || isPracticeChargesLoading) {
    return (
      <>
        <ShimmerLine width="40%"></ShimmerLine>
        <ShimmerLine></ShimmerLine>
        <ShimmerLine></ShimmerLine>
        <ShimmerLine width="30%"></ShimmerLine>
        <ShimmerLine width="30%"></ShimmerLine>
        <ShimmerLine width="30%"></ShimmerLine>
        <ShimmerLine width="30%"></ShimmerLine>
      </>
    );
  } else {
    return (
      <BorderedGridContainer columns="1fr" padding="1rem" margin="4px 0">
        {/* Schedule Modal */}
        <ReactModal
          isOpen={scheduleModal}
          style={isDarkTheme ? darkCustomStyles : customStyles}
          onRequestClose={() => setScheduleModal(false)}
        >
          <div style={{ margin: "2rem" }}>
            <CenterFlexContainer
              style={{
                justifyContent: "space-between",
                width: "100%",
                position: "sticky",
                top: "0",
                zIndex: "10",
                backgroundColor: "#fff",
              }}
            >
              <Title style={{ width: "100%" }}>
                Schedule Update<br></br>
                <small>Total No. of Schedules: {newSchedule?.length}</small>
              </Title>
              <AddIcon onClick={addSchedule} />
              <Button
                blue
                label="Update Schedule"
                isloading={isUpdateScheduleLoading}
                disabled={isUpdateScheduleLoading}
                onclick={handleUpdateSchedule}
              />
            </CenterFlexContainer>
            <CenterFlexContainer style={{ flexDirection: "column-reverse" }}>
              {newSchedule?.map(createScheduleRow)}
            </CenterFlexContainer>
          </div>
        </ReactModal>

        {/* Availability Modal */}
        <ReactModal
          isOpen={editingAvailability}
          style={
            isDarkTheme ? darkAvailabilityModalStyles : availabilityModalStyles
          }
          onRequestClose={() => setEditAvailability(false)}
        >
          {!subscribed && (
            <div style={{ position: "absolute", top: "-38px", right: "-40px" }}>
              <img height="80px" src={crown} logo="premium"></img>
            </div>
          )}
          <Tabs>
            <ul className="nav">
              <li
                onClick={() => setActiveTab("doctorLeave")}
                className={activeTab === "doctorLeave" && "active"}
              >
                Enter Doctor Leave
              </li>
              <li
                onClick={() => setActiveTab("offShift")}
                className={activeTab === "offShift" && "active"}
              >
                Enter Doctor Off Shift
              </li>
            </ul>
          </Tabs>
          {activeTab === "doctorLeave" && (
            <>
              {!existingAppointment && (
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={leaveType}
                  defaultValue="full"
                  onChange={handleLeaveType}
                >
                  <Heading2>Record Doctor Time off or Leave</Heading2>
                  <GridContainer
                    columns="1fr"
                    margin="0"
                    place="flex-start"
                    align="flex-start"
                  >
                    <StyledFormControlLabel
                      value="full"
                      control={<Radio />}
                      label="Full Day Leave"
                    />
                    {leaveType === "full" && (
                      <GridContainer
                        margin="0 0 0 1.8rem"
                        rgap="1rem"
                        columns="1fr"
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StyledDatePicker
                            label="From"
                            value={leaveFromDate || ""}
                            onChange={(date) => setLFDate(date)}
                            disabled={existingAppointment || !subscribed}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StyledDatePicker
                            label="To"
                            value={leaveToDate || ""}
                            onChange={(date) => setLTDate(date)}
                            disabled={existingAppointment || !subscribed}
                          />
                        </LocalizationProvider>
                      </GridContainer>
                    )}
                  </GridContainer>

                  <GridContainer columns="1fr">
                    <StyledFormControlLabel
                      value="partial"
                      control={<Radio />}
                      label="Off for less than a Day"
                    />
                    {leaveType === "partial" && (
                      <Container margin="0 0 0 1.8rem">
                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StyledDatePicker
                              value={lfhDate || ""}
                              onChange={(date) => setLFHDate(date)}
                              label="Date"
                              disabled={!subscribed}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <GridContainer
                          width="100%"
                          columns="120px 1fr 120px"
                          margin="0.7rem 0 0 0"
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StyledMobileTimePicker
                              label="From"
                              value={lfhTime}
                              onChange={(time) => setLFHTime(time)}
                              disabled={!subscribed}
                            />
                          </LocalizationProvider>

                          <LightText style={{ textAlign: "center" }}>
                            To
                          </LightText>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StyledMobileTimePicker
                              label="To"
                              value={lthTime}
                              onChange={(time) => setLTHTime(time)}
                              disabled={!subscribed}
                            />
                          </LocalizationProvider>
                        </GridContainer>
                      </Container>
                    )}
                  </GridContainer>
                </RadioGroup>
              )}

              {avlError && <ErrorContainer>{avlError}</ErrorContainer>}

              {existingAppointment && (
                <>
                  <Heading>Appointments found on choosen dates</Heading>
                  <LightText>
                    There are Appointments already booked for the cancelled slot
                    <br></br>
                    Please select the appropriate action for below appointments
                  </LightText>
                  <StyledFormControlLabel
                    control={
                      <Checkbox
                        checked={cancelAllApt}
                        onChange={(e) => setCancelAllApt(e.target.checked)}
                      />
                    }
                    label="Cancel All Appointments"
                  />
                  <ScrollableContainer height="300px">
                    {existingAptData?.map(createAppointmentRow)}
                  </ScrollableContainer>
                  <BtnWrap columns="120px 100px">
                    <TextButton onClick={() => setExistingAppointment(false)}>
                      Go Back
                    </TextButton>
                    {cancelAllApt ? (
                      <Button
                        isloading={isRejectMultipleAppointmentsLoading}
                        disabled={isRejectMultipleAppointmentsLoading}
                        onclick={handleCancelAllApt}
                        label="Update"
                      ></Button>
                    ) : (
                      <Button
                        isloading={
                          isRescheduletMultipleAppointmentsLoading ||
                          isRejectMultipleAppointmentsLoading
                        }
                        disabled={
                          isRescheduletMultipleAppointmentsLoading ||
                          isRejectMultipleAppointmentsLoading
                        }
                        label="Update"
                        onclick={handleReschCancelAppointment}
                      ></Button>
                    )}
                  </BtnWrap>
                </>
              )}
              {!existingAppointment && (
                <BtnWrap columns="120px 100px">
                  <TextButton onClick={handleDocLeaveReset}>Reset</TextButton>

                  {leaveType === "full" ? (
                    <Button
                      isloading={isDoctorLeaveLoading}
                      disabled={isDoctorLeaveLoading}
                      onclick={handleUpdateDoctorLeave}
                      label="Update"
                      premium={!subscribed}
                      gatingMessage={gatingMessage.docAvailabilityGM}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      isloading={isDoctorLeaveLoading}
                      disabled={isDoctorLeaveLoading}
                      onclick={handleLessThanDayLeave}
                      label="Update"
                      premium={!subscribed}
                      gatingMessage={gatingMessage.docAvailabilityGM}
                    >
                      Update
                    </Button>
                  )}
                </BtnWrap>
              )}
            </>
          )}
          {activeTab === "offShift" && (
            <>
              <Heading>Record One Off Doctor Shift</Heading>
              <GridContainer margin="1rem 0" rgap="1rem" columns="1fr">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StyledMobileDateTimePicker
                    type="datetime-local"
                    value={offShiftFromDate || ""}
                    onChange={(date) => setOSFDate(date)}
                    label="From"
                    disabled={!subscribed}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StyledMobileDateTimePicker
                    type="datetime-local"
                    value={offShiftToDate || ""}
                    onChange={(date) => setOSTDate(date)}
                    label="To"
                    disabled={!subscribed}
                  />
                </LocalizationProvider>
              </GridContainer>

              {avlError && <ErrorContainer>{avlError}</ErrorContainer>}

              {scheduleOverlap ? (
                <>
                  <Heading2 style={{ margin: "1rem 0" }}>
                    Overlapped Schedules:
                  </Heading2>
                  <SchTileContainer>
                    {schOverlapData?.overlapping_schedules?.map((sch) => (
                      <SchTile {...sch}></SchTile>
                    ))}
                  </SchTileContainer>
                  <Text style={{ margin: " 1rem 0" }}>
                    Do you want to discard the existing Schedule?
                  </Text>

                  <BtnWrap rows="1fr 1fr" columns="120px 120px">
                    <TextButton onClick={handleKeepSchedule}>No</TextButton>
                    <Button
                      isloading={isScheduleOverlapLoading}
                      disabled={isScheduleOverlapLoading}
                      onclick={handleDiscardSchedule}
                      label="Yes"
                    />
                  </BtnWrap>
                </>
              ) : (
                <BtnWrap columns="120px 120px">
                  <TextButton onClick={handleOffShiftReset}>Reset</TextButton>
                  <Button
                    isloading={isDoctorOffShiftLoading}
                    disabled={isDoctorOffShiftLoading}
                    onclick={updateOffShift}
                    label="Update"
                    premium={!subscribed}
                    gatingMessage={gatingMessage.docAvailabilityGM}
                  >
                    Update
                  </Button>
                </BtnWrap>
              )}
            </>
          )}
        </ReactModal>

        {/* Practice Charge Modal */}
        <ReactModal
          isOpen={addingCharge}
          onRequestClose={() => setAddingCharge(false)}
          style={availabilityModalStyles}
        >
          <GridContainer columns="1fr auto">
            <Heading>Update Practice Charges</Heading>
            {/* <AddIcon onClick={()=>setAddingCharge(true)}/> */}
            <CloseIcon onClick={() => setAddingCharge(false)} />
          </GridContainer>
          <ScrollableContainer height="300px">
            <GridContainer rgap="2rem">
              {updatedPC?.map(createPracticeChargeRow)}
            </GridContainer>
            {addingNewCharge && (
              <GridContainer
                width="100%"
                margin="1rem 0"
                columns="1fr 120px auto"
              >
                <StyledTextField
                  value={pcName}
                  onChange={(e) => setPCName(e.target.value)}
                  label="Name"
                ></StyledTextField>
                <StyledTextField
                  value={pcValue}
                  onChange={(e) => setPCValue(e.target.value)}
                  label="Value"
                ></StyledTextField>
                <Button orange label="Add" onclick={handleAddPC}></Button>
              </GridContainer>
            )}
          </ScrollableContainer>
          <AnchorText
            style={{ justifyContent: "start", margin: "0.7rem 0" }}
            onClick={() => setAddingNewCharge(true)}
          >
            <FaPlus></FaPlus>&nbsp;Add New Charge
          </AnchorText>
          <BtnWrap justify="space-between" columns="auto auto">
            <TextButton onClick={() => setAddingCharge(false)}>
              Cancel
            </TextButton>
            <Button
              isloading={isPracticeChargeLoading}
              disabled={isPracticeChargeLoading}
              label="Update"
              onclick={updateCharges}
            ></Button>
          </BtnWrap>
        </ReactModal>
        <GridContainer
          style={{ position: "relative" }}
          columns="1fr max-content"
        >
          <Heading2 margin="0">{hospital.name}</Heading2>
          <MenuIcon
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {/* <MenuItem onClick={handleClose}>Edit</MenuItem> */}
            <MenuItem
              onClick={() => {
                setScheduleModal(true);
                handleClose();
              }}
            >
              Manage Schedule
            </MenuItem>
            <MenuItem
              onClick={() => {
                setEditAvailability(true);
                handleClose();
              }}
            >
              Manage Availability
            </MenuItem>
            <MenuItem
              onClick={() => {
                openPracticeChargeModal();
                handleClose();
              }}
            >
              View Practice Charges
            </MenuItem>
            <MenuItem onClick={deleteHospital}>Remove</MenuItem>
          </Menu>
        </GridContainer>

        <LightText>
          {hospital.address_1}
          {hospital.address_2}
        </LightText>
        <small>{docSchedule?.map(createScheduleList)}</small>
        {practiceCharges?.length !== 0 && (
          <GridContainer align="flex-start" width="100%">
            <Title margin="4px 0">Practice Charges</Title>
            {practiceCharges?.map(createPracticeChargesRow)}
          </GridContainer>
        )}
      </BorderedGridContainer>
    );
  }
};

export default HospitalRow;

function ExistingAppointmentRow({
  appointmentData,
  cancelAllApt,
  setCurCancelledApt,
  setCurRescheduledApt,
}) {
  const [selectedAction, setSelectedAction] = useState("cancel");

  const [docSlots, setDocSlots] = useState([]);
  const [slotId, setSlotId] = useState();
  const [docSlotsCopy, setDocSlotsCopy] = useState([]);
  const [avblDates, setAvblDates] = useState([]);
  const [reschduleDT, setReschduleDT] = useState(
    dayjs(appointmentData?.appointment_ts)
  );
  const [appointmentTS, setAppointmentTS] = useState("");

  const disableDate = (currentDate) => {
    return !avblDates.includes(currentDate.toISOString().split("T")[0]);
  };

  async function getDocSlots() {
    try {
      const response = await axios.get(
        `https://l1x3gkp512.execute-api.ap-south-1.amazonaws.com/test/doctor-listing?type=availability-slot&doctor_hospital_id=${appointmentData?.doctor_hospital}`
      );
      setDocSlots(response.data || []);
      setDocSlotsCopy(response.data || []);

      const availableDates = response.data.map((ad) => ad.schedule_date);
      setAvblDates(availableDates);
    } catch (err) {
      console.log(err);
    }
  }

  //call get slots api on selecting reschedule
  useEffect(() => {
    if (selectedAction === "reschedule") {
      getDocSlots();
    }
  }, [selectedAction, reschduleDT]);

  //logic to concat time slot and date chosen
  useEffect(() => {
    let appointmentts = docSlotsCopy?.filter(
      (sch) => sch.schedule_date === reschduleDT.toISOString().split("T")[0]
    );

    let availableSlots = appointmentts
      ?.map((apt) => apt.available_slots)
      .flat(1);

    let id = availableSlots.filter((slot) => {
      return slot.slot_id === parseInt(slotId);
    });

    if (id?.length > 0) {
      let newDate =
        appointmentts[0]?.schedule_date + "T" + id[0].start_time.slice(0, 5);
      setAppointmentTS(newDate);
    }
  }, [slotId, reschduleDT]);

  useEffect(() => {
    if (cancelAllApt) {
      setSelectedAction("cancel");
    }
  }, [cancelAllApt]);

  //store cancelled is in one array

  useEffect(() => {
    if (selectedAction === "cancel") {
      setCurCancelledApt((current) => [...current, appointmentData.id]);
      setCurRescheduledApt((current) => {
        return current.filter((obj) => obj.id !== appointmentData.id);
      });
    } else {
      setCurCancelledApt((current) => {
        return current.filter((obj) => obj !== appointmentData.id);
      });
    }

    if (selectedAction === "reschedule") {
      setCurRescheduledApt((current) => [
        ...current,
        {
          id: appointmentData.id,
          appointment_ts: appointmentTS,
          slot_id: slotId,
        },
      ]);
    }
  }, [selectedAction, appointmentTS, slotId]);
  return (
    <GridContainer
      align="flex-start"
      columns="1fr 1fr 2fr"
      style={{ borderBottom: "1px solid #dcdcdc" }}
    >
      <LightText style={{ margin: "0.5rem 0" }}>
        {appointmentData.patient_name}
        <br></br>
        {appointmentData.patient_phone_number}
      </LightText>
      <LightText style={{ margin: "0.5rem 0" }}>
        {parseDate(appointmentData.appointment_ts)}
        <br></br>
        {handleTimeFormat(appointmentData.appointment_ts)}
      </LightText>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={cancelAllApt ? "cancel" : selectedAction}
        defaultValue="cancel"
        onChange={(e) => setSelectedAction(e.target.value)}
      >
        <GridContainer columns="1fr">
          <StyledFormControlLabel
            value="cancel"
            control={<Radio />}
            label="Cancel Appointment"
            disabled={cancelAllApt}
          />
        </GridContainer>
        <GridContainer
          columns="1fr"
          margin="0"
          place="flex-start"
          align="flex-start"
        >
          <StyledFormControlLabel
            value="reschedule"
            control={<Radio />}
            label="Reschedule Appointment"
            disabled={cancelAllApt}
          />
          {selectedAction === "reschedule" && (
            <GridContainer
              margin="0 0 0 1.8rem"
              padding="0 1rem 0 0"
              columns="repeat(auto-fill,minmax(250px,1fr))"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledDatePicker
                  label="Enter the date"
                  minDate={dayjs()}
                  slotProps={{
                    textField: {
                      error: false,
                    },
                  }}
                  shouldDisableDate={disableDate}
                  value={reschduleDT}
                  onChange={(date) => {
                    setReschduleDT(date);
                    const selectedSchedule = docSlots.filter(
                      (ad) =>
                        ad.schedule_date === date.toISOString().split("T")[0]
                    );

                    setDocSlots(selectedSchedule);
                  }}
                  disabled={
                    appointmentData?.status === "Done" ||
                    appointmentData?.status === "Rejected"
                      ? true
                      : false
                  }
                />
              </LocalizationProvider>

              <SelectFieldContainer>
                <select
                  className="phone-field"
                  name="slot_id"
                  value={slotId}
                  onChange={(e) => {
                    setSlotId(e.target.value);
                  }}
                >
                  <option>Select Timeslot</option>
                  {docSlots?.map((sch) => {
                    if (
                      sch.schedule_date ===
                      reschduleDT.toISOString().split("T")[0]
                    ) {
                      return sch.available_slots.map((slots) => {
                        return (
                          <option key={slots.slot_id} value={slots.slot_id}>
                            {handleTimeFormat(slots.start_time)}
                          </option>
                        );
                      });
                    }
                  })}
                </select>
              </SelectFieldContainer>
            </GridContainer>
          )}
        </GridContainer>
      </RadioGroup>
    </GridContainer>
  );
}
