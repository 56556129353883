import React, { useContext, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { FaLock } from "react-icons/fa";
import Gating from "../ui/Gating";

import styled from "styled-components";
import AuthContext from "../../context/authProvider";
import ReactModal from "react-modal";
import {
  darkMarkVisitModalStyles,
  markVisitModalStyles,
} from "../../utils/ModalStyles";
export const Btn = styled.button`
  padding: 0.6rem 1rem;
  height: fit-content;
  border: none;
  color: #fff;
  transition: all 0.2s ease-in-out;
  font-family: "Nunito Sans";
  background-color: ${(props) => props.theme.colors.curusBlue};
  border-radius: 4px;
  font-size: 14px;
  min-width: max-content;
  font-weight: 500;
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  }
  @media screen and (max-width: 768px) {
    font-weight: 500;
    margin: 1rem 0;
    font-size: 14px;
    padding: 0.7rem 1rem;
  }
`;

export const BlueButton = styled(Btn)`
  margin: ${(props) => (props.margin ? props.margin : "0")};
  background-color: ${(props) =>
    props.disabled ? "#86ACFF" : props.theme.colors.curusLightBlue};
  box-shadow: ${(props) =>
    props.disabled
      ? ""
      : "0 2px 3px rgba(0, 0, 0, 0.19), 0 2px 2px rgba(0, 0, 0, 0.23)"};
  &:hover {
    background-color: ${(props) => (props.disabled ? "" : "#6797ff")};
  }
`;
export const RedButton = styled(Btn)`
  margin: ${(props) => (props.margin ? props.margin : "0")};
  background-color: ${(props) => (props.disabled ? "#995D5B" : "#96312e")};
  box-shadow: ${(props) =>
    props.disabled
      ? ""
      : "0 2px 3px rgba(0, 0, 0, 0.19), 0 2px 2px rgba(0, 0, 0, 0.23)"};
  &:hover {
    background-color: ${(props) => (props.disabled ? "" : "#c33f3b")};
  }
`;
export const OrangeButton = styled(Btn)`
  margin: ${(props) => (props.margin ? props.margin : "0")};
  background-color: ${(props) =>
    props.disabled ? "#E49B62" : props.theme.colors.brightCurusOrange};
  box-shadow: ${(props) =>
    props.disabled
      ? ""
      : "0 2px 2px rgba(0, 0, 0, 0.09), 0 2px 2px rgba(0, 0, 0, 0.13)"};
  &:hover {
    background-color: ${(props) =>
      props.disabled ? "" : props.theme.colors.curusOrange};
  }
`;

function Button(props) {
  const { isDarkTheme } = useContext(AuthContext);
  const [premiumModal, setPremiumModal] = useState(false);
  const openPremiumModal = () => {
    setPremiumModal(true);
  };

  function getButtons() {
    if (props.orange) {
      return (
        <OrangeButton
          margin={props.margin}
          type={props.type}
          disabled={props.disabled}
          onClick={props.premium ? openPremiumModal : props.onclick}
        >
          {props.label}
          {props.isloading && (
            <ClipLoader
              color={"#fff"}
              loading={props.isloading}
              size={15}
              cssOverride={{ marginLeft: "0.5rem" }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
          {props.premium && <FaLock />}
        </OrangeButton>
      );
    } else if (props.red) {
      return (
        <RedButton
          margin={props.margin}
          type={props.type}
          disabled={props.disabled}
          onClick={props.premium ? openPremiumModal : props.onclick}
        >
          {props.label}
          {props.isloading && (
            <ClipLoader
              color={"#fff"}
              loading={props.isloading}
              cssOverride={{ marginLeft: "0.5rem" }}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
          {props.premium && <FaLock />}
        </RedButton>
      );
    } else {
      return (
        <BlueButton
          margin={props.margin}
          type={props.type}
          disabled={props.disabled}
          onClick={props.premium ? openPremiumModal : props.onclick}
        >
          {props.label}
          {props.isloading && (
            <ClipLoader
              color={"#fff"}
              loading={props.isloading}
              cssOverride={{ marginLeft: "0.5rem" }}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          )}
          {props.premium && <FaLock />}
        </BlueButton>
      );
    }
  }

  return (
    <>
      <ReactModal
        isOpen={premiumModal}
        onRequestClose={() => setPremiumModal(false)}
        style={isDarkTheme ? darkMarkVisitModalStyles : markVisitModalStyles}
      >
        <Gating gatingMessage={props.gatingMessage} />
      </ReactModal>
      {getButtons()}
    </>
  );
}

export default Button;
