//React imports
import React, { memo, useState } from "react";

//Styled Components
import {
  Container,
  MobileCard,
  MobileCardWrapper,
  MobileCardTitle,
  MobileCardText,
  MobileCardSmallText,
  CheckIcon,
  EditIcon,
} from "../../MainPages.elements";
import {
  GridContainer,
  LightText,
  StyledInputLabel,
  StyledSelect,
} from "../../../../Global";

//Utils Function
import { parseDate } from "../../../../utils/parseDate";
import { changeTimeFormat } from "../../../../utils/parseSchedule";

//RTK Query
import { useChangeDoctorAssistantMutation } from "../../../../api/userEndpoints/doctorsEndpoint";
import { FormControl, MenuItem } from "@mui/material";

const DirectoryMobileCard = memo(
  ({ setError, setSuccess, doctorsData, cardColumn, members, i }) => {
    const [changeDoctorAssistant, { isLoading: isChangeAssitantLoading }] =
      useChangeDoctorAssistantMutation();

    //handle card height
    const [expanded, setExpanded] = useState(false);
    const [newAssistant, setNewAssistant] = useState(
      doctorsData[i]?.assistant_id
    );

    async function updateAdmin(id) {
      try {
        let result = await changeDoctorAssistant({
          id: id,
          newAssistant: newAssistant,
        });
        console.log(result);
        if (result.data || result.data === null) {
          setExpanded(false);
          setError("");
          setSuccess(
            `Admin of Dr. ${doctorsData[i]?.name} succesfully changed.`
          );
        } else if (result.error) {
          setSuccess("");
          setError(result.error.data.message || "Please Select an Admin");
        }
      } catch (err) {
        console.log(err);
        setSuccess("");
        setError("Please Select an Admin");
      }
    }

    return (
      <MobileCardWrapper
        key={doctorsData[i]?.doctor_hospital_id}
        expanded={expanded ? "true" : undefined}
      >
        <MobileCard cardColumn={cardColumn}>
          <Container justify="flex-start" align="flex-start">
            <MobileCardTitle>Dr. {doctorsData[i]?.name}</MobileCardTitle>

            {doctorsData[i]?.next_availability && (
              <>
                {doctorsData[i]?.next_availability === "Right Now" ? (
                  <MobileCardSmallText>
                    Availability:Right Now
                  </MobileCardSmallText>
                ) : (
                  <MobileCardSmallText>
                    Available On:
                    {parseDate(doctorsData[i]?.next_availability)}
                    <br></br>
                    <b>{changeTimeFormat(doctorsData[i]?.next_availability)}</b>
                  </MobileCardSmallText>
                )}
              </>
            )}
          </Container>

          <GridContainer columns="2fr 50px">
            <Container width="100%">
              <LightText width="100%" size="11px">
                Managed By:
              </LightText>
              <MobileCardText>{doctorsData[i]?.assistant_name}</MobileCardText>
            </Container>

            {!expanded && (
              <Container>
                <EditIcon
                  onClick={() => {
                    setExpanded(true);
                  }}
                />
              </Container>
            )}
          </GridContainer>
        </MobileCard>

        {expanded && (
          <GridContainer margin="8px 0" columns="3fr auto">
            <FormControl>
              <StyledInputLabel id="invoice-item-id">
                Select Admin Name
              </StyledInputLabel>
              <StyledSelect
                margin="dense"
                // name="assistant"
                value={newAssistant}
                onChange={(e) => setNewAssistant(e.target.value)}
                label="Select Admin name"
              >
                {/* <MenuItem value="Select Admin name">Select Admin name</MenuItem> */}
                {members.map((member) => {
                  return (
                    <MenuItem key={member.id} value={member.id}>
                      {member.name}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>

            <Container>
              <CheckIcon
                disabled={isChangeAssitantLoading}
                onClick={() => updateAdmin(doctorsData[i]?.doctor_hospital_id)}
              />
            </Container>
          </GridContainer>
        )}
      </MobileCardWrapper>
    );
  }
);
export default DirectoryMobileCard;
