import React, { useEffect, useState } from "react";
import { AnchorText, Button, GridContainer, LightText,StyledTextField } from "../../Global";
import { FormControl } from "@mui/material";
import { DeleteIcon, SubTitle, Title } from "../Admin/MainPages.elements";

import { FaPlus } from "react-icons/fa";

function PracticeCharge(props) {
  const [addingNewCharge, setAddingNewCharge] = useState(false);
  const [pcValue, setPCValue] = useState("");
  const [pcName, setPCName] = useState("");
  const setDummyPC = props.setDummyPC;
  const dummyPC = props.dummyPC;
  function createPracticeChargeRow(props) {
    return <PracticeChargeRow {...props}></PracticeChargeRow>;
  }
  console.log(props);
  function handleAddPC() {
    const newPC = dummyPC.map((obj) => {
      if (obj.hospital_id === props.hospital_id) {
        obj.practiceCharges = [
          ...obj.practiceCharges,
          {
            practiceChargeName: pcName,
            practiceChargeValue: pcValue,
            status: true,
          },
        ];
      }
      return obj;
    });

    setDummyPC(newPC);
    setPCValue("");
    setPCName("");
    setAddingNewCharge(false);
  }

  return (
    <GridContainer key={props.id} padding="0 1rem 0 0">
      <GridContainer columns="3fr 1fr">
        <Title>{props.hospital_name}</Title>
      </GridContainer>

      <FormControl>
        <GridContainer rgap="4px">
          {props.practiceCharges.map(createPracticeChargeRow)}
          {addingNewCharge && (
            <GridContainer columns="2fr 1fr auto">
              <StyledTextField
                value={pcName}
                onchange={(e) => setPCName(e.target.value)}
                label="Enter Charge Name"
              ></StyledTextField>
              <StyledTextField
                value={pcValue}
                onchange={(e) => setPCValue(e.target.value)}
                margin="0"
                label="Enter Charge Value"
              ></StyledTextField>
              <Button onClick={handleAddPC}>Add</Button>
            </GridContainer>
          )}

          <AnchorText onClick={() => setAddingNewCharge(true)}>
            <FaPlus></FaPlus>&nbsp;Add New Charge
          </AnchorText>
        </GridContainer>
      </FormControl>
    </GridContainer>
  );
}

export default PracticeCharge;

export const PracticeChargeRow = (props) => {
  const [chargeValue, setChargeValue] = useState(props.charge);
  var setUpdatedPC = props.setData;
  var updatedPC = props.data;

  // console.log(updatedPC);
  useEffect(() => {
    const newPc = updatedPC.map((obj) => {
      if (obj?.id === props.id) {
        return {
          ...obj,
          charge: chargeValue,
        };
      }
      return obj;
    });
    setUpdatedPC(newPc);
  }, [chargeValue]);

  function handleChargeDelete() {
    const newPc = updatedPC.filter((obj) => {
      return obj.id !== props.id;
    });
    setUpdatedPC(newPc);
  }

  return (
    <GridContainer
      width="100%"
      columns={
        props.name !== "Consultation" ? "100px 100px 100px" : "100px 100px"
      }
      gap="1rem"
    >
      <LightText>
        {props.name !== "Consultation" ? (
          props.name
        ) : (
          <SubTitle>Consultation</SubTitle>
        )}
      </LightText>
      <StyledTextField
        value={chargeValue}
        fullWidth
        onChange={(e) => setChargeValue(e.target.value)}
      ></StyledTextField>
      {props.name !== "Consultation" && (
        <DeleteIcon
          style={{ margin: "1rem auto" }}
          onClick={handleChargeDelete}
        />
      )}
    </GridContainer>
  );
};
