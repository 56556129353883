import React, { useEffect, useState } from "react";

import {
  CardContainer,
  ErrorContainer,
  SuccessContainer,
  Form,
  Button,
  GridContainer,
  BtnWrap,
  StyledTextField,
  ResponsiveGrid,
} from "../../Global";
import { Heading, Text } from "../../pages/Admin/MainPages.elements";

function Help() {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [phone, setPhone] = useState("");
  useEffect(() => {
    setError("");
  }, [phone]);
  function handleFormSubmit(e) {
    e.preventDefault();
    if (phone === "") {
      setError("Please Enter phone number");
      return;
    } else if (phone.length !== 10) {
      setError("Invalid Phone Number");
      return;
    } else if (parseInt(phone).toString().length !== 10) {
      setError("Please Enter only Numbers in Phone field");
      return;
    }
    const scriptURL =
      "https://script.google.com/macros/s/AKfycby48kiCnBNYtkOIfi5ubq-rHLn44nbIkT5KSh6jx-darONIPCnAZsEmsjbaW0uB4z2b/exec";
    const form = document.forms["submit-to-google-sheet"];
    const submitButton = document.querySelector("#submit");

    submitButton.disabled = true;

    let requestBody = new FormData(form);
    fetch(scriptURL, {
      method: "POST",
      body: requestBody,
    })
      .then((response) => {
        if (response.status === 200) {
          document.getElementById("submit-to-google-sheet").reset();
          setPhone("");
        }
        setSuccess("Message Submitted, We will contact you shortly.");
        submitButton.disabled = false;
      })
      .catch((error) => {
        console.log(error);
        setError("Something went wrong.Please try again");
        submitButton.disabled = false;
      });
  }
  return (
    <ResponsiveGrid margin="0 1.5rem" columns="11fr 1fr" align="stretch">
      <CardContainer style={{ flex: "50%" }}>
        <div>
          <Heading style={{ width: "100%" }}>Contact Us</Heading>
          <Text>
            If you have any feedback or suggestions about our Platform, do not
            hesitate to contact us at &nbsp;
            <a href="mailto:contact@curus.co.in">contact@curus.co.in</a>{" "}
          </Text>
        </div>
      </CardContainer>
      <CardContainer style={{ flex: "50%" }}>
        <Heading margin="0 0 1rem 0" style={{ width: "100%" }}>
          Need any support?
        </Heading>
        <Form
          onSubmit={(e) => handleFormSubmit(e)}
          name="submit-to-google-sheet"
          id="submit-to-google-sheet"
          method="post"
        >
          <GridContainer
            width="100%"
            columns="repeat(auto-fill,minmax(250px,1fr))"
            rgap="1rem"
          >
            {/* full Name */}
            <div class="input">
              <StyledTextField
                fullWidth
                name="Name"
                id="fullName"
                label="Full Name"
                placeholder="Full Name"
                autocomplete="off"
                required
              />
            </div>
            {/* Phone number  */}

            <StyledTextField
              value={phone}
              onchange={(e) => setPhone(e.target.value)}
              fullWidth
              type="tel"
              id="phone"
              label="Phone Number"
              name="PhoneNumber"
              placeholder="Phone Number"
              required
            />

            {/* Message field  */}
            <div class="input margin-top">
              <StyledTextField
                fullWidth
                id="msg"
                label="Message"
                name="Message"
                required
              />
            </div>
          </GridContainer>
          <BtnWrap>
            <Button id="submit" type="submit" label="Contact Us">
              Submit
            </Button>
          </BtnWrap>
        </Form>
        {error ? <ErrorContainer>{error}</ErrorContainer> : ""}
        {success ? <SuccessContainer>{success}</SuccessContainer> : ""}
      </CardContainer>
    </ResponsiveGrid>
  );
}

export default Help;
