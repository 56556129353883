//React imports
import React, { useEffect, useState, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

//Styled Components
import {
  MainContainer,
  CenterFlexContainer,
  CardContainer,
  GridContainer,
  ErrorContainer,
  SuccessContainer,
  TextButton,
  DesktopOnly,
  MobileOnly,
} from "../../Global";
import {
  AdminBody,
  StyledTable,
  StyledTR,
  StyledTH,
  TableHeader,
  TableWrapper,
  Text,
  Heading,
  AddIcon,
  FilterDropDown,
  BtnWrap,
  FilterContainer,
  ScrollableContainer,
  RedirectIcon,
  PlusIcon,
} from "../Admin/MainPages.elements";

//Components
import HeaderCard from "../../components/ui/HeaderCard";
import TextField from "../../components/ui/TextField";
import SearchField from "../../components/ui/SearchField";
import FilterButton from "../../components/ui/FilterButton";
import SelectField from "../../components/ui/SelectField";
import AppointmentMobileCard from "../Admin/Appointments/helpers/AppointmentMobileCard";
import NoRecords from "../../components/ui/NoRecords";
import DoctorTableRow from "../Admin/Doctors/helpers/DoctorTableRow";
import TaggedDocMobileCard from "../Admin/Doctors/helpers/TaggedDocMobileCard";
import CardShimmering from "../../components/ui/CardShimmering";

//auth hooks
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from "../../hooks/useAuth";
//shadow hook
import { useScrollWithShadow } from "../../hooks/useScrollShadow";

//RTK QUery
import {
  useGetLatestAppointmentsQuery,
  useMarkVisitAppointmentMutation,
  useRejectAppointmentMutation,
} from "../../api/userEndpoints/appointmentEndpoint";
import { useGetTaggedDoctorsQuery } from "../../api/userEndpoints/taggedDoctorEndpoint";
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";

import AppointmentRow from "../Admin/Appointments/helpers/AppointmentRow";
const VALIDATE_APPOINTMENT_CACHE = "/api/validate-cache/";

const SupportPage = () => {
  const {
    refetch: refetchLatestAppointments,
    data: appointments,
    isLoading: isAppointmentLoading,
    isSuccess: isAppointmentSuccess,
    isError: isAppointmentError,
    error: appointmentError,
  } = useGetLatestAppointmentsQuery();

  const { data: taggedDoctor, isLoading: isTaggedDoctorLoading } =
    useGetTaggedDoctorsQuery();
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const navigate = useNavigate();
  const { boxShadow, onScrollHandler } = useScrollWithShadow();

  //filter dropdown

  const [dropDownMobileState, setDropDownMobileState] = useState(false);

  const [appointmentsData, setAppointmentData] = useState(appointments || []);

  const [query, setQuery] = useState("");
  const [taggedDoctorsData, setTaggedDoctorsData] = useState(
    taggedDoctor || []
  );

  //ack on tagged doctor actions
  const [tagError, setTagError] = useState();
  const [tagSuccess, setTagSuccess] = useState();

  //apt acknowledgements
  const [aptSuccess, setAptSuccess] = useState();
  const [aptError, setAptError] = useState();

  //filter
  const [dateFilter, setDateFilter] = useState("Select Booked date");
  const [pNameFilter, setPNameFilter] = useState("");
  const [dNameFilter, setDNameFilter] = useState("");
  const [phoneFilter, setPhoneFilter] = useState("");
  const [aptFilter, setAptFilter] = useState("");

  //ref for detecting outside click
  const dropDownMobileRef = useRef(null);

  //regular refresh api for Appointments
  useEffect(() => {
    async function getAptCacheValidation() {
      try {
        const response = await axiosPrivate.get(VALIDATE_APPOINTMENT_CACHE, {
          headers: {
            Authorization: `Bearer ${auth?.accessToken}`,
          },
        });

        if (response.data.is_valid == false) {
          refetchLatestAppointments();
        }
      } catch (err) {}
    }

    const isValid = setInterval(getAptCacheValidation, 30000);

    return () => {
      clearInterval(isValid);
    };
  }, []);

  function createAppointmentRow(props, i) {
    return (
      <AppointmentRow
        i={i}
        key={i}
        appointmentData={props}
        setAppointmentData={setAppointmentData}
        setAptError={setAptError}
        setAptSuccess={setAptSuccess}
      />
    );
  }

  //for mobile decices: appointment
  function createAptMobileCard(props, i) {
    return (
      <AppointmentMobileCard
        key={i}
        cardColumn="2fr 1fr 2fr"
        i={props.i}
        appointmentData={props}
        setAppointmentData={setAppointmentData}
        setAptError={setAptError}
        setAptSuccess={setAptSuccess}
      ></AppointmentMobileCard>
    );
  }

  function createTaggedDocRow(props, i) {
    return (
      <DoctorTableRow
        key={i}
        setDoctorsData={setTaggedDoctorsData}
        doctorsData={taggedDoctorsData}
        setSuccess={setTagSuccess}
        setError={setTagError}
        i={i}
      />
    );
  }

  //mobile card for tagged doctors
  function createTaggedDocMobileCard(props, i) {
    return (
      <TaggedDocMobileCard
        key={i}
        cardColumn="3fr 1fr 1fr"
        setDoctorsData={setTaggedDoctorsData}
        doctorsData={taggedDoctorsData}
        setSuccess={setTagSuccess}
        setError={setTagError}
        index={i}
      ></TaggedDocMobileCard>
    );
  }

  //filter according to date created
  function handleDateFilter(filter) {
    if (filter === "Last 3 Months") {
      return "P3M";
    } else if (filter === "Last 6 Months") {
      return "P6M";
    } else if (filter === "Last Year") {
      return "P1Y";
    } else {
      return "";
    }
  }
  async function clearFilter() {
    setDateFilter("Select Booked date");
    setPNameFilter("");
    setDNameFilter("");
    setPhoneFilter("");
    setAptFilter("");
    handleFilter();
  }

  async function handleFilter() {
    var dateIso = "";

    dateIso = handleDateFilter(dateFilter);

    try {
      const response = await axiosPrivate.get(
        `https://backend.curus.co.in/api/appointment/?patient_phone_number=${phoneFilter}&status=${aptFilter}&doctor_name=${dNameFilter}&patient_name=${pNameFilter}&from=${dateIso}`,
        {
          headers: {
            Authorization: `Bearer ${auth?.accessToken}`,
          },
        }
      );

      setAppointmentData(response.data ?? []);
    } catch (err) {}
  }
  const currentDate = Math.floor(new Date().getTime / 1000);
  const filteredItems = appointments?.filter((apt) => {
    return (
      apt.patient_name?.toLowerCase().includes(query.toLowerCase()) ||
      apt.doctor_name?.toLowerCase().includes(query.toLowerCase()) ||
      (apt.patient_phone_number?.toString().includes(query.toLowerCase()) &&
        currentDate - Math.floor(new Date(apt.created_ts).getTime() / 1000) <=
          2629743)
    );
  });
  useEffect(() => {
    setAppointmentData(filteredItems ?? []);
  }, [query]);

  //rerender upon any change in cached data
  useEffect(() => {
    setAppointmentData(appointments ?? []);
  }, [appointments]);
  useEffect(() => {
    setTaggedDoctorsData(taggedDoctor ?? []);
  }, [taggedDoctor]);

  useDetectOutsideClick(dropDownMobileRef, () => {
    setDropDownMobileState(false);
  });
  if (isTaggedDoctorLoading || isAppointmentLoading) {
    return <CardShimmering />;
  } else {
    return (
      <MainContainer>
        <CenterFlexContainer
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            zIndex: "99",
          }}
        >
          <AdminBody>
            <MobileOnly>
              <GridContainer columns="1fr" rgap="0.2rem" margin="0.2rem">
                <Outlet />
                <CardContainer>
                  <CenterFlexContainer width="100%" justify="space-between">
                    <Heading>Appointments</Heading>
                    {location.pathname !== "/support/addappointment" && (
                      <AddIcon
                        small
                        onClick={() => navigate("/support/addappointment")}
                      />
                    )}
                  </CenterFlexContainer>
                  <GridContainer width="100%" columns="2fr 100px">
                    <SearchField
                      onchange={(e) => setQuery(e.target.value)}
                      value={query}
                      label="Search Users Here"
                    ></SearchField>
                    <FilterButton
                      onclick={() => setDropDownMobileState(true)}
                    />
                    <div ref={dropDownMobileRef}>
                      <FilterDropDown dropDownState={dropDownMobileState}>
                        <FilterContainer>
                          <Text light>Filter By</Text>
                          <CenterFlexContainer width="100%">
                            <SelectField
                              width="100%"
                              active={dateFilter !== ""}
                              firstChoice="Select Booked date"
                              choices={[
                                "Last 3 Months",
                                "Last 6 Months",
                                "Last Year",
                              ]}
                              onchange={(e) => setDateFilter(e.target.value)}
                              value={dateFilter !== "" ? dateFilter : ""}
                            />
                          </CenterFlexContainer>
                          <CenterFlexContainer width="100%">
                            <TextField
                              width="100%"
                              active={pNameFilter !== ""}
                              label="Patient Name"
                              onchange={(e) => setPNameFilter(e.target.value)}
                              value={pNameFilter}
                            />
                          </CenterFlexContainer>
                          <CenterFlexContainer width="100%">
                            <TextField
                              width="100%"
                              active={phoneFilter !== ""}
                              label="Patient Pnone Number"
                              onchange={(e) => setPhoneFilter(e.target.value)}
                              value={phoneFilter}
                            />
                          </CenterFlexContainer>
                          <CenterFlexContainer width="100%">
                            <TextField
                              width="100%"
                              active={dNameFilter !== ""}
                              label="Doctor Name"
                              onchange={(e) => setDNameFilter(e.target.value)}
                              value={dNameFilter}
                            />
                          </CenterFlexContainer>
                          <CenterFlexContainer width="100%">
                            <SelectField
                              width="100%"
                              active={aptFilter !== ""}
                              firstChoice="Select appointment status"
                              choices={["Confirmed", "Rejected", "Done"]}
                              onchange={(e) => setAptFilter(e.target.value)}
                              value={aptFilter}
                            />
                          </CenterFlexContainer>
                        </FilterContainer>

                        <CenterFlexContainer>
                          <BtnWrap
                            style={{
                              backgroundColor: "#eee",
                              justifyContent: "space-between",
                              padding: "0.3rem 1rem",
                              marginTop: "0",
                            }}
                          >
                            <TextButton
                              textColor="#6c6c6c"
                              onClick={clearFilter}
                            >
                              Clear All
                            </TextButton>
                            <TextButton
                              textColor="blue"
                              onClick={() => {
                                handleFilter();
                                setDropDownMobileState(false);
                              }}
                            >
                              Apply
                            </TextButton>
                          </BtnWrap>
                        </CenterFlexContainer>
                      </FilterDropDown>
                    </div>
                  </GridContainer>
                  {aptError && (
                    <ErrorContainer>{aptError ? aptError : ""}</ErrorContainer>
                  )}
                  {aptSuccess && (
                    <SuccessContainer>
                      {aptSuccess ? aptSuccess : ""}
                    </SuccessContainer>
                  )}

                  {appointmentsData?.length === 0 ? (
                    <NoRecords message="No Appointments available" />
                  ) : (
                    <ScrollableContainer
                      onScroll={onScrollHandler}
                      style={{
                        boxShadow,
                      }}
                    >
                      {appointmentsData?.map(createAptMobileCard)}
                    </ScrollableContainer>
                  )}
                </CardContainer>
                <CardContainer>
                  <HeaderCard
                    title="Doctors tagged to you"
                    onclick={() => navigate("/doctors")}
                    icon={<RedirectIcon />}
                  />
                  {tagError && (
                    <ErrorContainer>{tagError ? tagError : ""}</ErrorContainer>
                  )}
                  {tagSuccess && (
                    <SuccessContainer>
                      {tagSuccess ? tagSuccess : ""}
                    </SuccessContainer>
                  )}
                  {taggedDoctorsData?.length === 0 ? (
                    <NoRecords message="No Doctors tagged to you" />
                  ) : (
                    <ScrollableContainer
                      onScroll={onScrollHandler}
                      style={{
                        boxShadow,
                      }}
                    >
                      {taggedDoctorsData?.map(createTaggedDocMobileCard)}
                    </ScrollableContainer>
                  )}
                </CardContainer>
              </GridContainer>
            </MobileOnly>
            <DesktopOnly>
              <GridContainer margin="0 1.5rem" rgap="1.5rem" columns="1fr">
                <Outlet />
                <CardContainer>
                  <CenterFlexContainer width="100%" justify="space-between">
                    <Heading>Appointments</Heading>
                    {location.pathname !== "/support/addappointment" && (
                      <PlusIcon
                        onClick={() => navigate("/support/addappointment")}
                      />
                    )}
                  </CenterFlexContainer>
                  <GridContainer width="100%" columns="2fr auto" margin="8px">
                    <SearchField
                      onchange={(e) => setQuery(e.target.value)}
                      value={query}
                      label="Search Users Here"
                    ></SearchField>
                    <FilterButton
                      onclick={() => setDropDownMobileState(true)}
                    />
                    <div ref={dropDownMobileRef}>
                      <FilterDropDown dropDownState={dropDownMobileState}>
                        <FilterContainer>
                          <Text style={{ margin: "0" }} light>
                            Filter By
                          </Text>
                          <CenterFlexContainer width="100%">
                            <SelectField
                              width="100%"
                              active={dateFilter !== ""}
                              firstChoice="Select Booked date"
                              choices={[
                                "Last 3 Months",
                                "Last 6 Months",
                                "Last Year",
                              ]}
                              onchange={(e) => setDateFilter(e.target.value)}
                              value={dateFilter !== "" ? dateFilter : ""}
                            />
                          </CenterFlexContainer>
                          <CenterFlexContainer width="100%">
                            <TextField
                              width="100%"
                              active={pNameFilter !== ""}
                              label="Patient Name"
                              onchange={(e) => setPNameFilter(e.target.value)}
                              value={pNameFilter}
                            />
                          </CenterFlexContainer>
                          <CenterFlexContainer width="100%">
                            <TextField
                              width="100%"
                              active={phoneFilter !== ""}
                              label="Patient Pnone Number"
                              onchange={(e) => setPhoneFilter(e.target.value)}
                              value={phoneFilter}
                            />
                          </CenterFlexContainer>
                          <CenterFlexContainer width="100%">
                            <TextField
                              width="100%"
                              active={dNameFilter !== ""}
                              label="Doctor Name"
                              onchange={(e) => setDNameFilter(e.target.value)}
                              value={dNameFilter}
                            />
                          </CenterFlexContainer>
                          <CenterFlexContainer width="100%">
                            <SelectField
                              width="100%"
                              active={aptFilter !== ""}
                              firstChoice="Select appointment status"
                              choices={["Confirmed", "Rejected", "Done"]}
                              onchange={(e) => setAptFilter(e.target.value)}
                              value={aptFilter}
                            />
                          </CenterFlexContainer>
                        </FilterContainer>

                        <CenterFlexContainer>
                          <BtnWrap
                            style={{
                              backgroundColor: "#eee",
                              justifyContent: "space-between",
                              padding: "0.7rem 1rem",
                            }}
                          >
                            <TextButton
                              textColor="#6c6c6c"
                              onClick={clearFilter}
                            >
                              Clear All
                            </TextButton>
                            <TextButton
                              textColor="blue"
                              onClick={() => {
                                handleFilter();
                                setDropDownMobileState(false);
                              }}
                            >
                              Apply
                            </TextButton>
                          </BtnWrap>
                        </CenterFlexContainer>
                      </FilterDropDown>
                    </div>
                  </GridContainer>
                  {appointmentsData?.length === 0 ? (
                    <NoRecords message="No Appointments for today" />
                  ) : (
                    <TableWrapper
                      onScroll={onScrollHandler}
                      style={{
                        boxShadow,
                      }}
                    >
                      <StyledTable>
                        <TableHeader>
                          <StyledTR>
                            <StyledTH>Sr. No </StyledTH>
                            <StyledTH>Patient Details </StyledTH>
                            {/* <StyledTH>Booked on</StyledTH>
                            <StyledTH>Phone No. </StyledTH> */}
                            <StyledTH>Dr. Name </StyledTH>
                            <StyledTH>Appointment Status </StyledTH>
                            <StyledTH>Appointment Type </StyledTH>
                            <StyledTH>Actions </StyledTH>
                          </StyledTR>
                        </TableHeader>
                        <tbody>
                          {appointmentsData?.map(createAppointmentRow)}
                        </tbody>
                      </StyledTable>
                    </TableWrapper>
                  )}
                  {aptError && (
                    <ErrorContainer>{aptError ? aptError : ""}</ErrorContainer>
                  )}
                  {aptSuccess && (
                    <SuccessContainer>
                      {aptSuccess ? aptSuccess : ""}
                    </SuccessContainer>
                  )}
                </CardContainer>
                <CardContainer>
                  <HeaderCard
                    title="Doctors tagged to you"
                    onClickRoute="/login"
                  />
                  {taggedDoctorsData?.length === 0 ? (
                    <NoRecords message="No Doctors tagged to you" />
                  ) : (
                    <TableWrapper>
                      <StyledTable>
                        <TableHeader>
                          <StyledTR>
                            <StyledTH>Doctor Name</StyledTH>
                            <StyledTH>Availability</StyledTH>
                          </StyledTR>
                        </TableHeader>
                        <tbody>
                          {taggedDoctorsData?.map(createTaggedDocRow)}
                        </tbody>
                      </StyledTable>
                    </TableWrapper>
                  )}
                  {tagError && (
                    <ErrorContainer>{tagError ? tagError : ""}</ErrorContainer>
                  )}
                  {tagSuccess && (
                    <SuccessContainer>
                      {tagSuccess ? tagSuccess : ""}
                    </SuccessContainer>
                  )}
                </CardContainer>
              </GridContainer>
            </DesktopOnly>
          </AdminBody>
        </CenterFlexContainer>
      </MainContainer>
    );
  }
};
export default SupportPage;
