import React, { useState, useEffect, memo, useContext } from "react";

//styled Components
import {
  GridContainer,
  LightText,
  Text,
  StyledInputLabel,
  StyledSelect,
  StyledDatePicker,
  ResponsiveGrid,
} from "../../../Global";
import { Title } from "../../Admin/MainPages.elements";

import {
  StatusConfirmedText,
  StatusDoneText,
  StatusRejectedText,
} from "../../Admin/MainPages.elements";

import {
  StyledTD,
  StyledTR,
  InvoiceText,
} from "../../Admin/MainPages.elements";

//Components
import ReactModal from "react-modal";
import Button from "../../../components/ui/Button";
import { FormControl, MenuItem } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import NoRecords from "../../../components/ui/NoRecords";

//RTK-Query
import { useRejectAppointmentMutation } from "../../../api/docEndpoints/appointmentEndpoints";
import { useRescheduleAppointmentMutation } from "../../../api/docEndpoints/appointmentEndpoints";

//Util
import { parseDate } from "../../../utils/parseDate";
import { changeTimeFormat } from "../../../utils/parseSchedule";
import { handleTimeFormat } from "../../../utils/parseSchedule";
import axios from "axios";
import {
  darkAvailabilityModalStyles,
  availabilityModalStyles,
} from "../../../utils/ModalStyles";
import AuthContext from "../../../context/authProvider";
import ImageGallery from "./ImageViewer";
import { useSelector } from "react-redux";
import { docAuth } from "../../../reduxSlices/docAuthSlice";
import { CloseIcon } from "../../Auth/Auth.elements";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    transform: "translate(-50%, -50%)",
    height: "500px",
    padding: "1rem",
    overflow: "auto",
    borderRadius: "20px",
    width: "90vw",
    height: "90vh",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24)",
  },
  overlay: { zIndex: "1000" },
};

const DocAppointmentRow = memo(({ props, i, setAptError, setAptSuccess }) => {
  const { isDarkTheme } = useContext(AuthContext);
  const docAuthData = useSelector(docAuth);

  const [rescheduleAppointment, { isLoading: isRescheduleAppointmentLoading }] =
    useRescheduleAppointmentMutation();
  const [
    rejectAppointment,
    {
      isLoading: isRejectAppointmentLoading,
      isError: isRejectAppointmentError,
    },
  ] = useRejectAppointmentMutation();

  //reschdule new approach
  const [modifyModal, setModifyModal] = useState(false);
  const [docSlots, setDocSlots] = useState([]);
  const [docSlotsCopy, setDocSlotsCopy] = useState([]);
  const [slotId, setSlotId] = useState();
  const [avblDates, setAvblDates] = useState([]);
  const [appointmentTS, setAppointmentTS] = useState("");
  const [reschduleDT, setReschduleDT] = useState(dayjs(props?.appointment_ts));

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  //patient record modal
  const [patientRecordModal, setPatientRecordModal] = useState(false);
  const [patientRecords, setPatientRecords] = useState([]);

  const disableDate = (currentDate) => {
    return !avblDates.includes(dayjs(currentDate).format("YYYY-MM-DD"));
  };

  async function handleRejectAppointment(id) {
    try {
      await rejectAppointment(id);
      if (isRejectAppointmentError) {
        setAptError(isRejectAppointmentError);
        return;
      }
      setAptError("");
      setAptSuccess(`The appointment rejected succesfully.`);
    } catch (err) {
      console.log(err);
    }
  }

  //appointment Reschedule
  async function handleReschdule() {
    try {
      const result = await rescheduleAppointment({
        id: props?.id,
        newDate: appointmentTS,
        // newDate: "2023-05-20T17:00:00",
        slot_id: slotId,
      });

      if (result?.error?.status === 400) {
        setAptSuccess("");
        setModifyModal(false);
        setAptError(`The appointment is couldn't be rescheduled.`);
        return;
      } else {
        setAptError("");
        setModifyModal(false);
        setAptSuccess(`The appointment is successfully rescheduled.`);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function getDocSlots() {
    try {
      const response = await axios.get(
        `https://l1x3gkp512.execute-api.ap-south-1.amazonaws.com/test/doctor-listing?type=availability-slot&doctor_hospital_id=${
          props?.doctor_hospital_id
        }&availability_datetime=${reschduleDT.toISOString().split("T")[0]}`
      );
      setDocSlots(response.data || []);
      setDocSlotsCopy(response.data || []);

      const availableDates = response.data.map((ad) => ad.schedule_date);
      setAvblDates(availableDates);
    } catch (error) {
      console.log(error);
    }
  }

  //get record on click
  async function handleGetPatientRecords() {
    try {
      const response = await axios.get(
        `https://dev.curus.co.in/api/patient-record/shared/?appointment=${props.id}`,
        {
          headers: {
            Authorization: `Bearer ${docAuthData.WebappAuthenticationResult?.access}`,
          },
        }
      );
      setPatientRecords(response.data ?? []);
    } catch (e) {
      console.log(e);
    }
  }
  //logic to concat time slot and date chosen
  useEffect(() => {
    let appointmentts = docSlotsCopy?.filter(
      (sch) => sch.schedule_date === reschduleDT.toISOString().split("T")[0]
    );

    let id = appointmentts[0]?.available_slots.filter((slot) => {
      return slot.slot_id == slotId;
    });

    if (id?.length > 0) {
      let newDate =
        appointmentts[0]?.schedule_date + "T" + id[0].start_time.slice(0, 5);
      setAppointmentTS(newDate);
    }
  }, [slotId]);

  function onDocumentLoadSuccess(numPages) {
    setNumPages(numPages);
  }
  return (
    <>
      {/* Modify Modal */}
      <ReactModal
        isOpen={modifyModal}
        onRequestClose={() => setModifyModal(false)}
        style={
          isDarkTheme ? darkAvailabilityModalStyles : availabilityModalStyles
        }
      >
        <GridContainer
          height="100%"
          columns="1fr"
          rows="1fr 1fr"
          place="flex-start"
        >
          <GridContainer columns="1fr min-content" justify="space-between">
            <Title style={{ width: "max-content" }}>Appointment Details</Title>
            <Text style={{ textAlign: "start", width: "max-content" }}>
              Status:&nbsp;{" "}
              {props?.status === "confirmation pending"
                ? "Pending"
                : props.status}
            </Text>
          </GridContainer>
          <br></br>
          <GridContainer justify="flex-start" columns="1fr 10px">
            <GridContainer columns="1fr" justify="flex-start">
              <GridContainer columns="1fr 1fr" justify="flex-start">
                <InvoiceText>Name:</InvoiceText>
                <LightText>{props?.patient_name}</LightText>
              </GridContainer>

              <GridContainer columns="1fr 1fr" justify="flex-start">
                <InvoiceText>Phone:</InvoiceText>
                <LightText>{props?.patient_phone_number}</LightText>
              </GridContainer>

              <GridContainer
                columns="1fr 1fr"
                align="flex-start"
                justify="flex-start"
              >
                <InvoiceText>Created On:</InvoiceText>
                <LightText>
                  {parseDate(props?.appointment_ts)}&nbsp;
                  {changeTimeFormat(props?.appointment_ts)}
                </LightText>
              </GridContainer>
            </GridContainer>
          </GridContainer>
          <br></br>
          <Title>Reschedule appointment</Title>
          <br></br>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledDatePicker
              label="Enter the date"
              minDate={dayjs()}
              slotProps={{
                textField: {
                  error: false,
                },
              }}
              shouldDisableDate={disableDate}
              value={reschduleDT}
              onChange={(date) => {
                setReschduleDT(date);
                const selectedSchedule = docSlotsCopy.filter(
                  (ad) => ad.schedule_date == date.toISOString().split("T")[0]
                );

                setDocSlots(selectedSchedule);
              }}
              disabled={
                props?.status === "Done" || props?.status === "Rejected"
                  ? true
                  : false
              }
            />
          </LocalizationProvider>

          <FormControl fullWidth margin="dense">
            <StyledInputLabel>Select Timeslot</StyledInputLabel>
            <StyledSelect
              name="slot_id"
              value={slotId}
              label="Select Timeslot"
              onChange={(e) => {
                setSlotId(e.target.value);
              }}
            >
              <MenuItem value="">Select Timeslot</MenuItem>
              {docSlots?.map((sch) => {
                if (
                  sch.schedule_date == reschduleDT.toISOString().split("T")[0]
                ) {
                  return sch.available_slots.map((slots) => {
                    return (
                      <MenuItem key={slots.slot_id} value={slots.slot_id}>
                        {handleTimeFormat(slots.start_time)}
                        {/* {handleTimeFormat(slots.end_time)} */}
                      </MenuItem>
                    );
                  });
                }
              })}
            </StyledSelect>
          </FormControl>

          <ResponsiveGrid
            width="100%"
            columns="min-content min-content"
            justify="space-between"
            margin="8px 0"
          >
            <Button
              isloading={isRejectAppointmentLoading}
              red
              label="Reject Appointment"
              disabled={
                isRejectAppointmentLoading ||
                (props?.status === "Done"
                  ? true
                  : "" || props?.status === "rejected"
                  ? true
                  : "")
              }
              onclick={() => handleRejectAppointment(props?.id)}
            />
            <Button
              isloading={isRescheduleAppointmentLoading}
              onclick={handleReschdule}
              blue
              disabled={
                isRescheduleAppointmentLoading ||
                (props?.status === "Done" || props?.status === "Rejected"
                  ? true
                  : "")
              }
              label="Update Appointment"
            ></Button>
          </ResponsiveGrid>
        </GridContainer>
      </ReactModal>

      {/* Patient Record Modal */}
      <ReactModal
        isOpen={patientRecordModal}
        onRequestClose={() => setPatientRecordModal(false)}
        style={customStyles}
      >
        <GridContainer columns="1fr auto">
          <Title style={{ width: "max-content" }}>Patient Records</Title>
          <CloseIcon onClick={() => setPatientRecordModal(false)} />
        </GridContainer>
        {patientRecords.length === 0 ? (
          <GridContainer style={{ height: "70vh" }} columns="1fr  ">
            <NoRecords message="Patient has Not shared any previous Records" />
          </GridContainer>
        ) : (
          <ResponsiveGrid columns="1fr 1fr" justify="space-between">
            <GridContainer columns="1fr">
              <ImageGallery
                sty
                images={patientRecords.map((obj) =>
                  obj.document_file_type === "png" ? obj.document_url : ""
                )}
              />
            </GridContainer>
            <GridContainer columns="1fr">
              {patientRecords.map((obj) =>
                obj.document_file_type === "pdf" ? (
                  <iframe
                    style={{ height: "80vh", width: "40vw" }}
                    src={obj.document_url}
                  />
                ) : (
                  ""
                )
              )}
            </GridContainer>
          </ResponsiveGrid>
        )}
      </ReactModal>
      <StyledTR key={i + 1}>
        <StyledTD>{i + 1}</StyledTD>
        <StyledTD>
          <b>{props?.patient_name}</b>
          <br></br>
          <small>{props?.patient_phone_number}</small>
        </StyledTD>
        <StyledTD>
          {parseDate(props?.appointment_ts)}
          <br></br>
          <b>{changeTimeFormat(props?.appointment_ts)}</b>
        </StyledTD>

        <StyledTD style={{ width: "10%" }}>
          {(props?.status === "Done" || props?.status === "done") && (
            <StatusDoneText>Done</StatusDoneText>
          )}
          {props?.status === "Confirmed" && (
            <StatusConfirmedText>Confirmed</StatusConfirmedText>
          )}
          {props?.status === "pending" && (
            <StatusConfirmedText>Pending</StatusConfirmedText>
          )}
          {props?.status === "confirmation pending" && (
            <StatusConfirmedText>
              Confirmation<br></br> Pending
            </StatusConfirmedText>
          )}
          {props?.status === "rejected" && (
            <StatusRejectedText>Rejected</StatusRejectedText>
          )}
        </StyledTD>
        <StyledTD>{props?.appointment_type}</StyledTD>
        <StyledTD style={{ width: "25%" }}>
          <GridContainer
            rgap="1rem"
            columns="repeat(auto-fill, minmax(80px, 1fr))"
          >
            <Button
              orange
              label="Modify"
              disabled={
                props?.status === "done"
                  ? true
                  : "" || props?.status === "rejected"
                  ? true
                  : ""
              }
              onclick={() => {
                setModifyModal(true);
                getDocSlots();
              }}
            />
            <Button
              green
              label="View Records"
              disabled={
                props?.status === "done"
                  ? true
                  : "" || props?.status === "rejected"
                  ? true
                  : "" || props?.status === "confirmation pending"
                  ? true
                  : ""
              }
              onclick={() => {
                setPatientRecordModal(true);
                handleGetPatientRecords();
              }}
            />
          </GridContainer>
        </StyledTD>
      </StyledTR>
    </>
  );
});

export default DocAppointmentRow;
