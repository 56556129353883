import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

const DoughnutChart = ({ data }) => {
  const chartRef = useRef();

  useEffect(() => {
    const chart = new Chart(chartRef.current, {
      type: "doughnut",
      data: {
        labels: data.labels,
        datasets: [
          {
            label: data.title,
            data: data.values,
            backgroundColor: data.colors,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
            labels: {
              display: false,
            },
          },
          tooltip: {
            display: false,
          },
        },
      },
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default DoughnutChart;
