export const specialtiesOptions = [
  "Allergist",
  "Anaesthesiologist",
  "Andrologist",
  "Ayurvedic Practitioner",
  "Cardiologist",
  "Cardiac Electrophysiologist",
  "Dentist",
  "Dermatologist",
  "Emergency Room (ER) Doctor",
  "Endocrinologist",
  "Epidemiologist",
  "Family Medicine Physician",
  "Gastroenterologist",
  "Geriatrician",
  "Hyperbaric Physician",
  "Hematologist",
  "Hepatologist",
  "Homeopathic Doctor",
  "Immunologist",
  "Infectious Disease Specialist",
  "Intensivist",
  "Internal Medicine Specialist",
  "Medical Examiner",
  "Medical Geneticist",
  "Naturopathic Doctor",
  "Neonatologist",
  "Nephrologist",
  "Neurologist",
  "Neurosurgeon",
  "Nuclear Medicine Specialist",
  "Obstetrician/Gynecologists",
  "Occupational Medicine Specialist",
  "Oncologist",
  "Ophthalmologist",
  "Orthopedic Surgeon / Orthopedist",
  "Otolaryngologist (ENT Specialist)",
  "Palliative Care Specialist",
  "Parasitologist",
  "Pathologist",
  "Perinatologist",
  "Pediatrician",
  "Physiatrist",
  "Physiotherapist",
  "Plastic Surgeon",
  "Psychiatrist",
  "Pulmonologist",
  "Radiologist",
  "Rheumatologist",
  "Sleep Doctor / Sleep Disorders Specialist",
  "Spinal Cord Injury Specialist",
  "Sports Medicine Specialist",
  "Surgeon - Others",
  "Thoracic Surgeon",
  "Urologist",
  "Vascular Surgeon",
  "Veterinarian",
];
