//react Imports
import React, { useContext, useState } from "react";

//Styled Components
import {
  BorderedGridContainer,
  BtnWrap,
  ErrorContainer,
  GridContainer,
  LightText,
  ResponsiveGrid,
  StyledTextField,
  SuccessContainer,
  Text,
} from "../../../../Global";
import { Container, Heading, SubTitle, Title } from "../../MainPages.elements";
import { CloseIcon } from "../../../Auth/Auth.elements";

//Components
import Button from "../../../../components/ui/Button";
import ReactModal from "react-modal";
import { Tooltip } from "@mui/material";
import NoRecords from "../../../../components/ui/NoRecords";

//RTK Query
import {
  useAddFollowUpMutation,
  useLazyGetOthersFollowUpQuery,
} from "../../../../api/userEndpoints/followUpEndpoint";
import { useLazyGetPrescriptionQuery } from "../../../../api/userEndpoints/prescriptionEndpoint";
import {
  BoxContainer,
  ShimmerLine,
} from "../../../../components/ui/ui.elements";
import { FaDownload } from "react-icons/fa";

//utils
import { parseDate } from "../../../../utils/parseDate";
import { changeTimeFormat } from "../../../../utils/parseSchedule";
import AuthContext from "../../../../context/authProvider";

//constants
const mediumModalStyles = {
  content: {
    margin: "auto",
    height: "min-content",
    maxWidth: "700px",
    maxHeight: "85%",
    padding: "1.4rem",
    borderRadius: "8px",
    postion: "relative",
  },
  overlay: { zIndex: "1000", backgroundColor: "#000000bb" },
};
const ConsultationCard = ({ i, consultation, patientId }) => {
  const { consentTokens } = useContext(AuthContext);
  const [consultationModal, setConsultationModal] = useState(false);
  const [followUpModal, setFollowUpModal] = useState(false);

  const [followUpChanges, setFollowUpChanges] = useState("");
  const [nextCheckUp, setNextCheckUp] = useState("");
  const [fuError, setFuError] = useState("");
  const [fuSuccess, setFuSuccess] = useState("");

  const [
    addFollowup,
    { isLoading: isAddFollowUpLoading, isError: isAddFollowUpError },
  ] = useAddFollowUpMutation();
  const [getPrescription, { data: prescriptions }] =
    useLazyGetPrescriptionQuery();
  const [getFollowUp, { data: followUp, isLoading: isFollowUpLoading }] =
    useLazyGetOthersFollowUpQuery();

  const handleAddFollowUp = async () => {
    try {
      await addFollowup({
        consultationId: consultation.id,
        changes: followUpChanges,
        nextFollowUp: nextCheckUp,
      });
      if (isAddFollowUpError) {
        setFuSuccess("");
        setFuError("Could not add follow up. Please try again.");
        return;
      }
      setFuError("");
      setFuSuccess("Follow up added successfully.");
      setFollowUpModal(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <BorderedGridContainer margin="4px 0" padding="0.7rem 1rem" columns="1fr">
      {/* View details Modal */}
      <ReactModal
        isOpen={consultationModal}
        onRequestClose={() => setConsultationModal(false)}
        style={mediumModalStyles}
      >
        <GridContainer columns="1fr auto" margin="0 0 1rem 0">
          <Heading>Consultation Details</Heading>
          <CloseIcon onClick={() => setConsultationModal(false)} />
        </GridContainer>
        <LightText style={{ margin: "0 0 8px 0" }}>
          Consultation was made on {parseDate(consultation?.created_ts)}
        </LightText>
        <ResponsiveGrid align="stretch" columns="3fr 2fr">
          <TextContainer
            title="Basic Indications"
            text={consultation?.indications}
          ></TextContainer>
          <TextContainer
            title="Medications"
            text={consultation?.medications}
          ></TextContainer>
        </ResponsiveGrid>
        <ResponsiveGrid align="stretch" columns="3fr 1fr">
          <TextContainer
            title="Precautions"
            text={consultation?.precautions}
          ></TextContainer>
          <TextContainer
            title="Lab Tests"
            text={consultation?.lab_tests}
          ></TextContainer>
        </ResponsiveGrid>
        <TextContainer
          title="Next Checkup"
          text={consultation?.next_checkup}
        ></TextContainer>

        {followUp?.length === 0 ? (
          <NoRecords message="No Follow Ups available"></NoRecords>
        ) : isFollowUpLoading ? (
          <>
            <ShimmerLine width="40%" />
            <ShimmerLine />
          </>
        ) : (
          <>
            <Title style={{ margin: "1rem 0 0 0" }}>Follow Up</Title>
            <div style={{ height: "300px", overflow: "auto" }}>
              <GridContainer columns="1fr">
                {followUp?.map((flp, i) => {
                  return (
                    <BorderedGridContainer
                      key={i}
                      columns="1fr"
                      rows="1fr 1fr"
                      padding="4px 8px"
                      margin="4px 0"
                    >
                      <ResponsiveGrid width="100%" columns="auto 1fr 1fr">
                        <SubTitle>{i + 1}</SubTitle>
                        <Text>
                          Changes/Recommendations
                          <LightText>{flp.changes}</LightText>
                        </Text>
                        <Text>
                          Next Follow-up
                          <LightText>{flp.next_follow_up}</LightText>
                        </Text>
                      </ResponsiveGrid>

                      <small style={{ textAlign: "right" }}>
                        <LightText>
                          Added on:{parseDate(flp.created_ts)}
                        </LightText>
                      </small>
                    </BorderedGridContainer>
                  );
                })}
              </GridContainer>
            </div>
          </>
        )}
      </ReactModal>

      {/* Add Follow UP modal */}
      <ReactModal
        isOpen={followUpModal}
        onRequestClose={() => setFollowUpModal(false)}
        style={mediumModalStyles}
      >
        <GridContainer margin="0 0 1rem 0" columns="1fr auto">
          <Heading>Add Follow Up</Heading>
          <CloseIcon onClick={() => setFollowUpModal(false)} />
        </GridContainer>
        <GridContainer columns="1fr" width="100%">
          <SubTitle>Enter Follow Up Details</SubTitle>
          <StyledTextField
            label="Changes/Recommedations"
            fullWidth
            margin="dense"
            value={followUpChanges}
            multiline
            rows={4}
            onChange={(e) => setFollowUpChanges(e.target.value)}
          ></StyledTextField>
          <br></br>
          <SubTitle>Details of Next Checkup</SubTitle>
          <StyledTextField
            label="Next Checkup"
            fullWidth
            margin="dense"
            value={nextCheckUp}
            onChange={(e) => setNextCheckUp(e.target.value)}
          ></StyledTextField>
        </GridContainer>
        {fuError && <ErrorContainer>{fuError}</ErrorContainer>}
        {fuSuccess && <SuccessContainer>{fuSuccess}</SuccessContainer>}
        <BtnWrap>
          <Button
            label="Add"
            orange
            disabled={isAddFollowUpLoading}
            isloading={isAddFollowUpLoading}
            onclick={handleAddFollowUp}
          />
        </BtnWrap>
      </ReactModal>
      <ResponsiveGrid columns="1fr auto auto auto">
        {/* <LightText>{i + 1}</LightText> */}
        <Container width="100%" align="start">
          <GridContainer columns="1fr " gap="0" width="100%">
            <Text>
              Basic Indications:
              <LightText>
                {consultation?.indications.length > 100
                  ? consultation?.indications.substring(0, 50) + "..."
                  : consultation?.indications}
              </LightText>
            </Text>
          </GridContainer>
          <small>
            Consulted On: {parseDate(consultation?.created_ts)}{" "}
            {changeTimeFormat(consultation?.created_ts)}
          </small>
        </Container>
        <a
          onMouseEnter={() => getPrescription(consultation.id)}
          href={prescriptions?.presigned_url}
          download
          target="_blank"
          rel="noreferrer"
        >
          <Tooltip title="Download Prescription">
            <BoxContainer style={{ margin: "0" }}>
              <FaDownload />
            </BoxContainer>
          </Tooltip>
        </a>
        <GridContainer columns="1fr 1fr">
          <Button
            label="Add Note"
            onclick={() => setFollowUpModal(true)}
          ></Button>

          <Tooltip title="View Consultation details">
            <Button
              orange
              label="View"
              onclick={() => {
                getFollowUp({
                  id: consultation.id,
                  iv: consentTokens[patientId]?.iv,
                  token: consentTokens[patientId]?.consent_token,
                });
                setConsultationModal(true);
              }}
            ></Button>
          </Tooltip>
        </GridContainer>
      </ResponsiveGrid>
    </BorderedGridContainer>
  );
};

export default ConsultationCard;

export const TextContainer = ({ title, text }) => {
  return (
    <BorderedGridContainer
      style={{ position: "relative" }}
      padding="12px 8px 12px 0.5rem"
      margin="1rem 0"
      place="start"
    >
      <small
        style={{
          position: "absolute",
          left: "0.5rem",
          top: "-0.5rem",
          backgroundColor: "#fff",
          padding: "0 4px",
        }}
      >
        {title}
      </small>
      <LightText>{text}</LightText>
    </BorderedGridContainer>
  );
};
