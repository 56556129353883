//React import
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
//Styled Components
import {
  MainContainer,
  CenterFlexContainer,
  CardContainer,
  ErrorContainer,
  SuccessContainer,
  DesktopOnly,
  MobileOnly,
  GridContainer,
  StyledLockIcon,
} from "../../Global";
import {
  AdminBody,
  StyledTable,
  StyledTR,
  StyledTH,
  Heading,
  TableHeader,
  TableWrapper,
  RedirectIcon,
  ScrollableContainer,
  ReloadIcon,
  HeaderContainer,
} from "./MainPages.elements";

//Components
import AppointmentMobileCard from "./Appointments/helpers/AppointmentMobileCard";
import HeaderCard from "../../components/ui/HeaderCard";
import DoctorTableRow from "./Doctors/helpers/DoctorTableRow";
import NoRecords from "../../components/ui/NoRecords";
import TaggedDocMobileCard from "./Doctors/helpers/TaggedDocMobileCard";
import Shimmering from "../../components/ui/CardShimmering";
import AppointmentRow from "../Admin/Appointments/helpers/AppointmentRow";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";

//Hooks
import { useScrollWithShadow } from "../../hooks/useScrollShadow";

//Rtk Query
import {
  useGetLatestAppointmentsQuery,
  useGetMyAppointmentsQuery,
} from "../../api/userEndpoints/appointmentEndpoint";
import { useGetTaggedDoctorsQuery } from "../../api/userEndpoints/taggedDoctorEndpoint";
import {
  useGetSingleUserQuery,
  useGetUsersQuery,
} from "../../api/userEndpoints/usersEndpoint";
import { auth } from "../../reduxSlices/authSlice";
import { useSelector } from "react-redux";
import AuthContext from "../../context/authProvider";
import SimpleUserCard from "./Users/helpers/SimpleUserCard";

const VALIDATE_APPOINTMENT_CACHE = "/api/validate-cache/";
const AdminPage = () => {
  const authData = useSelector(auth);
  const { subscribed } = useContext(AuthContext);
  const {
    refetch: refetchLatestAppointments,
    data: appointments,
    isLoading: isAppointmentLoading,
  } = useGetLatestAppointmentsQuery();
  const { data: myAppointments } = useGetMyAppointmentsQuery();

  const { data: taggedDoctor, isLoading: isTaggedDoctorLoading } =
    useGetTaggedDoctorsQuery();
  const { data: users } = useGetUsersQuery();
  const { data: singleUser } = useGetSingleUserQuery(authData?.user);

  // const { auth } = useAuth();

  const { boxShadow: aptShadow, onScrollHandler: onAptScroll } =
    useScrollWithShadow();
  const { boxShadow: docShadow, onScrollHandler: onDocScroll } =
    useScrollWithShadow();

  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [tagError, setTagError] = useState();
  const [tagSuccess, setTagSuccess] = useState();

  const [aptSuccess, setAptSuccess] = useState();
  const [aptError, setAptError] = useState();

  const [alignment, setAlignment] = useState("all");

  //regular refresh api for Appointments
  useEffect(() => {
    async function getAptCacheValidation() {
      try {
        const response = await axiosPrivate.get(VALIDATE_APPOINTMENT_CACHE, {
          headers: {
            Authorization: `Bearer ${auth?.accessToken}`,
          },
        });

        if (response.data.is_valid == false) {
          refetchLatestAppointments();
        }
      } catch (err) {
        console.log(err);
      }
    }

    const isValid = setInterval(getAptCacheValidation, 30000);

    return () => {
      clearInterval(isValid);
    };
  }, []);

  function createAppointmentRow(props, i) {
    return (
      <AppointmentRow
        i={i}
        key={i}
        appointmentData={props}
        setAptError={setAptError}
        setAptSuccess={setAptSuccess}
      />
    );
  }

  function createTaggedDocRow(props, i) {
    return (
      <DoctorTableRow
        key={i}
        doctorsData={taggedDoctor}
        setSuccess={setTagSuccess}
        setError={setTagError}
        i={i}
      />
    );
  }

  function createAptMobileCard(props, i) {
    return (
      <AppointmentMobileCard
        key={i}
        cardColumn="2fr 1fr 2fr"
        i={props.i}
        appointmentData={props}
        setAptError={setAptError}
        setAptSuccess={setAptSuccess}
      ></AppointmentMobileCard>
    );
  }

  function createTaggedDocMobileCard(props, i) {
    return (
      <TaggedDocMobileCard
        key={i}
        cardColumn="3fr 1fr 1fr"
        doctorsData={taggedDoctor}
        setSuccess={setTagSuccess}
        setError={setTagError}
        index={i}
      ></TaggedDocMobileCard>
    );
  }

  function createUserRow(props, i) {
    return <SimpleUserCard key={i} users={props} cardColumn="auto 1fr " />;
  }

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  if (isTaggedDoctorLoading || isAppointmentLoading) {
    return <Shimmering />;
  } else {
    return (
      <MainContainer>
        <CenterFlexContainer
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            zIndex: "99",
          }}
        >
          <AdminBody>
            {/* Mobile Appointment Cards and Tagged Docs  */}
            <MobileOnly>
              <GridContainer columns="1fr" margin="0.2rem" rgap="0.2rem">
                <CardContainer>
                  <HeaderCard
                    title="Appointments"
                    onclick={() => navigate("/appointments")}
                    icon={<RedirectIcon />}
                    refreshIcon={<ReloadIcon />}
                    onrefresh={() => refetchLatestAppointments()}
                  />
                  {aptError && (
                    <ErrorContainer>{aptError ? aptError : ""}</ErrorContainer>
                  )}
                  {aptSuccess && (
                    <SuccessContainer>
                      {aptSuccess ? aptSuccess : ""}
                    </SuccessContainer>
                  )}
                  {alignment === "all" ? (
                    appointments?.length === 0 ? (
                      <NoRecords message="No Appointments found"></NoRecords>
                    ) : (
                      <ScrollableContainer
                        onScroll={onAptScroll}
                        style={{
                          boxShadow: aptShadow,
                        }}
                      >
                        {appointments?.map(createAptMobileCard)}
                      </ScrollableContainer>
                    )
                  ) : appointments?.length === 0 ? (
                    <NoRecords message="No Appointments found"></NoRecords>
                  ) : (
                    <ScrollableContainer
                      onScroll={onAptScroll}
                      style={{
                        boxShadow: aptShadow,
                      }}
                    >
                      {myAppointments?.map(createAptMobileCard)}
                    </ScrollableContainer>
                  )}
                </CardContainer>
                <CardContainer>
                  <HeaderCard
                    title="Doctors tagged to you"
                    onclick={() => navigate("/doctors")}
                    icon={<RedirectIcon />}
                  />
                  {tagError && (
                    <ErrorContainer>{tagError ? tagError : ""}</ErrorContainer>
                  )}
                  {tagSuccess && (
                    <SuccessContainer>
                      {tagSuccess ? tagSuccess : ""}
                    </SuccessContainer>
                  )}
                  {taggedDoctor?.length === 0 ? (
                    <NoRecords message="No Doctor tagged to you"></NoRecords>
                  ) : (
                    <ScrollableContainer
                      onScroll={onDocScroll}
                      style={{
                        boxShadow: docShadow,
                      }}
                    >
                      {taggedDoctor?.map(createTaggedDocMobileCard)}
                    </ScrollableContainer>
                  )}
                </CardContainer>
              </GridContainer>
            </MobileOnly>

            {/* Desktop APpointment Card */}
            <DesktopOnly>
              <GridContainer margin="0 1.5rem" columns="1fr" rgap="1.5rem">
                <CardContainer>
                  <HeaderContainer>
                    <GridContainer
                      width="100%"
                      columns={
                        singleUser?.roles?.includes("doctor")
                          ? "1fr min-content min-content min-content"
                          : "1fr min-content min-content"
                      }
                      margin="0 0 1rem 0"
                    >
                      <Heading>Appointments</Heading>
                      {singleUser?.roles?.includes("doctor") && (
                        <ToggleButtonGroup
                          style={{
                            backgroundColor: "#fff",
                            height: "28px",
                          }}
                          color="primary"
                          value={alignment}
                          exclusive
                          onChange={handleChange}
                        >
                          <ToggleButton
                            style={{ fontSize: "14px", textTransform: "none" }}
                            value="all"
                          >
                            All
                          </ToggleButton>
                          <ToggleButton
                            style={{ fontSize: "14px", textTransform: "none" }}
                            value="my"
                          >
                            Self
                          </ToggleButton>
                        </ToggleButtonGroup>
                      )}

                      <div onClick={() => refetchLatestAppointments()}>
                        <ReloadIcon />
                      </div>
                      <div onClick={() => navigate("/appointments")}>
                        <RedirectIcon />
                      </div>
                    </GridContainer>
                  </HeaderContainer>

                  {appointments?.length === 0 ? (
                    <NoRecords message="No Appointments for today" />
                  ) : (
                    <TableWrapper
                      onScroll={onAptScroll}
                      style={{
                        boxShadow: aptShadow,
                      }}
                    >
                      <StyledTable>
                        <TableHeader>
                          <StyledTR>
                            <StyledTH>Sr. No </StyledTH>
                            <StyledTH>Patient Details </StyledTH>

                            <StyledTH>Dr. Name </StyledTH>
                            <StyledTH>Appointment Status </StyledTH>
                            <StyledTH>Appointment Type </StyledTH>
                            <StyledTH>Actions </StyledTH>
                          </StyledTR>
                        </TableHeader>
                        {alignment === "all" ? (
                          <tbody>
                            {appointments?.map(createAppointmentRow)}
                          </tbody>
                        ) : (
                          <tbody>
                            {myAppointments?.map(createAppointmentRow)}
                          </tbody>
                        )}
                      </StyledTable>
                    </TableWrapper>
                  )}
                  {aptError && (
                    <ErrorContainer>{aptError ? aptError : ""}</ErrorContainer>
                  )}
                  {aptSuccess && (
                    <SuccessContainer>
                      {aptSuccess ? aptSuccess : ""}
                    </SuccessContainer>
                  )}
                </CardContainer>

                <GridContainer align="stretch" columns="3fr 2fr" gap="1.5rem">
                  <CardContainer>
                    <HeaderCard
                      title={
                        "Doctors tagged to you " +
                        "(" +
                        taggedDoctor?.length +
                        ")"
                      }
                      onclick={() => navigate("/doctors")}
                      icon={<RedirectIcon />}
                    />
                    {taggedDoctor?.length === 0 ? (
                      <NoRecords message="No Doctors tagged to you" />
                    ) : (
                      <TableWrapper
                        onScroll={onDocScroll}
                        style={{
                          boxShadow: docShadow,
                        }}
                      >
                        <StyledTable>
                          <TableHeader>
                            <StyledTR>
                              <StyledTH>Doctor Name</StyledTH>

                              <StyledTH>Next Available at</StyledTH>
                            </StyledTR>
                          </TableHeader>
                          <tbody>{taggedDoctor?.map(createTaggedDocRow)}</tbody>
                        </StyledTable>
                      </TableWrapper>
                    )}
                    {tagError && (
                      <ErrorContainer>
                        {tagError ? tagError : ""}
                      </ErrorContainer>
                    )}
                    {tagSuccess && (
                      <SuccessContainer>
                        {tagSuccess ? tagSuccess : ""}
                      </SuccessContainer>
                    )}
                  </CardContainer>

                  <CardContainer>
                    <GridContainer columns="1fr" width="100%">
                      <GridContainer columns="1fr auto">
                        <Heading>
                          {`Users (${users?.length})`}
                          {!subscribed && <StyledLockIcon />}
                        </Heading>

                        <RedirectIcon onClick={() => navigate("/users")} />
                      </GridContainer>

                      {users?.length === 0 ? (
                        <NoRecords message="No Users Available" />
                      ) : (
                        <ScrollableContainer>
                          {users?.map(createUserRow)}
                        </ScrollableContainer>
                      )}
                    </GridContainer>
                  </CardContainer>
                </GridContainer>
              </GridContainer>
            </DesktopOnly>
          </AdminBody>
        </CenterFlexContainer>
      </MainContainer>
    );
  }
};

export default AdminPage;
