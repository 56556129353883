//React imports
import React, { useState, useEffect, memo, useContext } from "react";
import Modal from "react-modal";

//Styled Components
import {
  CenterFlexContainer,
  GridContainer,
  ErrorContainer,
  TextButton,
  BtnWrap,
  LightText,
  AnchorText,
  StyledLockIcon,
  StyledMobileTimePicker,
  StyledDatePicker,
  StyledMobileDateTimePicker,
  StyledTextField,
  StyledFormControlLabel,
} from "../../../../Global";
import {
  Text,
  MobileCard,
  Container,
  MobileCardTitle,
  MobileCardWrapper,
  MobileCardSmallText,
  ClockIcon,
  CalendarIcon,
  Heading,
  AddIcon,
  Tabs,
  Heading2,
  SchTileContainer,
  ScrollableContainer,
  MoneyIcon,
} from "../../MainPages.elements";

//Components
import Button from "../../../../components/ui/Button";
import ScheduleMobileCard from "./ScheduleMobileCard";
import SchTile from "./SchTile";

//Util Functions
import { parseDate } from "../../../../utils/parseDate";
import { changeTimeFormat } from "../../../../utils/parseSchedule";
import { format } from "date-fns";
//RTK Query
import {
  useHandleScheduleOverlapMutation,
  useUpdateScheduleMutation,
  useUpdateDoctorLeaveMutation,
  useUpdateOffShiftMutation,
} from "../../../../api/userEndpoints/taggedDoctorEndpoint";
import { Radio, RadioGroup, Checkbox } from "@mui/material";

import { ExistingAppointmentRow } from "./TaggedDoctorsRow";
import {
  useRejectMultipleAppointmentsMutation,
  useRescheduleMultipleAppointmentMutation,
} from "../../../../api/userEndpoints/appointmentEndpoint";
import { FaPlus } from "react-icons/fa";
import { PracticeChargeRow } from "../../../Doctor/PracticeCharge";
import { useAddPracticeChargeMutation } from "../../../../api/userEndpoints/practiceChargeEndpoint";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { FormControl } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import crown from "../../../../assets/crown.png";
import AuthContext from "../../../../context/authProvider";
import { gatingMessage } from "../../../../data/GatingMessage";
import { CloseIcon } from "../../../Auth/Auth.elements";
//Constants
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    width: "90%",
    transform: "translate(-50%, -50%)",
    height: "500px",
    padding: "0",
    overflow: "auto",
    borderRadius: "20px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24)",
  },
  overlay: { zIndex: "1000" },
};

const styleNAModal = {
  content: {
    width: "min-content",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    padding: "1.5rem",
    overflow: "visible",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24)",
  },
  overlay: { zIndex: "1000" },
};

const availabilityModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    transform: "translate(-50%, -50%)",
    padding: "1.5rem",

    overflow: "visible",
    borderRadius: "20px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24)",
  },
  overlay: { zIndex: "1000" },
};

const TaggedDocMobileCard = memo(
  ({ doctorsData, docId, setError, setSuccess, index, cardColumn }) => {
    //get subscribed status
    const { subscribed } = useContext(AuthContext);

    const [updateSchedule, { isLoading: isUpdateScheduleLoading }] =
      useUpdateScheduleMutation();

    const [updateDoctorLeave, { isLoading: isDoctorLeaveLoading }] =
      useUpdateDoctorLeaveMutation();
    const [updateDoctorOffShift, { isLoading: isDoctorOffShiftLoading }] =
      useUpdateOffShiftMutation();

    const [handleScheduleOverlap, { isLoading: isScheduleOverlapLoading }] =
      useHandleScheduleOverlapMutation();

    const [
      rejectMultipleAppointments,
      { isLoading: isRejectMultipleAppointmentsLoading },
    ] = useRejectMultipleAppointmentsMutation();

    const [
      rescheduleMultipleAppointments,
      { isLoading: isRescheduletMultipleAppointmentsLoading },
    ] = useRescheduleMultipleAppointmentMutation();

    const [updatePracticeCharge, { isLoading: isPracticeChargeLoading }] =
      useAddPracticeChargeMutation();

    //next availability modal states
    const [avlError, setAvlError] = useState("");

    const [leaveFromDate, setLFDate] = useState("");
    const [leaveToDate, setLTDate] = useState("");

    //New Leave states
    const [leaveType, setLeaveType] = useState("full");

    //Less than a day leave states
    const [lthTime, setLTHTime] = useState("");
    const [lfhTime, setLFHTime] = useState("");
    const [lfhDate, setLFHDate] = useState("");

    //Handle Existing appointments on schedule leave or adhoc shift
    const [existingAppointment, setExistingAppointment] = useState(false);
    const [existingAptData, setExistingAptData] = useState({});
    const [cancelAllApt, setCancelAllApt] = useState(false);

    const [offShiftFromDate, setOSFDate] = useState("");
    const [offShiftToDate, setOSTDate] = useState("");

    const [modalIsOpen, setIsOpen] = useState(false);

    const [editingAvailability, setEditAvailability] = useState(false);

    const [activeTab, setActiveTab] = useState("offShift");
    const [scheduleOverlap, setScheduleOverlap] = useState(false);
    const [schOverlapData, setOverlapData] = useState({});
    const [delSchedule, setDelSchedule] = useState([]);

    //Practicwe charges states
    const [updatingCharges, setUpdateCharges] = useState(false);
    const [addingNewCharge, setAddingNewCharge] = useState(false);
    const [pcValue, setPCValue] = useState("");
    const [pcName, setPCName] = useState("");
    const [updatedPC, setUpdatedPC] = useState(doctorsData[index]?.practices);

    const [newSchedule, setNewSchedule] = useState(
      doctorsData[index]?.schedules
    );

    const [curReschduledApt, setCurRescheduledApt] = useState([]);
    const [curCancelledApt, setCurCancelledApt] = useState([]);

    function openEditModal() {
      setIsOpen(true);
    }

    function afterOpenModal() {
      // references are now sync'd and can be accessed.
    }

    function closeModal() {
      setIsOpen(false);
    }

    var upcomingSchedule = {
      start_time: "",
      end_time: "",
      days_of_week: [],
    };

    //add new schedule
    function addSchedule() {
      setNewSchedule([...newSchedule, upcomingSchedule]);
    }

    function createScheduleMobileCard(data, i) {
      return (
        <ScheduleMobileCard
          key={i}
          newSchedule={newSchedule}
          setNewSchedule={setNewSchedule}
          setDelSchedule={setDelSchedule}
          delSchedule={delSchedule}
          i={i}
          docId={docId}
        />
      );
    }

    async function handleUpdateSchedule() {
      const scheduleToAdd = [];
      const scheduleToDelete = [];

      //Loop through the updated schedule array and compare each object
      //with the existing schedule array to determine whether it needs
      //to be added or updated. If the object is already present in the existing
      // schedule array, update its properties. Otherwise, add the object to the scheduleToAdd array.
      for (let i = 0; i < newSchedule.length; i++) {
        let updatedObject = newSchedule[i];
        const schCopy = { ...doctorsData[index].schedules };
        let existingIndex = doctorsData[index].schedules.findIndex(
          (obj) => JSON.stringify(obj) === JSON.stringify(updatedObject)
        );
        if (existingIndex !== -1) {
          schCopy[existingIndex] = updatedObject;
        } else {
          scheduleToAdd.push(updatedObject);
        }
      }

      //Loop through the existing schedule array and compare each object with
      // the updated schedule array to determine whether it needs to be deleted.
      //If the object is not present in the updated schedule array, add it to the scheduleToDelete array.
      for (let i = 0; i < doctorsData[index].schedules.length; i++) {
        let existingObject = doctorsData[index].schedules[i];
        let updatedIndex = newSchedule.findIndex(
          (obj) => JSON.stringify(obj) === JSON.stringify(existingObject)
        );
        let difference = doctorsData[index].schedules[i]?.days_of_week.filter(
          (x) => !newSchedule[i]?.days_of_week.includes(x)
        );
        if (updatedIndex === -1 && difference.length !== 0) {
          console.log(difference);
          const objCopy = { ...existingObject };
          objCopy.days_of_week = difference;

          scheduleToDelete.push(objCopy);
          console.log(objCopy);
        }
      }
      try {
        let result = await updateSchedule({
          id: doctorsData[index]?.doctor_hospital_id,
          add: scheduleToAdd,
          delete: scheduleToDelete,
        });
        if (result.data || result.data === null) {
          closeModal();
          setError("");
          setSuccess(
            `Details of Dr.${doctorsData[index]?.name} update successfully.`
          );
        } else if (result.error) {
          setError(
            result.error.data.message ||
              "Schedule Update failed. Please verify entered details."
          );
        }
      } catch (err) {
        console.log(err);
        setSuccess("");
        setError(
          err.data.message ||
            "Schedule Update failed. Please verify entered details."
        );
      }
    }

    //function for Next availability
    //input: 2023-01-15T09:35:22.677670+05:30
    async function updateOffShift() {
      const curDate = new Date();
      if (offShiftFromDate === "" && offShiftToDate === "") {
        setAvlError("Please enter either Leave fields or Off Shift fields");
        return;
      }

      if (offShiftFromDate !== "" && offShiftToDate !== "") {
        if (offShiftFromDate > offShiftToDate) {
          setAvlError("Invalid range in doctor shift field");
          return;
        }
        if (offShiftFromDate <= curDate || offShiftToDate <= curDate) {
          setAvlError("Please enter future date");
          return;
        }
      }

      try {
        let result = await updateDoctorOffShift({
          id: doctorsData[index]?.doctor_hospital_id,
          offShiftFromDate:
            format(offShiftFromDate.$d, "yyyy-MM-dd") +
            "T" +
            format(offShiftFromDate.$d, "HH:mm:ss"),
          offShiftToDate:
            format(offShiftToDate.$d, "yyyy-MM-dd") +
            "T" +
            format(offShiftToDate.$d, "HH:mm:ss"),
        });
        if (result.data) {
          setEditAvailability(false);
          setError("");
          setSuccess(
            `Details of Dr.${doctorsData[index]?.name} update successfully.`
          );
        } else if (result.error) {
          if (result.error.status === 409) {
            setScheduleOverlap(true);
            setOverlapData(result.error.data);
            return;
          }
          setError(
            result.error.data.message ||
              "Update failed. Please verify entered details."
          );
        }
      } catch (err) {
        console.log(err);
        setError(err || "Update failed. Please verify entered details.");
      }
    }

    async function handleUpdateDoctorLeave() {
      const curDate = new Date();
      if (leaveFromDate === "" && leaveToDate === "") {
        setAvlError("Please enter either Leave fields or Off Shift fields");
        return;
      }
      if (leaveFromDate !== "" && leaveToDate !== "") {
        if (leaveFromDate > leaveToDate) {
          setAvlError("Invalid range in Doctor leave fields");
          return;
        }
        if (leaveToDate <= curDate || leaveFromDate <= curDate) {
          setAvlError("Please enter future date");
          return;
        }
      }

      try {
        let result = await updateDoctorLeave({
          id: doctorsData[index]?.doctor_hospital_id,
          leaveFromDate:
            format(leaveFromDate.$d, "yyyy-MM-dd") +
            "T" +
            format(leaveFromDate.$d, "HH:mm:ss"),
          leaveToDate:
            leaveFromDate.toString() == leaveToDate.toString()
              ? format(leaveToDate.add(1, "day").$d, "yyyy-MM-dd") +
                "T" +
                format(leaveToDate.$d, "HH:mm:ss")
              : format(leaveToDate.$d, "yyyy-MM-dd") +
                "T" +
                format(leaveToDate.$d, "HH:mm:ss"),
        });
        if (result.data || result.data === null) {
          setEditAvailability(false);
          setError("");
          setSuccess(
            `Details of Dr.${doctorsData[index]?.name} update successfully.`
          );
        } else if (result.error) {
          if (result.error.status === 409) {
            setExistingAppointment(true);
            setExistingAptData(result.error.data?.appointments);
            return;
          }
          setError(
            result.error.data.message ||
              "Update failed. Please verify entered details."
          );
        }
      } catch (err) {
        console.log(err);
        setError(err || "Update failed. Please verify entered details.");
      }
    }

    const handleOffShiftReset = () => {
      setOSFDate("");
      setOSTDate("");
      setAvlError("");
    };
    const handleDocLeaveReset = () => {
      setLFDate("");
      setLTDate("");
      setAvlError("");
    };
    useEffect(() => {
      setAvlError("");
    }, [
      leaveFromDate,
      leaveToDate,
      offShiftFromDate,
      offShiftToDate,
      editingAvailability,
    ]);
    async function handleDiscardSchedule() {
      try {
        let result = await handleScheduleOverlap({
          id: doctorsData[index]?.doctor_hospital_id,
          offShiftFromDate:
            format(offShiftFromDate.$d, "yyyy-MM-dd") +
            "T" +
            format(offShiftFromDate.$d, "HH:mm:ss"),
          offShiftToDate:
            format(offShiftToDate.$d, "yyyy-MM-dd") +
            "T" +
            format(offShiftToDate.$d, "HH:mm:ss"),
          status: "true",
        });
        if (result.data) {
          setEditAvailability(false);
          handleOffShiftReset();
          setError("");
          setSuccess(
            `Details of Dr.${doctorsData[index]?.name} update successfully.`
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
    async function handleKeepSchedule() {
      try {
        let result = await handleScheduleOverlap({
          id: doctorsData[index]?.doctor_hospital_id,
          offShiftFromDate:
            format(offShiftFromDate.$d, "yyyy-MM-dd") +
            "T" +
            format(offShiftFromDate.$d, "HH:mm:ss"),
          offShiftToDate:
            format(offShiftToDate.$d, "yyyy-MM-dd") +
            "T" +
            format(offShiftToDate.$d, "HH:mm:ss"),
          status: "false",
        });
        if (result.data) {
          setEditAvailability(false);
          handleOffShiftReset();
          setError("");
          setSuccess(
            `Details of Dr.${doctorsData[index]?.name} update successfully.`
          );
        }
      } catch (err) {
        console.log(err);
      }
    }

    function handleLeaveType(e) {
      setLeaveType(e.target.value);
    }

    function createAppointmentRow(props, i) {
      return (
        <ExistingAppointmentRow
          appointmentData={props}
          cancelAllApt={cancelAllApt}
          setCurRescheduledApt={setCurRescheduledApt}
          setCurCancelledApt={setCurCancelledApt}
          i={i}
        ></ExistingAppointmentRow>
      );
    }

    async function handleCancelAllApt() {
      const idList = existingAptData.map((apt) => apt.id);

      try {
        let result = await rejectMultipleAppointments({
          appointment_id: idList,
        });
        if (result.data || result.data === null) {
          setEditAvailability(false);
          setError("");
          setExistingAppointment(false);
          setSuccess(`${idList.length} Appointments cancelled successfully.`);
        } else if (result.error) {
          setError("Update failed. Please verify entered details.");
        }
      } catch (err) {
        console.log(err);
        setError("Update failed. Please verify entered details.");
      }
    }

    async function handleLessThanDayLeave() {
      if (lfhTime === "" || lthTime === "" || lfhDate === "") {
        setAvlError("Please enter either Leave fields");
        return;
      }

      try {
        let result = await updateDoctorLeave({
          id: doctorsData[index]?.doctor_hospital_id,
          leaveFromDate:
            format(lfhDate.$d, "yyyy-MM-dd") +
            "T" +
            format(lfhTime.$d, "HH:mm:ss"),
          leaveToDate:
            format(lfhDate.$d, "yyyy-MM-dd") +
            "T" +
            format(lthTime.$d, "HH:mm:ss"),
        });
        if (result.data || result.data === null) {
          setEditAvailability(false);
          setScheduleOverlap(false);
          setError("");
          setSuccess(
            `Details of Dr.${doctorsData[index]?.name} update successfully.`
          );
        } else if (result.error) {
          if (result.error.status === 409) {
            setExistingAppointment(true);
            setExistingAptData(result.error.data?.appointments);
            return;
          }
          setError(
            result.error.data.message ||
              "Update failed. Please verify entered details."
          );
        }
      } catch (err) {
        console.log(err);
        setError("Update failed. Please verify entered details.");
      }
    }

    function createPracticeChargeRow(props) {
      return (
        <PracticeChargeRow
          data={updatedPC}
          setData={setUpdatedPC}
          {...props}
        ></PracticeChargeRow>
      );
    }

    async function handleAddPC() {
      const newPC = [
        ...updatedPC,
        {
          id:
            updatedPC?.length !== 0
              ? updatedPC[updatedPC.length - 1]?.id + 1
              : 0,
          name: pcName,
          charge: pcValue,
          doctor_hospital: doctorsData[index]?.doctor_hospital_id,
        },
      ];
      setAddingNewCharge(false);

      setUpdatedPC(newPC);
      setPCValue("");
      setPCName("");
    }

    async function updateCharges() {
      try {
        let result = await updatePracticeCharge({
          id: doctorsData[index]?.doctor_hospital_id,
          practiceCharges: updatedPC,
        });
        if (result.data || result.data === null) {
          setAddingNewCharge(false);
          setError("");
          setSuccess(
            `Practice Charge of Dr.${doctorsData[index]?.name} updated successfully.`
          );
        } else if (result.error) {
          setError(
            result.error.data.message ||
              "Update failed. Please verify entered details."
          );
        }
      } catch (err) {
        console.log(err);
        setError("Update failed. Please verify entered details.");
      }
      setPCValue("");
      setPCName("");
      setUpdateCharges(false);
    }

    //Reschdule and cancel appointments based on choosen options
    async function handleReschCancelAppointment() {
      const filteredReschduleApt = curReschduledApt.filter(
        (apt, i) =>
          apt.slot_id !== undefined &&
          apt.appointment_ts !== "" &&
          curReschduledApt.indexOf(apt) === i
      );
      if (curCancelledApt.length !== 0) {
        try {
          let result = await rejectMultipleAppointments({
            appointment_ids: curCancelledApt,
          });
          if (result.data || result.data === null) {
            setEditAvailability(false);
            setError("");
            setExistingAppointment(false);
            setSuccess(`${curCancelledApt.length} Appointments cancelled.`);
          } else if (result.error) {
            // setError("Update failed. Please verify entered details.");
          }
        } catch (err) {
          console.log(err);
          setError("Update failed. Please verify entered details.");
        }
      }

      if (curReschduledApt.length !== 0) {
        try {
          let result = await rescheduleMultipleAppointments({
            appointments: filteredReschduleApt,
          });
          if (result.data || result.data === null) {
            setEditAvailability(false);
            setError("");
            setExistingAppointment(false);
            setSuccess(
              `${filteredReschduleApt.length} Appointments rescheduled.`
            );
          } else if (result.error) {
            setError("Update failed. Please verify entered details.");
          }
        } catch (err) {
          console.log(err);
          // setError("Update failed. Please verify entered details.");
        }
      }
    }

    return (
      <MobileCardWrapper>
        {/* Schedule Modal */}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          style={customStyles}
        >
          <div style={{ margin: "1rem" }}>
            <GridContainer
              columns="1fr 50px 80px"
              style={{
                justifyContent: "space-between",
                width: "100%",
                position: "sticky",
                top: "0",
                zIndex: "10",
                backgroundColor: "#fff",
              }}
            >
              <Heading width="100%">Schedules ({newSchedule?.length})</Heading>

              <AddIcon onClick={addSchedule} />
              <Button
                blue
                label="Update"
                isloading={isUpdateScheduleLoading}
                disabled={isUpdateScheduleLoading}
                onclick={() => handleUpdateSchedule(index)}
              />
            </GridContainer>
            <CenterFlexContainer style={{ flexDirection: "column-reverse" }}>
              {newSchedule?.map(createScheduleMobileCard)}
            </CenterFlexContainer>
          </div>
        </Modal>
        {/* Availability Modal */}
        <Modal
          isOpen={editingAvailability}
          onRequestClose={() => setEditAvailability(false)}
          style={styleNAModal}
        >
          <Tabs>
            <ul className="nav">
              <li
                onClick={() => setActiveTab("doctorLeave")}
                className={activeTab === "doctorLeave" && "active"}
              >
                Enter Doctor Leave
              </li>
              <li
                onClick={() => setActiveTab("offShift")}
                className={activeTab === "offShift" && "active"}
              >
                Enter Doctor Off Shift
              </li>
            </ul>
          </Tabs>
          {activeTab === "doctorLeave" && (
            <>
              {!existingAppointment && (
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={leaveType}
                  defaultValue="full"
                  onChange={handleLeaveType}
                >
                  <Heading>
                    Record Doctor Time off or Leave{" "}
                    {!subscribed && <StyledLockIcon />}
                  </Heading>
                  <GridContainer
                    columns="1fr"
                    margin="0"
                    place="flex-start"
                    align="flex-start"
                  >
                    <StyledFormControlLabel
                      value="full"
                      control={<Radio />}
                      label="Full Day Leave"
                    />
                    {leaveType === "full" && (
                      <GridContainer
                        rgap="1rem"
                        margin="0 0 0 1.8rem"
                        columns="1fr"
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StyledDatePicker
                            label="From"
                            value={leaveFromDate || ""}
                            onChange={(date) => setLFDate(date)}
                            disabled={existingAppointment || !subscribed}
                          />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StyledDatePicker
                            label="To"
                            value={leaveToDate || ""}
                            onChange={(date) => setLTDate(date)}
                            disabled={existingAppointment || !subscribed}
                          />
                        </LocalizationProvider>
                      </GridContainer>
                    )}
                  </GridContainer>

                  <GridContainer columns="1fr">
                    <StyledFormControlLabel
                      value="partial"
                      control={<Radio />}
                      label="Off for less than a Day"
                    />
                    {leaveType === "partial" && (
                      <Container margin="0 0 0 1.8rem">
                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StyledDatePicker
                              value={lfhDate || ""}
                              onChange={(date) => setLFHDate(date)}
                              label="Date"
                              disabled={!subscribed}
                            />
                          </LocalizationProvider>
                        </FormControl>
                        <GridContainer
                          width="100%"
                          columns="120px 1fr 120px "
                          margin="0.7rem 0 0 0"
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StyledMobileTimePicker
                              label="From"
                              value={lfhTime}
                              onChange={(time) => setLFHTime(time)}
                              disabled={!subscribed}
                            />
                          </LocalizationProvider>

                          <LightText style={{ textAlign: "center" }}>
                            To
                          </LightText>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <StyledMobileTimePicker
                              label="To"
                              value={lthTime}
                              onChange={(time) => setLTHTime(time)}
                              disabled={!subscribed}
                            />
                          </LocalizationProvider>
                        </GridContainer>
                      </Container>
                    )}
                  </GridContainer>
                </RadioGroup>
              )}

              {avlError && <ErrorContainer>{avlError}</ErrorContainer>}

              {existingAppointment && (
                <>
                  <Heading>
                    Appointments found on choosen dates <StyledLockIcon />
                  </Heading>
                  <LightText>
                    There are Appointments already booked for the cancelled slot
                    <br></br>
                    Please select the appropriate action for below appointments
                  </LightText>
                  <StyledFormControlLabel
                    control={
                      <Checkbox
                        checked={cancelAllApt}
                        onChange={(e) => setCancelAllApt(e.target.checked)}
                      />
                    }
                    label="Cancel All Appointments"
                  />
                  <ScrollableContainer height="250px">
                    {existingAptData?.map(createAppointmentRow)}
                  </ScrollableContainer>
                  <BtnWrap columns="120px 100px">
                    <TextButton onClick={() => setExistingAppointment(false)}>
                      Go Back
                    </TextButton>
                    {cancelAllApt ? (
                      <Button
                        gatingMessage={gatingMessage.docAvailabilityGM}
                        isloading={isRejectMultipleAppointmentsLoading}
                        disabled={isRejectMultipleAppointmentsLoading}
                        onclick={handleCancelAllApt}
                        label="Update"
                      ></Button>
                    ) : (
                      <Button
                        label="Update"
                        isloading={
                          isRescheduletMultipleAppointmentsLoading ||
                          isRejectMultipleAppointmentsLoading
                        }
                        disabled={
                          isRescheduletMultipleAppointmentsLoading ||
                          isRejectMultipleAppointmentsLoading
                        }
                        onclick={handleReschCancelAppointment}
                      ></Button>
                    )}
                  </BtnWrap>
                </>
              )}
              {!existingAppointment && (
                <BtnWrap columns="120px 100px">
                  <TextButton onClick={handleDocLeaveReset}>Reset</TextButton>

                  {leaveType === "full" ? (
                    <Button
                      premium={!subscribed}
                      isloading={isDoctorLeaveLoading}
                      disabled={isDoctorLeaveLoading}
                      onclick={handleUpdateDoctorLeave}
                      gatingMessage={gatingMessage.docAvailabilityGM}
                      label="Update"
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      premium={!subscribed}
                      isloading={isDoctorLeaveLoading}
                      disabled={isDoctorLeaveLoading}
                      onclick={handleLessThanDayLeave}
                      gatingMessage={gatingMessage.docAvailabilityGM}
                      label="Update"
                    >
                      Update
                    </Button>
                  )}
                </BtnWrap>
              )}
            </>
          )}
          {activeTab === "offShift" && (
            <>
              <Heading>
                Record One Off Doctor Shift{!subscribed && <StyledLockIcon />}
              </Heading>
              <GridContainer margin="1rem 0" columns="1fr" rgap="1rem">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StyledMobileDateTimePicker
                    type="datetime-local"
                    value={offShiftFromDate || ""}
                    onChange={(date) => setOSFDate(date)}
                    label="From"
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StyledMobileDateTimePicker
                    type="datetime-local"
                    value={offShiftToDate || ""}
                    onChange={(date) => setOSTDate(date)}
                    label="To"
                  />
                </LocalizationProvider>
              </GridContainer>
              {avlError && <ErrorContainer>{avlError}</ErrorContainer>}

              {scheduleOverlap ? (
                <>
                  <Heading2 style={{ margin: "1rem 0" }}>
                    Overlapped Schedules:
                  </Heading2>
                  <SchTileContainer>
                    {schOverlapData?.overlapping_schedules?.map((sch) => (
                      <SchTile {...sch}></SchTile>
                    ))}
                  </SchTileContainer>
                  <Text style={{ margin: " 1rem 0" }}>
                    Do you want to discard the existing Schedule?
                  </Text>

                  <BtnWrap rows="1fr 1fr" columns="120px 120px">
                    <TextButton onClick={handleKeepSchedule}>No</TextButton>
                    <Button
                      isloading={isScheduleOverlapLoading}
                      disabled={isScheduleOverlapLoading}
                      onclick={handleDiscardSchedule}
                      label="Yes"
                    />
                  </BtnWrap>
                </>
              ) : (
                <BtnWrap columns="120px 120px">
                  <TextButton onClick={handleOffShiftReset}>Reset</TextButton>
                  <Button
                    premium={!subscribed}
                    isloading={isDoctorOffShiftLoading}
                    disabled={isDoctorOffShiftLoading}
                    onclick={updateOffShift}
                    gatingMessage={gatingMessage.docAvailabilityGM}
                    label="Update"
                  >
                    Update
                  </Button>
                </BtnWrap>
              )}
            </>
          )}
        </Modal>

        {/* Practice Charge Modal */}
        <Modal
          isOpen={updatingCharges}
          onRequestClose={() => setUpdateCharges(false)}
          style={availabilityModalStyles}
        >
          {!subscribed && (
            <div style={{ position: "absolute", top: "-38px", right: "-40px" }}>
              <img height="80px" alt="premium" src={crown}></img>
            </div>
          )}
          <GridContainer columns="1fr auto">
            <Heading>Update Practice Charges</Heading>
            <CloseIcon onClick={() => setUpdateCharges(false)} />
          </GridContainer>
          <GridContainer
            margin="1rem 0 0 0"
            width="100%"
            columns="1fr"
            rgap="4px"
          >
            {updatedPC?.map(createPracticeChargeRow)}
          </GridContainer>
          {addingNewCharge && (
            <GridContainer
              margin="1rem 0"
              width="100%"
              columns="120px 120px 1fr"
            >
              <StyledTextField
                value={pcName}
                onChange={(e) => setPCName(e.target.value)}
                label="Name"
              ></StyledTextField>
              <StyledTextField
                value={pcValue}
                onChange={(e) => setPCValue(e.target.value)}
                label="Value"
              ></StyledTextField>
              <Button
                orange
                margin="0"
                label="Add"
                onclick={handleAddPC}
              ></Button>
            </GridContainer>
          )}
          <AnchorText
            style={{ justifyContent: "center", margin: "0.7rem 0" }}
            onClick={() => setAddingNewCharge(true)}
          >
            <FaPlus></FaPlus>&nbsp;Add New Charge
          </AnchorText>
          <BtnWrap justify="space-between" columns="auto auto">
            <TextButton onClick={() => setUpdateCharges(false)}>
              Cancel
            </TextButton>
            <Button
              isloading={isPracticeChargeLoading}
              disabled={isPracticeChargeLoading}
              label="Update"
              onclick={updateCharges}
            ></Button>
          </BtnWrap>
        </Modal>
        <MobileCard cardColumn={cardColumn}>
          <Container justify="flex-start" align="flex-start">
            <MobileCardTitle>Dr. {doctorsData[index]?.name}</MobileCardTitle>

            {doctorsData[index].next_availability && (
              <>
                {doctorsData[index]?.next_availability === "Right Now" ? (
                  <MobileCardSmallText>
                    Availability:Right Now
                  </MobileCardSmallText>
                ) : (
                  <MobileCardSmallText>
                    Available On:
                    {parseDate(doctorsData[index]?.next_availability)}
                    <br></br>
                    <b>
                      {changeTimeFormat(doctorsData[index]?.next_availability)}
                    </b>
                  </MobileCardSmallText>
                )}
              </>
            )}
          </Container>
          <Container>
            <MoneyIcon onClick={() => setUpdateCharges(true)} />
          </Container>
          <CenterFlexContainer justify="space-between" align="center">
            {doctorsData[index]?.schedules.length !== 0 && (
              <CenterFlexContainer>
                <ClockIcon
                  onClick={() => {
                    setEditAvailability(true);
                  }}
                />
              </CenterFlexContainer>
            )}

            {doctorsData[index]?.schedules.length !== 0 && (
              <CenterFlexContainer>
                <CalendarIcon
                  onClick={() => {
                    openEditModal();
                  }}
                />
              </CenterFlexContainer>
            )}
          </CenterFlexContainer>
        </MobileCard>
        {/* <MobileCardSmallText>
        {doctorsData[props.i].specialties.join()}
      </MobileCardSmallText> */}
      </MobileCardWrapper>
    );
  }
);

export default TaggedDocMobileCard;
