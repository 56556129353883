import styled from "styled-components";
import { GridContainer } from "../../Global";





export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const Price = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #1a73e8;
  margin: 4px;
`;

export const Features = styled.ul`
  list-style: none;
  margin-top: 20px;
  padding: 0;
  text-align: left;
  margin-bottom: 20px;

  li {
    margin-bottom: 5px;
  }
`;

export const Button = styled.button`
  background-color: #1a73e8;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #0c5fa5;
  }
`;

export const AggregatorCardContainer = styled(GridContainer)`
padding: 0.7rem 1rem;
border-bottom: 1px solid ${props=>props.theme.colors.borderColor};
background-color: ${props=>props.active?"#eee":"#fff"};

&:hover{
  background-color: #eee;
  cursor: pointer;
}
`