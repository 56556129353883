import React, { useState, useContext, useEffect } from "react";

//Styled components
import {
  BorderedGridContainer,
  CardContainer,
  DesktopOnly,
  ErrorContainer,
  GridContainer,
  LightText,
  MainContainer,
  MobileOnly,
  StyledDatePicker,
  StyledFormControlLabel,
  StyledInputLabel,
  StyledSelect,
  StyledTextField,
  SuccessContainer,
} from "../../../Global";
import {
  CrossIcon,
  Heading,
  PatientsSuggestion,
  PlusIcon,
  StyledTH,
  Title,
} from "../MainPages.elements";
import {
  TableWrapper,
  TableHeader,
  StyledTR,
  StyledTable,
} from "../MainPages.elements";
//Components
import SearchField from "../../../components/ui/SearchField";
import NoRecords from "../../../components/ui/NoRecords";

//hooks
import { useScrollWithShadow } from "../../../hooks/useScrollShadow";
import DocPatientRow from "./helpers/DocPatientRow";
import {
  useAddPatientMutation,
  useLazyGetPatientsQuery,
  useLazyGetPatientsSuggestionQuery,
} from "../../../api/userEndpoints/patientsEndpoint";

//package imports
import ReactModal from "react-modal";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  FormControl,
  MenuItem,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";

import Button from "../../../components/ui/Button";
import CardShimmering from "../../../components/ui/CardShimmering";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import {
  availabilityModalStyles,
  darkAvailabilityModalStyles,
} from "../../../utils/ModalStyles";
import AuthContext from "../../../context/authProvider";
import { useRef } from "react";

function ManageDocPatients() {
  const { isDarkTheme } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  //rtk  query
  const [getPatients, { isLoading: isPatientLoading }] =
    useLazyGetPatientsQuery();

  const [
    addPatient,
    { isLoading: isAddPatientLoading, error: addPatientError },
  ] = useAddPatientMutation();

  const [getSuggestion, { isLoading: isGetPatientLoading }] =
    useLazyGetPatientsSuggestionQuery();

  const [success, setSuccess] = useState("");
  const [addingPatient, setAddingPatient] = useState(false);
  const [patients, setPatients] = useState([]);

  //Pending:add filter functionality
  const [query, setQuery] = useState("");
  const [searchDropDownState, setSearchDropDownState] = useState(false);

  //add patient form states
  const [patientName, setPatientName] = useState("");
  const [patientDob, setPatientDob] = useState();
  const [patientBloodGroup, setPatientBloodGroup] = useState("");
  const [patientGender, setPatientGender] = useState("");
  const [patientPhone, setPatientPhone] = useState("");

  const [apError, setAPError] = useState("");

  const { boxShadow, onScrollHandler } = useScrollWithShadow();

  async function handleViewPatient(id) {
    window.scrollTo(0, 0);
    const curPatient = patientsSuggestions.find((obj) => obj.id === id);
    await searchPatient(curPatient.phone_number.toString());
    navigate(`/patients/${id}`);
    setSearchDropDownState(false);
  }
  async function handleViewPatientDetails(id) {
    window.scrollTo(0, 0);
    navigate(`/patients/${id}`);
    setSearchDropDownState(false);
  }

  //Destop UI
  function createPatientRow(props, i) {
    return (
      <DocPatientRow
        key={props.id}
        i={i}
        patients={props}
        patientId={id}
      ></DocPatientRow>
    );
  }

  //Mobile UI
  const createMobilePatientRow = (props, i) => {
    return (
      <BorderedGridContainer
        padding="4px 8px"
        margin="4px 0"
        columns="3fr 2fr max-content"
      >
        <GridContainer align="start">
          <Title>{props.name}</Title>
          <LightText>
            {props.age}&nbsp;<b>{props.gender}</b>
          </LightText>
        </GridContainer>
        <GridContainer align="start">
          <small>Blood Group:</small>
          <small>{props.blood_group}</small>
        </GridContainer>
        <Button
          onclick={() => handleViewPatientDetails(props?.id)}
          label={<FaInfoCircle />}
        ></Button>
      </BorderedGridContainer>
    );
  };
  async function handleAddPatient() {
    if (patientName === "") {
      setAPError("Enter Patient Name");
      return;
    } else if (patientDob === undefined) {
      setAPError("Please enter Patient's Date of birth");
      return;
    } else if (patientBloodGroup === "") {
      setAPError("Please select Patient's Blood Group");
      return;
    } else if (patientGender === "") {
      setAPError("Please enter choose Patient's gender");
      return;
    } else if (patientPhone === "") {
      setAPError("Please enter Patient phone number");
      return;
    }

    try {
      await addPatient({
        name: patientName,
        dob: patientDob.format("YYYY-MM-DD"),
        blood_group: patientBloodGroup,
        gender: patientGender,
        mobile: patientPhone,
      });
      if (addPatientError) {
        console.log(addPatientError);
        setAPError(addPatientError);
        return;
      } else {
        setAPError("");
        setSuccess(`The patient ${patientName} is added successfully.`);
        clearPatientAddForm();
        setAddingPatient(false);
      }
    } catch (err) {
      console.log(err);
    }
  }
  const handleDobChange = (newValue) => {
    setPatientDob(newValue);
  };

  const clearPatientAddForm = () => {
    setPatientName();
    setPatientBloodGroup("");
    setPatientDob();
    setPatientGender();
    setPatientPhone();
  };

  const searchPatient = async (num) => {
    if (num.length === 12) {
      var phone_number_str = num.toString();
      var slicedPhoneNumber = phone_number_str.substring(2, 12);
      const response = await getPatients(slicedPhoneNumber);
      setPatients(response.data ?? []);
      setSearchDropDownState(false);
    } else if (num.length === 10) {
      const response = await getPatients(num);
      setPatients(response.data ?? []);
      setSearchDropDownState(false);
    } else {
      alert("Please Enter correct Phone number");
    }
  };
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      searchPatient(query);
    }
  };

  const [debouncedInputValue, setDebouncedInputValue] = useState("");
  const [patientsSuggestions, setPatientsSuggestions] = useState([]);

  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedInputValue(query);
    }, 500);

    return () => clearTimeout(delayInputTimeoutId);
  }, [query, 500]);

  useEffect(() => {
    if (debouncedInputValue.length !== 0) {
      getPatientsSuggestion();
      setSearchDropDownState(true);
    }
  }, [debouncedInputValue]);

  const getPatientsSuggestion = async () => {
    const response = await getSuggestion(query);
    setSearchDropDownState(true);
    setPatientsSuggestions(response.data ?? []);
  };

  return (
    <MainContainer>
      <ReactModal
        isOpen={addingPatient}
        onRequestClose={() => setAddingPatient(false)}
        style={
          isDarkTheme ? darkAvailabilityModalStyles : availabilityModalStyles
        }
      >
        <GridContainer columns="repeat(auto-fill,minmax(250px,1fr))" gap="1rem">
          <GridContainer margin="0 0 1rem 0" columns="1fr auto">
            <Heading style={{ width: "max-content" }}>Add Patient</Heading>

            <CrossIcon onClick={() => setAddingPatient(false)} />
          </GridContainer>
          <StyledTextField
            margin="dense"
            label="Patient Name"
            value={patientName}
            onChange={(e) => setPatientName(e.target.value)}
          ></StyledTextField>
          <FormControl fullWidth margin="dense">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledDatePicker
                label="Date of Birth"
                value={patientDob}
                onChange={(newValue) => handleDobChange(newValue)}
              ></StyledDatePicker>
            </LocalizationProvider>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <StyledInputLabel id="invoice-item-id">
              Select Blood Group
            </StyledInputLabel>
            <StyledSelect
              labelId="invoice-item-id"
              label="Select Blood Group"
              onChange={(e) => setPatientBloodGroup(e.target.value)}
              value={patientBloodGroup}
            >
              <MenuItem value="A+">A+</MenuItem>
              <MenuItem value="A+">A-</MenuItem>
              <MenuItem value="A+">B+</MenuItem>
              <MenuItem value="A+">B-</MenuItem>
              <MenuItem value="A+">O+</MenuItem>
              <MenuItem value="A+">O-</MenuItem>
              <MenuItem value="A+">AB+</MenuItem>
              <MenuItem value="A+">AB-</MenuItem>
            </StyledSelect>
          </FormControl>
          <FormControl margin="dense">
            <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={patientGender}
              onChange={(e) => setPatientGender(e.target.value)}
            >
              <StyledFormControlLabel
                value="female"
                control={<Radio />}
                label="Female"
              />
              <StyledFormControlLabel
                value="male"
                control={<Radio />}
                label="Male"
              />
            </RadioGroup>
          </FormControl>
          <StyledTextField
            margin="dense"
            label="Patient Phone"
            value={patientPhone}
            onChange={(e) => setPatientPhone(e.target.value)}
          ></StyledTextField>
          {apError && <ErrorContainer>{apError}</ErrorContainer>}{" "}
          <Button
            orange
            label="Add Patient"
            onclick={handleAddPatient}
            isloading={isAddPatientLoading}
            disabled={isAddPatientLoading}
            margin="1rem 0 0 0"
          ></Button>
        </GridContainer>
      </ReactModal>

      <DesktopOnly>
        <MainContainer>
          <GridContainer
            align="flex-start"
            gap="1.5rem"
            columns={id ? "3fr 2fr" : "1fr"}
            margin="0 1.5rem"
          >
            <CardContainer>
              <GridContainer columns="1fr min-content" width="100%">
                <Heading>
                  {location.pathname.includes("consultation")
                    ? "Add Consultation"
                    : "Patients"}
                </Heading>
                <PlusIcon onClick={() => setAddingPatient(true)}></PlusIcon>
              </GridContainer>
              <GridContainer
                width="100%"
                columns="1fr auto"
                margin="8px 0"
                onKeyDown={handleKeypress}
                style={{ position: "relative" }}
              >
                <SearchField
                  value={query}
                  onchange={(e) => setQuery(e.target.value)}
                  width="100%"
                  label="Search Patient by their Name/Phone number"
                  type="tel"
                  onfocus={() => setSearchDropDownState(true)}
                  onblur={() => setSearchDropDownState(false)}
                ></SearchField>
                {patientsSuggestions.length !== 0 && searchDropDownState && (
                  <PatientsSuggestion>
                    {patientsSuggestions.map((pat) => (
                      <li onMouseDown={() => handleViewPatient(pat.id)}>
                        <b>{pat.name}</b>
                        <small>{pat.phone_number}</small>
                      </li>
                    ))}
                  </PatientsSuggestion>
                )}
                <Button
                  label="Search"
                  isloading={isPatientLoading}
                  disabled={isPatientLoading}
                  onclick={() => searchPatient(query)}
                />
              </GridContainer>

              {patients?.length === 0 ? (
                <NoRecords message="Type the patient's phone number to get the list of patients" />
              ) : isPatientLoading ? (
                <CardShimmering />
              ) : (
                <>
                  <DesktopOnly>
                    <TableWrapper
                      onScroll={onScrollHandler}
                      style={{
                        boxShadow,
                      }}
                    >
                      <StyledTable>
                        <TableHeader>
                          <StyledTR>
                            <StyledTH>Sr. No </StyledTH>
                            <StyledTH>Patient Name </StyledTH>

                            <StyledTH>Blood Group </StyledTH>

                            <StyledTH>Date of Birth</StyledTH>
                            <StyledTH>Actions </StyledTH>
                          </StyledTR>
                        </TableHeader>
                        <tbody>{patients?.map(createPatientRow)}</tbody>
                      </StyledTable>
                    </TableWrapper>
                  </DesktopOnly>
                  <MobileOnly>
                    {patients?.map(createMobilePatientRow)}
                  </MobileOnly>
                </>
              )}

              {success && <SuccessContainer>{success}</SuccessContainer>}
            </CardContainer>
            <Outlet />
          </GridContainer>
        </MainContainer>
      </DesktopOnly>
      <MobileOnly>
        <GridContainer columns="1fr">
          {" "}
          <Outlet />
          <CardContainer margin="0.2rem">
            <GridContainer columns="1fr min-content" width="100%">
              <Heading>Patients</Heading>
              <PlusIcon onClick={() => setAddingPatient(true)}></PlusIcon>
            </GridContainer>
            <GridContainer
              width="100%"
              columns="1fr auto"
              margin="8px 0"
              style={{ position: "relative" }}
            >
              <SearchField
                value={query}
                onchange={(e) => setQuery(e.target.value)}
                width="100%"
                label="Enter phone number"
                type="tel"
              ></SearchField>
              {patientsSuggestions.length !== 0 && searchDropDownState && (
                <PatientsSuggestion>
                  {patientsSuggestions.map((pat) => (
                    <li onClick={() => handleViewPatient(pat.id)}>
                      <b>{pat.name}</b>
                      <small>{pat.phone_number}</small>
                    </li>
                  ))}
                </PatientsSuggestion>
              )}
              <Button
                label="Search"
                isloading={isPatientLoading}
                disabled={isPatientLoading}
                onclick={() => searchPatient(query)}
              />
            </GridContainer>

            {patients?.length === 0 ? (
              <NoRecords message="Type the patient's phone number to get the list of patients" />
            ) : (
              <>
                <DesktopOnly>
                  <TableWrapper
                    onScroll={onScrollHandler}
                    style={{
                      boxShadow,
                    }}
                  >
                    <StyledTable>
                      <TableHeader>
                        <StyledTR>
                          <StyledTH>Sr. No </StyledTH>
                          <StyledTH>Patient Name </StyledTH>
                          <StyledTH>Age</StyledTH>
                          <StyledTH>Blood Group </StyledTH>

                          <StyledTH>Gender </StyledTH>
                          <StyledTH>Date of Birth</StyledTH>
                          <StyledTH>Actions </StyledTH>
                        </StyledTR>
                      </TableHeader>
                      <tbody>{patients?.map(createPatientRow)}</tbody>
                    </StyledTable>
                  </TableWrapper>
                </DesktopOnly>
                <MobileOnly>{patients?.map(createMobilePatientRow)}</MobileOnly>
              </>
            )}

            {success && <SuccessContainer>{success}</SuccessContainer>}
          </CardContainer>
        </GridContainer>
      </MobileOnly>
    </MainContainer>
  );
}

export default ManageDocPatients;
