import { apiSlice } from "../apiSlice";

export const usersEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => "/auth/member",
      providesTags: ["Users"],
    }),
    getReferralCode: builder.query({
      query: () => "/coupon/referral-code",
      providesTags: ["RefCode"],
    }),
    getSingleUser: builder.query({
      query: (id) => `/auth/member/${id}`,
      providesTags: ["SingleUser"],
    }),
    addUser: builder.mutation({
      query: (apt) => ({ url: "/auth/member", method: "POST", body: apt }),
      invalidatesTags: ["Users"],
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: `/auth/member/${data.id}`,
        method: "PUT",
        body: data.data,
      }),
      invalidatesTags: ["Users", "SingleUser"],
    }),
    uploadPicture: builder.mutation({
      query: (data) => ({
        url: "/auth/profile-picture/",
        method: "PUT",
        body: data,
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=------WebKitFormBoundarygTiX0rNBfhw8KnM0",
        },
      }),
      invalidatesTags: ["Users", "SingleUser"],
    }),
    deleteMultipleUsers: builder.mutation({
      query: (data) => ({
        url: "/auth/member",
        method: "DELETE",
        body: {
          id_list: data,
        },
      }),

      invalidatesTags: ["Users"],
    }),
    deleteProfilePicture: builder.mutation({
      query: () => ({
        url: "/auth/profile-picture/",
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["SingleUser"],
    }),
    deleteSingleUser: builder.mutation({
      query: (id) => ({
        url: `/auth/member/${id}/`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: ["Users"],
    }),
  }),
});

export const { useGetUsersQuery } = usersEndpoints;
export const { useGetSingleUserQuery } = usersEndpoints;
export const { useAddUserMutation } = usersEndpoints;
export const { useUploadPictureMutation } = usersEndpoints;
export const { useUpdateUserMutation } = usersEndpoints;
export const { useDeleteMultipleUsersMutation } = usersEndpoints;
export const { useDeleteSingleUserMutation } = usersEndpoints;
export const { useDeleteProfilePictureMutation } = usersEndpoints;
export const { useGetReferralCodeQuery } = usersEndpoints;
