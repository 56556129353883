import axios from "axios";
const BASE_URL =
  "https://l1x3gkp512.execute-api.ap-south-1.amazonaws.com/test/";
export default axios.create({
  withCredentials: true,
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  header: { "Content-Type": "application/json" },
  // withCredentials: true,
});
