//React Imports
import React, { useState, useEffect } from "react";
import styled from "styled-components";

//Styled Components
import { CenterFlexContainer } from "../../../../Global";
import { Container, DeleteIcon, Text, Title } from "../../MainPages.elements";

//Components
import TimeField from "../../../../components/ui/TimeField";
import WeekDayToggle from "./WeekDayToggle";

//Util Function
import { getDayName, getDayNum } from "../../../../utils/parseSchedule";

const SHContainer = styled.div`
  margin: auto;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
  max-width: 700px;
`;

const SHWrapper = styled(CenterFlexContainer)`
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
`;

const Header = styled(CenterFlexContainer)`
  position: sticky;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

function ScheduleMobileCard({
  i,
  newSchedule,
  setNewSchedule,
  setDelSchedule,
  delSchedule,
}) {
  let days = newSchedule[i]?.days_of_week.map(getDayName);

  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [selectedWeekdays, setSelectedWeekdays] = useState(days);
  const [startTime, setStartTime] = useState(newSchedule[i]?.start_time);
  const [endTime, setEndTime] = useState(newSchedule[i]?.end_time);

  const handleWeekdayChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedWeekdays([...selectedWeekdays, value]);
    } else {
      setSelectedWeekdays(
        selectedWeekdays.filter((weekday) => weekday !== value)
      );
    }
  };

  useEffect(() => {
    setNewSchedule((prevItems) => [
      ...prevItems.slice(0, i),
      {
        ...prevItems[i],
        start_time: startTime?.length === 5 ? startTime + ":00" : startTime,
        end_time: endTime?.length === 5 ? endTime + ":00" : endTime,
        days_of_week: selectedWeekdays?.map(getDayNum),
      },
      ...prevItems.slice(i + 1),
    ]);
  }, [endTime, startTime, selectedWeekdays]);
  function deleteSchedule() {
    const newArr = newSchedule.splice(i, 1);
    setDelSchedule([...delSchedule, newArr]);
    console.log(newSchedule);
    setNewSchedule([...newSchedule]);
  }
  return (
    <SHContainer>
      <SHWrapper>
        <Header>
          <Title margin="0">
            {newSchedule[i].start_time === "" &&
            newSchedule[i].end_time === "" &&
            newSchedule[i].days_of_week.length === 0
              ? "New"
              : ""}
            Schedule
          </Title>
          <DeleteIcon onClick={deleteSchedule} />
        </Header>
        <Text style={{ margin: "0" }}>Working days</Text>
        <CenterFlexContainer
          style={{ flexWrap: "wrap", justifyContent: "flex-start" }}
        >
          {weekdays.map((weekday) => (
            <WeekDayToggle
              key={weekday}
              type="checkbox"
              value={weekday}
              checked={selectedWeekdays.includes(weekday)}
              onchange={handleWeekdayChange}
              weekday={weekday}
            >
              {weekday}
            </WeekDayToggle>
          ))}
        </CenterFlexContainer>
        <Text style={{ margin: "0" }}>Shift timings</Text>
        <Container>
          <p style={{ margin: "0" }}>From</p>

          <TimeField
            onchange={(e) => setStartTime(e.target.value)}
            value={startTime}
          />
          <p style={{ margin: "0" }}>To</p>

          <TimeField
            onchange={(e) => setEndTime(e.target.value)}
            value={endTime}
          />
        </Container>
      </SHWrapper>
    </SHContainer>
  );
}

export default ScheduleMobileCard;
