import React from "react";
import { FilterContainer } from "./ui.elements";
import { FaFilter } from "react-icons/fa";
import { Text } from "../../Global";
function FilterButton(props) {
  return (
    <FilterContainer onClick={props.onclick}>
      <FaFilter style={{color:"#666"}}/>
      <Text style={{color:"#666",fontWeight:"600"}}>Filter {props.text}</Text>
    </FilterContainer>
  );
}

export default FilterButton;
