import React from "react";
import {
  TextfieldContainer,
  TextfieldWrapper,
  Input,
  Label,
  Message,
  ErrorMessage,
} from "./ui.elements";

export const TextField = (props) => {
  return (
    <TextfieldContainer  margin = {props.margin} width={props.width}>
      <TextfieldWrapper>
        <Input
          value={props.value}
          name={props.name}
          multiple={props.multiple ? "multiple" : ""}
          type={props.type}
          placeholder={props.label}
          accept={props.accept}
          onChange={props.onchange}
          ref={props.fieldRef}
          disabled={props.disabled}
          id={props.id ? props.id : ""}
          required={props.required ? "required" : ""}
        />
        <Label>{props.label}</Label>
      </TextfieldWrapper>
      {props.error ? <ErrorMessage>{props.error}</ErrorMessage> : ""}
      <Message>{props.message}</Message>
    </TextfieldContainer>
  );
};

export default TextField;
