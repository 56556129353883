//React Imports
import React, { useEffect, useReducer, useState } from "react";
import Modal from "react-modal";

//Styled Components

import {
  CenterFlexContainer,
  CardContainer,
  ErrorContainer,
  SuccessContainer,
  DesktopOnly,
  MobileOnly,
  GridContainer,
  StyledInputLabel,
  StyledSelect,
  StyledTextField,
  RedTextButton,
} from "../../../Global";
import { BtnWrap, Heading, Text, Title } from "../MainPages.elements";

//Components
// import TextField from "../../../components/ui/TextField";
import Button from "../../../components/ui/Button";
import ProspectiveDoctorRow from "./helpers/ProspectiveDoctorRow";
import ProspectiveDocCardCard from "./helpers/ProspectiveDocCardCard";
import Shimmering from "../../../components/ui/CardShimmering";
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";

//data
import { specialtiesOptions } from "../../../data/specialties";

//Redux Imports RTK Query
import { useSelector } from "react-redux";
import { auth } from "../../../reduxSlices/authSlice";
import { useGetUsersQuery } from "../../../api/userEndpoints/usersEndpoint";
import {
  useAcceptMultipleProsDoctorMutation,
  useGetProspectiveDoctorsQuery,
  useRejectMultipleProsDoctorMutation,
} from "../../../api/userEndpoints/prospectiveDocEndpoint";

import { useAddDoctorMutation } from "../../../api/userEndpoints/doctorsEndpoint";

//hooks
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
const ADD_DOCTORS_URL = "/api/doctor/";

function AddDoctor() {
  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery();

  const { data: prospectiveDoctors, isLoading: isProspectiveDoctorsLoading } =
    useGetProspectiveDoctorsQuery();

  const [addDoctor, { isLoading: isAddDoctorLoading }] = useAddDoctorMutation();

  const [acceptMultipleProspectiveDoctor, { isLoading: isAcceptLoading }] =
    useAcceptMultipleProsDoctorMutation();
  const [rejectMultipleProspectiveDoctor] =
    useRejectMultipleProsDoctorMutation();

  const authData = useSelector(auth);

  const axiosPrivate = useAxiosPrivate();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [members, setMembers] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [assistantName, setAssistantName] = useState();

  const [existingDocData, setExistingDocData] = useState({});
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "1rem",
      transform: "translate(-50%, -50%)",
    },
  };

  const initialValues = {
    name: "",
    registration_number: "",
    phone_number: "",
    specialty: [],
    assistant_id: "",
    allow_multiple_hospitals: true,
  };

  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      registration_number: "",
      phone_number: "",
      specialty: [],
      assistant_id: "",
      allow_multiple_hospitals: true,
    }
  );

  function createProspectiveDoctorsRow(props, i) {
    return (
      <ProspectiveDoctorRow
        key={i}
        addSelectedRows={addSelectedRows}
        removeSelectedRows={removeSelectedRows}
        doctors={prospectiveDoctors}
        setDoctors={setDoctors}
        members={users}
        setMembers={setMembers}
        selectedRows={selectedRows}
        assistantName={assistantName}
        setAssistantName={setAssistantName}
        setSelectedRows={setSelectedRows}
        i={i}
      />
    );
  }
  function createProspectiveMobileCard(props, i) {
    return (
      <ProspectiveDocCardCard
        key={i}
        cardColumn="3fr 2fr"
        doctors={prospectiveDoctors}
        members={users}
        assistantName={assistantName}
        setAssistantName={setAssistantName}
        i={i}
      />
    );
  }

  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }
  function addSelectedRows(i) {
    setSelectedRows([...selectedRows, i]);
  }

  function removeSelectedRows(i) {
    const result = arrayRemove(selectedRows, i);
    setSelectedRows([...result]);
  }
  const handleFormDataChange = (event) => {
    const { name, value } = event.target;
    setFormData({ [name]: value });
  };

  // clear ACK message on editing textfield
  useEffect(() => {
    setSuccess("");
    setError("");
  }, [
    formData.name,
    formData.phone_number,
    formData.assistant_id,
    formData.registration_number,
    selectedOptions.length,
  ]);

  async function handleAddDoctors() {
    if (formData.name === "") {
      setError("Please Enter name");
      return;
    } else if (formData.phone_number === "") {
      setError("Please Enter phone number");
      return;
    } else if (formData.phone_number.length !== 10) {
      setError("Invalid Phone Number");
      return;
    } else if (parseInt(formData.phone_number).toString().length !== 10) {
      setError("Please Enter only Numbers in Phone field");
      return;
    }

    setError("");
    setSuccess("");
    if (formData.phone_number === "") {
      setError("Enter valid phone number");
      return;
    } else if (formData.name === "") {
      setError("Please Enter name");
      return;
    } else if (formData.registration_number === "") {
      setError("Please Enter registration number");
      return;
    }

    try {
      let result = await addDoctor({
        name: formData.name,
        phone_number: "+91" + formData.phone_number,
        registration_number: formData.registration_number,
        assistant_id: formData.assistant_id,
        specialty: selectedOptions,
      }).unwrap();

      console.log(result);
      if (result.status === 200) {
        setExistingDocData(result.data);
        setConfirmModal(true);
      } else if (result.status === 201) {
        setSuccess("Doctor added successfully");
        setFormData(initialValues);
        setSelectedOptions([]);
      } else if (result.status === 409) {
        setError(
          "This particular Doctor is already onboard with your hospital"
        );
      } else if (result.message) {
        setSuccess(result.message);
      }
    } catch (err) {
      console.log(err);
      if (err.status === 409) {
        setError(
          "This particular Doctor is already onboard with your hospital"
        );
      } else if (err.status === 400) {
        setError("Registration Failed");
      }
    }
  }
  const handleEnterKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleAddDoctors();
    }
  };

  function confirmDoctor() {
    axiosPrivate
      .post(
        ADD_DOCTORS_URL + "?confirm=yes",
        {
          name: formData.name,
          phone_number: formData.phone_number,
          registration_number: formData.registration_number,
          assistant_id: formData.assistant_id,
          specialty: selectedOptions,
        },
        {
          headers: { Authorization: `Bearer ${authData?.accessToken}` },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          setFormData(initialValues);
          setConfirmModal(false);
          setSuccess("Doctor added succesfully");
        }
      })
      .catch((e) => {
        setError(e.response.data.detail);
      });
  }
  useEffect(() => {
    setError("");
  }, [
    formData.name,
    formData.email,
    formData.phone_number,
    formData.specialty,
  ]);

  function unCheckSpecialties(value) {
    setSelectedOptions(selectedOptions.filter((option) => option !== value));
  }

  async function acceptDoctors() {
    try {
      await acceptMultipleProspectiveDoctor({
        selectedId: selectedRows,
      });
      setSelectedRows([]);
    } catch (err) {
      console.log(err);
    }
  }

  async function rejectDoctors() {
    //reject multiple doctors api
    try {
      await rejectMultipleProspectiveDoctor({
        selectedId: selectedRows,
      });
      setSelectedRows([]);
    } catch (err) {
      console.log(err);
    }
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function clearForm() {
    setFormData({});
  }

  Modal.setAppElement("#root");
  if (isProspectiveDoctorsLoading || isUsersLoading) {
    return <Shimmering />;
  } else {
    return (
      <>
        <Modal
          isOpen={confirmModal}
          onRequestClose={() => setConfirmModal(false)}
          style={customStyles}
        >
          <Title>Doctor already exists</Title>
          <Text style={{ margin: "1rem" }}>
            Following are the details of the existing{" "}
          </Text>
          <CenterFlexContainer
            style={{ justifyContent: "start", margin: "0.7rem 0" }}
          >
            <Text>Name:</Text>
            <b>{existingDocData.name}</b>
          </CenterFlexContainer>
          <CenterFlexContainer
            style={{ justifyContent: "start", margin: "0.7rem 0" }}
          >
            <Text>Phone Number:</Text>
            <b>{existingDocData.phone_number}</b>
            <hr></hr>
          </CenterFlexContainer>
          <CenterFlexContainer
            style={{
              alignItems: "start",
              justifyContent: "start",
              margin: "0.7rem 0",
              maxWidth: "400px",
            }}
          >
            <Text>Specialties:</Text>
            <b>{existingDocData.specialties?.join(", ")}</b>
          </CenterFlexContainer>

          <BtnWrap style={{ justifyContent: "end" }}>
            <Button red label="Cancel" onclick={() => setConfirmModal(false)} />

            <Button label="Confirm" onclick={confirmDoctor} />
          </BtnWrap>
        </Modal>

        <MobileOnly>
          <GridContainer columns="1fr" rgap="0.2rem" margin="0.2rem">
            <CardContainer>
              <Heading style={{ width: "100%" }}>Add Doctor</Heading>
              <GridContainer
                width="100%"
                columns="repeat(auto-fill, minmax(250px, 1fr))"
              >
                <StyledTextField
                  value={formData.name}
                  onChange={handleFormDataChange}
                  name="name"
                  message=""
                  label="Full Name"
                  margin="dense"
                  required
                ></StyledTextField>
                <StyledTextField
                  value={formData.registration_number}
                  onChange={handleFormDataChange}
                  name="registration_number"
                  message=""
                  label="Medical Registration No."
                  margin="dense"
                  required
                ></StyledTextField>

                <StyledTextField
                  value={formData.phone_number}
                  onchange={handleFormDataChange}
                  name="phone_number"
                  label="Your Phone Number"
                  margin="dense"
                  required
                ></StyledTextField>

                <FormControl margin="dense" fullWidth>
                  <StyledInputLabel id="assistant_id_label">
                    Select Assistant
                  </StyledInputLabel>
                  <StyledSelect
                    labelId="assistant_id_label"
                    label="Select Assistant"
                    name="assistant_id"
                    value={formData.assistant_id}
                    onChange={handleFormDataChange}
                    required
                  >
                    <MenuItem value="">Select Assistant</MenuItem>
                    {users?.map((member) => {
                      return (
                        <MenuItem key={member.id} value={member.id}>
                          {member.name}
                        </MenuItem>
                      );
                    })}
                  </StyledSelect>
                </FormControl>

                <FormControl margin="dense">
                  <StyledInputLabel id="demo-multiple-checkbox-label">
                    Select specialties
                  </StyledInputLabel>
                  <StyledSelect
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedOptions}
                    onChange={handleChange}
                    input={<OutlinedInput label="Select specialties" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {specialtiesOptions.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={selectedOptions.indexOf(name) > -1}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </GridContainer>
              <CenterFlexContainer width="100%" justify="flex-start">
                <input
                  type="checkbox"
                  name="allow_multiple_hospitals"
                  checked={formData.allow_multiple_hospitals}
                  value={formData.allow_multiple_hospitals}
                  onChange={handleFormDataChange}
                ></input>{" "}
                <Text>Allow doctors to add multiple hospitals</Text>
              </CenterFlexContainer>
              {error ? <ErrorContainer>{error}</ErrorContainer> : ""}
              {success ? <SuccessContainer>{success}</SuccessContainer> : ""}

              <BtnWrap style={{ justifyContent: "flex-end" }}>
                <RedTextButton
                  onClick={() => {
                    setFormData(initialValues);
                    setSelectedOptions([]);
                  }}
                >
                  Clear
                </RedTextButton>
                <Button
                  isloading={isAddDoctorLoading}
                  disabled={isAddDoctorLoading}
                  onclick={handleAddDoctors}
                  label="Add Doctor"
                />
              </BtnWrap>
            </CardContainer>
            {/* <CardContainer>
              <Heading style={{ width: "100%" }}>Prospective Doctors</Heading>
              {prospectiveDoctors?.length === 0 ? (
                <NoRecords message="No Doctors Available" />
              ) : (
                <ScrollableContainer>
                  {prospectiveDoctors?.map(createProspectiveMobileCard)}
                </ScrollableContainer>
              )}
            </CardContainer> */}
          </GridContainer>
        </MobileOnly>
        <DesktopOnly>
          <GridContainer align="stretch" columns="1fr" gap="1.5rem">
            {/* Prospective Doctors Card */}
            {/* <CardContainer>
              <Heading width="100%">Prospective Doctors</Heading>

              {prospectiveDoctors?.length === 0 ? (
                <NoRecords message="No Doctors Available" />
              ) : (
                <TableWrapper>
                  <StyledTable>
                    <TableHeader>
                      <StyledTR>
                        <StyledTH></StyledTH>
                        <StyledTH>Sr. No </StyledTH>
                        <StyledTH>Name </StyledTH>
                        <StyledTH>Speciality </StyledTH>
                        <StyledTH>Action </StyledTH>
                      </StyledTR>
                    </TableHeader>
                    <tbody>
                      {prospectiveDoctors?.map(createProspectiveDoctorsRow)}
                    </tbody>
                  </StyledTable>
                </TableWrapper>
              )}

              {selectedRows.length !== 0 && (
                <GridContainer
                  columns="200px 200px "
                  width="100%"
                  justify="flex-end"
                >
                  <Button
                    isloading={isRejectLoading}
                    disabled={
                      isRejectLoading || selectedRows.length === 0
                        ? "disabled"
                        : ""
                    }
                    onclick={rejectDoctors}
                    label="Reject Selected "
                    red
                  />

                  <Button
                    isloading={isAcceptLoading}
                    disabled={
                      isAcceptLoading || selectedRows.length === 0
                        ? "disabled"
                        : ""
                    }
                    onclick={acceptDoctors}
                    label="Accept Selected "
                  />
                </GridContainer>
              )}
            </CardContainer> */}
            {/* Add Doctor */}
            <CardContainer tabIndex="0" onKeyDown={handleEnterKeypress}>
              <Heading style={{ width: "100%" }}>Add Doctor</Heading>
              <GridContainer
                width="100%"
                columns="repeat(auto-fill, minmax(250px, 1fr))"
              >
                <StyledTextField
                  margin="dense"
                  value={formData.name}
                  onChange={handleFormDataChange}
                  name="name"
                  message=""
                  label="Full Name"
                  required
                ></StyledTextField>
                <StyledTextField
                  margin="dense"
                  value={formData.registration_number}
                  onChange={handleFormDataChange}
                  name="registration_number"
                  message=""
                  label="Medical Registration No."
                  required
                ></StyledTextField>

                <StyledTextField
                  margin="dense"
                  value={formData.phone_number}
                  type="tel"
                  onChange={handleFormDataChange}
                  name="phone_number"
                  label="Your Phone Number"
                  required
                ></StyledTextField>
                <FormControl margin="dense" fullWidth>
                  <StyledInputLabel id="assistant_id_label">
                    Select Assistant
                  </StyledInputLabel>
                  <StyledSelect
                    labelId="assistant_id_label"
                    label="Select Assistant"
                    name="assistant_id"
                    value={formData.assistant_id}
                    onChange={handleFormDataChange}
                    required
                  >
                    <MenuItem value="">Select Assistant</MenuItem>
                    {users?.map((member) => {
                      return (
                        <MenuItem key={member.id} value={member.id}>
                          {member.name}
                        </MenuItem>
                      );
                    })}
                  </StyledSelect>
                </FormControl>

                <FormControl margin="dense">
                  <StyledInputLabel id="demo-multiple-checkbox-label">
                    Select specialties
                  </StyledInputLabel>
                  <StyledSelect
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedOptions}
                    onChange={handleChange}
                    input={<OutlinedInput label="Select specialties" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {specialtiesOptions.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={selectedOptions.indexOf(name) > -1}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </GridContainer>
              <CenterFlexContainer width="100%" justify="flex-start">
                <input
                  type="checkbox"
                  name="allow_multiple_hospitals"
                  checked={formData.allow_multiple_hospitals}
                  value={formData.allow_multiple_hospitals}
                  onChange={handleFormDataChange}
                ></input>{" "}
                <Text>Allow doctors to add multiple hospitals</Text>
              </CenterFlexContainer>
              {error ? <ErrorContainer>{error}</ErrorContainer> : ""}
              {success ? <SuccessContainer>{success}</SuccessContainer> : ""}

              <BtnWrap style={{ justifyContent: "space-between" }}>
                <RedTextButton
                  onClick={() => {
                    setFormData(initialValues);
                    setSelectedOptions([]);
                  }}
                >
                  Clear
                </RedTextButton>
                <Button
                  isloading={isAddDoctorLoading}
                  disabled={isAddDoctorLoading}
                  onclick={handleAddDoctors}
                  label="Add Doctor"
                />
              </BtnWrap>
            </CardContainer>
          </GridContainer>
        </DesktopOnly>
      </>
    );
  }
}

export default AddDoctor;
