//React Imports
import React, { useEffect, useState, memo, useContext } from "react";

//Styled Components
import {
  CenterFlexContainer,
  ErrorContainer,
  ErrorIcon,
  GridContainer,
  TextButton,
  LightText,
  BtnWrap,
  StyledLockIcon,
  StyledMobileTimePicker,
  StyledDatePicker,
  StyledFormControlLabel,
  StyledMobileDateTimePicker,
} from "../../../../Global";
import {
  StyledTD,
  StyledTR,
  EditIcon,
  Text,
  Heading,
  Tabs,
  Heading2,
  SchTileContainer,
  ScrollableContainer,
  Container,
} from "../../MainPages.elements";

//Components

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { format } from "date-fns";

import MessageBox from "../../../../components/ui/MessageBox";
import Button from "../../../../components/ui/Button";
import SchTile from "./SchTile";
// import { DateField } from "@mui/x-date-pickers";
//Utils Function
import { parseDate } from "../../../../utils/parseDate";

//RTK Query
import {
  useHandleScheduleOverlapMutation,
  useUpdateDoctorLeaveMutation,
  useUpdateOffShiftMutation,
} from "../../../../api/userEndpoints/taggedDoctorEndpoint";
import Modal from "react-modal";

import { RadioGroup, Checkbox, Radio, FormControl } from "@mui/material";

import {
  useRejectMultipleAppointmentsMutation,
  useRescheduleMultipleAppointmentMutation,
} from "../../../../api/userEndpoints/appointmentEndpoint";
import { ExistingAppointmentRow } from "./TaggedDoctorsRow";

import AuthContext from "../../../../context/authProvider";

//Modal Styles
import {
  darkAvailabilityModalStyles,
  availabilityModalStyles,
} from "../../../../utils/ModalStyles";
import { gatingMessage } from "../../../../data/GatingMessage";

//parseDate
const DoctorTableRow = memo(({ doctorsData, setError, setSuccess, i }) => {
  const { subscribed, isDarkTheme } = useContext(AuthContext);
  const [updateDoctorLeave, { isLoading: isDoctorLeaveLoading }] =
    useUpdateDoctorLeaveMutation();
  const [updateDoctorOffShift, { isLoading: isDoctorOffShiftLoading }] =
    useUpdateOffShiftMutation();

  const [handleScheduleOverlap] = useHandleScheduleOverlapMutation();

  const [
    rejectMultipleAppointments,
    { isLoading: isRejectMultipleAppointmentsLoading },
  ] = useRejectMultipleAppointmentsMutation();

  const [
    rescheduleMultipleAppointments,
    { isLoading: isRescheduletMultipleAppointmentsLoading },
  ] = useRescheduleMultipleAppointmentMutation();

  const [avlError, setAvlError] = useState("");

  const [leaveFromDate, setLFDate] = useState("");
  const [leaveToDate, setLTDate] = useState("");

  //New Leave states
  const [leaveType, setLeaveType] = useState("full");

  //Less than a day leave states
  const [lthTime, setLTHTime] = useState("");
  const [lfhTime, setLFHTime] = useState("");
  const [lfhDate, setLFHDate] = useState("");

  //Handle Existing appointments on schedule leave or adhoc shift
  const [existingAppointment, setExistingAppointment] = useState(false);
  const [existingAptData, setExistingAptData] = useState({});
  const [cancelAllApt, setCancelAllApt] = useState(false);

  const [offShiftFromDate, setOSFDate] = useState("");
  const [offShiftToDate, setOSTDate] = useState("");

  const [editingAvailability, setEditAvailability] = useState(false);
  const [activeTab, setActiveTab] = useState("offShift");
  const [message, setMessage] = useState(false);
  const [scheduleOverlap, setScheduleOverlap] = useState(false);
  const [schOverlapData, setOverlapData] = useState({});

  const [curReschduledApt, setCurRescheduledApt] = useState([]);
  const [curCancelledApt, setCurCancelledApt] = useState([]);

  async function updateOffShift() {
    const curDate = new Date();
    if (offShiftFromDate === "" && offShiftToDate === "") {
      setAvlError("Please enter either Leave fields or Off Shift fields");
      return;
    }

    if (offShiftFromDate !== "" && offShiftToDate !== "") {
      if (offShiftFromDate > offShiftToDate) {
        setAvlError("Invalid range in doctor shift field");
        return;
      }
      if (offShiftFromDate <= curDate || offShiftToDate <= curDate) {
        setAvlError("Please enter future date");
        return;
      }
    }

    try {
      let result = await updateDoctorOffShift({
        id: doctorsData[i]?.doctor_hospital_id,
        offShiftFromDate:
          format(offShiftFromDate.$d, "yyyy-MM-dd") +
          "T" +
          format(offShiftFromDate.$d, "HH:mm:ss"),
        offShiftToDate:
          format(offShiftToDate.$d, "yyyy-MM-dd") +
          "T" +
          format(offShiftToDate.$d, "HH:mm:ss"),
      });
      if (result.data || result.data === null) {
        setEditAvailability(false);
        setError("");
        setSuccess(
          `Details of Dr.${doctorsData[i]?.name} update successfully.`
        );
      } else if (result.error) {
        if (result.error.status === 409) {
          setScheduleOverlap(true);
          setOverlapData(result.error.data);
          return;
        }
        setError(
          result.error.data.message ||
            "Update failed. Please verify entered details."
        );
      }
    } catch (err) {
      console.log(err);
      setError(err || "Update failed. Please verify entered details.");
    }
  }

  async function handleUpdateDoctorLeave() {
    const curDate = new Date();

    if (leaveFromDate === "" && leaveToDate === "") {
      setAvlError("Please enter either Leave fields or Off Shift fields");
      return;
    }
    if (leaveFromDate !== "" && leaveToDate !== "") {
      if (leaveFromDate > leaveToDate) {
        setAvlError("Invalid range in Doctor leave fields");
        return;
      }
      if (leaveToDate <= curDate || leaveFromDate <= curDate) {
        setAvlError("Please enter future date");
        return;
      }
    }

    try {
      let result = await updateDoctorLeave({
        id: doctorsData[i]?.doctor_hospital_id,
        leaveFromDate:
          format(leaveFromDate.$d, "yyyy-MM-dd") +
          "T" +
          format(leaveFromDate.$d, "HH:mm:ss"),
        leaveToDate:
          leaveFromDate.toString() === leaveToDate.toString()
            ? format(leaveToDate.add(1, "day").$d, "yyyy-MM-dd") +
              "T" +
              format(leaveToDate.$d, "HH:mm:ss")
            : format(leaveToDate.$d, "yyyy-MM-dd") +
              "T" +
              format(leaveToDate.$d, "HH:mm:ss"),
      });
      if (result.data || result.data === null) {
        setEditAvailability(false);
        setError("");
        setSuccess(
          `Details of Dr.${doctorsData[i]?.name} update successfully.`
        );
      } else if (result.error) {
        if (result.error.status === 409) {
          setExistingAppointment(true);
          setExistingAptData(result.error.data?.appointments);
          return;
        }
        setError(
          result.error.data.message ||
            "Update failed. Please verify entered details."
        );
      }
    } catch (err) {
      console.log(err);
      setError(err || "Update failed. Please verify entered details.");
    }
  }

  const handleOffShiftReset = () => {
    setOSFDate("");
    setOSTDate("");
    setAvlError("");
  };
  const handleDocLeaveReset = () => {
    setLFDate("");
    setLTDate("");
    setAvlError("");
  };

  async function handleDiscardSchedule() {
    try {
      let result = await handleScheduleOverlap({
        id: doctorsData[i]?.doctor_hospital_id,
        offShiftFromDate:
          format(offShiftFromDate.$d, "yyyy-MM-dd") +
          "T" +
          format(offShiftFromDate.$d, "HH:mm:ss"),
        offShiftToDate:
          format(offShiftToDate.$d, "yyyy-MM-dd") +
          "T" +
          format(offShiftToDate.$d, "HH:mm:ss"),
        status: "true",
      });
      if (result.data) {
        setEditAvailability(false);
        setScheduleOverlap(false);
        handleOffShiftReset();
        setError("");
        setSuccess(
          `Details of Dr.${doctorsData[i]?.name} update successfully.`
        );
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function handleKeepSchedule() {
    try {
      let result = await handleScheduleOverlap({
        id: doctorsData[i]?.doctor_hospital_id,
        offShiftFromDate:
          format(offShiftFromDate.$d, "yyyy-MM-dd") +
          "T" +
          format(offShiftFromDate.$d, "HH:mm:ss"),
        offShiftToDate:
          format(offShiftToDate.$d, "yyyy-MM-dd") +
          "T" +
          format(offShiftToDate.$d, "HH:mm:ss"),
        status: "false",
      });
      if (result.data) {
        setEditAvailability(false);
        setScheduleOverlap(false);
        handleOffShiftReset();
        setError("");
        setSuccess(
          `Details of Dr.${doctorsData[i]?.name} update successfully.`
        );
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    setAvlError("");
  }, [
    leaveFromDate,
    leaveToDate,
    offShiftFromDate,
    offShiftToDate,
    editingAvailability,
  ]);

  function handleLeaveType(e) {
    setLeaveType(e.target.value);
  }

  function createAppointmentRow(props, i) {
    return (
      <ExistingAppointmentRow
        appointmentData={props}
        cancelAllApt={cancelAllApt}
        setCurRescheduledApt={setCurRescheduledApt}
        setCurCancelledApt={setCurCancelledApt}
        i={i}
      ></ExistingAppointmentRow>
    );
  }

  async function handleLessThanDayLeave() {
    if (lfhTime === "" || lthTime === "" || lfhDate === "") {
      setAvlError("Please enter either Leave fields");
      return;
    }

    try {
      let result = await updateDoctorLeave({
        id: doctorsData[i]?.doctor_hospital_id,
        leaveFromDate:
          format(lfhDate.$d, "yyyy-MM-dd") +
          "T" +
          format(lfhTime.$d, "HH:mm:ss"),
        leaveToDate:
          format(lfhDate.$d, "yyyy-MM-dd") +
          "T" +
          format(lthTime.$d, "HH:mm:ss"),
      });
      if (result.data || result.data === null) {
        setEditAvailability(false);
        setError("");
        setSuccess(
          `Details of Dr.${doctorsData[i]?.name} update successfully.`
        );
      } else if (result.error) {
        if (result.error.status === 409) {
          setExistingAppointment(true);
          setExistingAptData(result.error.data?.appointments);
          return;
        }
        setError(
          result.error.data.message ||
            "Update failed. Please verify entered details."
        );
      }
    } catch (err) {
      console.log(err);
      setError("Update failed. Please verify entered details.");
    }
  }

  async function handleCancelAllApt() {
    const idList = existingAptData.map((apt) => apt.id);

    try {
      let result = await rejectMultipleAppointments({
        appointment_id: idList,
      });
      if (result.data || result.data === null) {
        setEditAvailability(false);
        setError("");
        setExistingAppointment(false);
        setSuccess(`${idList.length} Appointments cancelled successfully.`);
      } else if (result.error) {
        setError("Update failed. Please verify entered details.");
      }
    } catch (err) {
      console.log(err);
      setError("Update failed. Please verify entered details.");
    }
  }
  //Reschdule and cancel appointments based on choosen options
  async function handleReschCancelAppointment() {
    const filteredReschduleApt = curReschduledApt.filter(
      (apt, i) =>
        apt.slot_id !== undefined &&
        apt.appointment_ts !== "" &&
        curReschduledApt.indexOf(apt) === i
    );
    if (curCancelledApt.length !== 0) {
      try {
        let result = await rejectMultipleAppointments({
          appointment_ids: curCancelledApt,
        });
        if (result.data || result.data === null) {
          setEditAvailability(false);
          setError("");
          setExistingAppointment(false);
          setSuccess(`${curCancelledApt.length} Appointments cancelled.`);
        } else if (result.error) {
          // setError("Update failed. Please verify entered details.");
        }
      } catch (err) {
        console.log(err);
        setError("Update failed. Please verify entered details.");
      }
    }

    if (curReschduledApt.length !== 0) {
      try {
        let result = await rescheduleMultipleAppointments({
          appointments: filteredReschduleApt,
        });
        if (result.data || result.data === null) {
          setEditAvailability(false);
          setError("");
          setExistingAppointment(false);
          setSuccess(
            `${filteredReschduleApt.length} Appointments rescheduled.`
          );
        } else if (result.error) {
          setError("Update failed. Please verify entered details.");
        }
      } catch (err) {
        console.log(err);
        // setError("Update failed. Please verify entered details.");
      }
    }
  }

  return (
    <StyledTR key={i + 1}>
      {/* Availability Modal */}
      <Modal
        isOpen={editingAvailability}
        onRequestClose={() => setEditAvailability(false)}
        style={
          isDarkTheme ? darkAvailabilityModalStyles : availabilityModalStyles
        }
      >
        <Tabs>
          <ul className="nav">
            <li
              onClick={() => setActiveTab("doctorLeave")}
              className={activeTab === "doctorLeave" && "active"}
            >
              Enter Doctor Leave
            </li>
            <li
              onClick={() => setActiveTab("offShift")}
              className={activeTab === "offShift" && "active"}
            >
              Enter Doctor Off Shift
            </li>
          </ul>
        </Tabs>
        {activeTab === "doctorLeave" && (
          <>
            {!existingAppointment && (
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={leaveType}
                defaultValue="full"
                onChange={handleLeaveType}
              >
                <Heading>
                  Record Doctor Time off or Leave{" "}
                  {!subscribed && <StyledLockIcon />}
                </Heading>
                <GridContainer
                  columns="1fr"
                  margin="0"
                  place="flex-start"
                  align="flex-start"
                >
                  <StyledFormControlLabel
                    value="full"
                    control={<Radio />}
                    label="Full Day Leave"
                  />
                  {leaveType === "full" && (
                    <GridContainer
                      margin="0 0 0 1.8rem"
                      rgap="1rem"
                      columns="1fr"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StyledDatePicker
                          label="From"
                          value={leaveFromDate || ""}
                          onChange={(date) => setLFDate(date)}
                          disabled={existingAppointment || !subscribed}
                        />
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <StyledDatePicker
                          label="To"
                          value={leaveToDate || ""}
                          onChange={(date) => setLTDate(date)}
                          disabled={existingAppointment || !subscribed}
                        />
                      </LocalizationProvider>
                    </GridContainer>
                  )}
                </GridContainer>

                <GridContainer columns="1fr">
                  <StyledFormControlLabel
                    value="partial"
                    control={<Radio />}
                    label="Off for less than a Day"
                  />
                  {leaveType === "partial" && (
                    <Container margin="0 0 0 1.8rem">
                      <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StyledDatePicker
                            value={lfhDate || ""}
                            onChange={(date) => setLFHDate(date)}
                            label="Date"
                            disabled={!subscribed}
                          />
                        </LocalizationProvider>
                      </FormControl>

                      <GridContainer
                        width="100%"
                        margin="0.7rem 0 0 0"
                        columns="120px 1fr 120px"
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StyledMobileTimePicker
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            label="From"
                            value={lfhTime}
                            onChange={(time) => setLFHTime(time)}
                            disabled={!subscribed}
                          />
                        </LocalizationProvider>

                        <LightText style={{ textAlign: "center" }}>
                          To
                        </LightText>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <StyledMobileTimePicker
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            label="To"
                            value={lthTime}
                            onChange={(time) => setLTHTime(time)}
                            disabled={!subscribed}
                          />
                        </LocalizationProvider>
                      </GridContainer>
                    </Container>
                  )}
                </GridContainer>
              </RadioGroup>
            )}

            {avlError && <ErrorContainer>{avlError}</ErrorContainer>}

            {existingAppointment && (
              <>
                <Heading>Appointments found on choosen dates</Heading>
                <LightText>
                  There are Appointments already booked for the cancelled slot
                  <br></br>
                  Please select the appropriate action for below appointments
                </LightText>
                <StyledFormControlLabel
                  control={
                    <Checkbox
                      checked={cancelAllApt}
                      onChange={(e) => setCancelAllApt(e.target.checked)}
                    />
                  }
                  label="Cancel All Appointments"
                />
                <ScrollableContainer height="250px">
                  {existingAptData?.map(createAppointmentRow)}
                </ScrollableContainer>
                <BtnWrap columns="120px 100px">
                  <TextButton onClick={() => setExistingAppointment(false)}>
                    Go Back
                  </TextButton>
                  {cancelAllApt ? (
                    <Button
                      isloading={isRejectMultipleAppointmentsLoading}
                      onclick={handleCancelAllApt}
                      label="Update"
                    ></Button>
                  ) : (
                    <Button
                      isloading={
                        isRescheduletMultipleAppointmentsLoading ||
                        isRejectMultipleAppointmentsLoading
                      }
                      disbaled={
                        isRescheduletMultipleAppointmentsLoading ||
                        isRejectMultipleAppointmentsLoading
                      }
                      label="Update"
                      onclick={handleReschCancelAppointment}
                    ></Button>
                  )}
                </BtnWrap>
              </>
            )}
            {!existingAppointment && (
              <BtnWrap columns="120px 100px">
                <TextButton onClick={handleDocLeaveReset}>Reset</TextButton>

                {leaveType === "full" ? (
                  <Button
                    premium={!subscribed}
                    isloading={isDoctorLeaveLoading}
                    onclick={handleUpdateDoctorLeave}
                    label="Update"
                    gatingMessage={gatingMessage.docAvailabilityGM}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    premium={!subscribed}
                    isloading={isDoctorLeaveLoading}
                    onclick={handleLessThanDayLeave}
                    label="Update"
                    gatingMessage={gatingMessage.docAvailabilityGM}
                  >
                    Update
                  </Button>
                )}
              </BtnWrap>
            )}
          </>
        )}
        {activeTab === "offShift" && (
          <>
            <Heading>
              Record One Off Doctor Shift {!subscribed && <StyledLockIcon />}
            </Heading>
            <GridContainer margin="1rem 0" columns="1fr" rgap="1rem">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledMobileDateTimePicker
                  type="datetime-local"
                  value={offShiftFromDate || ""}
                  onChange={(date) => setOSFDate(date)}
                  label="From"
                  disabled={!subscribed}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledMobileDateTimePicker
                  type="datetime-local"
                  value={offShiftToDate || ""}
                  onChange={(date) => setOSTDate(date)}
                  label="To"
                  disabled={!subscribed}
                />
              </LocalizationProvider>
            </GridContainer>
            {avlError && <ErrorContainer>{avlError}</ErrorContainer>}

            {scheduleOverlap ? (
              <>
                <Heading2 style={{ margin: "1rem 0" }}>
                  Overlapped Schedules:
                </Heading2>
                <SchTileContainer>
                  {schOverlapData?.overlapping_schedules?.map((sch) => (
                    <SchTile {...sch}></SchTile>
                  ))}
                </SchTileContainer>
                <Text style={{ margin: " 1rem 0" }}>
                  Do you want to discard the existing Schedule?
                </Text>

                <BtnWrap justify="right" rows="1fr 1fr" columns="120px 120px">
                  <TextButton onClick={handleKeepSchedule}>No</TextButton>
                  <Button onclick={handleDiscardSchedule} label="Yes" />
                </BtnWrap>
              </>
            ) : (
              <BtnWrap justify="right" columns="120px 120px">
                <TextButton onClick={handleOffShiftReset}>Reset</TextButton>

                <Button
                  premium={!subscribed}
                  isloading={isDoctorOffShiftLoading}
                  onclick={updateOffShift}
                  label="Update"
                  gatingMessage={gatingMessage.docAvailabilityGM}
                >
                  Update
                </Button>
              </BtnWrap>
            )}
          </>
        )}
      </Modal>
      <StyledTD>
        <Text>
          <b> {doctorsData[i]?.name}</b>
        </Text>
        <LightText>
          <small>{doctorsData[i]?.specialties.join(", ")}</small>
        </LightText>
      </StyledTD>

      <StyledTD>
        {doctorsData[i]?.schedules.length !== 0 && (
          <GridContainer columns="1fr auto">
            {doctorsData[i]?.next_availability === "Right Now" ? (
              <Text>Right Now</Text>
            ) : (
              <Text>
                {doctorsData[i]?.next_availability &&
                  parseDate(doctorsData[i]?.next_availability)}
              </Text>
            )}
            <EditIcon onClick={() => setEditAvailability(true)} />
          </GridContainer>
        )}
        {doctorsData[i]?.schedules.length === 0 && (
          <CenterFlexContainer justify="flex-start">
            <ErrorIcon
              onMouseOver={() => setMessage(true)}
              onMouseLeave={() => setMessage(false)}
            />
          </CenterFlexContainer>
        )}
        {message && (
          <MessageBox message="Please add Doctor schedule in Doctors page." />
        )}
      </StyledTD>
    </StyledTR>
  );
});

export default DoctorTableRow;
