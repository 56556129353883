import React from "react";
import { FaUpload } from "react-icons/fa";
import styled from "styled-components";
import { CenterFlexContainer, InfoIcon } from "../../Global";

import {
  TextfieldContainer,
  TextfieldWrapper,
  Message,
  ErrorMessage,
} from "./ui.elements";

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;

  outline: none;
  border: 2px solid ${(props) => props.theme.colors.borderColor};
  border-radius: 5px;
  padding: 0.7rem 1rem;
  color: ${(props) => (props.disabled ? "#ccc" : "gray")};
  transition: 0.1s ease-out;
  &:hover {
    cursor: ${(props) => (props.disabled ? "" : "pointer")};
    border: 2px solid
      ${(props) =>
        props.disabled
          ? props.theme.colors.borderColor
          : props.theme.colors.curusBlue};
    color: ${(props) =>
      props.disabled ? "#ccc" : props.theme.colors.curusBlue};
  }
`;

export const InputField = ({
  handleClick,
  disabled,
  btnLabel,
  setInfoState,
  error,
  message,
  infoIcon,
}) => {
  return (
    <TextfieldContainer>
      <TextfieldWrapper>
        <Button onClick={handleClick} disabled={disabled}>
          {btnLabel}
          <CenterFlexContainer>
            <FaUpload />
            {infoIcon && (
              <InfoIcon
                onMouseOver={() => setInfoState(true)}
                onMouseLeave={() => setInfoState(false)}
              />
            )}
          </CenterFlexContainer>
        </Button>
      </TextfieldWrapper>
      {error ? <ErrorMessage>{error}</ErrorMessage> : ""}

      <Message>
        {message.split("|").map((msg) => {
          return (
            <Message>
              {msg}
              <br></br>
            </Message>
          );
        })}
      </Message>
    </TextfieldContainer>
  );
};
