//React imports
import React, { useState, memo } from "react";

//Styled Component
import {
  StyledTD,
  StyledTR,
  EditIcon,
  Text,
  CheckIcon,
} from "../../MainPages.elements";
import {
  CenterFlexContainer,
  GridContainer,
  StyledInputLabel,
  StyledSelect,
} from "../../../../Global";

//utils function
import { getDayName, handleTimeFormat } from "../../../../utils/parseSchedule";
import { parseDate } from "../../../../utils/parseDate";

//RTK Query
import { useChangeDoctorAssistantMutation } from "../../../../api/userEndpoints/doctorsEndpoint";

//mui imports
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
const DirectoryRow = memo(
  ({ i, setError, setSuccess, doctorsData, members }) => {
    const [changeDoctorAssistant, { isLoading: isChangeAssitantLoading }] =
      useChangeDoctorAssistantMutation();

    const [editingAdmin, setEditingAdmin] = useState(false);
    const [assistantName, setAssistantName] = useState(
      doctorsData[i]?.assistant_id ?? "Select Admin name"
    );

    async function updateAdmin(id) {
      try {
        let result = await changeDoctorAssistant({
          id: id,
          newAssistant: assistantName,
        });

        console.log(result);
        if (result.data || result.data === null) {
          setEditingAdmin(false);
          setError("");
          setSuccess(
            `Admin of Dr. ${doctorsData[i]?.name} succesfully changed.`
          );
        } else if (result.error) {
          setSuccess("");
          setError(result.error.data.message || "Please Select an Admin");
        }
      } catch (err) {
        console.log(err);
        setSuccess("");
        setError(err.error.message.data || "Please Select an Admin");
      }
    }

    function createScheduleList(props, i) {
      let days = props.days_of_week.map(getDayName);
      let start_time = handleTimeFormat(props.start_time);
      let end_time = handleTimeFormat(props.end_time);

      return (
        <Text key={i} style={{ margin: "0.2rem 1rem ", textAlign: "start" }}>
          <b>{i + 1}</b>.&nbsp;{days.join()} <br></br>
          {start_time} - {end_time}
        </Text>
      );
    }

    return (
      <StyledTR key={i + 1}>
        <StyledTD>{i + 1}</StyledTD>
        <StyledTD>{doctorsData[i]?.name}</StyledTD>
        <StyledTD width="15%">
          {doctorsData[i]?.specialties.join(", ")}
        </StyledTD>
        <StyledTD>
          {doctorsData[i]?.next_availability === "Right Now" ? (
            <Text>Right Now</Text>
          ) : (
            <Text>
              {doctorsData[i]?.next_availability &&
                parseDate(doctorsData[i]?.next_availability)}
            </Text>
          )}
        </StyledTD>
        <StyledTD>
          <div>{doctorsData[i]?.schedules?.map(createScheduleList)}</div>
        </StyledTD>
        <StyledTD>
          {
            <CenterFlexContainer
              style={{ jusifyContent: "space-between", width: "100%" }}
            >
              {editingAdmin ? (
                <CenterFlexContainer
                  style={{ justifyContent: "space-between" }}
                >
                  <FormControl fullWdth>
                    <StyledInputLabel id="invoice-item-id">
                      Select Admin Name
                    </StyledInputLabel>
                    <StyledSelect
                      margin="dense"
                      // name="assistant"
                      value={assistantName}
                      onChange={(e) => setAssistantName(e.target.value)}
                      label="Select Admin name"
                    >
                      {!doctorsData[i]?.assistant_name && (
                        <MenuItem disabled value="Select Admin name">
                          Select Admin name
                        </MenuItem>
                      )}
                      {members.map((member) => {
                        return (
                          <MenuItem key={member.id} value={member.id}>
                            {member.name}
                          </MenuItem>
                        );
                      })}
                    </StyledSelect>
                  </FormControl>
                  <CheckIcon
                    disabled={isChangeAssitantLoading}
                    onClick={() =>
                      updateAdmin(doctorsData[i]?.doctor_hospital_id)
                    }
                  />
                </CenterFlexContainer>
              ) : (
                <GridContainer columns="2fr 1fr">
                  {doctorsData[i]?.assistant_name ? (
                    <Text>{doctorsData[i]?.assistant_name}</Text>
                  ) : (
                    <small>Not assigned</small>
                  )}

                  <EditIcon onClick={() => setEditingAdmin(true)} />
                </GridContainer>
              )}
            </CenterFlexContainer>
          }
        </StyledTD>
      </StyledTR>
    );
  }
);

export default DirectoryRow;
