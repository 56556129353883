import { configureStore } from "@reduxjs/toolkit";

//Authentication imports
import { apiSlice } from "../api/apiSlice";
import { docApiSlice } from "../api/docApiSlice";
import authReducer from "../reduxSlices/authSlice";
import docAuthReducer from "../reduxSlices/docAuthSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [docApiSlice.reducerPath]: docApiSlice.reducer,

    auth: authReducer,
    docAuth: docAuthReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      apiSlice.middleware,
      docApiSlice.middleware,
    ]),

  devTools: true,
});
