export function getDayName(num) {
  let dayName = "";
  switch (num) {
    case "0":
      dayName = "Sun";
      break;
    case 0:
      dayName = "Sun";
      break;
    case "1":
      dayName = "Mon";
      break;
    case 1:
      dayName = "Mon";
      break;

    case "2":
      dayName = "Tue";
      break;
    case 2:
      dayName = "Tue";
      break;

    case "3":
      dayName = "Wed";
      break;
    case 3:
      dayName = "Wed";
      break;

    case "4":
      dayName = "Thu";
      break;
    case 4:
      dayName = "Thu";
      break;

    case "5":
      dayName = "Fri";
      break;
    case 5:
      dayName = "Fri";
      break;

    case "6":
      dayName = "Sat";
      break;
    case 6:
      dayName = "Sat";
      break;

    default:
      dayName = "";
  }
  return dayName;
}

export function getDayNum(name) {
  let dayNum = "";
  switch (name) {
    case "Sun":
      dayNum = "0";
      break;
    case "Mon":
      dayNum = "1";
      break;

    case "Tue":
      dayNum = "2";
      break;

    case "Wed":
      dayNum = "3";
      break;

    case "Thu":
      dayNum = "4";
      break;

    case "Fri":
      dayNum = "5";
      break;

    case "Sat":
      dayNum = "6";
      break;

    default:
      dayNum = "";
  }
  return dayNum;
}
//
//
//input: 2023-01-15T09:35:22.677670+05:30
export function changeTimeFormat(timeString) {
  if (timeString === null || timeString === undefined) {
    return;
  }

  let localDate = new Date(timeString);
  let localHour = localDate.getHours();
  let localMinute = localDate.getMinutes();

  return (
    (localHour === 12 || localHour === 0 ? "12" : localHour % 12) +
    ":" +
    (parseInt(localMinute) === 0
      ? "00"
      : parseInt(localMinute) <= 9
      ? "0" + localMinute
      : localMinute) +
    (localHour < 12 ? "AM" : "PM")
  );
}

//handle normal time
//input: 04:19:00
export function handleTimeFormat(timeString) {
  const [hourString, minuteString] = timeString?.split(":");

  let hour = parseInt(hourString);
  let minute = parseInt(minuteString);
  // if (minute >= 60) {
  //   minute = minute % 60;
  //   hour = hour + 1;
  // }
  return (
    (hour % 12 || 12) +
    ":" +
    (minute <= 9 ? `0${minute}` : minute) +
    (hour < 12 ? "AM" : "PM")
  );
}

export function parseRole(role) {
  if (role === "hospital_admin") {
    return "Hospital Admin";
  } else if (role === "support_member") {
    return "Support Team";
  } else if (role === "board_member") {
    return "Leadership Role";
  } else if (role === "curus_admin") {
    return "Curus Admin";
  } else if (role === "doctor") {
    return "Doctor";
  }
}
