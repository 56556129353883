import React from "react";
import { BoxContainer, BoxText } from "./ui.elements";

function Box(props) {
  return (
    <BoxContainer active={props.active}>
      <BoxText>{props.text}</BoxText>
    </BoxContainer>
  );
}

export default Box;
