import { format } from "date-fns";
import { convertToLocalTime } from "date-fns-timezone";

export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
function parseMonth(num) {
  let month = "";
  const n = num.toString();
  switch (n) {
    case "01":
      month = "Jan";
      break;
    case "02":
      month = "Feb";
      break;

    case "03":
      month = "Mar";
      break;

    case "04":
      month = "Apr";
      break;

    case "05":
      month = "May";
      break;

    case "06":
      month = "Jun";
      break;

    case "07":
      month = "Jul";
      break;

    case "08":
      month = "Aug";
      break;

    case "09":
      month = "Sep";
      break;

    case "10":
      month = "Oct";
      break;

    case "11":
      month = "Nov";
      break;

    case "12":
      month = "Dec";
      break;

    default:
      month = "Invalid";
  }

  return month;
}
export function getMonthName(monthNumber) {
  switch (monthNumber) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    default:
      return "December";
  }
}

export function parseDate(isoDate) {
  let date = new Date(isoDate);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  const parsedDate = dt + " " + parseMonth(month) + " " + year;

  return parsedDate;
}

//convert input date time to iso 8601 format
export function inputDTtoIso(date) {
  const timeZone = new Date(date).getTimezoneOffset();
  const timeZoneHour = Math.floor((timeZone * -1) / 60);
  const timeZoneMinute = (((timeZone * -1) / 60) % 1) * 60;

  let newDate =
    date +
    ":00" +
    "+" +
    (timeZoneHour <= 9 ? "0" : "") +
    timeZoneHour +
    ":" +
    timeZoneMinute;
  return newDate;
}

export const utcToIst = (date) => {
  //get date

  const dateTimeValue = new Date(date);
  let curDate = dateTimeValue.getUTCDate();
  let curMonth = dateTimeValue.getMonth() + 1;
  let curYear = dateTimeValue.getUTCFullYear();
  let curHour = dateTimeValue.getHours();
  let curMinute = dateTimeValue.getMinutes();

  if (curMinute > 60) {
    curHour = curHour + 1;
    curMinute = curMinute % 60;
  }

  if (curMonth <= 9) {
    curMonth = "0" + curMonth;
  }
  if (curDate <= 9) {
    curDate = "0" + curDate;
  }
  if (curHour <= 9) {
    curHour = "0" + curHour;
  }
  if (curMinute <= 9) {
    curMinute = "0" + curMinute;
  }

  let newDate = curYear + "-" + curMonth + "-" + curDate + "T";
  newDate = newDate + curHour.toString() + ":" + curMinute.toString();

  return newDate;
  //get time
  //add time and timezone offset
  //merge into string and return
};

export const formatDate = (date) => {
  if (!date) return new Date().toLocaleString();

  // Get the timezone from browser using native methods
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dateTmp = Date.parse(date.toLocaleString());

  const localDate = convertToLocalTime(dateTmp, {
    timeZone: timezone,
  });

  return format(localDate, DEFAULT_DATE_FORMAT);
};