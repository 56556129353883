import { useSelector } from "react-redux";
import { docApiSlice } from "../docApiSlice";

export const patientEndpoint = docApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatients: builder.query({
      query: (num) => `/patients?mobile=${num}`,
      providesTags: ["Patients"],
    }),

    addPatient: builder.mutation({
      query: (data) => ({
        url: `/patients`,
        method: "POST",
        body: {
          name: data.name,
          dob: data.dob,
          blood_group: data.bloodGroup,
          gender: data.gender,
          mobile: data.mobile,
        },
      }),
      invalidatesTags: ["Patients"],
    }),
  }),
});

export const {
  useGetPatientsQuery,
  useLazyGetPatientsQuery,
  useAddPatientMutation,
  useLazyGetPatientsRecordsQuery,
  useLazyGetPatientsSuggestionQuery,
} = patientEndpoint;
