import styled, { createGlobalStyle } from "styled-components";
import { MdOutlineError } from "react-icons/md";
import { FaInfoCircle, FaLock } from "react-icons/fa";
import { FormControlLabel, InputLabel, Select, TextField } from "@mui/material";
import {
  DatePicker,
  MobileDateTimePicker,
  MobileTimePicker,
} from "@mui/x-date-pickers";

export const GlobalStyle = createGlobalStyle`
  body {
     
    margin: 0;
  font-family: 'Nunito Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background-image: url(${({ theme }) => theme.bg});
  background-color: #bbb;
    box-sizing: border-box;
    
  
  
  }
  html{
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }
  *{
    transition: all 200ms ease-in-out;
    
  }
 
  
 ${
   "" /* &::-webkit-scrollbar {
    width: 0.2rem;
    height: 0.3rem;
    
  } */
 }

  ${
    "" /* &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.curusOrange};
    outline: none;
  }  */
  }
`;

export const CenterFlexContainer = styled.div`
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  width: ${(props) => (props.width ? props.width : "auto")};
  gap: ${(props) => props.gap};
  margin: ${(props) => (props.margin ? props.margin : "0")};
`;

export const GridContainer = styled.div`
  display: grid;
  grid-column-gap: ${(props) => (props.gap ? props.gap : "1rem")};
  grid-row-gap: ${(props) => (props.rgap ? props.rgap : "0")};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: ${(props) => (props.padding ? props.padding : "0")};

  place-content: ${(props) => (props.place ? props.place : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "start")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  grid-template-columns: ${(props) => props.columns};
  grid-template-rows: ${(props) => props.rows};
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "auto")};
  max-width: 100%;
  @media screen and (max-width: 768px) {
    grid-column-gap: 0.4rem;
  }
`;

export const BorderedGridContainer = styled(GridContainer)`
  padding: ${(props) => props.padding};
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  border-radius: 4px;
`;

export const ResponsiveGrid = styled(GridContainer)`
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 8px;
  }
`;
export const MainContainer = styled.div`
  margin: auto;
  @media screen and (max-width: 768px) {
    padding-bottom: 60px;
  }

  @media screen and (min-width: 1376px) {
    max-width: 1747px;
  }
`;

export const Button = styled.button`
  padding: 14px 24px;
  width: max-content;
  border: none;
  color: #fff;
  transition: all 0.2s ease-in-out;

  background-color: ${(props) =>
    props.color
      ? props.disabled
        ? "#3498DB"
        : props.theme.colors.curusLightBlue
      : props.disabled
      ? props.theme.colors.curusOrange
      : props.theme.colors.brightCurusOrange};
  border-radius: 7px;
  font-size: 14px;
  margin: ${(props) => (props.margin ? props.margin : "1rem 0")};
  font-weight: 600;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.19), 0 2px 2px rgba(0, 0, 0, 0.23);
  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};

    background-color: ${(props) =>
      props.disabled
        ? ""
        : props.color
        ? "#3498DB"
        : props.theme.colors.curusOrange};
  }
`;

export const BorderedButton = styled.button`
  border: 1px solid grey;
  border-radius: 4px;
  background-color: transparent;
  color: ${(props) =>
    props.disabled ? "grey" : props.theme.colors.curusLightBlue};
  padding: 0.5rem 1rem;

  &:hover {
    cursor: ${(props) => (props.disabled ? "" : "pointer")};
  }
`;

export const BtnWrap = styled(GridContainer)`
  width: 100%;
  justify-content: ${(props) => (props.justify ? props.justify : "right")};
  margin-top: 1rem;
`;
export const CardContainer = styled(CenterFlexContainer)`
  flex-wrap: wrap;
  position: relative;
  outline: none;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: ${(props) => (props.padding ? props.padding : "1.5rem")};
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${(props) => props.theme.colors.fillColor};
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    /* width: 100%; */
    padding: 0.8rem;

    /* display: none; */

    & > div {
      width: 100%;
    }
  }
`;

export const SharpCard = styled(CardContainer)`
  border-radius: 4px;
`;

export const ModalContainer = styled(CenterFlexContainer)`
  flex-wrap: wrap;

  margin: 1rem;
  padding: 1rem;
  justify-content: start;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 20px;
  & > div {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    padding: 0.7rem;
    margin: 0.2rem;
  }
`;

export const ErrorContainer = styled(CenterFlexContainer)`
  background-color: ${(props) =>
    props.theme.dark ? "transparent" : "#e57373"};
  border: ${(props) => (props.theme.dark ? "2px solid #e57373" : "none")};
  color: ${(props) => (props.theme.dark ? "#e57373" : "#fff")};
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  font-weight: 500;
  padding: 0.7rem 1rem;
  margin: 0.7rem 0;
  border-radius: 5px;
`;

export const SuccessContainer = styled(CenterFlexContainer)`
  background-color: ${(props) =>
    props.theme.dark ? "transparent" : "#53a654"};
  border: ${(props) => (props.theme.dark ? "2px solid #53a654" : "none")};
  color: ${(props) => (props.theme.dark ? "#53a654" : "#fff")};
  flex: 1;

  font-weight: 500;
  padding: 0.7rem 1rem;
  margin: 1rem 0;
  border-radius: 5px;
`;

export const Text = styled.p`
  margin: 0.2rem 0;
  color: ${(props) => (props.light ? "#6c6c6c" : props.theme.colors.curusBlue)};
  font-size: 14px;
  font-weight: 500;
`;
export const LargeText = styled.p`
  margin: 0.2rem 0;
  color: ${(props) => (props.light ? "#6c6c6c" : props.theme.colors.curusBlue)};
  font-size: 18px;
  font-weight: 400;
`;
export const BoldText = styled.p`
  margin: 8px -4px;
  color: ${(props) => props.theme.colors.lightTextColor};
  font-size: 18px;
  font-weight: 400;
  width: 100%;
`;

export const TextButton = styled(Text)`
  color: ${(props) => props.textColor};
  display: flex;
  padding: 0.7rem 1rem;
  /* margin: 0 0.6rem; */
  transition: 0.1s ease-in-out;
  text-align: center;
  justify-content: center;
  border-radius: 20px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

export const RedTextButton = styled(TextButton)`
  color: ${(props) => (props.disabled ? "#995D5B" : "#96312e")};

  &:hover {
    color: ${(props) => (props.disabled ? "" : "#c33f3b")};
  }
`;

export const IconContainer = styled(CenterFlexContainer)`
  aspect-ratio: 1/1;

  outline: 1px solid #ccc;
  margin: 0;
  padding: 6px;
  border-radius: 4px;

  &:hover {
    cursor: ${(props) => (props.disabled ? "" : "pointer")};
    outline: ${(props) => (props.disabled ? "" : "1px solid black")};
  }
`;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const DesktopOnly = styled.div`
  width: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileOnly = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
export const LightText = styled.p`
  margin: 0;
  font-size: ${(props) => props.size};
  width: ${(props) => props.width};
  text-align: ${(props) => (props.talign ? props.talign : "flex-start")};
  font-weight: 400;
  color: ${(props) => props.theme.colors.lightTextColor};
`;

export const MessageContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 200px;
  background-color: #fff;
  border-radius: 4px;
  padding: 4px;
  box-shadow: 0 0 10px 4px #ccc;
  position: absolute;
  top: 4rem;
`;

export const ErrorIcon = styled(MdOutlineError)`
  color: #6c6c6c;
  height: 22px;
  width: 22px;
  margin-left: 1rem;
  &:hover {
    cursor: pointer;
  }
`;
export const InfoIcon = styled(FaInfoCircle)`
  color: #6c6c6c;
  height: 18px;
  width: 18px;
  margin-left: 0.5rem;
  &:hover {
    cursor: pointer;
  }
`;

export const AbsoluteDiv = styled.div`
  background: #fff;
  padding: 0.7rem 1rem;
  position: absolute;
  top: 70px;
  border-radius: 8px;
  border: 1px solid #ccc;
  z-index: 99;
`;
//link text
export const AnchorText = styled.a`
  color: ${(props) => props.theme.colors.curusLightBlue};
  text-align: ${(props) => props.align};
  /* display: flex; */
  align-items: center;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusBlue};
  }
`;

export const FloatingButton = styled(Button)``;

export const StyledFormControlLabel = styled(FormControlLabel)`
  color: ${(props) => props.theme.colors.lightTextColor};
`;
export const StyledInputLabel = styled(InputLabel)`
  color: ${(props) => props.theme.colors.lightTextColor} !important;
`;
export const StyledTextField = styled(TextField)`
  & .MuiInputBase-input {
    color: ${(props) => props.theme.colors.lightTextColor};
  }
  & .MuiInputLabel-root {
    color: ${(props) => props.theme.colors.lightTextColor};
  }

  & .MuiInputLabel-root.Mui-focused {
    color: ${(props) => props.theme.colors.curusBlue};
  }

  & .MuiOutlinedInput-root {
    color: #0000008a;
    & fieldset {
      border-color: ${(props) => props.theme.colors.curusLightBlue};
    }
    &:hover fieldset {
      border-color: ${(props) => props.theme.colors.curusBlue};
    }
    &:hover .MuiInputLabel-root {
      color: ${(props) => props.theme.colors.curusBlue};
    }

    &.Mui-focused fieldset {
      border: 1px solid ${(props) => props.theme.colors.curusBlue};
    }
  }
`;
export const StyledMobileTimePicker = styled(MobileTimePicker)`
  & .MuiInputLabel-root {
    color: ${(props) => props.theme.colors.lightTextColor};
  }
  & .MuiSvgIcon-root {
    color: ${(props) => props.theme.colors.lightTextColor};
  }
  & .MuiInputBase-input {
    color: ${(props) => props.theme.colors.curusBlue};
  }

  & .MuiInputLabel-root.Mui-focused {
    color: ${(props) => props.theme.colors.curusBlue};
  }

  & .MuiOutlinedInput-root {
    color: #0000008a;
    & fieldset {
      border-color: ${(props) => props.theme.colors.curusLightBlue};
    }
    &:hover fieldset {
      border-color: ${(props) => props.theme.colors.curusBlue};
    }
    &:hover .MuiInputLabel-root {
      color: ${(props) => props.theme.colors.curusBlue};
    }

    &.Mui-focused fieldset {
      border: 1px solid ${(props) => props.theme.colors.curusBlue};
    }
  }
`;
export const StyledDatePicker = styled(({ ...props }) => (
  <DatePicker {...props} format="DD-MM-YYYY"></DatePicker>
))`
  & .MuiInputLabel-root {
    color: ${(props) => props.theme.colors.lightTextColor};
  }

  & .MuiSvgIcon-root {
    color: ${(props) => props.theme.colors.lightTextColor};
  }

  & .MuiInputBase-input {
    color: ${(props) => props.theme.colors.curusBlue};
  }

  & .MuiInputLabel-root.Mui-focused {
    color: ${(props) => props.theme.colors.curusBlue};
  }

  & .MuiOutlinedInput-root {
    color: #0000008a;
    & fieldset {
      border-color: ${(props) => props.theme.colors.curusLightBlue};
    }
    &:hover fieldset {
      border-color: ${(props) => props.theme.colors.curusBlue};
    }
    &:hover .MuiInputLabel-root {
      color: ${(props) => props.theme.colors.curusBlue};
    }

    &.Mui-focused fieldset {
      border: 1px solid ${(props) => props.theme.colors.curusBlue};
    }
  }
`;
export const StyledMobileDateTimePicker = styled(MobileDateTimePicker)`
  & .MuiInputLabel-root {
    color: ${(props) => props.theme.colors.curusLightBlue};
  }

  & .MuiSvgIcon-root {
    color: ${(props) => props.theme.colors.curusLightBlue};
  }

  & .MuiInputBase-input {
    color: ${(props) => props.theme.colors.curusBlue};
  }

  & .MuiInputBase-input {
    color: ${(props) => props.theme.colors.curusBlue};
  }

  & .MuiInputLabel-root.Mui-focused {
    color: ${(props) => props.theme.colors.curusBlue};
  }

  & .MuiOutlinedInput-root {
    color: #0000008a;
    & fieldset {
      border-color: ${(props) => props.theme.colors.curusLightBlue};
    }
    &:hover fieldset {
      border-color: ${(props) => props.theme.colors.curusBlue};
    }
    &:hover .MuiInputLabel-root {
      color: ${(props) => props.theme.colors.curusBlue};
    }

    &.Mui-focused fieldset {
      border: 1px solid ${(props) => props.theme.colors.curusBlue};
    }
  }
`;
export const StyledSelect = styled(({ ...props }) => (
  <Select
    {...props}
    MenuProps={{
      disableScrollLock: true,
      sx: {
        maxHeight: "300px",
      },
    }}
  ></Select>
))`
  color: "white";
  & .MuiInputBase-input {
    color: ${(props) => props.theme.colors.lightTextColor};
  }
  & .MuiInputLabel-root {
    color: ${(props) => props.theme.colors.curusLightBlue};
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.curusLightBlue};
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.curusBlue};
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.curusBlue};
  }
  .MuiSvgIcon-root {
    fill: ${(props) => props.theme.colors.curusBlue} !important;
  }
`;

export const StyledLockIcon = styled(FaLock)`
  height: 22px;
`;
