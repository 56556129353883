//React components
import React from "react";

//Styled components
import {
  Container,
  MobileCardTitle,
  MobileCardWrapper,
  MobileCard,
  MobileCardSmallText,
  Avatar,
  ProfileImg,
} from "../../MainPages.elements";

//Assets
import avatar from "../../../../assets/avatar.svg";

function SimpleUserCard({ users, cardColumn }) {
  return (
    <MobileCardWrapper>
      <MobileCard cardColumn={cardColumn}>
        <Container align="flex-start">
          <Avatar>
            <ProfileImg
              loading="lazy"
              src={users.profile_picture ? users.profile_picture : avatar}
            />
          </Avatar>
        </Container>
        <Container justify="center" align="flex-start" gap="4px">
          <MobileCardTitle>{users.name}</MobileCardTitle>
          <MobileCardSmallText>{users.phone_number}</MobileCardSmallText>
        </Container>
      </MobileCard>
    </MobileCardWrapper>
  );
}

export default SimpleUserCard;
