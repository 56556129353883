import React from "react";
import {
  TextfieldContainer,
  TextfieldWrapper,
  Input,
  Label,
  ErrorMessage,
} from "./ui.elements";

function DateTimeField(props) {
  return (
    <TextfieldContainer disabled={props.disabled}>
      <TextfieldWrapper>
        <Input
          value={props.value}
          multiple={props.multiple ? "multiple" : ""}
          type="datetime-local"
          name="begin"
          placeholder="dd/mm/yyyy"
          accept={props.accept}
          onChange={props.onchange}
          ref={props.fieldRef}
          disabled={props.disabled}
          id={props.id ? props.id : ""}
          required={props.required ? "required" : ""}
          min="1997-01-01"
          max="2030-12-31"
        />
        <Label>{props.label}</Label>
      </TextfieldWrapper>
      {props.error ? <ErrorMessage>{props.error}</ErrorMessage> : ""}
    </TextfieldContainer>
  );
}

export default DateTimeField;
