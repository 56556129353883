import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useDocRefreshToken from "../hooks/useDocRefreshToken";
import useAuth from "../hooks/useAuth";

import React from "react";

import LogoLoading from "../components/ui/LogoLoading";
const PersistDoctor = () => {
  const { docAuth, persist } = useAuth();
  const refresh = useDocRefreshToken();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (e) {
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !docAuth?.AuthenticationResult && persist
      ? verifyRefreshToken()
      : setIsLoading(false);

    return () => (isMounted = false);
  }, []);

  return (
    <>{!persist ? <Outlet /> : isLoading ? <LogoLoading /> : <Outlet />}</>
  );
};

export default PersistDoctor;
