import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut, setCredential } from "../reduxSlices/authSlice";
import { authApiSlice } from "./userEndpoints/authApiSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://dev.curus.co.in/",
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.accessToken;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 403) {
    console.log("Sending refresh token");
    // var refreshResult = await baseQuery(
    //   "/auth/login/refresh/",
    //   api,
    //   extraOptions
    // );
    const refreshResult = await api.dispatch(
      authApiSlice.endpoints.refresh.initiate()
    );

    if (refreshResult?.data) {
      const user = api.getState().auth.user;
      //store the new token
      api.dispatch(setCredential({ ...refreshResult.data, user }));

      //retru the original query with result
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({}),
});
