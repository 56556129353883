//React imports
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";

//styled components
import {
  MainContainer,
  CardContainer,
  ErrorContainer,
  SuccessContainer,
  GridContainer,
  StyledLockIcon,
  StyledInputLabel,
  StyledSelect,
  StyledTextField,
} from "../../../Global";
import { BtnWrap, Heading, Text } from "../MainPages.elements";

//Components
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import Button from "../../../components/ui/Button";

//Util Function
import { getMonthName } from "../../../utils/parseDate";
import { parseRole } from "../../../utils/parseSchedule";
//RTK Query
import { useAddUserMutation } from "../../../api/userEndpoints/usersEndpoint";
import AuthContext from "../../../context/authProvider";
import crown from "../../../assets/crown.png";
import { FaLock } from "react-icons/fa";
import { gatingMessage } from "../../../data/GatingMessage";

function AddUser() {
  const { subscribed } = useContext(AuthContext);
  const [addUser, { isLoading: isAddUserLoading }] = useAddUserMutation();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);

  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      name: "",
      email: "",
      phone_number: "",
      roles: "",
    }
  );

  const roles = [
    {
      value: "hospital_admin",
      label: "Hospital Admin",
    },
    { value: "support_member", label: "Support Member" },
    { value: "board_member", label: "Leadership Role" },
    { value: "doctor", label: "Doctor" },
  ];

  const handleRolesChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  function getCurrentDate() {
    var currentDate = new Date();
    var day = currentDate.getDate();
    var month = getMonthName(currentDate.getMonth() + 1);
    var year = currentDate.getFullYear();
    var curDate = day + " " + month + " " + year;
    setFormData({ ["addedOn"]: curDate });
  }
  useEffect(() => {
    getCurrentDate();
  }, []);

  useEffect(() => {
    setError("");
  }, [formData.email, formData.name, formData.phone_number]);

  function clearAddUserForm() {
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      roles: "",
    });
    setSelectedRoles([]);
  }
  const handleFormDataChange = (event) => {
    const { name, value } = event.target;
    setFormData({ [name]: value });
  };

  async function handleAddUser() {
    if (formData.name === "") {
      setError("Please Enter name");
      return;
    } else if (formData.email === "") {
      setError("Enter valid Email");
      return;
    } else if (formData.phone_number === "") {
      setError("Please Enter phone number");
      return;
    } else if (formData.phone_number.length !== 10) {
      setError("Invalid Phone Number");
      return;
    } else if (parseInt(formData.phone_number).toString().length !== 10) {
      setError("Please Enter only Numbers in Phone field");
      return;
    }

    try {
      let result = await addUser({
        email: formData.email,
        name: formData.name,
        phone_number: "+91" + formData.phone_number,
        roles: selectedRoles,
      });

      if (result.data) {
        setSuccess("User Added Successfully");
        setError("");
        clearAddUserForm();
      } else if (result.error) {
        setSuccess("");
        setError(result.error.data.message);
      }
    } catch (err) {
      console.log(err);

      setError(err.data.message || "Could not add User,Please try again.");
    }
  }

  const handleEnterKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleAddUser();
    }
  };

  useEffect(() => {
    setError("");
  }, [formData.name, formData.email, formData.phone_number, formData.roles]);

  return (
    <CardContainer tabIndex="0" onKeyDown={handleEnterKeypress}>
      <GridContainer columns="1fr" width="100%">
        <Heading style={{ width: "100%" }}>
          Add User {!subscribed && <StyledLockIcon />}
        </Heading>
        <GridContainer
          rgap="0.7rem"
          gap="0.7rem"
          margin="1rem 0"
          columns="repeat(auto-fill, minmax(250px, 1fr))"
        >
          <StyledTextField
            margin="0"
            value={formData.name}
            disabled={!subscribed}
            onChange={handleFormDataChange}
            name="name"
            label="Your Name"
            required
          />
          <StyledTextField
            margin="0"
            value={formData.email}
            onChange={handleFormDataChange}
            name="email"
            disabled={!subscribed}
            message=""
            label="Your Email"
            required
          />
          <FormControl fullWidth>
            <StyledInputLabel id="role-label">Select Role</StyledInputLabel>
            <StyledSelect
              labelId="role-label"
              multiple
              disabled={!subscribed}
              value={selectedRoles}
              onChange={handleRolesChange}
              input={<OutlinedInput label="Select Roles" />}
              renderValue={(selected) =>
                selected.map((obj) => parseRole(obj)).join(", ")
              }
            >
              {roles.map((obj) => (
                <MenuItem key={obj.value} value={obj.value}>
                  <Checkbox checked={selectedRoles.indexOf(obj.value) > -1} />
                  <ListItemText primary={obj.label} />
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>

          <StyledTextField
            margin="0"
            value={formData.phone_number}
            onChange={handleFormDataChange}
            name="phone_number"
            disabled={!subscribed}
            label="Your Phone Number"
            required
          />
        </GridContainer>

        <Text style={{ width: "100%" }}>
          To add a Doctor please visit <Link to="/doctors">Manage Doctors</Link>{" "}
          page.
        </Text>
        {error ? <ErrorContainer>{error}</ErrorContainer> : ""}
        {success ? <SuccessContainer>{success}</SuccessContainer> : ""}

        <BtnWrap style={{ justifyContent: "flex-end", margin: "1rem 0 " }}>
          <Button
            premium={!subscribed}
            onclick={handleAddUser}
            isloading={isAddUserLoading}
            disabled={isAddUserLoading}
            gatingMessage={gatingMessage.addMoreHospitalUserGM}
            label="Add User"
          ></Button>
        </BtnWrap>
      </GridContainer>
    </CardContainer>
  );
}

export default AddUser;
