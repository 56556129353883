//React Imports
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import jwt_decode from "jwt-decode";

//styled-components
import {
  AuthContainer,
  LoginWrapper,
  Column1,
  Line,
  Column2,
  ImgWrap,
  Img,
  Heading,
  FormContainer,
  ResendOtp,
  InputWrap,
  Text2,
  ModalHeading,
  ToggleCard,
} from "./Auth.elements";
import {
  AnchorText,
  GridContainer,
  LightText,
  StyledTextField,
} from "../../Global";

//Components
// import TextField from "../../components/ui/TextField";
import Shimmering from "../../components/ui/CardShimmering";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "../../components/ui/Button";

//images
import doctors from "../../assets/doctors.svg";
import doctorAvt from "../../assets/doctor.png";
import hospital from "../../assets/hospital.png";

//icons
import { FaCheck, FaPhone } from "react-icons/fa";

//navigation
import { useNavigate } from "react-router-dom";

//api
import axios from "../../api/axiosApi";

//Hooks
import useAuth from "../../hooks/useAuth";

//Redux imports
import { useDispatch } from "react-redux";
import { setCredential } from "../../reduxSlices/authSlice";
import { setDocCredential } from "../../reduxSlices/docAuthSlice";

//RTK Query
import {
  useLoginMutation,
  useRequestotpMutation,
} from "../../api/userEndpoints/authApiSlice";

import {
  useDocLoginMutation,
  useRequestDocOtpMutation,
} from "../../api/docEndpoints/docAuthApiSlice";
import storage from "local-storage-fallback";

const OTP_REQUEST_URL = "/auth/login?action=requestOTP";

function LoginPage() {
  const { setAuth, auth, persist, setPersist, setDocAuth, docAuth } = useAuth();
  const navigate = useNavigate();
  // const authData = useSelector(auth);
  const dispatch = useDispatch();
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const [requestotp, { isLoading: isOtpLoading }] = useRequestotpMutation();

  //dotor login
  const [docLogin, { isLoading: isDocLoginLoading }] = useDocLoginMutation();
  const [requestDocOtp, { isLoading: isDocOtpLoading }] =
    useRequestDocOtpMutation();

  const iconRef = useRef(null);
  const col2Ref = useRef(null);
  const headingRef = useRef(null);
  const buttonRef = useRef(null);
  const phoneNumberFieldRef = useRef(null);
  const otpFieldRef = useRef(null);

  const [otpState, setOtpState] = useState(false);
  const [newOtpSent, setNewOtpState] = useState(false);
  const [otp, setOtp] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [session, setSession] = useState("");
  const [activeCard, setActiveCard] = useState("hospital");

  const [docOtpLoading, setDocOtpLoading] = useState(false);
  const [docLoginLoading, setDocLoginLoading] = useState(false);

  const [timer, setTimer] = useState(59);
  const [resendOtp, setResend] = useState(false);
  const [error, setError] = useState("");

  //focus on the phone number field on load
  useEffect(() => {
    if (!otpState) {
      phoneNumberFieldRef.current.focus();
    }
  }, []);

  //clear error ACK on editing textfield
  useEffect(() => {
    setError("");
  }, [otp, phoneNumber]);

  function startTimer() {
    var timeLeft = 59;
    var x = setInterval(() => {
      if (timeLeft === 0) {
        clearTimeout(x);
        setResend(true);
      }
      setTimer(timeLeft);
      timeLeft--;
    }, 1000);
  }

  //handle doctor phone number
  async function handleDocPhoneNumber() {
    setError("");
    if (!phoneNumber) {
      setError("Please enter your Phone Number");
      return;
    } else if (phoneNumber.length !== 10) {
      setError("Invalid Phone Number Please enter without country code ");
      return;
    } else if (parseInt(phoneNumber).toString().length !== 10) {
      setError("Please Enter only Numbers in Phone field");
      return;
    }
    try {
      setDocOtpLoading(true);
      const docDataStatus = await fetch(
        "https://dev.curus.co.in/auth/app-auth/?action=initiate-auth&user=doctor",
        {
          body: JSON.stringify({
            phone_number: "+91" + phoneNumber,
          }),
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const docData = await docDataStatus.json();
      if (docDataStatus.status === 200) {
        startTimer();
        setSession(docData?.Session);
        setOtpState(true);
      } else if (docDataStatus.status === 400) {
        setError(docData?.message ?? "Something went wrong");
      } else {
        setError("");
      }
      setDocOtpLoading(false);
      // otpFieldRef.current.focus();
    } catch (err) {
      console.log(err);

      if (!err) {
        setError("No Server response");
      } else if (err.status === 400) {
        setError(err.data.message || "Missing Username or Password");
      } else if (err.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Login failed");
      }
    }
  }

  //handle phone number submission
  async function handlePhoneNumber() {
    setError("");
    if (!phoneNumber) {
      setError("Please enter your Phone Number");
      return;
    } else if (phoneNumber.length !== 10) {
      setError("Invalid Phone Number");
      return;
    } else if (parseInt(phoneNumber).toString().length !== 10) {
      setError("Please Enter only Numbers in Phone field");
      return;
    }
    try {
      await requestotp({
        phone_number: "+91" + phoneNumber,
      }).unwrap();

      startTimer();
      setOtpState(true);
    } catch (err) {
      console.log(err);

      if (!err) {
        setError("No Server response");
      } else if (err.status === 400) {
        setError(err.data.message);
      } else if (err.status === 404) {
        setError("User with this number doesn't exists.");
      } else {
        setError("Something went wrong.");
      }
    }
  }
  //handle resend OTP
  function resendOTP() {
    setError("");
    setOtp("");
    // setOtpState(false);
    axios
      .post(OTP_REQUEST_URL, {
        phone_number: "+91" + phoneNumber,
      })
      .then((response) => {
        setResend(false);
        setNewOtpState(true);
      })
      .catch((e) => {
        if (e.response.status === 404) {
          setError("Account doesn't exist.Please Sign up.");
          return;
        }
        // setError("Please verify you Phone Number.");npm startt
        setError(e.response.data.message);
      });
  }
  //handle doctor login
  async function handleDoctorLogin() {
    if (otp.length !== 6) {
      setError("Please enter valid OTP");
    } else {
      setError("");
      try {
        setDocLoginLoading(true);
        const userDataStatus = await fetch(
          "https://dev.curus.co.in/auth/app-auth/?action=respond-to-auth&user=doctor",
          {
            body: JSON.stringify({
              phone_number: "+91" + phoneNumber,
              otp: otp,
              session: session,
            }),
            method: "POST",
            headers: {
              // authorization: `Bearer ${userData?.AuthenticationResult?.IdToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            withCredntials: true,
            credentials: "include",
          }
        );
        const userData = await userDataStatus.json();
        console.log(userDataStatus);

        const subscriptionStatus = await fetch(
          "https://l1x3gkp512.execute-api.ap-south-1.amazonaws.com/dev/verify-doctor-subscription",
          {
            headers: {
              authorization: `Bearer ${userData?.AuthenticationResult?.IdToken}`,
            },
          }
        );

        if (userDataStatus?.status === 400) {
          setError("Invalid OTP");
        } else if (userDataStatus?.status === 401) {
          setError("Unauthorized");
        } else {
          setError("Login failed");
        }
        const ss = await subscriptionStatus.json();

        if (ss) {
          dispatch(
            setDocCredential({
              phoneNumber: phoneNumber,
              subscribed: ss.is_subscribed,
              AuthenticationResult: userData?.AuthenticationResult,
              WebappAuthenticationResult: userData?.WebappAuthenticationResult,
            })
          );

          setDocAuth({
            phoneNumber: phoneNumber,
            AuthenticationResult: userData?.AuthenticationResult,
            WebappAuthenticationResult: userData?.WebappAuthenticationResult,
            subscribed: ss.is_subscribed,
          });
        }
        setDocLoginLoading(false);

        storage.setItem(
          "refreshToken",
          userData?.AuthenticationResult?.RefreshToken
        );
        storage.setItem("doctorPhone", phoneNumber);
        storage.setItem("currentUser", "doctor");
      } catch (err) {
        console.log(err);
      }
    }
  }
  //handle user login
  async function handleLogin() {
    if (otp.length !== 6) {
      setError("Please enter valid OTP");
    } else {
      setError("");
      try {
        const userData = await login({
          phone_number: "+91" + phoneNumber,
          otp: otp,
        }).unwrap();

        dispatch(setCredential({ ...userData }));

        var decoded = jwt_decode(userData.access);
        const accessToken = userData.access;
        const roles = decoded.user_roles;
        const applicationStatus = decoded.status;
        const userId = decoded.user_id;
        const fullName = decoded.name;
        const isSubscribed = decoded.is_subscribed;

        setAuth({
          phoneNumber,
          roles,
          accessToken,
          fullName,
          userId,
          applicationStatus,
          isSubscribed,
        });
      } catch (err) {
        console.log(err);
        if (!err.data) {
          setError("No Server response");
        } else if (err.status == "400") {
          setError("Invalid OTP");
        } else if (err.status === "401") {
          setError("Unauthorized");
        } else {
          setError("Login failed");
        }
      }
    }
  }

  useLayoutEffect(() => {
    if (docAuth?.AuthenticationResult?.IdToken) {
      console.log("docAuth is present");
      navigate("/doctor");
    } else if (auth?.applicationStatus === "pending") {
      navigate("/underprocess");
    } else if (auth.roles?.includes("board_member")) {
      navigate("/admin");
    } else if (
      auth.roles?.includes("hospital_admin") ||
      auth.roles?.includes("curus_admin")
    ) {
      navigate("/admin");
    } else if (auth.roles?.includes("support_member")) {
      navigate("/support");
    }
  }, [auth.roles, docAuth?.AuthenticationResult?.IdToken]);

  function togglePersist() {
    setPersist((prev) => !prev);
  }
  useEffect(() => {
    storage.setItem("persist", persist);
  }, [persist]);

  const handleEnterKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      activeCard === "doctor" ? handleDocPhoneNumber() : handlePhoneNumber();
    }
  };
  const handleOtpKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      activeCard === "doctor" ? handleDoctorLogin() : handleLogin();
    }
  };

  const content = (
    <AuthContainer>
      <LoginWrapper>
        <Column1>
          <ImgWrap ref={iconRef}>
            <Img src={doctors} alt="hero-icon1"></Img>
          </ImgWrap>
        </Column1>
        <Line />
        <Column2 ref={col2Ref}>
          <FormContainer>
            {otpState ? (
              <>
                {/* <Label>Enter OTP recieved *</Label> */}
                <Heading dark ref={headingRef}>
                  Login
                </Heading>
                <InputWrap>
                  <StyledTextField
                    margin="dense"
                    ref={otpFieldRef}
                    value={otp}
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                    error={error}
                    helperText={error ? error : ""}
                    label="Enter OTP recieved *"
                    autoFocus={true}
                    type="tel"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FaPhone />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    onKeyDown={handleOtpKeypress}
                  />
                </InputWrap>

                {resendOtp ? (
                  <a className="link" onClick={() => resendOTP()}>
                    Resend OTP
                  </a>
                ) : newOtpSent ? (
                  <small style={{ color: "grey" }}>
                    new OTP was sent to {phoneNumber}{" "}
                  </small>
                ) : (
                  <ResendOtp>Resend OTP in 0:{timer}</ResendOtp>
                )}
              </>
            ) : (
              <>
                {/* <Label>Enter Registered Email*</Label> */}
                <ModalHeading
                  style={{
                    textAlign: "center",
                    width: "100%",
                    margin: "0 0 1rem 0",
                  }}
                  className="m-1"
                  dark
                  ref={headingRef}
                >
                  Choose Account Type
                </ModalHeading>
                <GridContainer
                  columns="1fr 1fr"
                  width="max-content"
                  justify="space-between"
                  gap="1rem"
                  style={{ margin: "0 0 2rem 0" }}
                >
                  <ToggleCard
                    onClick={() => setActiveCard("hospital")}
                    active={activeCard === "hospital"}
                  >
                    <img width="80px" src={hospital} alt="hospital"></img>
                    <Text2 style={{ textAlign: "center" }}>
                      Hospital Staff
                    </Text2>
                    {activeCard === "hospital" && (
                      <FaCheck
                        style={{
                          position: "absolute",
                          right: "-8px",
                          bottom: "-8px",
                          color: "white",
                          borderRadius: "100%",
                          background: "green",
                          padding: "4px",
                          transition: "0.3s",
                        }}
                      />
                    )}
                  </ToggleCard>
                  <ToggleCard
                    onClick={() => setActiveCard("doctor")}
                    active={activeCard === "doctor"}
                  >
                    <img width="80px" src={doctorAvt} alt="Doctor"></img>
                    <Text2 style={{ textAlign: "center" }}>Doctor</Text2>
                    {activeCard === "doctor" && (
                      <FaCheck
                        style={{
                          position: "absolute",
                          right: "-8px",
                          bottom: "-8px",
                          color: "white",
                          borderRadius: "100%",
                          background: "green",
                          padding: "4px",
                          transition: "0.3s",
                        }}
                      />
                    )}
                  </ToggleCard>
                </GridContainer>
                <InputWrap>
                  <StyledTextField
                    fullWidth
                    margin="dense"
                    ref={phoneNumberFieldRef}
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                    error={error !== ""}
                    helperText={error}
                    label="Enter Phone Number"
                    placeholder="12345 67890"
                    type="tel"
                    name="phone"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FaPhone />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    onKeyDown={handleEnterKeypress}
                  ></StyledTextField>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="persist"
                      onChange={togglePersist}
                      checked={persist}
                      onKeyDown={handleEnterKeypress}
                    ></input>
                    <Text2 htmlFor="persist">Remember Me</Text2>
                  </div>
                  <GridContainer
                    columns="1fr min-content"
                    justify="space-between"
                    width="100%"
                  >
                    <LightText style={{ margin: "0", fontSize: "14px" }}>
                      No Account? <br></br>
                      <AnchorText onClick={() => navigate("/signup")}>
                        Sign Up
                      </AnchorText>
                    </LightText>

                    <Button
                      orange
                      ref={buttonRef}
                      isloading={isOtpLoading || docOtpLoading}
                      disabled={isOtpLoading || docOtpLoading}
                      label="Request OTP"
                      onclick={() =>
                        activeCard === "doctor"
                          ? handleDocPhoneNumber()
                          : handlePhoneNumber()
                      }
                    >
                      Request OTP
                    </Button>
                  </GridContainer>
                </InputWrap>
              </>
            )}
            {otpState && (
              <GridContainer
                width="100%"
                columns="auto auto"
                justify="space-between"
              >
                <AnchorText onClick={() => setOtpState(false)}>
                  Go Back
                </AnchorText>
                <Button
                  ref={buttonRef}
                  label="Login"
                  isloading={isLoginLoading || docLoginLoading}
                  disabled={isLoginLoading || docLoginLoading}
                  onclick={() =>
                    activeCard === "doctor"
                      ? handleDoctorLogin()
                      : handleLogin()
                  }
                >
                  Login
                </Button>
              </GridContainer>
            )}
          </FormContainer>
        </Column2>
      </LoginWrapper>
    </AuthContainer>
  );

  return content;
}

export default LoginPage;
