//React imports
import React, { useState, useEffect, useReducer, memo } from "react";
import Modal from "react-modal";

//Styled components
import {
  Container,
  MobileCard,
  MobileCardSmallText,
  MobileCardText,
  MobileCardTitle,
  MobileCardWrapper,
  EditIcon,
  DeleteIcon,
  BtnWrap,
  Heading,
} from "../../MainPages.elements";
import {
  CenterFlexContainer,
  LightText,
  ModalContainer,
  GridContainer,
  StyledInputLabel,
  StyledSelect,
  StyledTextField,
} from "../../../../Global";

//Util Functions
import { parseRole } from "../../../../utils/parseSchedule";

//Components
import Button from "../../../../components/ui/Button";
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
} from "@mui/material";

//RTK QUery
import {
  useDeleteSingleUserMutation,
  useUpdateUserMutation,
} from "../../../../api/userEndpoints/usersEndpoint";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    zIndex: "10",
    position: "absolute",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24)",
  },
  overlay: {
    zIndex: 99,
  },
};

const UserMobileCard = memo(
  ({ setError, setSuccess, userData, i, cardColumn, currentUser }) => {
    //rtk query
    const [deleteSingleUser, { isLoading: isDeleteUserLoading }] =
      useDeleteSingleUserMutation();
    const [updateUser, { isLoading: isUpdateUserLoading }] =
      useUpdateUserMutation();

    const [isEditable, setEdit] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedRoles, setSelectedRoles] = useState(userData[i]?.roles);

    const [formData, setFormData] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        name: userData[i]?.name,
        email: userData[i]?.email,
        phone_number: userData[i]?.phone_number,
        roles: userData[i]?.roles,
      }
    );
    const handleFormDataChange = (event) => {
      const { name, value } = event.target;
      setFormData({ [name]: value });
    };

    const handleRolesChange = (event) => {
      const {
        target: { value },
      } = event;
      setSelectedRoles(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    };

    async function handleUpdateUser(i) {
      try {
        let result = await updateUser({
          id: userData[i].id,
          data: {
            email: formData.email,
            name: formData.name,
            phone_number: formData.phone_number,
            roles: selectedRoles,
          },
        });
        console.log(result);
        if (result.data) {
          setSuccess(`User: ${userData[i].name} updated successfully.`);
        } else if (result.error) {
          setError(
            result.error.data.message ||
              "Something went wrong,Please try again."
          );
        }
      } catch (err) {
        console.log(err);
        setError("Something went wrong,Please try again.");
      }
      closeModal();
    }
    const roles = [
      {
        value: "hospital_admin",
        label: "Hospital Admin",
      },
      { value: "support_member", label: "Support Member" },

      { value: "board_member", label: "Board Member" },
      { value: "doctor", label: "Doctor" },
    ];

    function openEditModal() {
      setIsOpen(true);
    }

    function afterOpenModal() {
      // references are now sync'd and can be accessed.
    }

    function closeModal() {
      setIsOpen(false);
    }

    async function deleteSingleRow() {
      try {
        await deleteSingleUser(userData[i].id);
        if (isDeleteUserLoading) {
          setError("");
          setSuccess(
            `User with name ${userData[i].name} deleted successfully.`
          );
        }
      } catch (err) {
        console.log(err);
        setError("Couldn't delete the user,Please try again.");
        setSuccess("");
      }
    }

    useEffect(() => {
      const cur = currentUser?.roles?.includes("board_member");
      const res = userData[i]?.roles?.includes("board_member");
      const isAdmin = currentUser?.roles?.includes("hospital_admin");

      if (cur === true && res === true) {
        setEdit(true);
      } else if (cur === true && res === false) {
        setEdit(true);
      } else if (cur === false && res === true) {
        setEdit(false);
      } else if (isAdmin === true && res === false) {
        setEdit(true);
      }
    }, []);
    return (
      <MobileCardWrapper>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
        >
          <ModalContainer>
            <GridContainer rgap="1rem" columns="1fr">
              <Heading>Update User Details</Heading>
              <StyledTextField
                style={{ minWidth: "250px" }}
                fullWidth
                value={formData.name}
                onChange={handleFormDataChange}
                name="name"
                message=""
                label="Your Name"
                required
              />
              <StyledTextField
                fullWidth
                value={formData.email}
                onChange={handleFormDataChange}
                name="email"
                message=""
                label="Your Email"
                required
              />
              <FormControl fullWidth>
                <StyledInputLabel id="role-label">
                  Select Role*
                </StyledInputLabel>
                <StyledSelect
                  labelId="role-label"
                  multiple
                  value={selectedRoles}
                  onChange={handleRolesChange}
                  input={<OutlinedInput label="Select Roles" />}
                  renderValue={(selected) =>
                    selected.map((obj) => parseRole(obj)).join(", ")
                  }
                  required
                >
                  {roles.map((obj) => (
                    <MenuItem key={obj.value} value={obj.value}>
                      <Checkbox
                        checked={selectedRoles.indexOf(obj.value) > -1}
                      />
                      <ListItemText primary={obj.label} />
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
              <StyledTextField
                value={formData.phone_number}
                onChange={handleFormDataChange}
                name="phone_number"
                label="Your Phone Number"
                required
              />
              <BtnWrap style={{ justifyContent: "flex-end" }}>
                <Button
                  isloading={isUpdateUserLoading}
                  disabled={isUpdateUserLoading}
                  onclick={() => handleUpdateUser(i)}
                  orange
                  label="Update User"
                ></Button>
              </BtnWrap>
            </GridContainer>
          </ModalContainer>
        </Modal>
        <MobileCard cardColumn={cardColumn}>
          <Container justify="flex-start" align="flex-start" gap="4px">
            <MobileCardTitle>{userData[i]?.name}</MobileCardTitle>
            <MobileCardSmallText>{userData[i]?.email}</MobileCardSmallText>
            <CenterFlexContainer justify="flex-start" align="flex-start">
              <MobileCardSmallText>
                {userData[i]?.roles.map((r) => parseRole(r)).join()}
              </MobileCardSmallText>
            </CenterFlexContainer>
          </Container>
          <CenterFlexContainer justify="flex-end" gap="1rem">
            <EditIcon disabled={!isEditable} onClick={openEditModal} />
            <DeleteIcon
              disabled={isDeleteUserLoading || !isEditable}
              small="true"
              onClick={deleteSingleRow}
            />
          </CenterFlexContainer>
        </MobileCard>
      </MobileCardWrapper>
    );
  }
);

export default UserMobileCard;
