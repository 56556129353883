import React from "react";
import {
  TextfieldContainer,
  TextfieldWrapper,
  Input,
  Label,
  ErrorMessage,
} from "./ui.elements";

function TimeField(props) {
  return (
    <TextfieldContainer width={props.width} style={{ margin: "0 0 0.7rem 0" }}>
      <TextfieldWrapper>
        <Input
          value={props.value}
          multiple={props.multiple ? "multiple" : ""}
          type="time"
          name="begin"
          placeholder="dd/mm/yyyy"
          accept={props.accept}
          onChange={props.onchange}
          ref={props.fieldRef}
          disabled={props.disabled}
          id={props.id ? props.id : ""}
          required={props.required ? "required" : ""}
          min="1997-01-01"
          max="2030-12-31"
        />
        <Label>{props.label}</Label>
      </TextfieldWrapper>
      {props.error ? <ErrorMessage>{props.error}</ErrorMessage> : ""}
    </TextfieldContainer>
  );
}

export default TimeField;
