import { apiSlice } from "../apiSlice";

export const latestAppointmentEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLatestAppointments: builder.query({
      query: () => "/api/appointment/?from=P3D",
      providesTags: ["LatestAppointments"],
    }),
    addAppointment: builder.mutation({
      query: (apt) => ({ url: "/api/appointment/", method: "POST", body: apt }),
      invalidatesTags: ["Appointments", "LatestAppointments"],
    }),
  }),
});
export const appointmentEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppointments: builder.query({
      query: () => "/api/appointment/",
      providesTags: ["Appointments"],
    }),
    getMyAppointments: builder.query({
      query: () => "/api/appointment?associated_by=self",
      providesTags: ["MyAppointments"],
    }),
    getHospitalDetails: builder.query({
      query: () => "/api/hospital/",
      providesTags: ["Hospital"],
    }),
    markVisitAppointment: builder.mutation({
      query: (id) => ({
        url: `/api/appointment/${id}`,
        method: "PUT",
        body: { action: "mark visit" },
      }),
      invalidatesTags: ["Appointments", "LatestAppointments"],
    }),
    rejectAppointment: builder.mutation({
      query: (id) => ({
        url: `/api/appointment/${id}`,
        method: "PUT",
        body: { action: "reject" },
      }),
      invalidatesTags: ["Appointments", "LatestAppointments"],
    }),
    rejectMultipleAppointments: builder.mutation({
      query: (data) => ({
        url: `/api/appointment/`,
        method: "PUT",
        body: {
          action: "reject multiple",
          appointment_ids: data.appointment_ids,
        },
      }),
      invalidatesTags: ["Appointments", "LatestAppointments"],
    }),
    rescheduleAppointment: builder.mutation({
      query: (data) => ({
        url: `/api/appointment/${data.id}`,
        method: "PUT",
        body: {
          action: "reschedule",
          appointment_ts: data.newDate,
          availability_slot: data.availability_slot,
          slot_id: data.slot_id,
        },
      }),
      invalidatesTags: ["Appointments", "LatestAppointments"],
    }),
    rescheduleMultipleAppointment: builder.mutation({
      query: (data) => ({
        url: `/api/appointment/`,
        method: "PUT",
        body: {
          action: "reschedule multiple",
          appointment_data: data.appointments,
        },
      }),
      invalidatesTags: ["Appointments", "LatestAppointments"],
    }),
  }),
});

export const { useGetLatestAppointmentsQuery } = latestAppointmentEndpoints;
export const { useAddAppointmentMutation } = latestAppointmentEndpoints;
export const { useGetAppointmentsQuery } = appointmentEndpoints;
export const { useGetMyAppointmentsQuery } = appointmentEndpoints;
export const { useRejectAppointmentMutation } = appointmentEndpoints;
export const { useRejectMultipleAppointmentsMutation } = appointmentEndpoints;
export const { useMarkVisitAppointmentMutation } = appointmentEndpoints;
export const { useRescheduleAppointmentMutation } = appointmentEndpoints;
export const { useLazyGetHospitalDetailsQuery } = appointmentEndpoints;
export const { useRescheduleMultipleAppointmentMutation } =
  appointmentEndpoints;
