import { docApiSlice } from "../docApiSlice";

export const prescriptionEndpoints = docApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPrescription: builder.query({
      query: (id) => `/download-prescription?consultation_id=${id} `,
    }),
  }),
});

export const { useGetPrescriptionQuery } = prescriptionEndpoints;
