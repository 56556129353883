import React, { memo, useContext, useEffect, useRef, useState } from "react";
import {
  StatusDoneText,
  StatusRejectedText,
  StatusConfirmedText,
  StyledTR,
  StyledTD,
  InvoiceText,
  Title,
  PlusIcon,
  CrossIcon,
  Container,
  DeleteIcon,
  Heading2,
  HLine,
} from "../../MainPages.elements";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

import { CloseIcon, Heading3 } from "../../../Auth/Auth.elements";
import Button from "../../../../components/ui/Button";
import { parseDate } from "../../../../utils/parseDate";
import {
  changeTimeFormat,
  handleTimeFormat,
} from "../../../../utils/parseSchedule";
import {
  FloatingButton,
  GridContainer,
  LightText,
  Text,
  TextButton,
  BorderedGridContainer,
  LargeText,
  RedTextButton,
  AnchorText,
  ErrorContainer,
  StyledInputLabel,
  StyledSelect,
  StyledDatePicker,
  StyledTextField,
  BoldText,
} from "../../../../Global";

import axios from "axios";
import {
  useLazyGetHospitalDetailsQuery,
  useMarkVisitAppointmentMutation,
  useRejectAppointmentMutation,
  useRescheduleAppointmentMutation,
} from "../../../../api/userEndpoints/appointmentEndpoint";
import ReactModal from "react-modal";

import Invoice from "./Invoice";

import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
} from "@mui/material";

import NoRecords from "../../../../components/ui/NoRecords";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useLazyGetPracticeChargeQuery } from "../../../../api/userEndpoints/practiceChargeEndpoint";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { auth } from "../../../../reduxSlices/authSlice";
import {
  generateInvoiceModalStyles,
  markVisitModalStyles,
  InvoiceModalStyles,
  darkGenerateInvoiceModalStyles,
  darkMarkVisitModalStyles,
  darkInvoiceModalStyles,
} from "../../../../utils/ModalStyles";
import AuthContext from "../../../../context/authProvider";

const AppointmentRow = memo(
  ({ i, appointmentData, setAptError, setAptSuccess }) => {
    const { isDarkTheme } = useContext(AuthContext);

    const navigate = useNavigate();

    const authData = useSelector(auth);
    const UPLOAD_INVOICE_URL = `https://dev.curus.co.in/api/upload-appointment-invoice/${appointmentData?.id}`;
    //rtk  query

    const [getPracticeCharge, practiceCharges] =
      useLazyGetPracticeChargeQuery();
    const [
      markVisitAppointment,
      { isLoading: isMarkVisitLoading, isError: isMarkVisitError },
    ] = useMarkVisitAppointmentMutation();
    const [
      rejectAppointment,
      { isLoading: isRejectAppointmentLoading, error: rejectAppointmentError },
    ] = useRejectAppointmentMutation();
    const [
      rescheduleAppointment,
      { isLoading: isRescheduleAppointmentLoading },
    ] = useRescheduleAppointmentMutation();
    const [getHospitalDetails] = useLazyGetHospitalDetailsQuery();

    const [recieptModal, setRecieptModal] = useState(false);
    const [generateInvoiceModal, setGenerateInvoiceModal] = useState(false);
    const [modifyModal, setModifyModal] = useState(false);
    const [markVisitModal, setMarkVisitModal] = useState(false);
    const [pc, setPc] = useState([]);

    //reschdule new approach
    const [reschduleDT, setReschduleDT] = useState(dayjs());
    const [docSlots, setDocSlots] = useState([]);
    const [docSlotsCopy, setDocSlotsCopy] = useState([]);
    const [slotId, setSlotId] = useState();
    const [avblDates, setAvblDates] = useState([]);
    const [appointmentTS, setAppointmentTS] = useState("");

    //Markvisit modal
    const [item, setItem] = useState("");
    const [rate, setRate] = useState("");
    const [quantity, setQuantity] = useState("");
    const [discount, setDiscount] = useState("");
    const [isAddingItem, setAddingItem] = useState(false);
    const [itemBuffer, setItemBuffer] = useState([]);
    const componentRef = useRef();

    //generate invoice Modal states
    const [selectedCharges, setSelectedCharges] = useState([]);
    const [hospitalData, setHospitalDetails] = useState({
      place_id: "",
      name: "",
      address: "",
      phone_number: "",
    });
    const [isAdvance, setAdvance] = useState(false);
    const [advanceAmount, setAdvanceAmount] = useState(0);
    const [advError, setAdvError] = useState("");

    async function toggleStatus(i, id, status, name) {
      if (status === "Done") {
        try {
          await rejectAppointment(id);
          if (rejectAppointmentError) {
            console.log(rejectAppointmentError);
            setAptError(rejectAppointmentError);
            return;
          }
          setAptError("");
          setAptSuccess(`The appointment of ${name} is successfully rejected.`);
        } catch (err) {
          console.log(err);
        }
      } else if (status === "Confirmed") {
        try {
          await markVisitAppointment(id);

          if (isMarkVisitError) {
            setAptError(isMarkVisitError);
            return;
          }
          setAptError("");
          setAptSuccess(`The appointment of ${name} is marked Visited.`);
          // setMarkVisitModal(true);
        } catch (err) {
          console.log(err);
        }
      }
    }
    async function cancelStatus(i, id, status, name) {
      if (status === "Confirmed" || status === "Confirmation Pending") {
        try {
          await rejectAppointment(id);
          if (rejectAppointmentError) {
            setAptError(rejectAppointmentError);
            return;
          }
          setAptError("");
          setAptSuccess(`The appointment of ${name} is successfully rejected.`);
          setModifyModal(false);
        } catch (err) {
          console.log(err);
        }
      }
    }

    function closeRecieptModal() {
      setRecieptModal(false);
    }
    async function openRecieptModal() {
      try {
        const res = await getHospitalDetails();
        setHospitalDetails(res?.data);
        if (!res.data) {
          setAdvError("Invoice could not be generated.");
          return;
        } else if (advanceAmount < 0) {
          setAdvError("Amount cannot be less than zero.");
          return;
        } else if (
          advanceAmount >
          selectedCharges
            .map((obj) => parseFloat(obj.charge))
            .reduce((sum, cur) => sum + cur, 0)
        ) {
          setAdvError("Advance cannot be larger than Total amount");
          return;
        }
      } catch (e) {
        console.log(e);
      }

      setRecieptModal(true);
    }

    async function handleReschdule() {
      try {
        const result = await rescheduleAppointment({
          id: appointmentData?.id,
          newDate: appointmentTS,
          slot_id: slotId,
        });

        if (result?.error?.status === 400) {
          setAptSuccess("");
          setModifyModal(false);
          setAptError(`The appointment is couldn't be rescheduled.`);
          return;
        } else {
          setAptError("");
          setModifyModal(false);
          setAptSuccess(`The appointment is successfully rescheduled.`);
        }
      } catch (err) {
        console.log(err);
      }
    }
    const handleAddItem = () => {
      setItemBuffer((prevItems) => [
        ...prevItems,
        {
          item: item,
          rate: rate,
          discount: discount,
          quantity: quantity,
        },
      ]);

      setAddingItem(false);
      setItem("");
      setRate("");
      setQuantity("");
      setDiscount("");
    };

    const handleRemoveItem = (delItem) => {
      const newItems = itemBuffer.filter((curItem) => curItem.item !== delItem);
      setItemBuffer(newItems);
    };
    async function getDocSlots() {
      const response = await axios.get(
        `https://l1x3gkp512.execute-api.ap-south-1.amazonaws.com/test/doctor-listing?type=availability-slot&doctor_hospital_id=${
          appointmentData?.doctor_hospital
        }&availability_datetime=${dayjs().format("YYYY-MM-DD")}`
      );
      setDocSlots(response?.data);
      setDocSlotsCopy(response?.data);

      const availableDates = response?.data.map((ad) => ad.schedule_date);
      setAvblDates(availableDates);
    }

    const disableDate = (currentDate) => {
      return !avblDates.includes(dayjs(currentDate).format("YYYY-MM-DD"));
    };

    //logic to concat time slot and date chosen
    useEffect(() => {
      let appointmentts = docSlotsCopy?.filter(
        (sch) => sch.schedule_date === reschduleDT.toISOString().split("T")[0]
      );

      let id = appointmentts[0]?.available_slots.filter((slot) => {
        return slot.slot_id === slotId;
      });

      if (id?.length > 0) {
        let newDate =
          appointmentts[0]?.schedule_date + "T" + id[0].start_time.slice(0, 5);
        setAppointmentTS(newDate);
      }
    }, [slotId]);

    const [practiceCharge, setPracticeCharge] = useState([]);

    const handlePracticeCharge = (event) => {
      const {
        target: { value },
      } = event;
      setPracticeCharge(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    };

    function openGenerateModal() {
      getPracticeCharge(appointmentData?.doctor_hospital);
      setGenerateInvoiceModal(true);
    }
    let generated = false;
    useEffect(() => {
      if (practiceCharges && practiceCharges.data) {
        setPc(practiceCharges.data);
      }
    }, [practiceCharges]);
    useEffect(() => {
      setSelectedCharges(
        practiceCharge.map((obj) => pc.find((n) => n.id === obj))
      );
    }, [practiceCharge]);

    function handleAdvance() {
      setAdvance(true);
    }

    useEffect(() => {
      setAdvError("");
    }, [advanceAmount]);

    async function handleDownloadPdf() {
      console.log("calling");

      html2canvas(document.getElementById("pdf-container")).then(
        async (canvas) => {
          const imgData = canvas.toDataURL("image/png");

          const pdf = new jsPDF();
          pdf.addImage(imgData, "PNG", 0, 0);
          pdf.save(`appointment-${appointmentData?.id}.pdf`);

          console.log(new File([pdf], "appointment.pdf"));
          const response = await axios.put(
            UPLOAD_INVOICE_URL,
            {
              invoice: new File(
                [pdf],
                `appointment-${appointmentData?.id}.jpg`
              ),
            },
            {
              headers: {
                Authorization: `Bearer ${authData?.accessToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          console.log(response);
        }
      );
    }

    return (
      <>
        {/* Generate Invoice Modal */}
        <ReactModal
          isOpen={generateInvoiceModal}
          style={
            isDarkTheme
              ? darkGenerateInvoiceModalStyles
              : generateInvoiceModalStyles
          }
          onRequestClose={() => setGenerateInvoiceModal(false)}
        >
          <GridContainer columns="1fr auto" margin="0 0 1rem 0">
            <Heading2>Generate Invoice</Heading2>
            <CloseIcon onClick={() => setGenerateInvoiceModal(false)} />
          </GridContainer>
          <GridContainer columns="1fr" align="flex-start">
            <GridContainer columns="1fr">
              <FormControl margin="dense" fullWidth>
                <StyledInputLabel id="specialties-label">
                  Select Practice Charge
                </StyledInputLabel>
                <StyledSelect
                  labelId="specialties-label"
                  label="Select Practice Charge"
                  fullWidth
                  style={{ maxWidth: "300px" }}
                  multiple
                  disabled={pc.length === 0}
                  value={practiceCharge ?? []}
                  onChange={handlePracticeCharge}
                  input={<OutlinedInput label="Select Practice Charge" />}
                  renderValue={(selected) => {
                    return selected
                      .map((obj) => pc.find((n) => n.id === obj).name)
                      .join(", ");
                  }}
                >
                  {pc &&
                    pc?.map((currentpc) => (
                      <MenuItem key={currentpc.id} value={currentpc.id}>
                        <Checkbox
                          checked={practiceCharge?.indexOf(currentpc.id) > -1}
                        />
                        <ListItemText primary={currentpc.name} />
                      </MenuItem>
                    ))}
                </StyledSelect>
              </FormControl>
              {pc.length === 0 && (
                <LightText>
                  <small>
                    This doctor does not have any Practice Charge.
                    <AnchorText onClick={() => navigate("/doctors")}>
                      Add Here
                    </AnchorText>
                  </small>
                </LightText>
              )}

              {isAdvance ? (
                <>
                  <Heading3 margin="1rem 0 0 0">Advance amount</Heading3>
                  <LightText>
                    <small>Please enter advance amount taken.</small>
                  </LightText>
                  <StyledTextField
                    margin="dense"
                    fullWidth
                    style={{ maxWidth: "300px" }}
                    label="Advance Amount"
                    value={advanceAmount}
                    onChange={(e) => setAdvanceAmount(e.target.value)}
                  ></StyledTextField>
                </>
              ) : (
                <GridContainer
                  onClick={handleAdvance}
                  columns="auto 1fr"
                  gap="0.5rem"
                >
                  <FaPlus style={{ color: "#3C78FC" }} />
                  <AnchorText>Create an Advance </AnchorText>
                </GridContainer>
              )}
            </GridContainer>
            <GridContainer columns="1fr">
              <Heading3 style={{ margin: "1rem 0 0.5rem 0" }}>
                Selected Charges
              </Heading3>

              {selectedCharges.length === 0 ? (
                <NoRecords message="Please select Practice Charges" />
              ) : (
                <GridContainer rgap="8px" columns="1fr">
                  {selectedCharges.map((sc, i) => (
                    <GridContainer columns="auto 2fr 1fr">
                      <LightText>
                        <small>{i + 1}</small>
                      </LightText>
                      <LightText>
                        <b>{sc.name}</b>
                      </LightText>
                      <LightText>{sc.charge}&nbsp;Rs.</LightText>
                    </GridContainer>
                  ))}
                </GridContainer>
              )}
            </GridContainer>
          </GridContainer>

          {advError && <ErrorContainer>{advError}</ErrorContainer>}
          <GridContainer
            justify="space-between"
            width="100%"
            columns="auto auto"
            margin="1rem 0 0 0"
          >
            <AnchorText
              onClick={() => {
                setGenerateInvoiceModal(false);
              }}
            >
              Cancel
            </AnchorText>
            <Button onclick={openRecieptModal} label="Generate" orange></Button>
          </GridContainer>
        </ReactModal>
        {/* Mark Visit Modal */}
        <ReactModal
          isOpen={markVisitModal}
          onRequestClose={() => setMarkVisitModal(false)}
          style={isDarkTheme ? darkMarkVisitModalStyles : markVisitModalStyles}
        >
          <GridContainer columns="1fr 24px">
            <Title style={{ width: "max-content" }}>
              Patient marked visited
            </Title>
            {isAddingItem ? (
              <CrossIcon onClick={() => setAddingItem(false)} />
            ) : (
              <PlusIcon onClick={() => setAddingItem(true)} />
            )}
          </GridContainer>

          {isAddingItem ? (
            <GridContainer columns="1fr">
              <GridContainer width="100%" justify="flex-start">
                <LightText>Enter the invoice details of the patient:</LightText>
                <br></br>
                <FormControl margin="dense" fullWidth>
                  <StyledInputLabel id="invoice-item-id">
                    Select Item
                  </StyledInputLabel>
                  <StyledSelect
                    labelId="invoice-item-id"
                    label="Select Item"
                    margin="dense"
                    onChange={(e) => setItem(e.target.value)}
                    value={item}
                  >
                    <MenuItem value="Facility fee">Facility fee</MenuItem>
                    <MenuItem value="Professional fee">
                      Professional fee
                    </MenuItem>
                    <MenuItem value="Diagnostic fees">Diagnostic fees</MenuItem>
                    <MenuItem value="Surgical fees">Surgical fees</MenuItem>
                    <MenuItem value="Medication fees">Medication fees</MenuItem>
                    <MenuItem value="Room Charges">Room Charges</MenuItem>
                    <MenuItem value="Administrative fees">
                      Administrative fees
                    </MenuItem>
                  </StyledSelect>
                </FormControl>
                <GridContainer columns="180px 120px">
                  <StyledTextField
                    margin="dense"
                    variant="outlined"
                    label="Rate"
                    value={rate}
                    onChange={(e) => setRate(e.target.value)}
                  ></StyledTextField>
                  <StyledTextField
                    margin="dense"
                    variant="outlined"
                    label="Quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  ></StyledTextField>
                </GridContainer>

                <StyledTextField
                  margin="dense"
                  variant="outlined"
                  label="Discount"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                ></StyledTextField>
              </GridContainer>
              <Button orange label="Add Item" onclick={handleAddItem}></Button>
            </GridContainer>
          ) : (
            <GridContainer columns="1fr">
              {itemBuffer.length !== 0 ? (
                <GridContainer columns="1fr">
                  {itemBuffer.map((curItem, index) => (
                    <GridContainer gap="16px" key={index} columns="1fr 24px">
                      <BorderedGridContainer
                        columns="3fr 1fr 1fr"
                        style={{ padding: "0.4rem 1rem", margin: "8px 0" }}
                      >
                        <LargeText style={{ width: "max-content" }}>
                          {curItem.item}
                        </LargeText>
                        <Container align="flex-start">
                          <Text>Rate</Text>
                          <LightText>{curItem.rate}</LightText>
                        </Container>
                        <Container align="flex-start">
                          <Text>Quantity</Text>
                          <LightText>{curItem.quantity}</LightText>
                        </Container>
                      </BorderedGridContainer>
                      <DeleteIcon
                        style={{ margin: "0" }}
                        small
                        onClick={() => handleRemoveItem(curItem.item)}
                      />
                    </GridContainer>
                  ))}
                </GridContainer>
              ) : (
                <GridContainer columns="1fr">
                  <LightText width="max-content">
                    Would you like to enter the Invoice details?
                  </LightText>
                  <NoRecords message="No Invoice items added"></NoRecords>
                  <br></br>
                  <GridContainer columns="1fr 1fr">
                    <RedTextButton onClick={() => setMarkVisitModal(false)}>
                      Cancel
                    </RedTextButton>
                    <TextButton onClick={() => setAddingItem(true)}>
                      Add Item
                    </TextButton>
                  </GridContainer>
                </GridContainer>
              )}
            </GridContainer>
          )}
        </ReactModal>
        {/* Invoice Modal */}
        <ReactModal
          style={isDarkTheme ? darkInvoiceModalStyles : InvoiceModalStyles}
          onRequestClose={closeRecieptModal}
          isOpen={recieptModal}
        >
          <div id="pdf-container" ref={componentRef}>
            <Invoice
              invoiceData={{
                charges: selectedCharges,
                advanceAmount: advanceAmount,
              }}
              appointmentData={appointmentData}
              hospitalData={hospitalData}
              hospitalStaff={authData?.fullName}
            />
          </div>
          <GridContainer
            style={{
              position: "fixed",
              bottom: "0",
              right: "0",
              padding: "0 3rem",
              background: "rgba(0,0,0,0.1)",
              width: "100%",
              zIndex: "999",
            }}
            justify="flex-end"
          >
            <FloatingButton onClick={handleDownloadPdf}>
              Print & Save
            </FloatingButton>
          </GridContainer>
        </ReactModal>

        {/* Modify Modal */}
        <ReactModal
          isOpen={modifyModal}
          onRequestClose={() => setModifyModal(false)}
          style={isDarkTheme ? darkMarkVisitModalStyles : markVisitModalStyles}
        >
          <GridContainer columns="1fr" gap="1rem" place="flex-start">
            <GridContainer
              columns="1fr min-content"
              justify="space-between"
              place="flex-start"
              align="flex-start"
            >
              <div>
                <Title style={{ width: "max-content" }}>
                  Appointment Details
                </Title>
                <Text style={{ textAlign: "start", width: "max-content" }}>
                  Status:&nbsp; {appointmentData?.status}
                </Text>
              </div>

              <CrossIcon onClick={() => setModifyModal(false)} />
            </GridContainer>
            <br></br>
            <GridContainer justify="flex-start" columns="1fr 10px">
              <GridContainer columns="1fr" justify="flex-start">
                <GridContainer
                  columns="1fr 1fr"
                  justify="flex-start"
                  align="flex-start"
                >
                  <LightText>Patient:</LightText>
                  <div>
                    <Text>
                      <strong>{appointmentData?.patient_name}</strong>
                    </Text>
                    <Text>{appointmentData?.patient_phone_number}</Text>
                  </div>
                </GridContainer>

                <HLine />

                <GridContainer
                  columns="1fr 1fr"
                  justify="flex-start"
                  align="flex-start"
                >
                  <LightText>Doctor:</LightText>
                  <div>
                    <Text>
                      <strong>{appointmentData?.doctor_name}</strong>
                    </Text>
                    <Text>{appointmentData?.doctor_phone_number}</Text>
                  </div>
                </GridContainer>

                <GridContainer
                  columns="1fr 1fr"
                  justify="flex-start"
                  align="flex-start"
                >
                  <LightText>Date & Time:</LightText>
                  <div></div>
                  <Text>
                    <strong>
                      {parseDate(appointmentData?.appointment_ts)}
                    </strong>
                  </Text>
                  <Text>
                    {changeTimeFormat(appointmentData?.appointment_ts)}
                  </Text>
                </GridContainer>
              </GridContainer>
            </GridContainer>
            <br></br>
            <Title>Reschedule appointment</Title>
            <br></br>
            <GridContainer margin="0 0 1rem 0" columns="1fr" rgap="1rem">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StyledDatePicker
                  variant="inline"
                  label="Enter the date"
                  minDate={dayjs()}
                  slotProps={{
                    textField: {
                      error: false,
                    },
                  }}
                  shouldDisableDate={disableDate}
                  value={reschduleDT}
                  onChange={(date) => {
                    setReschduleDT(date);
                    const selectedSchedule = docSlotsCopy.filter(
                      (ad) =>
                        ad.schedule_date === date.toISOString().split("T")[0]
                    );

                    setDocSlots(selectedSchedule);
                  }}
                  disabled={
                    appointmentData?.status === "Done" ||
                    appointmentData?.status === "Rejected"
                      ? true
                      : false
                  }
                />
              </LocalizationProvider>

              <FormControl fullWidth>
                <StyledInputLabel id="demo-simple-select-label">
                  Select Timeslot
                </StyledInputLabel>
                <StyledSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Timeslot"
                  value={slotId}
                  onChange={(e) => {
                    setSlotId(e.target.value);
                  }}
                >
                  <MenuItem>Select Timeslot</MenuItem>

                  {docSlots?.map((sch) => {
                    if (
                      sch.schedule_date ===
                      reschduleDT.toISOString().split("T")[0]
                    ) {
                      return sch.available_slots.map((slots) => {
                        return (
                          <MenuItem key={slots.slot_id} value={slots.slot_id}>
                            {handleTimeFormat(slots.start_time)}
                          </MenuItem>
                        );
                      });
                    }
                  })}
                </StyledSelect>
              </FormControl>
            </GridContainer>

            <GridContainer
              width="100%"
              columns="min-content min-content"
              justify="space-between"
            >
              <Button
                isloading={isRejectAppointmentLoading}
                red
                disabled={
                  appointmentData?.status === "Done" ||
                  appointmentData?.status === "Rejected"
                    ? true
                    : ""
                }
                label="Reject Visit"
                onclick={() =>
                  cancelStatus(
                    i,
                    appointmentData?.id,
                    appointmentData?.status,
                    appointmentData?.patient_name
                  )
                }
              />
              <Button
                isloading={isRescheduleAppointmentLoading}
                onclick={handleReschdule}
                blue
                disabled={
                  appointmentData?.status === "Done" ||
                  appointmentData?.status === "Rejected"
                    ? true
                    : ""
                }
                label="Update Appointment"
              ></Button>
            </GridContainer>
          </GridContainer>
        </ReactModal>

        <StyledTR key={i + 1}>
          <StyledTD>{i + 1}</StyledTD>
          <StyledTD>
            {appointmentData?.patient_name}
            <LightText>
              <small>{appointmentData?.patient_phone_number}</small>
            </LightText>
            <LightText>
              <small>
                {parseDate(appointmentData?.appointment_ts)}&nbsp;
                {changeTimeFormat(appointmentData?.appointment_ts)}
              </small>
            </LightText>
          </StyledTD>

          <StyledTD>{appointmentData?.doctor_name}</StyledTD>
          <StyledTD>
            {appointmentData?.status === "Done" && (
              <StatusDoneText>{appointmentData?.status}</StatusDoneText>
            )}
            {appointmentData?.status === "Confirmed" && (
              <StatusConfirmedText>
                {appointmentData?.status}
              </StatusConfirmedText>
            )}
            {appointmentData?.status === "Confirmation Pending" && (
              <StatusConfirmedText>
                {appointmentData?.status}
              </StatusConfirmedText>
            )}
            {appointmentData?.status === "Rejected" && (
              <StatusRejectedText>{appointmentData?.status}</StatusRejectedText>
            )}
          </StyledTD>
          <StyledTD>{appointmentData?.appointment_type}</StyledTD>
          <StyledTD style={{ width: "40%" }}>
            <GridContainer columns="repeat(auto-fill, minmax(110px, 1fr))">
              <Button
                isloading={isMarkVisitLoading}
                disabled={
                  appointmentData?.status === "Confirmation Pending" ||
                  (appointmentData?.status === "Done"
                    ? true
                    : "" || appointmentData?.status === "Rejected"
                    ? true
                    : "")
                }
                label="Mark Visit"
                onclick={() =>
                  toggleStatus(
                    i,
                    appointmentData?.id,
                    appointmentData?.status,
                    appointmentData?.patient_name
                  )
                }
              />

              <Button
                red
                label="Modify"
                disabled={
                  appointmentData?.status === "Done"
                    ? true
                    : "" || appointmentData?.status === "Rejected"
                    ? true
                    : ""
                }
                onclick={() => {
                  setModifyModal(true);
                  getDocSlots();
                }}
              />

              <Button
                orange
                disabled={appointmentData?.status !== "Done" ? true : ""}
                label={generated ? "View Invoice" : "Generate Invoice"}
                onclick={generated ? openRecieptModal : openGenerateModal}
              />
            </GridContainer>
          </StyledTD>
        </StyledTR>
      </>
    );
  }
);

export default AppointmentRow;
