//React imports
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

//Styled Components
import {
  MainContainer,
  CenterFlexContainer,
  CardContainer,
  ErrorContainer,
  SuccessContainer,
  DesktopOnly,
  MobileOnly,
  GridContainer,
} from "../../../Global";
import {
  Heading,
  StyledTable,
  StyledTR,
  StyledTH,
  TableWrapper,
  ScrollableContainer,
  AddIcon,
  TableHeader,
} from "../MainPages.elements";

//Components
import Button from "../../../components/ui/Button";
import SearchField from "../../../components/ui/SearchField";
import TaggedDoctorsRow from "./helpers/TaggedDoctorsRow";
import NoRecords from "../../../components/ui/NoRecords";
import DirectoryRow from "./helpers/DirectoryRow";
import HeaderCard from "../../../components/ui/HeaderCard";
import TaggedDocMobileCard from "./helpers/TaggedDocMobileCard";
import DirectoryMobileCard from "./helpers/DirectoryMobileCard";
import CardShimmering from "../../../components/ui/CardShimmering";
//Hooks
import { useScrollWithShadow } from "../../../hooks/useScrollShadow";

//RTK QUery
import { useGetDoctorsQuery } from "../../../api/userEndpoints/doctorsEndpoint";
import { useGetTaggedDoctorsQuery } from "../../../api/userEndpoints/taggedDoctorEndpoint";
import { useGetUsersQuery } from "../../../api/userEndpoints/usersEndpoint";
import { useLayoutEffect } from "react";

function ManageDoctors() {
  //rtk query
  const { data: doctors, isLoading: isdoctorLoading } = useGetDoctorsQuery();

  const { data: taggedDoctor, isLoading: isTaggedDoctorLoading } =
    useGetTaggedDoctorsQuery();

  const { data: users, isLoading: isUsersLoading } = useGetUsersQuery();

  //hooks

  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const { boxShadow, onScrollHandler } = useScrollWithShadow();

  const [dirError, setDirError] = useState();
  const [taggedError, setTagError] = useState();

  const [dirSuccess, setDirSuccess] = useState();
  const [taggedSuccess, setTagSuccess] = useState();

  const [doctorsData, setDoctorsData] = useState(doctors || []);
  const [taggedDoctorsData, setTaggedDoctorsData] = useState(
    taggedDoctor || []
  );

  const [selectedRows, setSelectedRows] = useState([]);

  //getting users for admin dropdown
  const [assistantName, setAssistantName] = useState();

  useEffect(() => {
    const filteredItems = doctors?.filter((user) => {
      return (
        user.name?.toLowerCase().includes(query.toLowerCase()) ||
        user.email?.toLowerCase().includes(query.toLowerCase())
      );
    });
    setDoctorsData(filteredItems);
  }, [query]);

  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele != value;
    });
  }

  function addSelectedRows(i) {
    setSelectedRows([...selectedRows, i]);
  }

  function removeSelectedRows(i) {
    const result = arrayRemove(selectedRows, i);
    setSelectedRows([...result]);
  }

  // function deleteUsers() {
  //   //TO DO:make delete multiple doctor API
  //   const newUsers = doctorsData.filter((item) => {
  //     return !selectedRows.includes(item.duid);
  //   });
  //   setDoctorsData(newUsers);
  //   setSelectedRows([]);
  // }

  function createTaggedDoctorRow(props, i) {
    return (
      <TaggedDoctorsRow
        key={i}
        addSelectedRows={addSelectedRows}
        removeSelectedRows={removeSelectedRows}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        index={i}
        docId={props.doctor_hospital_id}
        setDoctorsData={setTaggedDoctorsData}
        doctorsData={taggedDoctorsData}
        setSuccess={setTagSuccess}
        setError={setTagError}
      />
    );
  }

  function createDirectoryRow(props, i) {
    return (
      <DirectoryRow
        key={i}
        doctorsData={doctorsData}
        setDoctorsData={setDoctorsData}
        members={users}
        selectedRows={selectedRows}
        assistantName={assistantName}
        setAssistantName={setAssistantName}
        setSelectedRows={setSelectedRows}
        setSuccess={setDirSuccess}
        setError={setDirError}
        i={i}
      />
    );
  }

  function createDirectoryMobileCard(props, i) {
    return (
      <DirectoryMobileCard
        cardColumn="3fr 2fr"
        key={i}
        doctorsData={doctorsData}
        setDoctorsData={setDoctorsData}
        members={users}
        selectedRows={selectedRows}
        assistantName={assistantName}
        setAssistantName={setAssistantName}
        setSelectedRows={setSelectedRows}
        setSuccess={setDirSuccess}
        setError={setDirError}
        i={i}
      />
    );
  }

  function createTaggedDocMobileCard(props, i) {
    return (
      <TaggedDocMobileCard
        key={i}
        cardColumn="3fr 1fr 1fr"
        setDoctorsData={setTaggedDoctorsData}
        doctorsData={taggedDoctorsData}
        docId={props.doctor_hospital_id}
        setSuccess={setTagSuccess}
        setError={setTagError}
        index={i}
      ></TaggedDocMobileCard>
    );
  }

  //rerender upon data change
  useEffect(() => {
    setDoctorsData(doctors ?? []);
    setTaggedDoctorsData(taggedDoctor ?? []);
  }, [doctors, taggedDoctor]);

  if (isUsersLoading || isdoctorLoading || isTaggedDoctorLoading) {
    return <CardShimmering />;
  } else {
    return (
      <MainContainer>
        <MobileOnly>
          <Outlet />
          <CardContainer margin="0.2rem">
            <HeaderCard
              title="Doctors tagged to you"
              onclick={() => navigate("/doctors/add")}
              icon={
                location.pathname === "/doctors/add" ? "" : <AddIcon small />
              }
            />
            <ScrollableContainer
              onScroll={onScrollHandler}
              style={{
                boxShadow,
              }}
            >
              {taggedDoctorsData?.map(createTaggedDocMobileCard)}
            </ScrollableContainer>
            {taggedError && (
              <ErrorContainer>{taggedError ? taggedError : ""}</ErrorContainer>
            )}
            {taggedSuccess && (
              <SuccessContainer>
                {taggedSuccess ? taggedSuccess : ""}
              </SuccessContainer>
            )}
          </CardContainer>

          <CardContainer margin="0.2rem">
            <HeaderCard title="Doctors Directory" />
            <ScrollableContainer
              onScroll={onScrollHandler}
              style={{
                boxShadow,
              }}
            >
              {doctors?.length === 0 ? (
                <NoRecords message="Doctors Directory is Empty" />
              ) : (
                doctors?.map(createDirectoryMobileCard)
              )}
            </ScrollableContainer>
            {dirError && (
              <ErrorContainer>{dirError ? dirError : ""}</ErrorContainer>
            )}
            {dirSuccess && (
              <SuccessContainer>
                {dirSuccess ? dirSuccess : ""}
              </SuccessContainer>
            )}
          </CardContainer>
        </MobileOnly>

        <DesktopOnly>
          <GridContainer columns="1fr" margin="0 1.5rem" rgap="1.5rem">
            <GridContainer
              align="stretch"
              columns={location.pathname.includes("add") ? "3fr 1fr" : "1fr"}
              gap="1.5rem"
            >
              <CardContainer>
                <GridContainer columns="1fr" width="100%">
                  <CenterFlexContainer
                    align="flex-start"
                    justify="space-between"
                    width="100%"
                    margin="0 0 1rem 0"
                  >
                    <Heading>Doctors Tagged to you</Heading>
                    {location.pathname !== "/doctors/add" && (
                      <Button
                        blue
                        label="Add Doctor"
                        onclick={() => navigate("/doctors/add")}
                      />
                    )}
                  </CenterFlexContainer>

                  {taggedDoctorsData?.length === 0 ? (
                    <NoRecords message="No Doctors are tagged to you" />
                  ) : (
                    <>
                      <TableWrapper
                        onScroll={onScrollHandler}
                        style={{
                          boxShadow,
                        }}
                      >
                        <StyledTable>
                          <TableHeader>
                            <StyledTR>
                              <StyledTH>Doctor </StyledTH>
                              <StyledTH>Practice Charges </StyledTH>
                              <StyledTH>Next Available at </StyledTH>
                              <StyledTH>Shift Schedule </StyledTH>
                            </StyledTR>
                          </TableHeader>
                          <tbody>
                            {taggedDoctorsData?.map(createTaggedDoctorRow)}
                          </tbody>
                        </StyledTable>
                      </TableWrapper>
                      {taggedError && (
                        <ErrorContainer>
                          {taggedError ? taggedError : ""}
                        </ErrorContainer>
                      )}
                      {taggedSuccess && (
                        <SuccessContainer>
                          {taggedSuccess ? taggedSuccess : ""}
                        </SuccessContainer>
                      )}
                      <CenterFlexContainer
                        style={{ width: "100%", justifyContent: "flex-end" }}
                      ></CenterFlexContainer>
                    </>
                  )}
                </GridContainer>
              </CardContainer>
              <Outlet />
            </GridContainer>
            <CardContainer>
              <CenterFlexContainer
                width="100%"
                justify="space-between"
                margin="0 0 1rem 0"
              >
                <Heading>Doctors Directory</Heading>
                <SearchField
                  onchange={(e) => setQuery(e.target.value)}
                  value={query}
                  label="Search Users Here"
                ></SearchField>
              </CenterFlexContainer>

              {doctorsData.length === 0 ? (
                <NoRecords message="No Doctors in the Directory" />
              ) : (
                <TableWrapper
                  onScroll={onScrollHandler}
                  style={{
                    boxShadow,
                  }}
                >
                  <StyledTable>
                    <TableHeader>
                      <StyledTR>
                        <StyledTH>Sr. No </StyledTH>
                        <StyledTH>Doctor Name</StyledTH>
                        <StyledTH>Speciality </StyledTH>
                        <StyledTH>Next Available at </StyledTH>
                        <StyledTH>Shift Schedule </StyledTH>
                        <StyledTH>Admin Managed By </StyledTH>
                      </StyledTR>
                    </TableHeader>

                    <tbody>{doctorsData?.map(createDirectoryRow)}</tbody>
                  </StyledTable>
                </TableWrapper>
              )}
              {dirError && (
                <ErrorContainer>{dirError ? dirError : ""}</ErrorContainer>
              )}
              {dirSuccess && (
                <SuccessContainer>
                  {dirSuccess ? dirSuccess : ""}
                </SuccessContainer>
              )}
            </CardContainer>
          </GridContainer>
        </DesktopOnly>
      </MainContainer>
    );
  }
}

export default ManageDoctors;
