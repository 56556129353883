//React Imports
import React, {
  useState,
  useReducer,
  useEffect,
  memo,
  useContext,
} from "react";
import Modal from "react-modal";

//Styled Components
import {
  StyledTD,
  StyledTR,
  EditIcon,
  DeleteIcon,
  BtnWrap,
  Heading,
  Heading2,
  CrossIcon,
} from "../../MainPages.elements";
import {
  BorderedGridContainer,
  GridContainer,
  LightText,
  LargeText,
  TextButton,
  StyledInputLabel,
  StyledSelect,
  StyledTextField,
} from "../../../../Global";

//Components

import Button from "../../../../components/ui/Button";
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
} from "@mui/material";

//Hooks

//RTK Query
import {
  useDeleteSingleUserMutation,
  useUpdateUserMutation,
} from "../../../../api/userEndpoints/usersEndpoint";

//Util Functions
import { parseRole } from "../../../../utils/parseSchedule";
import AuthContext from "../../../../context/authProvider";

import {
  availabilityModalStyles,
  darkAvailabilityModalStyles,
} from "../../../../utils/ModalStyles";
import { gatingMessage } from "../../../../data/GatingMessage";

const UserTableRow = memo(
  ({
    setError,
    setSuccess,
    userData,
    addSelectedRows,
    removeSelectedRows,
    selectedRows,
    currentUser,
    i,
  }) => {
    const { subscribed, isDarkTheme } = useContext(AuthContext);
    const [deleteSingleUser, { isLoading: isDeleteUserLoading }] =
      useDeleteSingleUserMutation();
    const [updateUser, { isLoading: isUpdateUserLoading }] =
      useUpdateUserMutation();

    const [isSelected, setSelected] = useState(false);
    const [isEditable, setEdit] = useState(true);
    const [selectedRoles, setSelectedRoles] = useState(userData[i]?.roles);
    const [deleteUserModal, setDeleteUserModal] = useState(false);
    const isCurrentUser = currentUser?.id === userData[i]?.id;

    useEffect(() => {
      const cur = currentUser?.roles?.includes("board_member");
      const res = userData[i]?.roles?.includes("board_member");
      const isAdmin = currentUser?.roles?.includes("hospital_admin");
      if (cur === true && res === true) {
        setEdit(true);
      } else if (cur === true && res === false) {
        setEdit(true);
      } else if (cur === false && res === true) {
        setEdit(false);
      } else if (isAdmin === true && res === false) {
        setEdit(true);
      }
    }, []);
    // console.log(isEditable);
    const [modalIsOpen, setIsOpen] = useState(false);

    const [formData, setFormData] = useReducer(
      (state, newState) => ({ ...state, ...newState }),
      {
        name: userData[i]?.name,
        email: userData[i]?.email,
        phone_number: userData[i]?.phone_number,
        roles: userData[i]?.roles,
      }
    );

    const roles = [
      {
        value: "hospital_admin",
        label: "Hospital Admin",
      },
      { value: "support_member", label: "Support Team" },
      { value: "board_member", label: "Leadership Role" },
      { value: "doctor", label: "Doctor" },
    ];

    const handleFormDataChange = (event) => {
      const { name, value } = event.target;
      setFormData({ [name]: value });
    };
    const handleRolesChange = (event) => {
      const {
        target: { value },
      } = event;
      setSelectedRoles(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    };
    async function handleUpdateUser(i) {
      try {
        let result = await updateUser({
          id: userData[i].id,
          data: {
            email: formData.email,
            name: formData.name,
            phone_number: formData.phone_number,
            roles: selectedRoles,
          },
        });

        if (result.data || result.data === null) {
          setSuccess(`User: ${userData[i].name} updated successfully.`);
        } else if (result.error) {
          setError(
            result.error.data.message ||
              "Something went wrong,Please try again."
          );
        }
      } catch (err) {
        console.log(err);
        setError(err.data.message || "Something went wrong,Please try again.");
      }
      closeModal();
    }

    function openEditModal() {
      setIsOpen(true);
    }

    function closeModal() {
      setIsOpen(false);
    }

    function handleSelection(e) {
      if (e.target.checked) {
        addSelectedRows(userData[i].id);
      } else {
        removeSelectedRows(userData[i].id);
      }
    }
    async function deleteSingleRow() {
      try {
        let result = await deleteSingleUser(userData[i].id);
        if (result.data || result.data === null) {
          setError("");
          setSuccess(
            `User with name ${userData[i].name} deleted successfully.`
          );
          setDeleteUserModal(false);
        } else if (result.error) {
          setError(
            result.error.data.message ||
              "Couldn't delete the user,Please try again."
          );
          setSuccess("");
        }
      } catch (err) {
        console.log(err);
        setError("Couldn't delete the user,Please try again.");
        setSuccess("");
      }
    }

    useEffect(() => {
      if (selectedRows.includes(userData[i].id)) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }, [selectedRows]);

    const ackDeleteSingleUser = () => {
      setDeleteUserModal(true);
    };

    return (
      <StyledTR
        style={isCurrentUser ? { background: "#ADD8E644" } : {}}
        isSelected={isSelected}
        key={i + 1}
      >
        {/* Update User Modal */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={
            isDarkTheme ? darkAvailabilityModalStyles : availabilityModalStyles
          }
        >
          <GridContainer columns="1fr" rgap="1rem">
            <GridContainer align="flex-start" columns="1fr auto">
              <Heading style={{ width: "100%" }}>Update User Details</Heading>
              <CrossIcon onClick={closeModal} />
            </GridContainer>
            <StyledTextField
              margin="0"
              value={formData.name}
              onChange={handleFormDataChange}
              name="name"
              label="Your Name"
              required
            />
            <StyledTextField
              margin="0"
              value={formData.email}
              onChange={handleFormDataChange}
              name="email"
              message=""
              label="Your Email"
              required
            />
            <FormControl fullWidth>
              <StyledInputLabel id="role-label">Select Role</StyledInputLabel>
              <StyledSelect
                labelId="role-label"
                multiple
                value={selectedRoles}
                onChange={handleRolesChange}
                input={<OutlinedInput label="Select Roles" />}
                renderValue={(selected) =>
                  selected.map((obj) => parseRole(obj)).join(", ")
                }
              >
                {roles.map((obj) => (
                  <MenuItem key={obj.value} value={obj.value}>
                    <Checkbox checked={selectedRoles.indexOf(obj.value) > -1} />
                    <ListItemText primary={obj.label} />
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>

            <StyledTextField
              margin="0"
              value={formData.phone_number}
              onChange={handleFormDataChange}
              name="phone_number"
              label="Your Phone Number"
              required
            />
            <BtnWrap style={{ justifyContent: "flex-end" }}>
              <Button
                isloading={isUpdateUserLoading}
                disabled={isUpdateUserLoading}
                margin="0"
                onclick={() => handleUpdateUser(i)}
                label="Update User"
              ></Button>
            </BtnWrap>
          </GridContainer>
        </Modal>
        {/* Delete Acknowledgement Modal */}
        <Modal
          onRequestClose={() => setDeleteUserModal(false)}
          isOpen={deleteUserModal}
          style={
            isDarkTheme ? darkAvailabilityModalStyles : availabilityModalStyles
          }
        >
          <Heading2>Confirm Delete</Heading2>
          <LightText>
            Please Confirm if you want to delete the following User from your
            Directory.
          </LightText>
          <BorderedGridContainer padding="0.2rem 1rem" margin="8px 0">
            <GridContainer>
              <LargeText>
                {userData[i]?.name} <small>({userData[i]?.email})</small>
              </LargeText>
              <LightText>
                <small>
                  {userData[i]?.roles.map((r) => parseRole(r)).join()}
                </small>
              </LightText>
            </GridContainer>
          </BorderedGridContainer>
          <GridContainer justify="space-between" columns="auto auto">
            <TextButton onClick={() => setDeleteUserModal(false)}>
              Cancel
            </TextButton>
            <Button
              red
              label="Delete"
              premium={!subscribed}
              gatingMessage={gatingMessage.addMoreHospitalUserGM}
              onclick={deleteSingleRow}
            ></Button>
          </GridContainer>
        </Modal>
        <StyledTD>
          <input
            checked={isSelected}
            onChange={handleSelection}
            disabled={isCurrentUser}
            type="checkbox"
          ></input>
        </StyledTD>
        <StyledTD>{userData[i]?.name}</StyledTD>
        <StyledTD>
          {userData[i]?.roles.map((r) => parseRole(r)).join()}
        </StyledTD>
        <StyledTD>{userData[i]?.email}</StyledTD>

        <StyledTD>
          <GridContainer gap="1rem" columns="auto auto">
            <EditIcon disabled={!isEditable} onClick={openEditModal} />
            <DeleteIcon
              disabled={isDeleteUserLoading || !isEditable || isCurrentUser}
              onClick={ackDeleteSingleUser}
            />
          </GridContainer>
        </StyledTD>
      </StyledTR>
    );
  }
);

export default UserTableRow;
