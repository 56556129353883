//React Imports
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import styled from "styled-components";
//Icons
import { FiLogOut } from "react-icons/fi";

import {
  FaHome,
  FaFileMedical,
  FaUsers,
  FaBriefcaseMedical,
  FaUserInjured,
} from "react-icons/fa";

//Styled Components
import {
  CenterFlexContainer,
  GridContainer,
  LightText,
  TextButton,
} from "../../Global";
import {
  Img,
  Avatar,
  UserIcon,
  HeaderWrapper,
  BottomNavBtn,
  //nav elements
  NavMenu,
  NavItem,
  NavLinks,
  DropdownContent,
  NavDropDown,
  LogoWrap,
  NavbarContainer,
  ProfileContainer,
  LogoutContainer,
  LogoutText,
  BottomNavigation,
  HLine,
  Container,
  ProfileImg,
} from "../../pages/Admin/MainPages.elements";

//Components
import ProfileCard from "./ProfileCard";
import DoughnutChart from "./DougnnutChart";
//Hooks
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";
import useLogout from "../../hooks/useLogout";

//redux
import { useSelector } from "react-redux";
import { auth } from "../../reduxSlices/authSlice";

//RTK Query
import { useGetSingleUserQuery } from "../../api/userEndpoints/usersEndpoint";

import AuthContext from "../../context/authProvider";

//iicons

import PlanValidity from "./PlanValidity";
import { useGetActivePlanQuery } from "../../api/userEndpoints/authApiSlice";
import { ActiveHomeIcon, InactiveHomeIcon } from "./ui.elements";

import DarkModeToggle from "../animation/DarkModeToggle";

const ProgressBarTextContainer = styled(Container)`
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: #333;
  & > small {
    text-align: center;
  }
  & > p {
    text-align: center;
  }
`;

function Navbar() {
  const authData = useSelector(auth);
  const { subscribed, setDarkTheme, isDarkTheme } = useContext(AuthContext);
  const { data: singleUser } = useGetSingleUserQuery(authData?.user);
  const { data: activePlan } = useGetActivePlanQuery();

  const [profileDropDownState, setProfileDropDownState] = useState(false);
  const [planWarning, setPlanWarning] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const logout = useLogout();
  const dropDownRef = useRef(null);

  const signOut = async () => {
    await logout();

    navigate("/login");
  };

  //close drop down on outside click
  useDetectOutsideClick(dropDownRef, () => {
    setProfileDropDownState(false);
  });

  const data = {
    title: activePlan && activePlan[0]?.plan_name,
    labels: ["Days Elapsed", "Days Remaining"],
    values: [
      365 - (activePlan && activePlan[0]?.days_remaining),
      activePlan && activePlan[0]?.days_remaining,
    ],
    colors: ["#eee", "#36A2EB"],
  };

  const unsubscribedData = {
    title: "Your Current plan status",
    labels: ["Completed", "Remaining"],
    values: [100, 0],
    colors: ["#eee", "#36A2EB"],
  };

  useEffect(() => {
    if (activePlan && activePlan[0]?.days_remaining < 7) {
      setPlanWarning(true);
    }
  }, [activePlan]);

  return (
    <>
      {planWarning && !location.pathname.includes("plans") && (
        <PlanValidity
          plan={activePlan && activePlan[0]?.plan_name}
          validity={activePlan && activePlan[0]?.days_remaining}
        />
      )}

      <NavbarContainer
        planWarning={planWarning && !location.pathname.includes("plans")}
      >
        <HeaderWrapper>
          <NavDropDown
            onClick={() => {
              setProfileDropDownState(true);
            }}
            className={location.pathname === "/register-option" ? "active" : ""}
          >
            <Avatar>
              {singleUser?.profile_picture ? (
                <ProfileImg src={singleUser?.profile_picture} />
              ) : (
                <UserIcon />
              )}
            </Avatar>
            <ProfileCard name={singleUser?.name} />
          </NavDropDown>
          <CenterFlexContainer>
            <NavMenu>
              <NavItem>
                <NavLinks
                  className={
                    location.pathname.includes("appointments") ? "active" : ""
                  }
                  to="/appointments"
                >
                  Appointments
                </NavLinks>
              </NavItem>
              {singleUser?.roles?.includes("doctor") ? (
                <NavItem>
                  <NavLinks
                    className={
                      location.pathname === "/patients" ? "active" : ""
                    }
                    to="/patients"
                  >
                    Consultation
                  </NavLinks>
                </NavItem>
              ) : (
                ""
              )}

              <NavItem>
                <NavLinks
                  className={location.pathname === "/doctors" ? "active" : ""}
                  to="/doctors"
                >
                  Doctors
                </NavLinks>
              </NavItem>
              {singleUser?.roles?.includes("hospital_admin") ? (
                <NavItem>
                  <NavLinks
                    className={location.pathname === "/users" ? "active" : ""}
                    to="/users"
                  >
                    Users
                  </NavLinks>
                </NavItem>
              ) : (
                ""
              )}

              {/* <NavItem>
                <div className="darkModeToggle">
                  <DarkModeToggle
                    onClickMethod={setDarkTheme}
                    isDark={isDarkTheme}
                    setIsDark={setDarkTheme}
                  />
                </div>
              </NavItem> */}
              {/* <ToggleButton onClick={toggleTheme}>
                {isDarkTheme ? (
                  <FiSun style={{ color: "#F5994A" }} size={24} />
                ) : (
                  <FiMoon style={{ color: "#27374D" }} size={24} />
                )}
              </ToggleButton> */}

              {/* <NavItem>
                <label>
                  Subscribed:
                  <input
                    type="checkbox"
                    checked={subscribed}
                    onChange={(e) => setSubscribed(e.target.checked)}
                  />
                </label>
              </NavItem> */}
            </NavMenu>
            {location.pathname === "/admin" ||
            location.pathname === "/support" ? (
              <ActiveHomeIcon
                onClick={() =>
                  navigate(
                    singleUser?.roles.includes("hospital_admin")
                      ? "/admin"
                      : "/support"
                  )
                }
              />
            ) : (
              <InactiveHomeIcon
                onClick={() =>
                  navigate(
                    singleUser?.roles.includes("hospital_admin")
                      ? "/admin"
                      : "/support"
                  )
                }
              />
            )}

            <LogoWrap>
              <LightText style={{ fontSize: "11px" }}>powered by:</LightText>
              <Img />
            </LogoWrap>
          </CenterFlexContainer>
        </HeaderWrapper>
      </NavbarContainer>
      <DropdownContent
        ref={dropDownRef}
        state={profileDropDownState}
        onClick={() => {
          setProfileDropDownState(false);
        }}
      >
        <ProfileContainer>
          <NavLinks to="/profile">My Profile</NavLinks>

          <NavLinks to="/help">Help & Support</NavLinks>
          {singleUser?.roles?.includes("board_member") && (
            <>
              <HLine />
              <LightText>Your Current Plan Status </LightText>

              {!subscribed ? (
                <>
                  <div style={{ position: "relative" }}>
                    <ProgressBarTextContainer>
                      <h3 style={{ margin: "0" }}>No Plan</h3>

                      {/* <LightText>0 days remaining</LightText> */}
                    </ProgressBarTextContainer>
                    <DoughnutChart data={unsubscribedData} />
                  </div>
                  <TextButton
                    onClick={() => navigate("/plans")}
                    style={{ width: "calc(100% - 2rem)" }}
                    orange
                  >
                    Subscribe &nbsp; &#12297;
                  </TextButton>
                </>
              ) : (
                <>
                  <div style={{ position: "relative" }}>
                    <ProgressBarTextContainer>
                      <small>{activePlan && activePlan[0]?.plan_name}</small>

                      <small>
                        {activePlan && activePlan[0]?.days_remaining} days
                        remaining
                      </small>
                    </ProgressBarTextContainer>
                    <DoughnutChart data={data} />
                  </div>
                  <GridContainer
                    columns={
                      activePlan && activePlan[0]?.days_remaining < 15
                        ? "1fr 1fr"
                        : "1fr"
                    }
                    width="100%"
                  >
                    <TextButton
                      onClick={() => navigate("/plan/details")}
                      style={{ width: "calc(100% - 2rem)" }}
                    >
                      Plan Details &nbsp; &#12297;
                    </TextButton>
                    {activePlan && activePlan[0]?.days_remaining < 15 && (
                      <TextButton
                        onClick={() => navigate("/plans")}
                        style={{ width: "calc(100% - 2rem)" }}
                      >
                        Renew &nbsp; &#12297;
                      </TextButton>
                    )}
                  </GridContainer>
                </>
              )}
            </>
          )}
        </ProfileContainer>
        <LogoutContainer onClick={() => signOut()}>
          <LogoutText>Logout</LogoutText>
          <FiLogOut />
        </LogoutContainer>
      </DropdownContent>

      <BottomNavigation isAdmin={singleUser?.roles?.includes("hospital_admin")}>
        <BottomNavBtn
          to={
            singleUser?.roles.includes("hospital_admin") ? "/admin" : "/support"
          }
        >
          <FaHome
            className={
              location.pathname === "/admin" || location.pathname === "/support"
                ? "nav-btn-active"
                : ""
            }
            style={{ width: "48px" }}
          />
        </BottomNavBtn>

        <BottomNavBtn to="/appointments">
          <FaFileMedical
            className={
              location.pathname === "/appointments" ? "nav-btn-active" : ""
            }
            style={{ width: "48px" }}
          />
        </BottomNavBtn>

        <BottomNavBtn to="/doctors">
          <FaBriefcaseMedical
            className={location.pathname === "/doctors" ? "nav-btn-active" : ""}
            style={{ width: "48px" }}
          />
        </BottomNavBtn>
        {singleUser?.roles?.includes("hospital_admin") ? (
          <BottomNavBtn to="/users">
            <FaUsers
              className={location.pathname === "/users" ? "nav-btn-active" : ""}
              style={{ width: "48px" }}
            />
          </BottomNavBtn>
        ) : (
          ""
        )}
        {singleUser?.roles?.includes("doctor") ? (
          <BottomNavBtn to="/patients">
            <FaUserInjured
              className={
                location.pathname === "/patients" ? "nav-btn-active" : ""
              }
              style={{ width: "48px" }}
            />
          </BottomNavBtn>
        ) : (
          ""
        )}
      </BottomNavigation>
      <Outlet />
      {/* <GrowingCircleCanvas isDark={isDarkTheme} /> */}
    </>
  );
}

export default Navbar;
