import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";
import axios from "../../../api/axiosApi";
import TextField from "../../../components/ui/TextField";
import { useDetectOutsideClick } from "../../../hooks/useDetectOutsideClick";

const StyledComboBox = styled.div`
  position: relative;
  z-index: 2;
  /* Your combobox styles here */
`;

export const Message = styled.small`
  /* Your message styles here */
`;

const DropDown = styled.ul`
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;

  border-radius: 4px;
  max-height: 300px;
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 0;
  & > li {
    border-bottom: 1px solid #ccc;
    padding: 8px;
    &:hover {
      background-color: #eee;
      cursor: pointer;
    }
  }
`;

const PlacesAutoComplete = ({
  value,
  setSelected,
  setHospitalAddress,
  setHospitalName,
  setPlaceId,
  message,
  setCoordinates,
  required,
}) => {
  const [placesOption, setPlacesOption] = useState([]);
  const [dropDownState, setDropDownState] = useState(false);
  const dropDownRef = useRef(null);
  const handleAddressSelect = async (address) => {
    var selectedOpt = placesOption.filter((option) => {
      return option.name === address;
    });

    const geoCode = await getLatLong(selectedOpt[0].place_id);
    setPlaceId(selectedOpt[0].place_id);
    setHospitalAddress({ ["hAddress"]: selectedOpt[0].address });
    setHospitalName({ ["hName"]: selectedOpt[0].name });

    setSelected({ lat: geoCode.data.latitude, lng: geoCode.data.longitude });
    setPlacesOption([]);
  };

  function getQuery(query) {
    axios
      .get(
        `https://l1x3gkp512.execute-api.ap-south-1.amazonaws.com/test/google-place?action=autocomplete&q=${query}`
      )
      .then(function (response) {
        // handle success
        setPlacesOption(response.data);
      })
      .catch(function (error) {
        // handle error
      })
      .finally(function () {
        // always executed
      });
  }

  async function getLatLong(placeId) {
    try {
      const res = await axios.get(
        `https://l1x3gkp512.execute-api.ap-south-1.amazonaws.com/test/google-place?action=geocode&place_id=${placeId}`
      );
      setCoordinates(res.data);
      return res;
    } catch (e) {}
  }

  //close drop down on outside click
  useDetectOutsideClick(dropDownRef, () => {
    setDropDownState(false);
  });
  useEffect(() => {
    setDropDownState(true);
  }, [value]);

  return (
    <StyledComboBox>
      <TextField
        value={value}
        onchange={(e) => {
          setHospitalName({ ["hName"]: e.target.value });
          getQuery(e.target.value);
        }}
        className="phone-field"
        label="Hospital Name*"
        selectOnClick={true}
        required={required ? "required" : ""}
      />

      {placesOption.length > 0 && dropDownState && (
        <DropDown ref={dropDownRef}>
          {placesOption.map(({ place_id, address, name }) => (
            <li key={place_id} onClick={() => handleAddressSelect(name)}>
              <strong>{name}</strong>
              <br></br>
              <small>{address}</small>
            </li>
          ))}
        </DropDown>
      )}
    </StyledComboBox>
  );
};

export default PlacesAutoComplete;
