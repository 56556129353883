//packages and stylings
import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./Global";

//Authentication Pages
import LoginPage from "./pages/Auth/LoginPage";
import SignUpPage from "./pages/Auth/SignUpPage";

//Doctor Pages
import ManageDoctors from "./pages/Admin/Doctors/ManageDoctors";
import AddDoctor from "./pages/Admin/Doctors/AddDoctor";

//Users Pages
import AddUser from "./pages/Admin/Users/AddUser";
import ManageUser from "./pages/Admin/Users/ManageUser";

//Appointments Pages
import AddAppointment from "./pages/Admin/Appointments/AddAppointment";
import ManageAppointments from "./pages/Admin/Appointments/ManageAppointments";

//Static Pages
import ErrorPage from "./pages/static_pages/404";
import UnderProcess from "./pages/static_pages/UnderProcess";
import Unauthorized from "./pages/static_pages/Unauthorized";
import Help from "./pages/static_pages/Help";

//Admin Main Pages
import AdminPage from "./pages/Admin/AdminPage";
import Profile from "./pages/Admin/Profile";
import ManageDocPatients from "./pages/Admin/Patients/ManageDocPatients";
import DocPatientsDetails from "./pages/Admin/Patients/helpers/DocPatientsDetails";

//Support Member Main Pages
import SupportPage from "./pages/Support/SupportPage";

//Functional Components
import RequireAuth from "./pages/Auth/RequireAuth";
import PersistLogin from "./utils/PersistLogin";

//Navbar Component
import Navbar from "./components/ui/Navbar";

//Docor Component
import DocNavbar from "./components/ui/DocNavbar";
import DocSignUp from "./pages/Doctor/DocSignUp";
import Dashboard from "./pages/Doctor/Dashboard";
import PersistDoctor from "./utils/PersistDoctor";
import RequireDocAuth from "./utils/RequireDocAuth";

import ManagePatients from "./pages/Doctor/Patients/ManagePatients";
import DocProfile from "./pages/Doctor/DocProfile";
import PatientsDetails from "./pages/Doctor/Patients/helpers/PatientsDetails";
import PlansPage from "./pages/Leader/PlansPage";
import PlansDetails from "./pages/Leader/PlanDetails";
import AuthContext from "./context/authProvider";

//Images
import bg from "./assets/bg.svg";
import darkBg from "./assets/newdarkbg.svg";
import logo from "./assets/logo-nobg.png";
import darkLogo from "./assets/dark-logo.png";
import Payment from "./pages/Payment/Payment";

//React Quill Stylings
import "react-quill/dist/quill.snow.css";

function App() {
  const { isDarkTheme } = useContext(AuthContext);
  //theme
  const theme = {
    dark: false,
    colors: {
      curusBlue: "#25337D",
      fillColor: "#FFF",
      lightText: "#293845",
      curusOrange: "#F5994A",
      brightCurusOrange: "#EA7C27",
      curusGreen: "#23CC23",
      curusLightBlue: "#3C78FC",
      borderColor: "#DEE3EB",
      textColor: "#57636d",
      fieldColor: "#ffffff",
      lightTextColor: "#6c6c6c",
      tableHeader: "#efefef",
    },
    bg: bg,
    logo: logo,
  };

  const darkTheme = {
    dark: true,
    colors: {
      curusBlue: "#DDE6ED",
      fillColor: "#27374D",
      lightText: "#eeeeee",
      curusOrange: "#F5994A",
      brightCurusOrange: "#EA7C27",
      curusGreen: "#23CC23",
      curusLightBlue: "#526D82",
      borderColor: "#DEE3EB",
      textColor: "#DEE3EB",
      fieldColor: "#DEE3EB",
      lightTextColor: "#fff",
      tableHeader: "#666",
    },
    bg: darkBg,
    logo: darkLogo,
  };

  return (
    <div className="App">
      <ThemeProvider theme={isDarkTheme ? darkTheme : theme}>
        <GlobalStyle />

        <Router>
          <Routes>
            {/* missing route */}
            <Route path="*" element={<ErrorPage />}></Route>

            {/* public routes */}
            <Route path="/login" element={<LoginPage />}></Route>
            <Route path="/signup" element={<SignUpPage />}></Route>
            <Route path="/" element={<LoginPage />}></Route>

            <Route path="/test/payment" element={<Payment />}></Route>

            {/* Doctor routes */}
            <Route element={<PersistDoctor />}>
              <Route path="/doctor-signup" element={<DocSignUp />} />
              <Route path="/unauthorized" element={<Unauthorized />}></Route>
              <Route path="/underprocess" element={<UnderProcess />}></Route>
              <Route element={<RequireDocAuth />}>
                <Route element={<DocNavbar />}>
                  <Route path="/doctor" element={<Dashboard />}></Route>
                  <Route path="/managepatients" element={<ManagePatients />}>
                    <Route
                      path="/managepatients/:id"
                      element={<PatientsDetails />}
                    ></Route>
                  </Route>
                  <Route
                    path="/addconsultation"
                    element={<ManagePatients />}
                  ></Route>

                  <Route path="/docprofile" element={<DocProfile />}></Route>
                  <Route path="/dochelp" element={<Help />}></Route>
                </Route>
              </Route>
            </Route>
            <Route element={<PersistLogin />}>
              {/* protected routes for ADMIN */}
              <Route path="/unauthorized" element={<Unauthorized />}></Route>
              <Route path="/underprocess" element={<UnderProcess />}></Route>
              <Route element={<Navbar />}>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        "hospital_admin",
                        "curus_admin",
                        "board_member",
                      ]}
                    />
                  }
                >
                  <Route path="/admin" element={<AdminPage />}></Route>
                  <Route path="/patients" element={<ManageDocPatients />}>
                    <Route
                      path="/patients/:id"
                      element={<DocPatientsDetails />}
                    ></Route>
                  </Route>
                  <Route
                    path="/patients/consultation"
                    element={<ManageDocPatients />}
                  ></Route>

                  <Route path="/users" element={<ManageUser />}>
                    <Route path="/users/add" element={<AddUser />}></Route>
                  </Route>
                  <Route path="/plans" element={<PlansPage />}></Route>
                  <Route
                    path="/plan/details"
                    element={<PlansDetails />}
                  ></Route>
                </Route>
                {/* protected routes for Support Members */}

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        "hospital_admin",
                        "support_member",
                        "curus_admin",
                        "board_member",
                      ]}
                    />
                  }
                >
                  <Route path="/support" element={<SupportPage />}>
                    <Route
                      path="/support/addappointment"
                      element={<AddAppointment />}
                    />
                  </Route>
                  <Route path="/profile" element={<Profile />}></Route>

                  <Route path="/appointments" element={<ManageAppointments />}>
                    <Route
                      path="/appointments/add"
                      element={<AddAppointment />}
                    ></Route>
                  </Route>
                  <Route path="/doctors" element={<ManageDoctors />}>
                    <Route path="/doctors/add" element={<AddDoctor />}></Route>
                  </Route>
                  <Route path="/help" element={<Help />}></Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
