import styled from "styled-components";
import { CardContainer, GridContainer } from "../../Global";

export const AuthHeader = styled(GridContainer)`
  margin: 1rem 2rem 0 2rem;
  grid-template-columns: 1fr max-content;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    margin: 1rem;
  }
`;
export const DocSignUpContainer = styled(GridContainer)`
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 768px) {
    margin: 1rem;
    grid-template-columns: 1fr;
  }
`;
export const DocAuthContainer = styled(CardContainer)`
  width: 40%;
  margin: 3rem auto;
  @media screen and (max-width: 768px) {
    margin: 1rem 0.2rem;
    width: auto;
  }
`;
