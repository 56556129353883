import React from "react";
import {
  SelectFieldContainer,
  StyledSelectField,
  Message,
} from "./ui.elements";

export const SelectField = (props) => {
  function getOption(opt, i) {
    return (
      <option key={i} value={opt}>
        {opt}
      </option>
    );
  }
  return (
    <SelectFieldContainer active={props.active} width={props.width}>
      <StyledSelectField
        onChange={props.onchange}
        value={props.value}
        name={props.name}
        disabled={props.disabled ? "disabled" : ""}
        required={props.required ? "required" : ""}
        placeholder={props.label ? "required" : ""}
      >
        <option value="">{props.firstChoice}</option>

        {props.choices?.map(getOption)}
      </StyledSelectField>
      <Message>{props.message}</Message>
    </SelectFieldContainer>
  );
};

export default SelectField;
