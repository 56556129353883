import React, { useState, useEffect, memo, useContext } from "react";

//styled Components
import {
  CardContainer,
  MainContainer,
  GridContainer,
  ErrorContainer,
  SuccessContainer,
  DesktopOnly,
  MobileOnly,
} from "../../Global";
import { Heading } from "../Admin/MainPages.elements";

import {
  StyledTR,
  StyledTH,
  TableHeader,
  TableWrapper,
  StyledTable,
} from "../Admin/MainPages.elements";

//hooks
import { useScrollWithShadow } from "../../hooks/useScrollShadow";

//Components
import ShimmerCard from "../../components/ui/CardShimmering";
import NoRecords from "../../components/ui/NoRecords";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

//RTK-Query
import {
  useGetAppointmentsQuery,
  useGetPendingAppointmentsQuery,
} from "../../api/docEndpoints/appointmentEndpoints";

//Util
import DocAppointmentRow from "./helpers/DocAppointmentRow";
import DocMobileAppointmentRow from "./helpers/DocMobileAppointmentRow";

function Dashboard() {
  //rtk  query
  const { data: appointments, isLoading: isAppointmentLoading } =
    useGetAppointmentsQuery();
  const { data: pendingAppointments, isLoading: isPendingAppointmentLoading } =
    useGetPendingAppointmentsQuery();

  //-----------------States-------------------------

  const [alignment, setAlignment] = useState("all");
  const [aptError, setAptError] = useState("");
  const [aptSuccess, setAptSuccess] = useState("");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const { boxShadow, onScrollHandler } = useScrollWithShadow();

  function createAppointmentRow(props, i) {
    return (
      <DocAppointmentRow
        props={props}
        i={i}
        setAptError={setAptError}
        setAptSuccess={setAptSuccess}
      ></DocAppointmentRow>
    );
  }

  function createMobileRow(props, i) {
    return (
      <DocMobileAppointmentRow
        props={props}
        i={i}
        setAptError={setAptError}
        setAptSuccess={setAptSuccess}
      ></DocMobileAppointmentRow>
    );
  }

  if (isAppointmentLoading || isPendingAppointmentLoading) {
    return <ShimmerCard />;
  } else {
    return (
      <MainContainer>
        <DesktopOnly>
          <CardContainer margin="0 1.5rem">
            <GridContainer
              margin="0 0 1rem 0"
              columns="1fr min-content"
              width="100%"
            >
              <Heading>Appointments</Heading>
              <ToggleButtonGroup
                style={{
                  backgroundColor: "#fff",
                  height: "28px",
                }}
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton style={{ fontSize: "12px" }} value="all">
                  All
                </ToggleButton>
                <ToggleButton style={{ fontSize: "12px" }} value="pending">
                  Pending
                </ToggleButton>
              </ToggleButtonGroup>
            </GridContainer>

            {alignment === "all" ? (
              appointments?.length === 0 ? (
                <NoRecords message="No Appointments available" />
              ) : (
                <>
                  <DesktopOnly>
                    <TableWrapper
                      onScroll={onScrollHandler}
                      style={{
                        boxShadow,
                      }}
                    >
                      <StyledTable>
                        <TableHeader>
                          <StyledTR>
                            <StyledTH>Sr. No </StyledTH>
                            <StyledTH>Patient Name </StyledTH>
                            <StyledTH>Booked on</StyledTH>

                            <StyledTH>Status</StyledTH>
                            <StyledTH>Appointment Type </StyledTH>
                            <StyledTH>Actions </StyledTH>
                          </StyledTR>
                        </TableHeader>

                        <tbody>{appointments?.map(createAppointmentRow)}</tbody>
                      </StyledTable>
                    </TableWrapper>
                  </DesktopOnly>
                  <MobileOnly>{appointments?.map(createMobileRow)}</MobileOnly>
                </>
              )
            ) : pendingAppointments?.length === 0 ? (
              <NoRecords message="No Pending appointments avaialable" />
            ) : (
              <>
                <DesktopOnly>
                  <TableWrapper
                    onScroll={onScrollHandler}
                    style={{
                      boxShadow,
                    }}
                  >
                    <StyledTable>
                      <TableHeader>
                        <StyledTR>
                          <StyledTH>Sr. No </StyledTH>
                          <StyledTH>Patient Name </StyledTH>
                          <StyledTH>Booked on</StyledTH>

                          <StyledTH>Appointment Status </StyledTH>
                          <StyledTH>Appointment Type </StyledTH>
                          <StyledTH>Actions </StyledTH>
                        </StyledTR>
                      </TableHeader>

                      <tbody>
                        {pendingAppointments?.map(createAppointmentRow)}
                      </tbody>
                    </StyledTable>
                  </TableWrapper>
                </DesktopOnly>
                <MobileOnly>
                  {pendingAppointments?.map(createMobileRow)}
                </MobileOnly>
              </>
            )}

            {aptError && <ErrorContainer>{aptError}</ErrorContainer>}
            {aptSuccess && <SuccessContainer>{aptSuccess}</SuccessContainer>}
          </CardContainer>
        </DesktopOnly>
        <MobileOnly>
          <CardContainer margin="0.2rem">
            <GridContainer
              margin="0 0 1rem 0"
              columns="1fr min-content"
              width="100%"
            >
              <Heading>Appointments</Heading>
              <ToggleButtonGroup
                style={{
                  backgroundColor: "#fff",
                  height: "28px",
                }}
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
              >
                <ToggleButton style={{ fontSize: "12px" }} value="all">
                  All
                </ToggleButton>
                <ToggleButton style={{ fontSize: "12px" }} value="pending">
                  Pending
                </ToggleButton>
              </ToggleButtonGroup>
            </GridContainer>

            {alignment === "all" ? (
              appointments?.length === 0 ? (
                <NoRecords message="No Appointments available" />
              ) : (
                <>
                  <DesktopOnly>
                    <TableWrapper
                      onScroll={onScrollHandler}
                      style={{
                        boxShadow,
                      }}
                    >
                      <StyledTable>
                        <TableHeader>
                          <StyledTR>
                            <StyledTH>Sr. No </StyledTH>
                            <StyledTH>Patient Name </StyledTH>
                            <StyledTH>Booked on</StyledTH>

                            <StyledTH>Appointment Status </StyledTH>
                            <StyledTH>Appointment Type </StyledTH>
                            <StyledTH>Actions </StyledTH>
                          </StyledTR>
                        </TableHeader>

                        <tbody>{appointments?.map(createAppointmentRow)}</tbody>
                      </StyledTable>
                    </TableWrapper>
                  </DesktopOnly>
                  <MobileOnly>{appointments?.map(createMobileRow)}</MobileOnly>
                </>
              )
            ) : pendingAppointments?.length === 0 ? (
              <NoRecords message="No Pending appointments avaialable" />
            ) : (
              <>
                <DesktopOnly>
                  <TableWrapper
                    onScroll={onScrollHandler}
                    style={{
                      boxShadow,
                    }}
                  >
                    <StyledTable>
                      <TableHeader>
                        <StyledTR>
                          <StyledTH>Sr. No </StyledTH>
                          <StyledTH>Patient Name </StyledTH>
                          <StyledTH>Booked on</StyledTH>

                          <StyledTH>Appointment Status </StyledTH>
                          <StyledTH>Appointment Type </StyledTH>
                          <StyledTH>Actions </StyledTH>
                        </StyledTR>
                      </TableHeader>

                      <tbody>
                        {pendingAppointments?.map(createAppointmentRow)}
                      </tbody>
                    </StyledTable>
                  </TableWrapper>
                </DesktopOnly>
                <MobileOnly>
                  {pendingAppointments?.map(createMobileRow)}
                </MobileOnly>
              </>
            )}

            {aptError && <ErrorContainer>{aptError}</ErrorContainer>}
            {aptSuccess && <SuccessContainer>{aptSuccess}</SuccessContainer>}
          </CardContainer>
        </MobileOnly>
      </MainContainer>
    );
  }
}

export default Dashboard;
