import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { store } from "./app/store";
import { Provider } from "react-redux";
import { AuthProvider } from "./context/authProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
