import { apiSlice } from "../apiSlice";

export const consultationEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMyConsultation: builder.query({
      query: (id) =>
        `/api/consultation?patient_detail=${id}&consultation_by=self`,
      providesTags: ["Consultation"],
    }),
    getAllConsultation: builder.query({
      query: (data) => ({
        url: `/api/consultation?patient_detail=${data.id}`,
        headers: {
          IV: data.iv,
          Token: data.token,
        },
      }),
    }),
    getConsultationHistoryRequest: builder.query({
      query: (id) =>
        `/api/consultation?patient_detail=${id}&action=request&consultation_by=self`,
      providesTags: ["ConsultationHistory"],
    }),

    addConsultation: builder.mutation({
      query: (data) => ({
        url: `/api/consultation/`,
        method: "POST",
        body: {
          // doctor_phone_number: data.doctorPhoneNumber,
          indications: data.indications,
          medications: data.medications,
          precautions: data.precautions,
          patient_detail: data.patientDetail,
          lab_tests: data.labTests,
          next_checkup: data.nextCheckup,
          appointment_id: data.appointmentId,
        },
      }),
      invalidatesTags: ["Consultation"],
    }),
  }),
});

export const { useGetMyConsultationQuery } = consultationEndpoints;
export const { useGetAllConsultationQuery, useLazyGetAllConsultationQuery } =
  consultationEndpoints;
export const { useAddConsultationMutation } = consultationEndpoints;
