import { docApiSlice } from "../docApiSlice";

export const followUpEndpoints = docApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFollowUp: builder.query({
      query: (id) => `/follow-up?consultation_id=${id}`,
      providesTags: ["FollowUp"],
    }),

    addFollowUp: builder.mutation({
      query: (data) => ({
        url: `/follow-up`,
        method: "POST",
        body: {
          consultation_id: data.consultationId,
          changes: data.changes,
          next_follow_up: data.nextFollowUp,
        },
      }),
      invalidatesTags: ["FollowUp"],
    }),
  }),
});

export const { useGetFollowUpQuery } = followUpEndpoints;
export const { useAddFollowUpMutation } = followUpEndpoints;
