import {
  FaArrowDown,
  FaArrowUp,
  FaTimes,
  FaUser,
  FaPlusCircle,
  FaClock,
  FaCalendarCheck,
  FaPlus,
  FaMoneyCheckAlt,
} from "react-icons/fa";
import styled from "styled-components";
import { CenterFlexContainer, GridContainer } from "../../Global";
import {
  FaEdit,
  FaCheckCircle,
  FaUserEdit,
  FaUsers,
  FaUpload,
  FaArrowRight,
} from "react-icons/fa";

import { ImBin } from "react-icons/im";

import { FiExternalLink } from "react-icons/fi";
import {
  MdPersonRemove,
  MdPersonAddAlt1,
  MdOutlinePersonRemoveAlt1,
} from "react-icons/md";

import { GoKebabHorizontal } from "react-icons/go";

import { IoIosArrowDown } from "react-icons/io";
import { RxReload } from "react-icons/rx";
import { Link } from "react-router-dom";

export const Header = styled.header`
  position: fixed;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 5px 5px -4px rgba(0, 0, 0, 0.3);
  height: 80px;
  z-index: 10;
`;

export const HeaderWrapper = styled(CenterFlexContainer)`
  width: 100%;
  justify-content: space-between;
  /* padding: 0 2rem; */
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;
export const Nav = styled.nav`
  margin: auto;

  height: 80px;
  transition: 0.2s ease-in-out;
`;
export const NavWrapper = styled(CenterFlexContainer)`
  justify-content: space-between;
  height: 80px;
  z-index: 5;
`;

//profile card styled components

export const Avatar = styled(CenterFlexContainer)`
  width: 48px;
  height: 48px;
  border-radius: 50%;

  overflow: hidden;
  border: 2px solid ${(props) => props.theme.colors.borderColor};
  @media screen and (max-width: 768px) {
    width: 38px;
    height: 38px;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const Container = styled(CenterFlexContainer)`
  flex-direction: column;
`;

export const Text = styled.p`
  font-size: 16px;
  text-align: left;
  margin: 0;
  color: ${(props) => props.theme.colors.textColor};
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ImgWrap = styled(CenterFlexContainer)`
  max-height: 300px;
  max-width: 300px;
  flex-direction: column;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: 0.2rem 0;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  content: url(${(props) => props.theme.logo});
`;
export const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const UnderLine = styled.hr`
  width: 100%;
  height: 5px;
  background-color: #6558f5;
  border: none;
`;

export const UserIcon = styled(FaUser)`
  width: 50%;
  height: 50%;
  color: ${(props) => props.theme.colors.borderColor};
`;

export const BtnWrap = styled(CenterFlexContainer)`
  width: 100%;
  justify-content: ${(props) => (props.justify ? props.justify : "flex-start")};
  flex-wrap: wrap;
  margin-top: 1rem;
`;

export const HiddenMenu = styled(CenterFlexContainer)`
  width: 100%;

  position: absolute;
  display: ${(props) => (props.navState ? "block" : "none")};
  height: 100px;
  top: 48px;
  z-index: 2;
  transition: all 0.5s cubic-bezier(0.6, 0.04, 0.24, 0.68);
`;

export const AdminBody = styled.div`
  /* margin: 80px 0; */
  width: 100%;

  transition: all 0.5s cubic-bezier(0.6, 0.04, 0.24, 0.68);
`;

export const HLine = styled.hr`
  width: 99.5%;
  background-color: #ccc;

  border: 1px solid #ececec;
  margin: 8px 0;
`;
export const VLine = styled(CenterFlexContainer)`
  width: 3px;
  background-color: #9eadba;
  height: 100vh;
  border-radius: 5px;
  border: 1px solid #9eadba;
  margin: 1rem;
`;

export const UpArrow = styled(FaArrowUp)`
  height: 28px;
  width: 28px;
  color: ${(props) => props.theme.colors.curusBlue};
  margin: 0.7rem 1rem;
  transform: ${(props) => (props.navState ? "" : "rotate(180deg)")};
  transition: all 0.5s cubic-bezier(0.6, 0.04, 0.24, 0.68);
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusLightBlue};
  }
`;
export const DownArrow = styled(FaArrowDown)`
  height: 28px;
  width: 28px;
  color: ${(props) => props.theme.colors.curusBlue};
  margin: 0.7rem 1rem;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusLightBlue};
  }
`;

//header card
export const HeaderContainer = styled(CenterFlexContainer)`
  width: 100%;
  margin: 0 0 1rem 0;
  /* border: 2px solid ${(props) => props.theme.colors.borderColor}; */
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const TableHeaderWrapper = styled(CenterFlexContainer)`
  width: 100%;
  justify-content: space-between;
`;
export const Title = styled.p`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.curusBlue};
  font-weight: 600;
  font-size: 22px;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  width: 100%;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;
export const SubTitle = styled(Title)`
  color: #6c6c6c;
  font-weight: 500;

  font-size: 20px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

export const BlueText = styled(Link)`
  color: ${(props) => props.theme.colors.curusLightBlue};
  font-weight: 600;
  font-size: 16px;
  margin: 0;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusBlue};
  }
`;
export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  max-height: 62vh;
  min-height: 62vh;
  overflow: auto;
`;
export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
  background-color: transparent;
  margin-bottom: 2rem;
  /* table-layout: fixed; */
`;

export const TableHeader = styled.thead`
  height: 48px;
  position: sticky;
  top: 0;
  z-index: 1;
`;
export const StyledTH = styled.th`
  text-align: center;
  padding: 0.7rem;

  text-align: start;
  width: max-content;
  font-size: 15px;
  color: ${(props) => props.theme.colors.curusBlue};
  background-color: ${(props) => props.theme.colors.tableHeader};
`;

export const StyledTR = styled.tr`
  padding: 0.2rem;
  background-color: ${(props) =>
    props.isSelected ? "#eeeeee" : "transparent"};
  &:hover {
    background-color: ${(props) => (props.isSelected ? "" : "#f5f5f555")};
  }
  & > th:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  & > th:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
`;
export const StyledTD = styled.td`
  text-align: start;
  position: relative;
  align-items: start;
  padding: 0.7rem;
  color: ${({ theme }) => theme.colors.textColor};
  background-color: transparent;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

//status text
export const StatusText = styled.p`
  display: flex;
  align-items: center;
  width: max-content;
  justify-content: center;
  text-align: center;
  padding: 0.2rem 0.5rem;
  border-radius: 14px;
  text-align: start;
  font-weight: 500;
  margin: 0%;
  @media screen and (max-width: 768px) {
    font-size: 11px;
    font-weight: 400;
  }
`;
export const StatusDoneText = styled(StatusText)`
  background-color: #dffcd8;
  border-radius: 14px;
  border: 1px solid #0faf33;
  color: #0faf33;
`;
export const StatusRejectedText = styled(StatusText)`
  background-color: rgba(255, 204, 203, 0.7);
  border-radius: 14px;

  border: 1px solid #ff2a26;
  color: #ff2a26;
`;
export const StatusConfirmedText = styled(StatusText)`
  background-color: #faffbf;
  border-radius: 14px;
  text-align: center;
  color: #ffa611;
  border: 1px solid #ffa611;
`;

export const Heading = styled.h1`
  font-size: 24px;
  font-weight: 500;
  align-items: center;
  display: flex;
  gap: 0.5rem;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  width: ${(props) => props.width};
  color: ${(props) => props.theme.colors.curusBlue};
  @media screen and (max-width: 768px) {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
  }
`;

export const Heading2 = styled.h2`
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  color: ${(props) => props.theme.colors.curusBlue};
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 20px;
    margin: 0 0 8px 0;
  }
`;

export const FormContainer = styled(CenterFlexContainer)`
  flex-wrap: wrap;
  margin: 0.7rem 2rem;
  padding: 2rem;
  justify-content: start;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  @media screen and (max-width: 768px) {
    padding: 1rem;
    margin: 0.7rem;
  }
`;

export const EditIcon = styled(FaEdit)`
  height: 28px;
  width: 28px;
  color: ${(props) =>
    props.disabled ? "grey" : props.theme.colors.brightCurusOrange};
  @media screen and (max-width: 768px) {
    height: 18px;
    width: 18px;
  }
  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};

    color: ${(props) =>
      props.disabled ? "grey" : props.theme.colors.curusOrange};
  }
`;
export const MenuIcon = styled(GoKebabHorizontal)`
  height: 28px;
  width: 28px;
  transform: rotate(90deg);
  margin: 0.7rem 0 0.7rem 10px;
  color: ${(props) => props.theme.colors.curusBlue};
  @media screen and (max-width: 768px) {
    height: 18px;
    width: 18px;
  }
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusLightBlue};
  }
`;
export const DeleteIcon = styled(ImBin)`
  height: ${(props) => (props.small ? "18px" : "28px")};
  width: ${(props) => (props.small ? "18px" : "28px")};

  color: ${(props) => (props.disabled ? "grey" : "red")};
  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    color: ${(props) => (props.disabled ? "grey" : "#ec6b6b")};
  }
`;
export const CheckIcon = styled(FaCheckCircle)`
  height: ${(props) => (props.small ? "18px" : "24px")};
  width: ${(props) => (props.small ? "18px" : "24px")};
  margin: 0.7rem 1rem;

  color: ${(props) =>
    props.disabled ? "grey" : props.theme.colors.curusGreen};
  &:hover {
    cursor: ${(props) => (props.disabled ? "grey" : "pointer")};
    color: ${(props) => (props.disabled ? "grey" : "#94ec6b")};
  }
`;
export const RedirectIcon = styled(FiExternalLink)`
  height: 22px;
  width: 22px;
  color: ${(props) => props.theme.colors.curusBlue};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusLightBlue};
  }
  @media screen and (max-width: 768px) {
    height: 18px;
    width: 18px;
    margin: 0 0.7rem;
  }
`;
export const ReloadIcon = styled(RxReload)`
  height: 22px;
  width: 22px;
  margin: 0 0.7rem;

  margin: 0;
  color: ${(props) => props.theme.colors.curusBlue};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusLightBlue};
  }
  @media screen and (max-width: 768px) {
    height: 18px;
    width: 18px;
    margin: 0 0.7rem;
  }
`;

export const AddUserIcon = styled(MdPersonAddAlt1)`
  height: 36px;
  width: 36px;
  margin: 0 1rem;
  color: ${(props) => props.theme.colors.curusBlue};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusLightBlue};
  }
`;
export const UploadIcon = styled(FaUpload)`
  height: 24px;
  width: 24px;
  margin: 0 2rem;
  color: ${(props) => props.theme.colors.curusBlue};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusLightBlue};
  }
`;
export const RemoveUserIcon = styled(MdPersonRemove)`
  height: 36px;
  width: 36px;
  margin: 0 1rem;
  color: ${(props) => props.theme.colors.curusBlue};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusLightBlue};
  }
`;

export const EditUserIcon = styled(FaUserEdit)`
  height: 36px;
  width: 36px;
  margin: 0 1rem;
  color: ${(props) => props.theme.colors.curusBlue};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusLightBlue};
  }
`;
export const ActiveUsersIcon = styled(FaUsers)`
  height: 36px;
  width: 36px;
  margin: 0 1rem;
  color: ${(props) => props.theme.colors.curusBlue};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusLightBlue};
  }
`;

export const HighlitedText = styled.h3`
  color: ${(props) => props.theme.colors.brightCurusOrange};
  font-weight: 600;
  font-size: 24px;
  margin: 1rem 0;
`;

//navbar elements
export const NavMenu = styled.ul`
  display: flex;
  padding: 0;
  align-items: center;
  text-align: center;
  list-style: none;
  margin: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;
export const NavItem = styled.li`
  height: 50px;
  height: 100%;
  > a {
    &:hover {
      background-color: transparent;
    }
  }
`;

export const NavLinks = styled(Link)`
  margin: 0;
  color: ${(props) => props.theme.colors.curusBlue};
  font-size: 16px;
  text-decoration: none;
  align-items: center;
  padding: 0.5rem;
  height: 100%;
  width: 100%;
  cursor: pointer;
  font-weight: ${(props) => props.state};
  &:hover {
    color: ${(props) => props.theme.colors.curusLightBlue};
    /* background-color: #dee3eb; */
  }
`;

export const LogoutContainer = styled(CenterFlexContainer)`
  background-color: ${(props) => props.theme.colors.borderColor};
  color: #6c6c6c;
  flex: 1;
  margin: 0;
  justify-content: space-between;
  padding: 0.7rem 1rem;
  overflow: hidden;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusBlue};
    > p {
      color: ${(props) => props.theme.colors.curusBlue};
    }
  }
`;

export const LogoutText = styled.p`
  font-weight: 600;
  color: #6c6c6c;
  margin: 0;
  &:hover {
    /* color: ${(props) => props.theme.colors.curusLightBlue}; */
  }
`;

export const NavDropDown = styled.span`
  display: grid;
  grid-template-columns: 50px 1fr;
  font-size: 14px;
  justify-content: space-around;
  color: #fff;
  text-decoration: none;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 100%;
  cursor: pointer;
  font-weight: ${(props) => props.state};

  &:hover {
    color: #ccc;
  }
  @media screen and (max-width: 768px) {
    padding: 0;
    grid-template-columns: 40px 1fr;
  }
`;
export const DropdownContent = styled.div`
  position: fixed;

  width: max-content;
  top: 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.24);
  display: ${(props) => (props.state ? "block" : "none")};
  transition: all 0.5s cubic-bezier(0.6, 0.04, 0.24, 0.68);
  left: 2rem;
  border: 2px solid ${(props) => props.theme.colors.borderColor};
  border-radius: 14px;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.borderColor};
  z-index: 99;

  @media screen and (max-width: 768px) {
    top: 80px;
    left: 0.2rem;
  }
`;

export const ProfileContainer = styled(CenterFlexContainer)`
  background-color: ${(props) => props.theme.colors.fillColor};
  border-radius: 14px;
  margin: 0.2rem;
  padding: 0 0.7rem;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
`;

export const FlexBoxContainer = styled(CenterFlexContainer)`
  width: 100%;
  align-items: stretch;
  @media screen and (max-width: 1248px) {
    flex-direction: column;
  }
`;

export const MobileContainer = styled.div`
  margin: 0 1rem;
  & > div {
    width: 100%;
    margin: auto;
  }
  @media screen and (max-width: 768px) {
    width: 100;
  }
`;

export const LogoWrap = styled(CenterFlexContainer)`
  max-height: 200px;
  max-width: 70px;
  flex-direction: column;

  align-items: start;
  width: 100px;
  @media screen and (max-width: 768px) {
    width: 85px;
  }
`;

export const ItemContainer = styled(CenterFlexContainer)`
  border: 2px solid ${(props) => props.theme.colors.borderColor};
  border-radius: 35px;
  color: #6c6c6c;
  width: min-content;
  padding: 0.5rem;
  margin: 0.2rem;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusBlue};
    border: 2px solid ${(props) => props.theme.colors.curusBlue};
    & > div {
      color: ${(props) => props.theme.colors.curusBlue};
    }
  }
`;

export const RemoveIcon = styled(FaTimes)`
  color: inherit;
  height: 16px;
  width: 16px;
  margin: 0 0.2rem;
  &:hover {
    color: ${(props) => props.theme.colors.curusBlue};
  }
`;

export const AddIcon = styled(FaPlusCircle)`
  color: #6c6c6c;
  height: ${(props) => (props.small ? "20px" : "36px")};
  width: ${(props) => (props.small ? "20px" : "36px")};
  margin: 0.3rem;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusBlue};
  }
  @media screen and (max-width: 768px) {
    height: 24px;
    width: 24px;
  }
`;
export const PlusIcon = styled(FaPlus)`
  color: #6c6c6c;
  height: 24px;
  width: 24px;
  margin: 0;
  color: ${(props) => props.theme.colors.curusLightBlue};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusBlue};
  }
  @media screen and (max-width: 768px) {
    height: 24px;
    width: 24px;
  }
`;
export const CrossIcon = styled(FaTimes)`
  color: #6c6c6c;
  height: 24px;
  width: 24px;
  margin: 0;
  color: ${(props) => props.theme.colors.curusLightBlue};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.colors.curusBlue};
  }
  @media screen and (max-width: 768px) {
    height: 24px;
    width: 24px;
  }
`;

export const MSContainer = styled(CenterFlexContainer)`
  max-width: 300px;
  flex-wrap: wrap;
  justify-content: start;
  @media screen and (max-width: 768px) {
    max-width: 1000px;
    width: 100%;
  }
`;

export const NavbarContainer = styled(CenterFlexContainer)`
  flex-wrap: wrap;

  flex: 1;
  margin: ${(props) =>
    props.planWarning ? "65px 1.5rem 1.5rem 1.5rem" : "1.5rem"};
  padding: 0.7rem 1.5rem;
  justify-content: start;
  align-items: flex-start;
  background-color: ${(props) => props.theme.colors.fillColor};
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);

  @media screen and (max-width: 768px) {
    /* width: 100%; */
    padding: 0.7rem 1rem;
    margin: ${(props) =>
      props.planWarning ? "59px 0.2rem 0 0.2rem" : "0.2rem 0.2rem 0 0.2rem"};
    box-shadow: 0px 15px 10px -15px #111;
  }
`;

export const AddPersonIcon = styled(MdPersonAddAlt1)`
  height: 32px;
  width: 32px;
  margin: 0.5rem;
  color: #6c6c6c;
  transition: 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    color: #9c9c9c;
  }
`;

export const RemovePersonIcon = styled(MdOutlinePersonRemoveAlt1)`
  height: 32px;
  width: 32px;
  margin: 0.5rem;

  color: #6c6c6c;
  transition: 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    color: #9c9c9c;
  }
`;

export const SelectFieldContainer = styled(CenterFlexContainer)`
  margin: 1rem 0;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FilterDropDown = styled.div`
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  right: 40px;
  top: 15%;
  background-color: #eee;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.24);
  display: ${(props) => (props.dropDownState ? "flex" : "none")};
  @media screen and (max-width: 768px) {
    top: 15%;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    left: 0;
    right: 0;
  }
`;

export const FilterContainer = styled(CenterFlexContainer)`
  background-color: #fff;
  border-radius: 14px;
  margin: 0.3rem 0.2rem;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  gap: 0.7rem;
  & > div > div {
    justify-content: stretch;
  }
`;
export const RightArrow = styled(FaArrowRight)`
  color: #6c6c6c;
`;

export const CardHeading = styled.h1`
  width: 100%;
  color: #424242;
  font-size: 36px;
  text-align: center;
  margin: 0 0 1rem 0;
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

// Mobile Responsive components

export const BottomNavigation = styled.div`
  display: none;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background-color: #fff;
  z-index: 99;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.8);
  @media screen and (max-width: 768px) {
    display: grid;
  }
`;

export const DocBottomNav = styled(BottomNavigation)`
  grid-template-columns: 1fr 1fr;
`;

export const BottomNavBtn = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  color: grey;
  font-size: 18px;
  text-decoration: none;
  align-items: center;
  padding: 0.5rem;

  cursor: pointer;
  font-weight: ${(props) => props.state};
  &:hover {
    color: ${(props) => props.theme.colors.curusLightBlue};
  }
`;
export const MobileCardWrapper = styled.div`
  padding: 8px;
  background-color: transparent;
  border-radius: 8px;
  margin: 0;
  height: ${(props) =>
    props.expanded ? "120px" : props.expandable ? "60px" : "auto"};
  transition: 0.2s ease-in-out;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;
`;
export const MobileCard = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.cardColumn};
  grid-template-rows: 1fr;
  place-content: start;
  gap: 1rem;

  /* height: 100%; */
  background-color: inherit;
  border-radius: 8px;

  transition: 0.2s ease-in-out;
`;

export const MobileCardContainer = styled.div`
  margin: 0;
  display: none;
  background-color: #fff;
  margin: 1rem 0.2rem;
  padding-bottom: 1rem;
  border-radius: 20px;
  position: relative;
  transition: 0.2s ease-in-out;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const MobileCardTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.curusBlue};
  margin: 0;
  text-align: left;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    font-weight: 600;
  }
`;

export const MobileCardText = styled.p`
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  text-align: left;
`;
export const MobileCardSmallText = styled.p`
  font-size: 11px;
  font-weight: 400;
  margin: 0;
  color: ${(props) => props.theme.colors.lightTextColor};
  text-align: left;
  line-height: 12px;
`;

export const ScrollableContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${(props) => (props.height ? props.height : "400px")};
  margin-top: 8px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  width: 100%;

  /* margin: 0.7rem 0 1rem 0; */
`;

export const CardArrow = styled(IoIosArrowDown)`
  color: ${(props) => props.theme.colors.borderColor};
  margin-top: 0.5rem;
  transition: 0.2s ease-in-out;
  display: ${(props) => (props.expanded ? "none" : "block")};
`;
export const ClockIcon = styled(FaClock)`
  width: 24px;
  height: 24px;
  margin: 0.2rem 0rem;
  color: ${(props) => props.theme.colors.curusBlue};
  &:hover {
    cursor: pointer;
  }
`;
export const MoneyIcon = styled(FaMoneyCheckAlt)`
  width: 24px;
  height: 24px;
  margin: 0.2rem 0rem;
  color: #5dad55;
  &:hover {
    cursor: pointer;
  }
`;
export const CalendarIcon = styled(FaCalendarCheck)`
  width: 24px;
  height: 24px;
  margin: 0.2rem 1rem;

  color: ${(props) => props.theme.colors.brightCurusOrange};
  &:hover {
    cursor: pointer;
  }
`;

//Schedule Card Components
export const SHContainer = styled.div`
  margin: auto;
  max-width: 700px;
  margin: 8px 0;
`;

export const SHWrapper = styled(CenterFlexContainer)`
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

export const SHHeader = styled(CenterFlexContainer)`
  position: sticky;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 1rem 0;
`;

/* Tab Container */
export const Tabs = styled.div`
  height: auto;
  background: #fff;
  margin: 0 auto 1.5rem auto;
  padding: 0;
  color: ${(props) => props.theme.colors.curusLightBlue};
  border: 1px solid ${(props) => props.theme.colors.curusLightBlue};
  border-radius: 2rem;
  @media (max-width: 769px) {
    margin: 0 auto 1rem auto;
    font-size: 12px;
    line-height: 12px;
  }
  ul.nav {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-left: 0px;
  }

  ul.nav li {
    width: calc(100% - 2rem);
    padding: 0.7rem 1rem;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.4s;
    text-decoration: none;
  }

  ul.nav li:first-child {
    border-bottom-left-radius: 2rem;
    border-top-left-radius: 2rem;
  }

  ul.nav li:last-child {
    border-bottom-right-radius: 2rem;
    border-top-right-radius: 2rem;
  }

  ul.nav li:hover {
    background: #eee;
  }

  ul.nav li.active {
    background: ${(props) => props.theme.colors.curusLightBlue};
    color: #fff;
    font-weight: 400;
  }
`;

export const SchTileContainer = styled(GridContainer)`
  border: 1px solid ${(props) => props.theme.colors.borderColor};
  border-radius: 4px;
  row-gap: 1rem;
  grid-template-columns: 1fr;
  padding: 0.7rem 1rem;
`;
//Invoice Pdf
export const InvoiceHeading = styled(Heading)`
  color: black;
  max-width: 400px;
`;

export const InvoiceText = styled(Text)`
  color: black;
  font-size: 16px;
`;

export const InvoiceHeading2 = styled(Heading2)`
  color: black;
`;

export const InvoiceTH = styled(StyledTH)`
  background-color: #eee;
  color: #222;
`;

export const ToggleButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`;

export const InvoiceContainer = styled(GridContainer)`
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

//Patients Suggestion Drop Down
export const PatientsSuggestion = styled.ul`
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #ccc;
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  max-height: 400px;
  overflow: auto;
  margin: 8px 0;
  z-index: 10;
  li {
    margin: 0;
    padding: 0.7rem 8px;
    border-bottom: 1px solid #eee;
    &:hover {
      background-color: #eee;
      cursor: pointer;
    }
  }
  b {
    color: ${(props) => props.theme.colors.curusBlue};
    display: flex;
    font-size: 18px;
  }
  small {
    font-size: 12px;
    color: #222;
  }
`;
