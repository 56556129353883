//React Imports
import React, { useRef, useState } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";

//Icons
import { FiLogOut } from "react-icons/fi";
import { FaHome, FaUserInjured } from "react-icons/fa";

//Styled Components
import { CenterFlexContainer } from "../../Global";
import {
  Img,
  Avatar,
  UserIcon,
  HeaderWrapper,
  BottomNavBtn,
  //nav elements
  NavMenu,
  NavItem,
  NavLinks,
  DropdownContent,
  NavDropDown,
  LogoWrap,
  NavbarContainer,
  ProfileContainer,
  LogoutContainer,
  LogoutText,
  DocBottomNav,
} from "../../pages/Admin/MainPages.elements";

//Components
import ProfileCard from "./ProfileCard";
import DarkModeToggle from "../animation/DarkModeToggle";
//Hooks
import { useDetectOutsideClick } from "../../hooks/useDetectOutsideClick";

//RTK Query
import { useGetDocQuery } from "../../api/docEndpoints/doctorEndpoint";
import CardShimmering from "./CardShimmering";
import useDocLogout from "../../hooks/useDocLogout";
import AuthContext from "../../context/authProvider";
import { useContext } from "react";

function DocNavbar() {
  const { isDarkTheme, setDarkTheme } = useContext(AuthContext);

  const { data: singleDoc, isLoading: isSingleDocLoading } = useGetDocQuery();

  const [profileDropDownState, setProfileDropDownState] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const dropDownRef = useRef(null);
  const logOut = useDocLogout();
  const signOut = async () => {
    await logOut();
    navigate("/login");
  };

  //close drop down on outside click
  useDetectOutsideClick(dropDownRef, () => {
    setProfileDropDownState(false);
  });

  if (isSingleDocLoading) {
    return <CardShimmering />;
  } else {
    return (
      <>
        <NavbarContainer>
          <HeaderWrapper>
            <NavDropDown
              onClick={() => {
                setProfileDropDownState(true);
              }}
              className={
                location.pathname === "/register-option" ? "active" : ""
              }
            >
              <Avatar>
                <UserIcon />
              </Avatar>
              <ProfileCard name={"Dr. " + singleDoc?.name} />
            </NavDropDown>
            <CenterFlexContainer>
              <NavMenu>
                <NavItem>
                  <NavLinks
                    className={location.pathname === "/doctor" && "active"}
                    to="/doctor"
                  >
                    Dashboard
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    className={
                      location.pathname === "/addconsultation" && "active"
                    }
                    to="/addconsultation"
                  >
                    Add Consultations
                  </NavLinks>
                </NavItem>
                <NavItem>
                  <NavLinks
                    className={
                      location.pathname === "/managepatients" ? "active" : ""
                    }
                    to="/managepatients"
                  >
                    View Patient History
                  </NavLinks>
                </NavItem>
                {/* <NavItem>
                  <div className="darkModeToggle">
                    <DarkModeToggle
                      onClickMethod={setDarkTheme}
                      isDark={isDarkTheme}
                      setIsDark={setDarkTheme}
                    />
                  </div>
                </NavItem> */}

                {/* <NavItem>
                  <ToggleButton onClick={toggleTheme}>
                    {isDarkTheme ? (
                      <FiSun style={{ color: "#F5994A" }} size={24} />
                    ) : (
                      <FiMoon style={{ color: "#27374D" }} size={24} />
                    )}
                  </ToggleButton>
                </NavItem> */}
                {/* <NavItem>
                <NavLinks
                  className={
                    location.pathname === "/docappointments" ? "active" : ""
                  }
                  to="/docappointments"
                >
                  Appointments
                </NavLinks>
              </NavItem> */}
              </NavMenu>
              <LogoWrap>
                <p style={{ margin: "0", color: "#6c6c6c", fontSize: "11px" }}>
                  powered by:
                </p>
                <Img />
              </LogoWrap>
            </CenterFlexContainer>
          </HeaderWrapper>
        </NavbarContainer>
        <DropdownContent
          ref={dropDownRef}
          state={profileDropDownState}
          onClick={() => {
            setProfileDropDownState(false);
          }}
        >
          <ProfileContainer>
            <NavLinks to="/docprofile">My Profile</NavLinks>

            <NavLinks to="/dochelp">Help & Support</NavLinks>
          </ProfileContainer>
          <LogoutContainer onClick={() => signOut()}>
            <LogoutText>Logout</LogoutText>
            <FiLogOut />
          </LogoutContainer>
        </DropdownContent>

        <DocBottomNav>
          <BottomNavBtn to="/doctor">
            <FaHome
              className={
                location.pathname === "/doctor" ? "nav-btn-active" : ""
              }
              style={{ width: "48px" }}
            />
          </BottomNavBtn>

          <BottomNavBtn to="/managepatients">
            <FaUserInjured
              className={
                location.pathname === "/managepatients" ? "nav-btn-active" : ""
              }
              style={{ width: "48px" }}
            />
          </BottomNavBtn>
        </DocBottomNav>
        <Outlet />
        {/* <GrowingCircleCanvas isDark={isDarkTheme} /> */}
      </>
    );
  }
}

export default DocNavbar;
