//React imports
import React, { useRef } from "react";
import styled from "styled-components";
import { CenterFlexContainer } from "../../../../Global";

export const DaysContainer = styled(CenterFlexContainer)`
  border-radius: 20px;
  border: 2px solid ${(props) => props.theme.colors.borderColor};
  padding: 0.3rem;
  margin: 0.4rem;
  width: 40px;

  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.curusLightBlue : "#fff"};
  color: ${(props) =>
    props.isSelected ? "#fff" : props.theme.colors.curusLightBlue};
  transition: 0.1s ease-in-out;
  &:hover {
    background-color: ${(props) => props.theme.colors.curusLightBlue};
    color: #fff;
    cursor: pointer;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-size: 14px;
`;
function WeekDayToggle(props) {
  const checkBoxRef = useRef();

  function handleDivClick() {
    checkBoxRef.current.click();
  }
  return (
    <DaysContainer onClick={handleDivClick} isSelected={props.checked}>
      <input
        type="checkbox"
        value={props.value}
        checked={props.checked}
        onChange={props.onchange}
        ref={checkBoxRef}
        className="hidden"
      />
      <Text>{props.weekday}</Text>
    </DaysContainer>
  );
}

export default WeekDayToggle;
