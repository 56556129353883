import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireDocAuth = () => {
  const { docAuth } = useAuth();
  const location = useLocation();

  if (docAuth.AuthenticationResult?.IdToken) {
    return <Outlet />;
  } else {
    console.log("no doc auth going to login");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};
export default RequireDocAuth;
